<template>
    <div :class="{'inline': inline}" class="create-contact">
        <div class="create-contact-header">
            <img :src="require(`../../../images/crmLogos/${crm.smallLogo}`)">
            <span>{{ crm.title }}</span>
        </div>

        <div v-if="offerIsShown" class="create-contact-offer">
            <p>{{ $t("integrations-popup.create-contact.offer") }}</p>

            <base-button
                @click="showCreateContact()"
                :color="'blue'"
                :text="crm.getCreateButtonName()"
                :disabled="!canCreateContact"
            ></base-button>
        </div>

        <div v-if="createContactIsShown" class="create-contact-create">
            <div v-for="field in newContactFields" class="form-group">
                <base-input
                    v-model="field.value"
                    :placeholder="field.label"
                    :label="field.label"
                    :error="Boolean(field.validation.length && field.dirty && !isValidField(field))"
                    type="text"
                    ref="inputField"
                    @input="setDirtyField(field)"
                ></base-input>

                <div v-if="field.validation.length && field.dirty" class="invalid-feedback">
                    <span v-for="val in field.validation" v-if="!val.func(field.value)">{{ $t(val.not_valid_label) }}</span>
                </div>
            </div>

            <div class="buttons-group">
                <base-button
                    v-on="isValid() ? {click: saveClickHandler} : {}"
                    :color="'blue'"
                    :text="'integrations-popup.create-contact.save'"
                    :disabled="Boolean(!isValid() || !canCreateContact)"
                    :loading="loading"
                ></base-button>

                <base-button
                    @click="showOffer"
                    :text="'integrations-popup.create-contact.cancel'"
                ></base-button>
            </div>
        </div>

        <div v-if="failedIsShown" class="create-contact-failed">
            <p>{{ $t("integrations-popup.create-contact.failed") }}</p>
            <base-button
                @click="showCreateContact()"
                :color="'blue'"
                :text="'integrations-popup.create-contact.try-again'"
            ></base-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Crm from "../../../helpers/integrations/crms";
import BaseButton from "../../defaults/BaseButton.vue";
import BaseInput from "../../defaults/BaseInput.vue";
import {isIntegrationEnabled} from "../../../utils/integrations";
import {formatNumber} from "../../../utils/phoneNumbers";

export default {
    name: "createContact",
    components: {BaseInput, BaseButton},
    props: {
        crmType: {
            type: String,
            default: ""
        },
        number: {
            type: String,
            default: ""
        },
        creatingFailed: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newContactFields: [],
            offerIsShown: true,
            createContactIsShown: false,
            failedIsShown: false,
            loading: false,
            crm: {}
        }
    },
    created() {
        this.crm = new Crm().create(this.crmType)
        const phonebookContact = this.getContactByNumber(this.number)
        this.newContactFields = this.crm.getCreateContactFields(formatNumber(this.number), phonebookContact.name, phonebookContact.email)
    },
    watch: {
        creatingFailed(value) {
            this.loading = false
            this.showFailed()
        }
    },
    computed: {
        ...mapGetters('user', ['email', 'crmTenantName', 'isIntegrationsEnabled']),
        ...mapGetters('details', ['getContactByNumber']),
        ...mapGetters('integrations', ['integrations']),

        canCreateContact() {
            return isIntegrationEnabled(this.crmType)
        }
    },
    methods: {
        ...mapActions('integrations', ['createContact', 'getCallInfoExtended', 'getCallInfo']),

        showOffer: function () {
            this.offerIsShown = true
            this.createContactIsShown = false
            this.failedIsShown = false
        },
        showFailed: function () {
            this.offerIsShown = false
            this.createContactIsShown = false
            this.failedIsShown = true
        },
        showCreateContact: function () {
            this.offerIsShown = false
            this.createContactIsShown = true
            this.failedIsShown = false

            this.$nextTick(this.focusFirstEmptyField)
        },
        saveClickHandler: function () {
            if (!this.canCreateContact) {
                return
            }

            let requestBody = {
                "tenant": this.crmTenantName,
                "user_email": this.email
            }

            this.newContactFields.forEach((field) => {
                requestBody[field.name] = field.value
            })

            this.$emit("create-click", {data: requestBody, contactName: this.crm.getNameFromContactCreationFields(this.newContactFields)})

            this.loading = true
        },
        isValid() {
            const notValidFields = this.newContactFields.filter((field) => !this.isValidField(field))
            return notValidFields.length === 0
        },
        isValidField(field) {
            const notValidRules = field.validation.filter((val) => !val.func(field.value))
            return notValidRules.length === 0
        },
        setDirtyField(field) {
          if (field.name == 'contact_phone') {
            field.value = formatNumber(field.value)
          }
            field.dirty = true
        },
        focusFirstEmptyField() {
            if (!this.$refs.inputField) {
                return
            }

            for (let i = 0; i < this.$refs.inputField.length; i++) {
                let field = this.$refs.inputField[i]
                if (!field) {
                    continue
                }

                if (!field.value) {
                    field.focus()
                    break
                }
            }
        }
    }
}
</script>

<style scoped>

</style>