import {mapActions, mapGetters} from "vuex";
import dayjsOverride from "../../helpers/dayjsOverride";
import {canShowIntegrations} from "../../utils/integrations";
import {downloadFile} from "../../utils/DOM";

const voicemailItemTableRowMixin = {
    props: {
        item: {
            type: Object,
            default: {}
        },
        voicemail: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            voicemailMessageUrl: null
        }
    },
    computed: {
        ...mapGetters('details', [ 'getContactByNumber', 'defaultContact', 'getDefaultPhonebook']),
        ...mapGetters('user', ['isIntegrationsEnabled', 'languageCode']),

        contact() {
            return this.getContactByNumber(this.item.callerid)
        },

        date() {
            const dayjsObj = dayjsOverride(this.item.datetime)

            return `${dayjsObj.locale(this.languageCode).format('D MMMM YYYY')} ${dayjsObj.format('LT')}`
        },

        canShowIntegrations() {
            return canShowIntegrations(this.item.callerid)
        },

        downloadFilename() {
            return `${this.voicemail.name}_${this.date}.wav`
        }
    },
    methods: {
        ...mapActions("voicemails", ["getVoicemailMessageFile", "markAsListenedMessages"]),

        getAudioUrl(resolve) {
            if (this.voicemailMessageUrl) {
                resolve(this.voicemailMessageUrl)
                return
            }

            this.getVoicemailMessageFile({
                voicemailId: this.voicemail.id,
                filename: this.item.filename,
                dirname: this.item.dirname
            }).then((blob) => {
                const url = URL.createObjectURL(blob)
                this.voicemailMessageUrl = url
                resolve(url)
            }).catch(() => {})
        },

        onDownloadClick() {
            new Promise((resolve) => {
                this.getAudioUrl(resolve)
            }).then((url) => {
                downloadFile(url, this.downloadFilename)
            })
        },

        onAudioEnded() {
            this.$emit("message-listened")
        }
    }
}

export default voicemailItemTableRowMixin