<template>
    <label class="base-switch" :class="classes">
        <span v-if="preLabel" class="prepend label-text">{{ $te(preLabel) ? $t(preLabel) : preLabel }}</span>

        <input
            @input="onInput"
            @change="onChange"
            :checked="value"
            :disabled="disabled"
            type="checkbox"
        >
        <span class="slider"></span>

        <span v-if="label" class="label-text">{{ $te(label) ? $t(label) : label }}</span>
    </label>
</template>

<script>

export default {
    name: "BaseSwitch",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        preLabel: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        border: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                "base-switch-border": this.border
            }
        }
    },
    methods: {
        onInput() {
            this.$emit('input', !this.value)
        },
        onChange() {
            this.$emit('change')
        }
    }
}
</script>

<style scoped>

</style>