<template>
    <base-tooltip text="internal-number-label.hint">
        <template v-slot:activator>
            <div class="internal-number-label">
                {{ $t("internal-number-label.text") }} {{ extension }}
            </div>
        </template>
    </base-tooltip>
</template>

<script>
import {mapGetters} from "vuex";
import BaseTooltip from "../../defaults/BaseTooltip.vue";

export default {
    name: "InternalNumberLabel",
    components: {BaseTooltip},
    computed: {
        ...mapGetters("user", ["extension"])
    }
}
</script>

<style scoped>

</style>