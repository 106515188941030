<template>
    <div class="crm-info" :class="{'inline': inline}">
        <!-- Need to show information from Zapier only during a call -->
        <transition
            v-if="(existEnabledCrms() && !onlyZapierEnabled() && inline && !isLoading) || (existEnabledCrms() && !inline && !isLoading) || (callInfo(number).length && !isLoading)"
        >
            <div class="crm-info-info" v-bar>
                <div class="crm-info-wrapper">
                    <div class="crm-info-item">
                        <div class="crm-contact-info-wrapper">
                            <slot name="prepend"></slot>
                        </div>
                    </div>

                    <div
                        class="crm-info-item"
                        v-for="item in callInfo(number)"
                        :key="item.crm_type"
                    >
                        <div
                            v-if="item.contacts.length"
                            v-for="contact in item.contacts"
                            :class="{'expanded': isContactExpanded(contact.crm_object_id)}"
                            class="crm-contact-info-wrapper"
                        >
                            <minimized-contact-info
                                @contact-click="expandCrmContact(contact, item.crm_type)"
                                :crm-type="item.crm_type"
                                :contact-name="contact.name"
                                :expanded="isContactExpanded(contact.crm_object_id)"
                                :inline="inline"
                            ></minimized-contact-info>

                            <div v-show="!callInfoExtended(contact.crm_object_id)" class="crm-info-spinner-wrapper">
                                <base-spinner :size="'medium'" :color="'blue'"></base-spinner>
                            </div>

                            <div v-if="callInfoExtended(contact.crm_object_id)" class="crm-contact-expand-wrapper">
                                <expanded-contact-info
                                    :crm-type="item.crm_type"
                                    :general-info="contact"
                                    :extended-info="callInfoExtended(contact.crm_object_id)"
                                    :inline="inline"
                                ></expanded-contact-info>

                                <open-in-crm-button
                                    v-if="contact.link"
                                    :crm-type="item.crm_type"
                                    :link="contact.link"
                                ></open-in-crm-button>

                                <div
                                    v-if="showCallLogBtn"
                                    class="form-group log-call-note"
                                >
                                    <base-textarea
                                        @blur="onInputBlur(item.crm_type, contact)"
                                        @input="inputNote($event, item.crm_type, contact)"
                                        :value="notes(item.crm_type, contact.crm_object_id, number, callStartTimestamp)"
                                        :ref="'note-' + contact.crm_object_id"
                                        placeholder="integrations-popup.notes"
                                        label="integrations-popup.notes"
                                        type="text"
                                    ></base-textarea>
                                </div>

                                <div v-if="showCallLogBtn" class="log-call-btn-wrapper">
                                    <slot name="logCallBtn" :contact="contact" :crmType="item.crm_type">

                                    </slot>
                                </div>
                            </div>
                        </div>

                        <template v-if="!item.contacts.length && !item.error_code">
                            <create-contact
                                @create-click="createContactAndExpand(item.crm_type, $event.data, $event.contactName)"
                                :inline="inline"
                                :crm-type="item.crm_type"
                                :number="number"
                                :creating-failed="contactCreateFailed"
                            ></create-contact>
                        </template>
                    </div>
                </div>
            </div>
        </transition>

        <div v-else-if="isLoading" class="crm-info-spinner-wrapper">
            <base-spinner :size="crmInfoSpinnerSize" :color="'blue'"></base-spinner>
        </div>

        <div v-else :class="{'inline': inline}" class="crm-info-no-enabled-crms">
            <i18n path="integrations-popup.no-active-integrations.open-settings" tag="p">
                <a @click=settingsClick()>{{ $t("integrations-popup.no-active-integrations.settings-click") }}</a>
            </i18n>
        </div>
    </div>
</template>

<script>
import MinimizedContactInfo from "./MinimizedContactInfo.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import ExpandedContactInfo from "./ExpandedContactInfo.vue";
import CreateContact from "./CreateContact.vue";
import Crm from "../../../helpers/integrations/crms";
import {mapActions, mapGetters} from "vuex";
import i18n from "../../../locales";
import {existEnabledCrms, onlyZapierEnabled, isIntegrationEnabled} from "../../../utils/integrations";
import BaseSpinner from "../../defaults/BaseSpinner.vue";
import OpenInCrmButton from "./OpenInCrmButton.vue";
import BaseTextarea from "../../defaults/BaseTextarea.vue";

export default {
    name: "CrmInfo",
    components: {
        BaseTextarea,
        OpenInCrmButton,
        BaseSpinner, MinimizedContactInfo, BaseButton, ExpandedContactInfo, CreateContact},
    props: {
        number: {
            type: String,
            default: ""
        },
        inline: {
            type: Boolean,
            default: false
        },
        showCallLogBtn: {
            type: Boolean,
            default: true
        },
        crmInfoSpinnerSize: {
            type: String,
            default: ""
        },
        customLoading: {
            type: Boolean,
            default: false
        },
        callStartTimestamp: { // need when use call logging
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            expandedContactsIds: [],
            contactCreateFailed: false,
            inputNoteTimeout: null
        }
    },
    created() {
        if (this.number) {
            this.getCrmInfo(this.number).then(() => {
                this.showCollapsedContactsWithExtendedCallInfo()
            }).catch(() => {})
        }
    },
    watch: {
        number() {
            this.getCrmInfo(this.number).catch(() => {})
        },
        isLoading(value) {
            if (!value) {
                this.showCollapsedContactsWithExtendedCallInfo()
            }
        }
    },
    computed: {
        ...mapGetters('integrations', ['callInfo', 'callInfoExtended', 'integrations', 'notes', 'isLoadingCallInfo', 'allCallInfoExtended']),
        ...mapGetters('user', ['crmTenantName', 'email', 'user']),

        isLoading() {
            return this.isLoadingCallInfo(this.number) || this.customLoading
        }
    },
    methods: {
        ...mapActions('integrations', ['getCallInfo', 'getCallInfoExtended', 'createContact', 'resetCallInfo', 'updateNote']),
        ...mapActions(['createAlertMessage', 'changeActiveTab']),

        getCrmInfo: function (number) {
            if (!this.existEnabledCrms() || this.onlyZapierEnabled() || this.isLoadingCallInfo(this.number)) {
                return Promise.reject()
            }

            const callInfo = this.callInfo(number)
            const isCallInfoExists = callInfo.length && callInfo.filter((crm) => crm.contacts.length).length
            if (isCallInfoExists) {
                return Promise.resolve()
            }

            return this.getCallInfo({
                tenant: this.crmTenantName,
                email: this.email,
                number: number
            })
        },

        expandCrmContact: function (contact, crmType) {
            if (!isIntegrationEnabled(crmType)) {
                return
            }

            const id = contact.crm_object_id
            let index = this.expandedContactsIds.indexOf(id)
            if (index !== -1) {
                this.expandedContactsIds.splice(index, 1)
            } else {
                this.expandedContactsIds.push(id)

                if (!this.callInfoExtended(id)) {
                    return this.getCallInfoExtended({contact, crmType})
                        .catch(() => {
                            let index = this.expandedContactsIds.indexOf(id)
                            this.expandedContactsIds.splice(index, 1)
                        })
                }
            }
        },

        showCollapsedContactsWithExtendedCallInfo: function () {
            const contactsIdsWithExtendedCallInfo = Object.keys(this.allCallInfoExtended)
            this.expandedContactsIds = [...new Set([...contactsIdsWithExtendedCallInfo, ...this.expandedContactsIds])]
        },

        createContactAndExpand: function (crmType, data, contactName) {
            this.createContact({crmType: crmType, data: data, contactName: contactName, number: this.number})
                .then((contact) => {
                    if (contact) {
                        this.expandCrmContact(contact, crmType)
                    }
                })
                .catch(() => {
                    this.contactCreateFailed = !this.contactCreateFailed
                })
        },

        isContactExpanded: function (contactId) {
            return this.expandedContactsIds.indexOf(contactId) !== -1
        },

        inputNote: function (value, crmType, contact) {
            this.$emit('input-note', {value, crmType, contact})
        },

        onInputBlur: function (crmType, contact) {
            const inputElement = this.$refs[`note-${contact.crm_object_id}`][0]
            if (!inputElement) {
                return
            }

            this.updateNote({
                crmType,
                contactId: contact.crm_object_id,
                number: this.number,
                timestamp: this.callStartTimestamp,
                value: inputElement.localValue
            })
        },

        settingsClick: function() {
            document.dispatchEvent(new CustomEvent('showIntegrationsSettingsTab'))
            this.$emit('settings-click')
        },

        existEnabledCrms,

        onlyZapierEnabled
    }
}
</script>

<style scoped>

</style>