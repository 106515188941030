<template>
    <div class="settings-menu-item" @click="$emit('click')" :class="{'active': isActive}">
        <div class="settings-menu-item-title">
            <span :class="['icon', iconClass]"></span>
            <span class="name">{{ $t(name) }}</span>
        </div>

        <span class="icon icon-chevron-right"></span>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        iconClass: {
            type: String,
            default: ''
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    name: "SettingsMenuItem",
}
</script>

<style scoped>

</style>