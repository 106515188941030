import {mapActions, mapGetters} from "vuex";
import {sendToElectron} from "../utils/electron";

const DNDSwitcherMixin = {
    data() {
        return {
            isDndLoading: false
        }
    },
    computed: {
        ...mapGetters("user", ["user"]),
        ...mapGetters("softphone", ["getIsRegistered"])
    },
    methods: {
        ...mapActions('user', ['changeIsOnline']),

        toggleDND() {
            if (!this.getIsRegistered) {
                return
            }

            this.isDndLoading = true

            Promise.all([
                Promise.resolve(sendToElectron("setDNDStatus", Boolean(this.user.isOnline))),
                Promise.resolve(this.changeIsOnline(!this.user.isOnline))
            ]).then((values) => {
                this.isDndLoading = false
            }).catch(() => {})
        }
    }
}

export default DNDSwitcherMixin