import {countNumberOfFreeSlots} from "../../utils/callParking";

const callParkingItemMixin = {
    props: {
        callParking: {
            type: Object
        }
    },
    computed: {
        freeSlotsCount() {
            return countNumberOfFreeSlots(this.callParking)
        },

        freeSlotsText() {
            if (this.freeSlotsCount === 1) {
                return this.$t('panel.services.call-parking.free-slot')
            }

            return this.$t('panel.services.call-parking.free-slots', {count: this.freeSlotsCount})
        }
    }
}

export default callParkingItemMixin