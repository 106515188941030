// MESSAGE_LEN - message length
// MESSAGE_LEN_MULTIPART - message length for multipart message
// CONNECT_CHARS_LEN - number of characters needed to combine multiple messages
const ENCODING = Object.freeze({
    UTF16: {
        MESSAGE_LEN: 70,
        MESSAGE_LEN_MULTIPART: 67,
        CONNECT_CHARS_LEN: 3
    },
    GSM_7BIT: {
        MESSAGE_LEN: 160,
        MESSAGE_LEN_MULTIPART: 153,
        CONNECT_CHARS_LEN: 7
    },
    GSM_7BIT_EX: {
        MESSAGE_LEN: 160,
        MESSAGE_LEN_MULTIPART: 153,
        CONNECT_CHARS_LEN: 7
    }
})

const CHARSET = Object.freeze({
    GSM_ESCAPED: '\\^{}\\\\\\[~\\]|€',
    GSM: '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà',
})

const REGEX = Object.freeze({
    GSM: RegExp(`^[${CHARSET.GSM}]*$`),
    GSM_ESCAPED: RegExp(`^[\\${CHARSET.GSM_ESCAPED}]*$`),
    GSM_FULL: RegExp(`^[${CHARSET.GSM}${CHARSET.GSM_ESCAPED}]*$`),
})

export default {
    /**
     * @param text
     * @returns {{maxCharCount: number, numberOfSms: number, remaining: number}}
     */
    calculate(text) {
        let length = text.length;
        const encoding = this._detectEncoding(text)

        if (encoding === ENCODING.GSM_7BIT_EX) {
            length += this._getEscapedCharCount(text)
        }

        return this._getPartData(length, encoding)
    },

    /**
     * Method determines the text encoding
     *
     * @param text
     * @returns {{MESSAGE_LEN: number, CONNECT_CHARS_LEN: number, MESSAGE_LEN_MULTIPART: number}}
     * @private
     */
    _detectEncoding(text) {
        if (text.match(REGEX.GSM)) {
            return ENCODING.GSM_7BIT
        } else if (text.match(REGEX.GSM_FULL)) {
            return ENCODING.GSM_7BIT_EX
        } else {
            return ENCODING.UTF16
        }
    },

    /**
     * Method returns count of escaped chars
     *
     * @param text
     * @returns {number}
     * @private
     */
    _getEscapedCharCount(text) {
        return [...text].reduce((acc, char) => acc + (char.match(REGEX.GSM_ESCAPED) ? 1 : 0), 0)
    },

    /**
     * The method returns the maximum number of characters for a message,
     * the number of messages that will be sent and the number of remaining characters
     *
     * @param totalLength
     * @param encoding
     * @returns {{maxCharCount: number, numberOfSms: number, remaining: number}}
     * @private
     */
    _getPartData(totalLength, encoding) {
        const msgLenMultipartWithoutConnectChars = encoding.MESSAGE_LEN_MULTIPART - encoding.CONNECT_CHARS_LEN
        let maxCharCount = encoding.MESSAGE_LEN_MULTIPART
        let numberOfSms = Math.ceil(totalLength / maxCharCount)
        let remaining = maxCharCount - (totalLength - (encoding.MESSAGE_LEN + msgLenMultipartWithoutConnectChars + (encoding.MESSAGE_LEN_MULTIPART * (numberOfSms - 3))))

        if (totalLength <= encoding.MESSAGE_LEN) {
            maxCharCount = encoding.MESSAGE_LEN
            numberOfSms = 1
            remaining = maxCharCount - totalLength
        } else if (totalLength > encoding[0] && totalLength <= (encoding.MESSAGE_LEN + msgLenMultipartWithoutConnectChars)) {
            maxCharCount = encoding[1]
            numberOfSms = 2
            remaining = maxCharCount - (totalLength - encoding.MESSAGE_LEN)
        }

        return {
            maxCharCount,
            numberOfSms,
            remaining
        };
    }
}