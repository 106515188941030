<template>
    <div class="base-audio-element">
        <audio
            @play="onPlay"
            @pause="onPause"
            @ended="onEnded"
            :src="src"
            ref="audio"
        ></audio>

        <slot :isPause="isPause" :playPause="playPause"></slot>
    </div>
</template>

<script>
export default {
    name: "BaseAudioElement",
    props: {
        src: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isPause: true
        }
    },
    watch: {
        src() {
            this.isPause = true
        }
    },
    methods: {
        playPause() {
            if (!this.$refs.audio) {
                return
            }

            if (this.isPause) {
                this.play()
                return
            }

            this.pause()
        },
        play() {
            this.$refs.audio.play().catch((error) => {
                this.isPause = true
                console.error(error)
            })
        },
        pause() {
            this.$refs.audio.pause()
        },
        onPlay() {
            this.isPause = false
            this.$emit("play")
        },
        onPause() {
            this.isPause = true
            this.$emit("pause")
        },
        onEnded() {
            this.isPause = true
            this.$emit("ended")
        }
    }
}
</script>

<style scoped>

</style>