<template>
    <dial-pad
        @input="onInput"
        :value="value"
        @typedSymbol="onTypedSymbol"
        color="color_dark"
        placeholder="dtmf-dial-pad.placeholder"
    ></dial-pad>
</template>

<script>
import DialPad from "./DialPad.vue";

export default {
    name: "DtmfDialPad",
    components: {DialPad},
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    methods: {
        onTypedSymbol($event) {
            this.$emit('typedSymbol', $event.key)
        },
        onInput(number) {
            this.$emit("input", number)
        }
    }
}
</script>

<style scoped>

</style>