import Api from '../../utils/api';
import i18n from "../../locales";
import SocketIOHelper from "../../helpers/SocketIOHelper";

export default {
    namespaced: true,
    state: {
        callParkings: []
    },
    getters: {
        callParkings: (state) => {
            return state.callParkings
        },
        callParkingDropdownOptions: (state) => {
            const options = state.callParkings.map((callParking) => {
                return {name: callParking.name + ' (' + callParking.first_slot_number + '-' + callParking.last_slot_number + ')', value: callParking.number}
            })

            options.unshift({name: i18n.t("panel.call-history.call-types.all"), value: "all"})

            return options
        },
        getSlot: (state) => (callParkingNumber, slotNumber) => {
            const callParking = state.callParkings.find((callParking) => callParking.number === callParkingNumber)
            if (!callParking) {
                return null
            }

            const slot = callParking.slots.find((slot) => slot.number === slotNumber)
            if (!slot) {
                return null
            }

            return slot
        }
    },
    actions: {
        getCallParkings: (context) => {
            return new Promise((resolve, reject) => {
                Api().get('/call-parkings/get-list')
                .then(response => {
                    context.commit('setCallParkings', response.data)
                    SocketIOHelper.emit("build_call_parkings", response.data.map(a => a.number))
                    resolve()
                })
                .catch(() => reject())
            })
        },
        removeSlot: (context, {callParkingNumber, slotNumber}) => {
            context.commit("removeSlot", {callParkingNumber, slotNumber})
        },
        addSlot: (context, {callParkingNumber, newSlot}) => {
            context.commit("addSlot", {callParkingNumber, newSlot})
        },
    },
    mutations: {
        setCallParkings: (state, callParkings) => {
            state.callParkings = callParkings
        },
        removeSlot: (state, {callParkingNumber, slotNumber}) => {
            const callParking = state.callParkings.find((callParking) => callParking.number === callParkingNumber)
            if (callParking) {
                callParking.slots = callParking.slots.filter((slot) => slot.number !== slotNumber)
            }
        },
        addSlot: (state, {callParkingNumber, newSlot}) => {
            let callParking = state.callParkings.find((callParking) => callParking.number === callParkingNumber)

            if (callParking) {
                let slotExists = callParking.slots.find((slot) => slot.number === newSlot.number)
                if (slotExists) {
                    callParking.slots = callParking.slots.filter((slot) => slot.number !== slotExists.number)
                }

                callParking.slots.push(newSlot)
                callParking.slots.sort((a, b) => a.number - b.number)
            }
        }
    }
}