var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab history-tab",class:{'active': this.active}},[_c('div',{staticClass:"history-wrapper",class:{'no-data': !_vm.callHistory.length}},[(!_vm.loading && (_vm.callHistory.length || _vm.filters.count || _vm.localLoading))?_c('v-app',[_c('v-data-table',{attrs:{"headers":[
                { text: '', align: 'title', value: 'title', sortable: false},
                { text: _vm.$t('panel.call-history.header-type'), align: 'type', value: 'type', sortable: false},
                { text: _vm.$t('panel.call-history.header-line-used'), align: 'callerid', value: 'callerid', sortable: false},
                { text: _vm.$t('panel.call-history.header-duration'), align: 'duration', value: 'duration', sortable: false},
                { text: _vm.$t('panel.call-history.header-date'), align: 'date', value: 'date', sortable: false},
                { text: '', align: 'controls', value: 'controls', sortable: false}
                ],"items":_vm.callHistory,"mobile-breakpoint":0,"options":_vm.options,"items-per-page":15,"hide-default-footer":true,"server-items-length":parseInt(_vm.callHistoryTotalCountRows),"expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var items = ref.items;
                var expand = ref.expand;
                var isExpanded = ref.isExpanded;
return [(!items.length && _vm.filters.count)?_c('div',{staticClass:"no-results"},[_vm._v("\n                        "+_vm._s(_vm.$t("panel.call-history.no-results-found"))+"\n                    ")]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(items.length),expression:"items.length"},{name:"bar",rawName:"v-bar"}],staticClass:"history-exist"},[_c('div',[_c('tbody',_vm._l((items),function(item){return _c('call-history-table-row',{key:item.id,attrs:{"is-expanded":isExpanded(item),"item":item,"call-duration":_vm.callDuration(item.duration),"can-show-integrations":_vm.canShowIntegrations(item.number),"is-extension":_vm.isExtension(item.number)},on:{"expand":function($event){expand(item, !isExpanded(item))}}})}),1)])])]}},{key:"top",fn:function(ref){
                var pagination = ref.pagination;
                var options = ref.options;
                var updateOptions = ref.updateOptions;
return [_c('table-top',{attrs:{"items-per-page":options.itemsPerPage,"pagination":pagination,"options":options,"items-per-page-disabled":""},on:{"updateOptions":updateOptions,"refresh":_vm.updateHistory},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"form-group"},[_c('base-input',{staticClass:"find-by-number",attrs:{"type":"text","placeholder":"panel.call-history.search-placeholder","icon":"icon-search"},on:{"input":_vm.activateSearch},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_vm._v(" "),_c('base-button',{staticClass:"btn-filter",attrs:{"color":'blue',"size":'big',"icon":'icon-filter',"text":'panel.call-history.filters-btn'},on:{"click":function($event){return _vm.toggleFiltersPopup(true)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.filters.count)?_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.filters.count))]):_vm._e()]},proxy:true}],null,true)})],1),_vm._v(" "),_vm._l((_vm.filters.chips),function(chip,index){return _c('chip',{key:index,attrs:{"text":chip.value,"size":'big',"color":'light-blue'},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('base-button',{staticClass:"chip-button-close",attrs:{"size":'small',"color":'white',"icon":'icon-clear',"icon-size":14,"radius":'50%'},on:{"click":function($event){return _vm.removeFilter(chip.name)}}})]},proxy:true}],null,true)})}),_vm._v(" "),(_vm.filtersPopup)?_c('filters-popup',{on:{"close":function($event){return _vm.toggleFiltersPopup(false)},"apply":_vm.filtersApply},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}):_vm._e()]},proxy:true}],null,true)})]}}],null,false,1941322363)})],1):(_vm.loading)?_c('div',[_c('base-spinner',{attrs:{"center":""}})],1):_c('div',{staticClass:"history-not-found"},[_vm._v("\n            "+_vm._s(_vm.$t("panel.call-history.no-calls-message"))+"\n        ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }