const sendToElectron = (funcName, ...payload) => {
    if (window.electronAPI && window.electronAPI[funcName]) {
        return window.electronAPI[funcName](...payload)
    }
    return new Promise(resolve => resolve())
}

const addElectronListener = (funcName, handler) => {
    if (window.electronAPI && window.electronAPI[funcName]) {
        window.electronAPI[funcName](handler)
    }
}

const isElectronExist = () => {
    return Boolean(window.electronAPI)
}

export {sendToElectron, addElectronListener, isElectronExist}