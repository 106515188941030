<template>
    <div class="sms-characters-counter">
        <span
            v-if="isReachedMaxSmsCount"
            class="sms-characters-counter-limit-exceeded"
        >
            {{ $t("sms-characters-counter.limit-exceeded") }}
        </span>

        <template v-else>
            <span>
                SMS: {{ calculatedData.numberOfSms }}
            </span>

            <span>
                Left: {{ calculatedData.remaining }}
            </span>
        </template>
    </div>
</template>

<script>
import SmsLengthCalculator from "../../../helpers/SmsLengthCalculator";

const MAX_SMS_COUNT = 4

export default {
    name: "SmsCharactersCounter",
    props: {
        text: {
            type: String,
            default: ""
        }
    },
    watch: {
        isReachedMaxSmsCount(value) {
            this.$emit("update:is-reached-max-sms-count", value)
        }
    },
    computed: {
        calculatedData() {
            return SmsLengthCalculator.calculate(this.text)
        },
        isReachedMaxSmsCount() {
            return this.calculatedData.numberOfSms >= MAX_SMS_COUNT
        }
    }
}
</script>

<style scoped>

</style>