<template>
    <div class="date-picker" ref="datePicker">
        <div class="date-picker-content">
            <div class="presets-wrapper">
                <date-picker-shortcuts @select="selectShortcut" :day-range="dayRange" :now="now"></date-picker-shortcuts>
            </div>
            <div class="calendar">
                <div v-if="shownMonthsPicker || shownDaysPicker" class="calendar-header">
                    <base-button
                        @click="clickPrevDate"
                        :size="'small'"
                        :color="'gray'"
                        :icon="'icon-chevron-left'"
                    ></base-button>

                    <div @click="clickSelectedDate" class="selected-date">
                        <span v-if="shownDaysPicker" class="month">{{ monthName }}</span>
                        <span class="year">{{ selectedDate.year() }}</span>
                    </div>

                    <base-button
                        @click="clickNextDate"
                        :size="'small'"
                        :color="'gray'"
                        :icon="'icon-chevron-right'"
                    ></base-button>
                </div>

                <date-picker-years v-if="shownYearsPicker" :day-range="dayRange" :now="now" @select="selectYear"></date-picker-years>
                <date-picker-months v-if="shownMonthsPicker" :day-range="dayRange" :selected-date="selectedDate" :now="now" @select="selectMonth"></date-picker-months>
                <date-picker-days v-if="shownDaysPicker" :day-range="dayRange" :selected-date="selectedDate" :now="now" @select="updateFooterRange"></date-picker-days>
            </div>
            <div class="range">
                <span class="date">{{ rangeFrom }}</span>
                <template v-if="rangeTo.length">
                    <span class="icon icon-chevron-right"></span>
                    <span class="date">{{ rangeTo }}</span>
                </template>
            </div>
        </div>
        <div class="date-picker-footer">
            <div class="buttons">
                <base-button
                    @click="clickApply"
                    :color="'blue'"
                    :disabled="!dayRange.rangeIsSet()"
                    :text="'date-picker.apply'"
                ></base-button>

                <base-button
                    @click="close"
                    :text="'date-picker.cancel'"
                ></base-button>
            </div>
        </div>
    </div>
</template>

<script>
import DatePickerShortcuts from "./DatePickerShortcuts.vue";
import DatePickerYears from "./DatePickerYears.vue";
import DatePickerMonths from "./DatePickerMonths.vue";
import DatePickerDays from "./DatePickerDays.vue";

import DayRange from "../../../helpers/DayRange";
import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"
import weekday from "dayjs/plugin/weekday"
import isBetween from "dayjs/plugin/isBetween"
import utc from "dayjs/plugin/utc"
import en from "dayjs/locale/en"
import fr from "dayjs/locale/fr"
import de from "dayjs/locale/de"
import it from "dayjs/locale/it"
import pt from "dayjs/locale/pt"
import es from "dayjs/locale/es"
import ru from "dayjs/locale/ru"
import BaseButton from "../BaseButton.vue";

const YEARS_PICKER = "years"
const MONTHS_PICKER = "months"
const DAYS_PICKER = "days"

export default {
    name: "DatePicker",
    components: {BaseButton, DatePickerDays, DatePickerMonths, DatePickerYears, DatePickerShortcuts},
    props: {
        value: {
            type: DayRange,
            default: null
        }
    },
    data() {
        return {
            rangeFrom: '',
            rangeTo: '',
            selectedDate: null,
            now: null,
            currentPicker: DAYS_PICKER,
            dayRange: this.value
        }
    },
    created() {
        dayjs.extend(weekday)
        dayjs.extend(localeData)
        dayjs.extend(isBetween)
        dayjs.extend(utc)

        const lng = this.$i18n.locale
        this.now = dayjs().locale(lng)
        this.selectedDate = this.now
        if (this.dayRange && this.dayRange.rangeIsSet()) {
            this.dayRange = new DayRange(this.dayRange.firstDay, this.dayRange.lastDay)
            this.selectedDate = this.selectedDate.set('year', this.dayRange.firstDay.year())
            this.selectedDate = this.selectedDate.set('month', this.dayRange.firstDay.month())
        } else {
            this.dayRange = new DayRange(this.now.startOf('month'), this.now.endOf('day'))
        }
        this.updateFooterRange()
    },
    watch: {
        '$i18n.locale': function() {
            const lng = this.$i18n.locale
            this.now = this.now.locale(lng)
            this.selectedDate = this.selectedDate.locale(lng)
        }
    },
    computed: {
        monthName() {
            const monthsNames = this.now.localeData().months()
            return monthsNames[this.selectedDate.month()]
        },
        shownDaysPicker() {
            return this.currentPicker === DAYS_PICKER
        },
        shownMonthsPicker() {
            return this.currentPicker === MONTHS_PICKER
        },
        shownYearsPicker() {
            return this.currentPicker === YEARS_PICKER
        }
    },
    methods: {
        selectYear(year) {
            this.selectedDate = this.selectedDate.set('year', year)
            this.currentPicker = MONTHS_PICKER
        },
        selectMonth(month) {
            this.selectedDate = this.selectedDate.set('month', month)
            this.currentPicker = DAYS_PICKER
        },
        updateFooterRange() {
            const formattedDays = this.dayRange.getFormattedDays()
            this.rangeFrom = formattedDays.first_day
            this.rangeTo = formattedDays.last_day
        },
        clickPrevDate() {
            if (this.shownMonthsPicker) {
                this.selectedDate = this.selectedDate.subtract(1, 'year')
            }

            if (this.shownDaysPicker) {
                this.selectedDate = this.selectedDate.subtract(1, 'month')
            }
        },
        clickNextDate() {
            if (this.shownMonthsPicker) {
                this.selectedDate = this.selectedDate.add(1, 'year')
            }

            if (this.shownDaysPicker) {
                this.selectedDate = this.selectedDate.add(1, 'month')
            }
        },
        clickSelectedDate() {
            if (this.shownMonthsPicker) {
                this.currentPicker = YEARS_PICKER
            }

            if (this.shownDaysPicker) {
                this.currentPicker = MONTHS_PICKER
            }
        },
        clickApply() {
            this.$emit('input', new DayRange(this.dayRange.firstDay.startOf('day'), this.dayRange.lastDay.endOf('day')))
            this.close()
        },
        selectShortcut(event) {
            this.dayRange = new DayRange(event.from, event.to)
            this.selectedDate = this.selectedDate.set('year', this.dayRange.firstDay.year())
            this.selectedDate = this.selectedDate.set('month', this.dayRange.firstDay.month())
            this.updateFooterRange()
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>