import i18n from "../locales"
import DayRange from "./DayRange";
import store from '../store'

const PARAM_NAME_PAGE = "page"
const PARAM_NAME_ITEMS_PER_PAGE = "items_per_page"
const PARAM_NAME_DATE_RANGE = "date_range"
const PARAM_NAME_RANGE_FROM = "range_from"
const PARAM_NAME_RANGE_TO = "range_to"
const PARAM_NAME_CALL_TYPE = "call_type"
const PARAM_NAME_SEARCH = "search"
const PARAM_NAME_INTERNALS_ONLY = "internals_only"

/**
 * Class that represent filters for call history
 */
export default class HistoryFilters {

    /**
     * @param {null|number} page
     * @param {null|number} itemsPerPage
     * @param {null|DayRange} dayRange
     * @param {null|string} callType
     * @param {null|string} search
     * @param {null|boolean} internalsOnly
     */
    constructor({page, itemsPerPage, dayRange, callType, search, internalsOnly}) {
        this._page = page ? page : 1
        this._itemsPerPage = itemsPerPage ? itemsPerPage : 15
        this._dayRange = dayRange ? dayRange : null
        this._callType = callType ? callType : ''
        this._search = search ? search : ''
        this._internalsOnly = internalsOnly ? internalsOnly : false
    }

    /**
     * @returns {number|null}
     */
    get page() {
        return this._page
    }

    /**
     * @returns {number|null}
     */
    get itemsPerPage() {
        return this._itemsPerPage
    }

    /**
     * @returns {null|DayRange}
     */
    get dayRange() {
        return this._dayRange
    }

    /**
     * @returns {null|string}
     */
    get callType() {
        return this._callType
    }

    /**
     * @returns {null|string}
     */
    get search() {
        return this._search
    }

    /**
     * @returns {null|boolean}
     */
    get internalsOnly() {
        return this._internalsOnly
    }

    /**
     * @param {number|null} value
     */
    set page(value) {
        this._page = value
    }

    /**
     * @param {number|null} value
     */
    set itemsPerPage(value) {
        this._itemsPerPage = value
    }

    /**
     * @param {DayRange|null} value
     */
    set dayRange(value) {
        this._dayRange = value
    }

    /**
     * @param {string|null} value
     */
    set callType(value) {
        this._callType = value
    }

    /**
     * @param {string|null} value
     */
    set search(value) {
        this._search = value
    }

    /**
     * @param {boolean|null} value
     */
    set internalsOnly(value) {
        this._internalsOnly = value
    }

    /**
     * Returns sets of small text info displaying filters that have been set.
     * @returns {[{name: (string), value: (any)}]}
     */
    get chips() {
        let result = []

        if (this.dayRange) {
            const formattedDays = this.dayRange.getFormattedDays()
            result.push({
                name: PARAM_NAME_DATE_RANGE,
                value: i18n.t("panel.call-history.installed-filters.date", {firstDay: formattedDays.first_day, lastDay: formattedDays.last_day})
            })
        }

        if (this.callType) {
            result.push({
                name: PARAM_NAME_CALL_TYPE,
                value: i18n.t("panel.call-history.installed-filters.call-type", {type: i18n.t(store.getters["details/callTypes"][this.callType])})
            })
        }

        if (this.search) {
            result.push({
                name: PARAM_NAME_SEARCH,
                value: i18n.t("panel.call-history.installed-filters.search", {search: this.search})
            })
        }

        if (this.internalsOnly) {
            result.push({
                name: PARAM_NAME_INTERNALS_ONLY,
                value: i18n.t("panel.call-history.installed-filters.internals-only")
            })
        }

        return result
    }

    /**
     * @returns {{}}
     */
    get requestParams() {
        let result = {}

        result[PARAM_NAME_PAGE] = this.page
        result[PARAM_NAME_ITEMS_PER_PAGE] = this.itemsPerPage

        if (this.dayRange) {
            const formattedDays = this.dayRange.getUTCFormattedDays()
            result[PARAM_NAME_RANGE_FROM] = formattedDays.first_day
            result[PARAM_NAME_RANGE_TO] = formattedDays.last_day
        }

        if (this.callType) {
            result[PARAM_NAME_CALL_TYPE] = this.callType
        }

        if (this.search) {
            result[PARAM_NAME_SEARCH] = this.search
        }

        if (this.internalsOnly) {
            result[PARAM_NAME_INTERNALS_ONLY] = 1
        }

        return result
    }

    /**
     * @returns {number}
     */
    get count() {
        let result = 0

        if (this.dayRange) {
            result += 1
        }

        if (this.callType) {
            result += 1
        }

        if (this.search) {
            result += 1
        }

        if (this.internalsOnly) {
            result += 1
        }

        return result
    }

    /**
     * @param {string} name
     */
    removeFilter(name) {
        if (name === PARAM_NAME_DATE_RANGE) {
            this.dayRange = null
        }

        if (name === PARAM_NAME_CALL_TYPE) {
            this.callType = ''
        }

        if (name === PARAM_NAME_SEARCH) {
            this.search = ''
        }

        if (name === PARAM_NAME_INTERNALS_ONLY) {
            this.internalsOnly = false
        }
    }
}