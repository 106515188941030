<template>
    <div class="softphone-state state-outgoing multiple-calls" v-if="getPrimarySession">
        <div class="person-collapsed-wrapper">
            <person-collapsed
                :session="getPrimarySession"
            ></person-collapsed>

            <person-collapsed
                :session="getSecondSession"
                status="outgoing"
            ></person-collapsed>
        </div>

        <buttons-group-manage-call
            :mute-btn="{show: true, disabled: !getIsAddCall, active: getIsAddCall && getIsMuted}"
            :pause-btn="{show: !getIsAddCall, disabled: true}"
            :add-call-btn="{show: !getIsAddCall, disabled: true}"
            :keypad-btn="{show: true, disabled: true}"
            :record-btn="{show: true, disabled: true}"
            :transfer-btn="{show: false, disabled: true}"
            :attended-transfer-btn="{show: true, disabled: true}"
            :swap-btn="{show: getIsAddCall, disabled: true}"
            :merge-btn="{show: getIsAddCall, disabled: true}"
            :class="{'attended-transfer-buttons': getIsAttendedTransfer}"
            @pressMute="mute"
        ></buttons-group-manage-call>
        <div class="active-call-buttons-wrapper">
            <call-button @press="hangUp(getSecondSession.id)" buttonClass="hangup-call-button" iconClass="icon-call-end"></call-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "../../../defaults/Avatar.vue";
import ButtonsGroupManageCall from "../../buttonsGroup/ButtonsGroupManageCall.vue";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import CallButton from "../../base/CallButton.vue";
import {formatNumber} from "../../../../utils/phoneNumbers";
import Person from "../../base/Person.vue";

export default {
    name: "SoftphoneStateOutgoingMultiple",
    components: {Person, CallButton, ButtonsGroupManageCall, Avatar, PersonCollapsed},
    computed: {
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession',
            'getIsAttendedTransfer',
            'getIsMuted',
            'getIsAddCall'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'mute'
        ]),
        formatNumber
    }
}
</script>

<style scoped>

</style>