<template>
    <tr
        v-click-outside="() => toggleControlsDropdown(false)"
        :class="{'expanded': isExpanded}"
        class="phonebooks-table-row"
        @mouseover="isHover = true"
        @mouseleave="isHover = false"
    >
        <td class="text-name" :class="{'all-phonebooks': isAllPhonebooks}">
            <Avatar :src="item.avatar" :text="item.name" :color="item.color" :show-status="isExtension" :status="item.status"></Avatar>
            <base-tooltip v-model="copyButton.isCopiedName" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
                <template v-slot:activator>
                    <div class="name-wrapper">
                        <span class="contact-name">{{item.name}}</span>
                        <span class="phonebook-name">{{ phonebookName }}</span>
                    </div>
                </template>
            </base-tooltip>
            <copy-button v-if="isHover" @click="[copyValue(item.name), showTooltip(copyButton, 'isCopiedName')]"></copy-button>
        </td>
        <td class="text-number">
            <base-tooltip v-model="copyButton.isCopiedNumber" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
                <template v-slot:activator>
                    <div class="entry-number" @click="speedDial(item.number)">
                        <span class="phone-icon icon-call"></span>
                        <span class="phone-number">{{ formattedNumber }}</span>
                    </div>
                </template>
            </base-tooltip>
            <copy-button v-if="isHover" @click="[copyNumber(item.number), showTooltip(copyButton, 'isCopiedNumber')]"></copy-button>
        </td>
        <td class="text-controls">
            <base-button
                v-if="canShowIntegrations || !isReadOnlyPhonebook"
                @click="toggleControlsDropdown(true)"
                :size="'medium'"
                :color="'table'"
                :icon="'icon-more'"
                class="entry-more-button"
            ></base-button>
            <div class="entry-controls" :class="{'active': isExpandControlsDropdown}">
                <base-button
                    v-if="canShowIntegrations"
                    @click="onExpandClick"
                    :size="'medium'"
                    :color="isExpanded ? 'dark-gray' : 'table'"
                    :icon="'icon-join-right-black'"
                ></base-button>
                <base-button
                    v-if="!isReadOnlyPhonebook"
                    @click="editContact(item)"
                    :size="'medium'"
                    :color="'table'"
                    :icon="'icon-edit'"
                ></base-button>
                <base-button
                    v-if="!isReadOnlyPhonebook"
                    @click="deleteContact(item)"
                    :size="'medium'"
                    :color="'table'"
                    icon="icon-delete-forever"
                    class="delete-contact-btn"
                ></base-button>
            </div>
        </td>
        <td v-if="canShowIntegrations" class="integrations-expanded">
            <integrations-inline v-if="isExpanded" :allow-call-logging="false" :number="item.number"></integrations-inline>
        </td>
    </tr>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import IntegrationsInline from "../../integrations/IntegrationsInline.vue";
import {isExtension, formatNumber} from "../../../utils/phoneNumbers";
import {speedDial} from "../../../utils/softphone";
import {canShowIntegrations} from "../../../utils/integrations";
import {showEditContactPopup, showDeleteContactPopup} from "../../../utils/phonebooks";
import {mapGetters} from "vuex";
import CopyButton from "../../defaults/CopyButton.vue";
import {copyValue, showTooltip, copyNumber} from "../../../utils/copy";
import BaseTooltip from "../../defaults/BaseTooltip.vue";

export default {
    name: "PhonebooksTableRow",
    components: {IntegrationsInline, BaseButton, Avatar, CopyButton, BaseTooltip},
    props: {
        item: {
            type: Object
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        isAllPhonebooks: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isExpandControlsDropdown: false,
            copyButton: {
                'isCopiedName': false,
                'isCopiedNumber': false
            },
            isHover: false
        }
    },
    computed: {
        ...mapGetters("details", ["getIsReadOnlyPhonebook", "phonebooks"]),

        formattedNumber() {
            return formatNumber(this.item.number)
        },

        phonebookName() {
            let phonebook = this.phonebooks.find(phonebook => phonebook.id === this.item.phonebook_id)

            if (phonebook) {
                return phonebook.name
            }

            return ''
        },

        isReadOnlyPhonebook() {
            return this.getIsReadOnlyPhonebook(this.item.phonebook_id)
        },

        canShowIntegrations() {
            return canShowIntegrations(this.item.number)
        },

        isExtension() {
            return isExtension(this.item.number)
        }
    },
    methods: {
        onExpandClick() {
            this.toggleControlsDropdown(false)
            this.$emit("expand")
        },

        editContact (contact) {
            this.toggleControlsDropdown(false)
            showEditContactPopup(contact)
        },

        deleteContact (contact) {
            this.toggleControlsDropdown(false)
            showDeleteContactPopup(contact)
        },

        toggleControlsDropdown(value) {
            this.isExpandControlsDropdown = value
        },

        speedDial,
        copyValue,
        showTooltip,
        copyNumber
    }
}
</script>

<style scoped>

</style>