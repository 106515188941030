<template>
    <base-tabs
        v-model="currentTab"
        :tabs="tabs"
        class="tab services-tab" :class="{'active': this.active}"
    >
        <template v-slot:tab_header_voicemail>
            <red-dot v-if="isUnreadVoicemailMessagesExists" class="unread-voicemails"></red-dot>
        </template>

        <template v-slot:queue_membership>
            <queue-membership></queue-membership>
        </template>

        <template v-slot:voicemail>
            <voicemails></voicemails>
        </template>

        <template v-slot:call_parking>
            <call-parking></call-parking>
        </template>

        <template v-slot:tab_header_tooltip>
            <base-tooltip text="panel.services.page-tooltip" :width="250" position="bottom"></base-tooltip>
        </template>
    </base-tabs>
</template>

<script>
import BaseTabs from "../../defaults/BaseTabs.vue";
import QueueMembership from "./queueMembership/QueueMembership.vue";
import RedDot from "../../defaults/RedDot.vue";
import Voicemails from "./voicemails/Voicemails.vue";
import {mapGetters} from "vuex";
import CallParking from "./callParking/CallParking.vue";
import BaseTooltip from "../../defaults/BaseTooltip.vue";

const QUEUE_MEMBERSHIP_TAB_NAME = "queue_membership"
const VOICEMAIL_TAB_NAME = "voicemail"
const CALL_PARKING_TAB_NAME = "call_parking"

export default {
    name: "Services",
    components: {CallParking, Voicemails, RedDot, QueueMembership, BaseTabs, BaseTooltip},
    props: {
        active: {
            type: Boolean,
            default: false
        },
        windowWidth: Number
    },
    data() {
        return {
            currentTab: ""
        }
    },
    created() {
        if (this.isUnreadVoicemailMessagesExists) {
            this.currentTab = VOICEMAIL_TAB_NAME
        } else {
            this.currentTab = this.defaultServicesPage
        }
    },
    watch: {
        defaultServicesPage(value) {
            this.currentTab = value
        }
    },
    computed: {
        ...mapGetters("user", ["isUnreadVoicemailMessagesExists", "defaultServicesPage"]),

        tabs() {
            return [
                {name: QUEUE_MEMBERSHIP_TAB_NAME, text: this.$t("panel.services.queue-membership.title")},
                {name: CALL_PARKING_TAB_NAME, text: this.$t("panel.services.call-parking.title")},
                {name: VOICEMAIL_TAB_NAME, text: this.$t("panel.services.voicemail.title")}
            ]
        }
    }
}
</script>

<style scoped>

</style>