<template>
    <div class="softphone-state state-active-multiple">
        <div v-if="!showKeypad" class="person-collapsed-wrapper" :class="{'union': window.width <= 1280 || window.height <= 875}">
            <person-collapsed
                v-for="session in getSessions"
                :key="session.id"
                :session="session"
            ></person-collapsed>
        </div>
        <div v-if="showKeypad" class="person-collapsed-wrapper">
            <person-collapsed
                :session="getPrimarySession"
            ></person-collapsed>
        </div>

        <buttons-group-manage-call
            v-show="!showKeypad"
            :mute-btn="{show: true, active: getIsMuted}"
            :add-call-btn="{show: false}"
            :pause-btn="{show: false}"
            :swap-btn="{show: true}"
            :merge-btn="{show: true}"
            :transfer-btn="{show: true, disabled: true}"
            :record-btn="{show: true, disabled: !getIsCallRecordingAllowed, active: getPrimarySession.isRecording, tooltip: true}"
            @pressMute="mute"
            @pressSwap="swapHoldSession"
            @pressKeypad="toggleKeypad"
            @pressMerge="mergeCalls"
            @pressRecord="record"
        ></buttons-group-manage-call>

        <div v-if="showKeypad" class="active-call-keypad-wrapper">
            <dtmf-dial-pad v-model="dtmfModel" @typedSymbol="sendDTMF"></dtmf-dial-pad>
        </div>
        <div class="active-call-buttons-wrapper">
            <call-button v-show="showKeypad" @press="toggleKeypad" buttonClass="hide-keypad-button" iconClass="icon-arrow-left" :label="$t('softphone.go-back-button')"></call-button>
            <call-button @press="hangUp(getPrimarySession.id)" buttonClass="hangup-call-button" iconClass="icon-call-end"></call-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import ButtonsGroupManageCall from "../../buttonsGroup/ButtonsGroupManageCall.vue";
import CallButton from "../../base/CallButton.vue";
import DtmfDialPad from "../../base/DtmfDialPad.vue";

export default {
    name: "SoftphoneStateActiveAddCall",
    components: {DtmfDialPad, CallButton, ButtonsGroupManageCall, PersonCollapsed},
    data() {
        return {
            showKeypad: false,
            dtmfModel: '',
            window: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }
    },
    created() {
        window.addEventListener("resize", this.resizeWindow);
    },
    mounted() {
        document.addEventListener('keyup', this.updateDTMFModel)
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindow);
        document.removeEventListener('keyup', this.updateDTMFModel)
    },
    computed: {
        ...mapGetters('softphone', [
            'getIsMuted',
            'getSessions',
            'isInCall',
            'getPrimarySession',
            'getIsCallRecordingAllowed'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'mute',
            'swapHoldSession',
            'hangUp',
            'sendDTMF',
            'mergeCalls',
            'record'
        ]),

        toggleKeypad() {
            this.showKeypad = !this.showKeypad;
        },
        resizeWindow() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        updateDTMFModel(event) {
            const activeElement = document.activeElement
            if (this.isInCall) {
                if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
                    return
                }
                this.dtmfModel += event.key
            }
        },
    }
}
</script>

<style scoped>

</style>