import AbstractCrm from "./AbstractCrm";
import Api from "../../../../utils/api";
import store from '../../../../store'
import HistoryFilters from "../../../HistoryFilters";
import DayRange from "../../../DayRange";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import {getPhoneNumberDigitsOnly} from "../../../../utils/phoneNumbers";

const CRM_TITLE = "Zapier"
const CRM_SMALL_LOGO = "zapier.svg"
const CRM_BIG_LOGO = "zapier_big.svg"

const CALL_STARTED_TRIGGER_NAME = "call_started"
const CALL_ANSWERED_TRIGGER_NAME = "call_answered"
const CALL_ENDED_TRIGGER_NAME = "call_ended"

const CALL_DIRECTION_INCOMING = "incoming"
const CALL_DIRECTION_OUTGOING = "outgoing"
const CALL_DIRECTION_ALL = "all"

const CALL_STATUS_ANSWERED = "answered"
const CALL_STATUS_UNANSWERED = "unanswered"
const CALL_STATUS_ALL = "all"

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Class that represent Zapier CRM
 */
export default class ZapierCrm extends AbstractCrm {

    /**
     * @param {string} type
     */
    constructor({type}) {
        super({
            type,
            smallLogo: CRM_SMALL_LOGO,
            bigLogo: CRM_BIG_LOGO,
            title: CRM_TITLE
        })
    }

    /**
     * The function sends a request to /trigger_zapier_callstarted
     *
     * @param {string} phoneNumber
     * @param {string} callDirection
     * @param {string} agentPhoneNumber
     * @param {number} timestamp
     * @returns {Promise}
     */
    triggerCallStarted(phoneNumber, callDirection, agentPhoneNumber, timestamp) {
        if (!this._isTriggerEnabled(CALL_STARTED_TRIGGER_NAME, callDirection)) {
            return new Promise(resolve => resolve())
        }

        return new Promise((resolve, reject) => {
            Api().post("/integrations/trigger_zapier_callstarted", {
                tenant: store.getters['user/crmTenantName'],
                user_email: store.getters['user/email'],
                phone_number: phoneNumber,
                call_direction: callDirection,
                agent_phone_number: agentPhoneNumber,
                agent_full_name: store.getters['user/userName'],
                timestamp: this._formatTimestamp(timestamp)
            }).then((response) => {
                resolve(response.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    /**
     * The function sends a request to /trigger_zapier_callanswered
     *
     * @param {string} phoneNumber
     * @param {string} callDirection
     * @param {string} agentPhoneNumber
     * @param {number} startTimestamp
     * @param {number} answerTimestamp
     * @returns {Promise}
     */
    triggerCallAnswered(phoneNumber, callDirection, agentPhoneNumber, startTimestamp, answerTimestamp) {
        if (!this._isTriggerEnabled(CALL_ANSWERED_TRIGGER_NAME, callDirection)) {
            return new Promise(resolve => resolve())
        }

        return new Promise((resolve, reject) => {
            Api().post("/integrations/trigger_zapier_callanswered", {
                tenant: store.getters['user/crmTenantName'],
                user_email: store.getters['user/email'],
                phone_number: phoneNumber,
                call_direction: callDirection,
                agent_phone_number: agentPhoneNumber,
                agent_full_name: store.getters['user/userName'],
                start_timestamp: this._formatTimestamp(startTimestamp),
                answer_timestamp: this._formatTimestamp(answerTimestamp)
            }).then((response) => {
                resolve(response.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    /**
     * The function sends a request to /trigger_zapier_callended
     *
     * @param {string} displayPhoneNumber
     * @param {string} phoneNumber
     * @param {string} callDirection
     * @param {string} agentPhoneNumber
     * @param {number} startTimestamp
     * @param {number} answerTimestamp
     * @param {number} finishTimestamp
     * @param {string} callStatus
     * @returns {Promise}
     */
    async triggerCallEnded(displayPhoneNumber, phoneNumber, callDirection, agentPhoneNumber, startTimestamp, answerTimestamp, finishTimestamp, callStatus) {
        if (!this._isTriggerEnabled(CALL_ENDED_TRIGGER_NAME, callDirection, callStatus)) {
            return new Promise(resolve => resolve())
        }

        const data = {
            tenant: store.getters['user/crmTenantName'],
            user_email: store.getters['user/email'],
            phone_number: displayPhoneNumber,
            call_direction: callDirection,
            agent_phone_number: agentPhoneNumber,
            agent_full_name: store.getters['user/userName'],
            start_timestamp: this._formatTimestamp(startTimestamp),
            finish_timestamp: this._formatTimestamp(finishTimestamp),
            call_status: callStatus
        }

        if (answerTimestamp) {
            data.answer_timestamp = this._formatTimestamp(answerTimestamp)
        }

        return new Promise((resolve, reject) => {
            Api().post("/integrations/trigger_zapier_callended", data).then((response) => {
                resolve(response.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    /**
     * Checks if the trigger is enabled
     *
     * @param {string} triggerName
     * @param {string} callDirection
     * @param {string} callStatus
     * @returns {boolean}
     * @private
     */
    _isTriggerEnabled(triggerName, callDirection, callStatus= "") {
        let triggerEnabled = false

        const subscription = store.getters['integrations/zapierSubscriptions'].find((subscription) => {
            return subscription.trigger_name === triggerName && subscription.active
        })

        if (!subscription) {
            return triggerEnabled
        }

        if (subscription.trigger_name === CALL_STARTED_TRIGGER_NAME &&
            (subscription.options.call_direction === CALL_DIRECTION_ALL || subscription.options.call_direction === callDirection)) {
            triggerEnabled = true
        }

        if (subscription.trigger_name === CALL_ANSWERED_TRIGGER_NAME &&
            (subscription.options.call_direction === CALL_DIRECTION_ALL || subscription.options.call_direction === callDirection)) {
            triggerEnabled = true
        }

        if (subscription.trigger_name === CALL_ENDED_TRIGGER_NAME) {
            if (subscription.options.call_direction === callDirection && subscription.options.call_status === CALL_STATUS_ALL) {
                triggerEnabled = true
            }

            if (subscription.options.call_direction === CALL_DIRECTION_ALL && subscription.options.call_status === callStatus) {
                triggerEnabled = true
            }

            if (subscription.options.call_direction === callDirection && subscription.options.call_status === callStatus) {
                triggerEnabled = true
            }

            if (subscription.options.call_direction === CALL_DIRECTION_ALL && subscription.options.call_status === CALL_STATUS_ALL) {
                triggerEnabled = true
            }
        }

        return triggerEnabled
    }

    /**
     * Returns a formatted date
     *
     * @param {int} timestamp
     *
     * @returns {string}
     *
     * @private
     */
    _formatTimestamp(timestamp) {
        return dayjs(timestamp).tz(store.getters["user/timezone"]).format('YYYY-MM-DDTHH:mm:ssZ')
    }
}

export {CALL_DIRECTION_INCOMING, CALL_DIRECTION_OUTGOING, CALL_STATUS_ANSWERED, CALL_STATUS_UNANSWERED}