<template>
    <div class="base-sortable-list" ref="sortableList">
        <div
            v-for="(item, idx) in localValue"
            @dragstart="onDragStart($event, idx)"
            @dragend="onDragEnd"
            @dragover.prevent="onDragOver($event, idx)"
            @drop="onDrop"
            :ref="'sortableListItem-' + idx"
            :key="item.id"
            :class="{'selected': idx === currentDragItemIdx}"
            draggable="true"
            class="base-sortable-list-item"
        >
            <slot name="listItem" :item="item">

            </slot>
        </div>
    </div>
</template>

<script>

export default {
    name: "BaseSortableList",
    props: {
        value: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            currentDragItemIdx: null,
            localValue: []
        }
    },
    created() {
        this.localValue = this.value
    },
    watch: {
        value() {
            this.localValue = this.value
        }
    },
    methods: {
        onDragStart(event, idx) {
            // need to update currentDragItemIdx after dragStart event finishes because need to hide draggable element
            window.requestAnimationFrame(() => {
                this.currentDragItemIdx = idx
            })
        },
        onDragEnd() {
            this.currentDragItemIdx = null
        },
        onDragOver(event, idx) {
            if (idx === this.currentDragItemIdx || typeof this.currentDragItemIdx !== 'number') {
                return
            }

            const element = this.$refs['sortableListItem-' + idx][0]
            const rect = element.getBoundingClientRect()
            const elementCenterPosition = rect.top + (rect.height / 2)
            // check that the element is in the drop zone
            if (event.clientY <= elementCenterPosition - 5 || event.clientY >= elementCenterPosition + 5) {
                return
            }

            event.dataTransfer.dropEffect = 'move'

            const newItems = this.localValue.map(item => item)
            // move element from this.currentDragItemIdx to idx
            newItems.splice(idx, 0, newItems.splice(this.currentDragItemIdx, 1)[0])

            this.currentDragItemIdx = idx
            this.localValue = newItems
        },
        onDrop() {
            this.$emit('input', this.localValue)
        }
    }
}
</script>

<style scoped>

</style>