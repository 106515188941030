<template>
    <div class="base-popup-wrapper">
        <MountingPortal :mountTo="mountTo" append>
            <div :class="popupClass" class="base-popup">
                <div v-if="blackout" class="blackout" @click="close"></div>
                <div :style="styles" :class="classes" class="base-popup-content">
                    <div :class="headerClasses" class="base-popup-header">
                        <slot name="headerPrepend"></slot>

                        <div class="title">
                            <span>{{ $te(title) ? $t(title) : title }}</span>
                        </div>

                        <base-button
                            @click="close"
                            :size="'small'"
                            :color="'gray'"
                            :icon="'icon-clear'"
                            class="close-button"
                        ></base-button>
                    </div>

                    <slot></slot>

                    <div v-if="!hideDefaultActions" class="base-popup-actions">
                        <slot name="actionsPrepend"></slot>

                        <base-button
                            v-if="saveBtnVisible"
                            @click="save"
                            :text="saveText"
                            :color="saveBtnColor"
                            :loading="saveBtnLoading"
                            :disabled="saveBtnDisabled"
                        ></base-button>

                        <base-button
                            @click="close"
                            :text="closeText"
                        ></base-button>
                    </div>
                </div>
            </div>
        </MountingPortal>
    </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

const POSITION_CENTER = 'center'
const POSITION_LEFT = 'left'
const POSITION_BOTTOM = 'bottom'

export default {
    name: "BasePopup",
    components: {BaseButton},
    props: {
        title: {
            type: String,
            default: ""
        },
        position: {
            type: String,
            default: POSITION_CENTER
        },
        mountTo: {
            type: String,
            default: "body"
        },
        hideDefaultActions: {
            type: Boolean,
            default: false
        },
        saveText: {
            type: String,
            default: "base-popup.save-btn"
        },
        closeText: {
            type: String,
            default: "base-popup.close-btn"
        },
        width: {
            type: Number,
            default: 360
        },
        height: {
            type: [Number, String],
            default: "auto"
        },
        popupClass: {
           type: String,
           default: ''
        },
        saveBtnDisabled: {
            type: Boolean,
            default: false
        },
        saveBtnVisible: {
            type: Boolean,
            default: true
        },
        saveBtnLoading: {
            type: Boolean,
            default: false
        },
        saveBtnColor: {
            type: String,
            default: "blue"
        },
        titlePosition: {
            type: String,
            default: POSITION_LEFT
        },
        blackout: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        styles() {
            return {
                width: `${this.width}px`,
                height: typeof this.height === "number" ? `${this.height}px` : this.height
            }
        },
        classes() {
            return {
                'base-popup-center': this.position === POSITION_CENTER,
                'base-popup-left': this.position === POSITION_LEFT,
                'base-popup-bottom': this.position === POSITION_BOTTOM
            }
        },
        headerClasses() {
            return {
                'base-popup-title-center': this.titlePosition === POSITION_CENTER
            }
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            this.$emit('save')
        }
    }
}
</script>

<style scoped>

</style>