/**
 * Calculates the number of free slots for a call parking object
 *
 * @param callParking
 * @returns {number}
 */
const countNumberOfFreeSlots = (callParking) => {
    const maxSlotsCount = callParking.last_slot_number - callParking.first_slot_number + 1

    return Math.max(0, maxSlotsCount - callParking.slots.length)
}

export {countNumberOfFreeSlots}