<template>
    <div class="integrations-inline">
        <crm-info
            :number="number"
            :inline="true"
            :show-call-log-btn="allowCallLogging"
            :crm-info-spinner-size="'medium'"
            :call-start-timestamp="inlineCallLogData.startTimestamp"
        >
            <template v-slot:logCallBtn="{contact, crmType}">
                <base-button
                    @click="callLogClickHandler(crmType, contact.owner, contact.crm_object_id, contact.crm_object_type, contact.name)"
                    :color="'blue'"
                    :text="'integrations-popup.call-log-btn'"
                    :loading="callLogProcessingIds.indexOf(contact.crm_object_id) !== -1"
                    :disabled="!isIntegrationEnabled(crmType)"
                    class="log-call-btn"
                ></base-button>
            </template>
        </crm-info>

        <base-button
            v-if="canShowCallLogAllBtn()"
            @click="callLogAllClickHandler()"
            :color="'blue'"
            :text="'integrations-popup.call-log-all-btn'"
            :loading="callLogAllProcessing"
            :disabled="!canCallLogAll"
            class="log-call-btn log-all-call-btn"
        ></base-button>
    </div>
</template>

<script>
import BaseButton from "../defaults/BaseButton.vue";
import ExpandedContactInfo from "./base/ExpandedContactInfo.vue";
import CreateContact from "./base/CreateContact.vue";
import MinimizedContactInfo from "./base/MinimizedContactInfo.vue";
import CrmInfo from "./base/CrmInfo.vue";
import {mapActions, mapGetters} from "vuex";
import {getCallLogDataStructure, logTheCall, isIntegrationEnabled, existEnabledCrms, onlyZapierEnabled} from "../../utils/integrations";

export default {
    name: "IntegrationsInline",
    components: {CrmInfo, MinimizedContactInfo, BaseButton, ExpandedContactInfo, CreateContact},
    props: {
        number: {
            type: String,
            default: ""
        },
        inlineCallLogData: {
            type: Object,
            default() {
                return {}
            }
        },
        allowCallLogging: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            callLogProcessingIds: [],
            callLogAllProcessing: false
        }
    },
    beforeDestroy() {
        if (this.number) {
            this.resetCallInfo([this.number])
        }
    },
    computed: {
        ...mapGetters('integrations', ['callInfo', 'callInfoExtended', 'notes', 'isLoadingCallInfo']),

        canCallLogAll() {
            return existEnabledCrms() && !onlyZapierEnabled()
        }
    },
    methods: {
        ...mapActions('integrations', ['resetCallInfo']),

        canShowCallLogAllBtn: function () {
            const callInfo = this.callInfo(this.number)
            const isCallInfoExists = callInfo.length && callInfo.filter((crm) => crm.contacts.length).length
            return isCallInfoExists && !this.isLoadingCallInfo(this.number) && this.allowCallLogging
        },

        callLogClickHandler: function(crmType, ownerName, contactId, contactType, contactName) {
            if (!isIntegrationEnabled(crmType)) {
                return
            }

            const logData = this.getCallLogDataForRequest(crmType, ownerName, contactId, contactType, contactName)

            this.callLogProcessingIds.push(contactId)
            logTheCall([logData])
                .catch(() => {})
                .finally(() => {
                    const index = this.callLogProcessingIds.indexOf(contactId)
                    if (index !== -1) {
                        this.callLogProcessingIds.splice(index, 1)
                    }
                })
        },

        callLogAllClickHandler: function() {
            if (!this.canCallLogAll) {
                return
            }

            let allCallLogData = []

            this.callInfo(this.number).forEach((info) => {
                if (!isIntegrationEnabled(info.crm_type)) {
                    return
                }

                info.contacts.forEach((contact) => {
                    allCallLogData.push(this.getCallLogDataForRequest(info.crm_type, contact.owner, contact.crm_object_id, contact.crm_object_type, contact.name))
                })
            })

            if (allCallLogData.length) {
                this.callLogAllProcessing = true
                logTheCall(allCallLogData, true).catch(() => {}).finally(() => this.callLogAllProcessing = false)
            }
        },

        getCallLogDataForRequest: function(crmType, ownerName, contactId, contactType, contactName) {
            const data = getCallLogDataStructure({
                direction: this.inlineCallLogData.direction,
                localNum: this.inlineCallLogData.cid,
                remoteNum: this.inlineCallLogData.number,
                ownerName: ownerName,
                contactType: contactType,
                contactId: contactId,
                startTimestamp: Math.round(this.inlineCallLogData.startTimestamp / 1000),
                callDuration: this.inlineCallLogData.seconds,
                note: this.notes(crmType, contactId, this.number, this.inlineCallLogData.startTimestamp)
            })
            return {
                'crm_type': crmType,
                'contact_name': contactName,
                'data': data
            }
        },

        isIntegrationEnabled
    }
}
</script>

<style scoped>

</style>