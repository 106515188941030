<template>
    <settings-tab :btn-loading="loading" :btn-disabled="!avatarHasBeenChanged" @clickSave="save()">
        <template v-slot:content>
            <form class="settings-form settings-tab-user">
                <div class="settings-avatar-section">
                    <avatar :size="'extraBig'" :src="dataUser.avatar" :text="dataUser.name" :color="dataUser.color"></avatar>
                    <input @input="inputImage" ref="imageUploader" type="file" style="display: none" accept=".jpg,.png" class="has-label">
                    <base-button
                        @click="$refs.imageUploader.click()"
                        :size="'big'"
                        :color="'blue'"
                        :text="'panel.settings.upload-avatar-button'"
                    ></base-button>
                    <base-button
                        @click="deleteImage"
                        :text="'panel.settings.delete-avatar-button'"
                        :size="'big'"
                    ></base-button>
                </div>
                <base-tooltip :width="336" position="bottom" text="top-bar.name-tooltip">
                    <template v-slot:activator>
                        <base-input
                            :value="userNameWithExtension"
                            class="name"
                            label="panel.settings.name-label"
                            type="text"
                            disabled
                        ></base-input>
                    </template>
                </base-tooltip>
                <base-tooltip :width="336" position="bottom" text="panel.settings.email-hint">
                    <template v-slot:activator>
                        <base-input
                            v-model="dataUser.email"
                            class="email"
                            label="panel.settings.email-label"
                            type="text"
                            disabled
                        ></base-input>
                    </template>
                </base-tooltip>
            </form>
        </template>

        <template v-slot:appendActions>
            <base-button
                @click="onResetPassword"
                :text="'panel.settings.reset-password-button'"
            ></base-button>
        </template>
    </settings-tab>
</template>

<script>
import SettingsTab from "./SettingsTab.vue";
import Avatar from "../../defaults/Avatar.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import i18n from "../../../locales";
import {mapActions, mapGetters} from "vuex";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import {getCaptchaToken} from "../../../helpers/CaptchaHelper";
import BaseInput from "../../defaults/BaseInput.vue";

export default {
    name: "SettingsTabUser",
    components: {BaseTooltip, BaseInput, BaseButton, Avatar, SettingsTab},
    data() {
        return {
            dataUser: {},
            image: null,
            loading: false
        }
    },
    created() {
        this.dataUser = Object.assign({}, this.user)
    },
    computed: {
        ...mapGetters('user', ['user', 'userNameWithExtension']),
        ...mapGetters(['captchaKey', 'partnerId']),

        avatarHasBeenChanged() {
            return this.dataUser.avatar != this.user.avatar
        }
    },
    methods: {
        ...mapActions(['createAlertMessage']),
        ...mapActions('user', ['editPhoto', 'sendResetLink', 'resetPassword']),

        inputImage(event) {
            this.image = event.target.files[0]
            this.dataUser.avatar = URL.createObjectURL(this.image)
            this.$refs.imageUploader.value = ''
        },

        deleteImage() {
            this.dataUser.avatar = ""
            this.image = null
        },

        onResetPassword() {
            this.resetPassword().then(() => {
                this.createAlertMessage({type: 'success', message: i18n.t('alert-messages.check-email')})
            }).catch(() => {})
        },

        save() {
            const user = this.user

            if (this.dataUser.avatar != user.avatar) {
                this.loading = true
                this.editPhoto({image: this.image, blob: this.dataUser.avatar})
                    .then(() => this.createAlertMessage({type: 'success', message: i18n.t('panel.settings.changes-saved-successfully')}))
                    .catch(() => {})
                    .finally(() => this.loading = false)
            }
        }
    }
}
</script>

<style scoped>

</style>