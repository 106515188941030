<template>
    <div class="date-picker-days">
        <div class="week weekdays-wrapper">
            <div v-for="weekDay in weekDays" class="day weekday">{{ weekDay }}</div>
        </div>

        <div v-for="week in weeks" class="week">
            <button
                v-for="day in week"
                @click="dayClickHandler(day)"
                @mouseover="mouseOverDay(day)"
                @mouseleave="mouseLeaveDay(day)"
                :class="dayClasses(day)"
                :disabled="!selectedDate.isSame(day, 'month')"
                class="calendar-btn day"
            >
                {{ day.date() }}
            </button>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs"
import DayRange from "../../../helpers/DayRange";

export default {
    name: "DatePickerDays",
    props: {
        selectedDate: {
            type: dayjs,
            default: null
        },
        now: {
            type: dayjs,
            default: null
        },
        dayRange: {
            type: DayRange,
            default: null
        }
    },
    data() {
        return {
            localDayRange: null,
            hoverDayRange: null,
            hoverDay: null
        }
    },
    created() {
        this.localDayRange = this.dayRange
    },
    watch: {
        dayRange() {
            this.localDayRange = this.dayRange
        }
    },
    computed: {
        weeks() {
            let result = []
            let week = []

            let startDay = this.selectedDate.startOf("month").weekday(0)
            while (result.length !== 6) { // 6 weeks
                week.push(startDay)
                startDay = startDay.add(1, "day")
                if (week.length === 7) { // 7 days
                    result.push(week)
                    week = []
                }
            }

            return result
        },
        weekDays() {
            let weekDaysFormatted = this.selectedDate.localeData().weekdays().map((value) => value.slice(0, 1))
            if (this.selectedDate.localeData().firstDayOfWeek() === 1) {
                const sunday = weekDaysFormatted.shift()
                weekDaysFormatted.push(sunday)
            }
            return weekDaysFormatted
        },

    },
    methods: {
        dayClickHandler(day) {
            if (this.localDayRange.rangeIsSet() || !this.localDayRange.firstDay) {
                this.localDayRange.clear()
                this.localDayRange.firstDay = day
                this.$emit('select')
                return
            }

            this.localDayRange.lastDay = day
            this.hoverDayRange = null
            this.$emit('select')
        },

        dayClasses(day) {
            if (!this.selectedDate.isSame(day, 'month')) {
                return {
                    'not-same-month': true
                }
            }

            return {
                'current': this.now.isSame(day, 'date'),
                'active': this.localDayRange.isFirst(day) || this.localDayRange.isLast(day) || day.isSame(this.hoverDay),
                'range-is-set': this.localDayRange.rangeIsSet() || (this.hoverDayRange && this.hoverDayRange.rangeIsSet()),
                'selection-range-in': this.dayInRange(day),
                'no-left-radius': this.dayHasNeighborOnTheLeft(day),
                'no-right-radius': this.dayHasNeighborOnTheRight(day),
                'no-top-radius': this.dayHasNeighborOnTheTop(day),
                'no-bottom-radius': this.dayHasNeighborOnTheBottom(day),
                'hover': this.isHover(day)
            }
        },

        dayInRange(day) {
            return this.localDayRange.isBetween(day, 'day', '()') || (this.hoverDayRange && this.hoverDayRange.isBetween(day, 'day', '()'))
        },

        isHover(day) {
            if (this.hoverDay && this.hoverDayRange) {
                return this.dayInRange(day)
            }

            return false
        },

        dayHasNeighborOnTheLeft(day) {
            if (day.weekday() === 0) {
                return false
            }

            return this.dayInRange(day.subtract(1, 'day'))
        },

        dayHasNeighborOnTheRight(day) {
            if (day.weekday() === 6) {
                return false
            }

            return this.dayInRange(day.add(1, 'day'))
        },

        dayHasNeighborOnTheTop(day) {
            return this.dayInRange(day.subtract(7, 'day'))
        },

        dayHasNeighborOnTheBottom(day) {
            return this.dayInRange(day.add(7, 'day'))
        },

        mouseOverDay(day) {
            this.hoverDay = day

            if (!this.localDayRange.rangeIsSet()) {
                this.hoverDayRange = new DayRange(this.localDayRange.firstDay, this.hoverDay)
            }
        },

        mouseLeaveDay(day) {
            this.hoverDay = null
            this.hoverDayRange = null
        }
    }
}
</script>

<style scoped>

</style>