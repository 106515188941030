<template>
    <div class="manage-call-buttons">
        <!--First line-->
        <call-button
            @press="$emit('pressMute')"
            buttonClass="manage-call-button"
            iconClass="icon-mic"
            iconClassActive="icon-mic-off"
            :activeState="true"
            :button-active="muteBtn.active"
            :disabled="muteBtn.disabled"
            :label="$t('buttons-group.buttons-group-manage-call.mute')"
            :labelActive="$t('buttons-group.buttons-group-manage-call.unmute')"
        ></call-button>

        <call-button
            v-if="pauseBtn.show"
            @press="$emit('pressPause')"
            buttonClass="manage-call-button"
            iconClass="icon-pause"
            iconClassActive="icon-play"
            :activeState="true"
            :buttonActive="pauseBtn.active"
            :disabled="pauseBtn.disabled"
            :label="$t('buttons-group.buttons-group-manage-call.pause')"
            :labelActive="$t('buttons-group.buttons-group-manage-call.resume')"
        ></call-button>

        <call-button
            v-if="swapBtn.show"
            @press="$emit('pressSwap')"
            buttonClass="manage-call-button"
            iconClass="icon-swap"
            :disabled="swapBtn.disabled"
            :label="$t('buttons-group.buttons-group-manage-call.swap')"
        ></call-button>

        <call-button
            v-if="addCallBtn.show"
            @press="$emit('pressAddCall')"
            buttonClass="manage-call-button"
            iconClass="icon-person-add"
            :disabled="addCallBtn.disabled"
            :label="$t('buttons-group.buttons-group-manage-call.add-call')"
        ></call-button>

        <call-button
            v-if="mergeBtn.show"
            @press="$emit('pressMerge')"
            buttonClass="manage-call-button"
            iconClass="icon-call-merge"
            :disabled="mergeBtn.disabled"
            :label="'Merge'"
        ></call-button>

        <call-button
            v-if="splitBtn.show"
            @press="$emit('pressSplit')"
            buttonClass="manage-call-button"
            iconClass="icon-split"
            :disabled="splitBtn.disabled"
            :label="'Split'"
        ></call-button>
        <!-- First line end -->

        <!-- Second line -->
        <call-button
            @press="$emit('pressKeypad')"
            buttonClass="manage-call-button"
            iconClass="icon-dialpad"
            :disabled="keypadBtn.disabled"
            :label="$t('buttons-group.buttons-group-manage-call.keypad')"
        ></call-button>

        <call-button
            v-if="recordBtn.show"
            @press="$emit('pressRecord')"
            buttonClass="manage-call-button record-button"
            iconClass="icon-radio-checked"
            iconClassActive="icon-radio-unchecked"
            :activeState="true"
            :buttonActive="recordBtn.active"
            :disabled="recordBtn.disabled"
            :label="$t('buttons-group.buttons-group-manage-call.record')"
            :labelActive="$t('buttons-group.buttons-group-manage-call.stop')"
            :disabled-state-tooltip="recordBtn.tooltip ? $t('buttons-group.buttons-group-manage-call.record-button.disabled-tooltip') : ''"
            :enabled-state-tooltip="recordBtn.tooltip ? $t('buttons-group.buttons-group-manage-call.record-button.enabled-tooltip'): ''"
        ></call-button>

        <call-button
            v-if="transferBtn.show"
            @press="$emit('pressTransfer')"
            buttonClass="manage-call-button transfer-button"
            iconClass="icon-call-forwarded"
            :disabled="transferBtn.disabled"
            :label="$t('buttons-group.buttons-group-manage-call.transfer')"
        ></call-button>

        <call-button
            v-show="attendedTransferBtn.show"
            @press="$emit('pressAttendedTransfer')"
            :label="$t('buttons-group.buttons-group-manage-call.transfer')"
            :disabled="attendedTransferBtn.disabled"
            buttonClass="transfer-call-button"
            iconClass="icon-call-forwarded"
        ></call-button>
        <!-- Second line end -->
    </div>
</template>

<script>
import CallButton from "../base/CallButton.vue";
export default {
    name: "ButtonsGroupManageCall",
    components: {CallButton},
    props: {
        muteBtn: {
            type: Object,
            default() {
                return {
                    show: true,
                    active: false,
                    disabled: false
                }
            }
        },
        pauseBtn: {
            type: Object,
            default() {
                return {
                    show: true,
                    active: false,
                    disabled: false,
                }
            }
        },
        swapBtn: {
            type: Object,
            default() {
                return {
                    show: false,
                    active: false,
                    disabled: false
                }
            }
        },
        addCallBtn: {
            type: Object,
            default() {
                return {
                    show: true,
                    active: false,
                    disabled: false
                }
            }
        },
        mergeBtn: {
            type: Object,
            default() {
                return {
                    show: false,
                    active: false,
                    disabled: false
                }
            }
        },
        splitBtn: {
            type: Object,
            default() {
                return {
                    show: false,
                    active: false,
                    disabled: false
                }
            }
        },
        keypadBtn: {
            type: Object,
            default() {
                return {
                    show: true,
                    active: false,
                    disabled: false
                }
            }
        },
        recordBtn: {
            type: Object,
            default() {
                return {
                    show: true,
                    active: false,
                    disabled: true,
                    tooltip: false
                }
            }
        },
        transferBtn: {
            type: Object,
            default() {
                return {
                    show: true,
                    active: false,
                    disabled: false
                }
            }
        },
        attendedTransferBtn: {
            type: Object,
            default() {
                return {
                    show: false,
                    active: false,
                    disabled: false
                }
            }
        },
    },
}
</script>

<style scoped>

</style>