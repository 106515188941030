<template>
    <div class="history-avatar">
        <span :class="icon" class="icon"></span>
    </div>
</template>

<script>
const INCOMING_CALL = "incoming"
const OUTGOING_CALL = "outgoing"
const MISSED_CALL = "missed"

export default {
    name: "HistoryAvatar",
    props: {
        callType: {
            type: String,
            default: INCOMING_CALL
        }
    },
    computed: {
        icon() {
            return {
                "icon-call-ingoing": this.callType === INCOMING_CALL,
                "icon-call-missed": this.callType === MISSED_CALL,
                "icon-call-outgoing": this.callType === OUTGOING_CALL
            }
        }
    }
}
</script>

<style scoped>

</style>