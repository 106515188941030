<template>
    <tr class="base-table-row" :class="classes">
        <td v-if="selectable" class="base-table-row-item-wrapper">
            <div class="base-table-row-item">
                <base-checkbox
                    @change="onSelect"
                    :value="selected"
                ></base-checkbox>
            </div>
        </td>

        <td
            v-for="(column, columnId) in columns"
            :key="columnId"
            :class="column.class"
            class="base-table-row-item-wrapper"
        >
            <div class="base-table-row-item">
                {{ column.value }}
            </div>
        </td>
    </tr>
</template>

<script>
import BaseCheckbox from "../BaseCheckbox.vue";

export default {
    name: "BaseTableRow",
    components: {BaseCheckbox},
    props: {
        columns: {
            type: Array,
            default: []
        },
        selected: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                "base-table-row-selected": this.selected && this.selectable,
                "base-table-row-selectable": this.selectable
            }
        }
    },
    methods: {
        onSelect() {
            this.$emit("select")
        }
    }
}
</script>

<style scoped>

</style>