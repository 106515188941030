<template>
    <div class="date-picker-shortcuts">
        <base-button
            v-for="(shortcut, i) in shortcuts"
            :key="i"
            @click="clickShortcut(shortcut)"
            :text="shortcut.name"
            :icon="activeShortcutIndex === i ? 'icon-done' : ''"
        ></base-button>
    </div>
</template>

<script>
import dayjs from "dayjs"
import DayRange from "../../../helpers/DayRange";
import BaseButton from "../BaseButton.vue";

export default {
    name: "DatePickerShortcuts",
    components: {BaseButton},
    props: {
        now: {
            type: dayjs,
            default: null
        },
        dayRange: {
            type: DayRange,
            default: null
        }
    },
    data() {
        return {
            localDayRange: null
        }
    },
    created() {
        this.localDayRange = this.dayRange
    },
    watch: {
        dayRange() {
            this.localDayRange = this.dayRange
        }
    },
    computed: {
        shortcuts() {
            return  [
                {
                    name: "date-picker.shortcuts.today",
                    from: this.now.startOf('day'),
                    to: this.now.endOf('day')
                },
                {
                    name: "date-picker.shortcuts.yesterday",
                    from: this.now.subtract(1, 'day').startOf('day'),
                    to: this.now.subtract(1, 'day').endOf('day')
                },
                {
                    name: "date-picker.shortcuts.last-seven-days",
                    from: this.now.subtract(6, 'day').startOf('day'),
                    to: this.now.endOf('day')
                },
                {
                    name: "date-picker.shortcuts.last-thirty-days",
                    from: this.now.subtract(29, 'day').startOf('day'),
                    to: this.now.endOf('day')
                },
                {
                    name: "date-picker.shortcuts.this-month",
                    from: this.now.startOf('month'),
                    to: this.now.endOf('day')
                },
                {
                    name: "date-picker.shortcuts.last-month",
                    from: this.now.subtract(1, 'month').startOf('month'),
                    to: this.now.subtract(1, 'month').endOf('month')
                }
            ]
        },

        activeShortcutIndex() {
            for (let i = 0; i < this.shortcuts.length; i++) {
                if (this.localDayRange.isFirst(this.shortcuts[i].from) && this.localDayRange.isLast(this.shortcuts[i].to)) {
                    return i
                }
            }

            return null
        }
    },
    methods: {
        isActive(id) {
            return this.localDayRange.isFirst(this.shortcuts[id].from) && this.localDayRange.isLast(this.shortcuts[id].to)
        },

        clickShortcut(shortcut) {
            this.$emit('select', {from: shortcut.from, to: shortcut.to})
        }
    }
}
</script>

<style scoped>

</style>