<template>
    <div class="service-tab">
        <div class="service-tab-header">
            <slot name="header"></slot>
        </div>

        <div class="service-tab-body" v-bar>
            <div class="service-tab-scroll">
                <slot name="body"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import BaseSpinner from "../../defaults/BaseSpinner.vue";

export default {
    name: "ServiceTab",
    components: {BaseSpinner}
}
</script>

<style scoped>

</style>