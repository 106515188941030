<template>
    <div class="softphone-state state-attended-transfer" v-if="getPrimarySession && getSecondSession">
        <div class="person-collapsed-wrapper" :class="{'union': (window.width <= 1280 || window.height <= 875) && !showKeypad}">
            <person-collapsed
                v-show="!showKeypad || (showKeypad && !getSecondSession.isOnHold)"
                :session="getSecondSession"
            ></person-collapsed>

            <person-collapsed
                v-show="!showKeypad || (showKeypad && !getPrimarySession.isOnHold)"
                :session="getPrimarySession"
            ></person-collapsed>
        </div>

        <buttons-group-manage-call
            v-show="!personTransferCollapsed"
            :add-call-btn="{show: true, disabled: true}"
            :record-btn="{show: true, disabled: false}"
            :transfer-btn="{show: false}"
            :mute-btn="{show: true, active: getIsMuted}"
            :attended-transfer-btn="{show: true, disabled: false}"
            @pressMute="mute"
            @pressPause="hold"
            @pressKeypad="toggleKeypad"
            @pressAttendedTransfer="attendedTransferCall"
            @pressRecord="record"
        ></buttons-group-manage-call>

        <div v-if="showKeypad" class="active-call-keypad-wrapper">
            <dtmf-dial-pad v-model="dtmfModel" @typedSymbol="sendDTMF"></dtmf-dial-pad>
        </div>

        <div class="active-call-buttons-wrapper">
            <call-button v-show="showKeypad" @press="toggleKeypad" buttonClass="hide-keypad-button" iconClass="icon-arrow-left" :label="$t('softphone.go-back-button')"></call-button>
            <call-button @press="hangUp(getPrimarySession.id)" buttonClass="hangup-call-button" iconClass="icon-call-end"></call-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "../../../defaults/Avatar.vue";
import ButtonsGroupManageCall from "../../buttonsGroup/ButtonsGroupManageCall.vue";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import CallButton from "../../base/CallButton.vue";
import {formatNumber} from "../../../../utils/phoneNumbers";
import Person from "../../base/Person.vue";
import DtmfDialPad from "../../base/DtmfDialPad.vue";

export default {
    name: "SoftphoneStateActiveAttendedTransfer",
    components: {
        DtmfDialPad,
        Person,
        CallButton,
        PersonCollapsed,
        Avatar,
        ButtonsGroupManageCall
    },
    data() {
        return {
            personTransferCollapsed: false,
            showKeypad: false,
            dtmfModel: '',
            window: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }
    },
    created() {
        window.addEventListener("resize", this.resizeWindow);
    },
    mounted() {
        document.addEventListener('keyup', this.updateDTMFModel)
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindow);
        document.removeEventListener('keyup', this.updateDTMFModel)
    },
    computed: {
        ...mapGetters('softphone', [
            'getIsMuted',
            'getPrimarySession',
            'getSecondSession',
            'isInCall',
            'getSessions'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'attendedTransferCall',
            'mute',
            'hold',
            'sendDTMF',
            'record'
        ]),

        toggleKeypad() {
            this.personTransferCollapsed = !this.personTransferCollapsed;
            this.showKeypad = !this.showKeypad;
        },

        resizeWindow() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },

        updateDTMFModel(event) {
            const activeElement = document.activeElement
            if (this.isInCall) {
                if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
                    return
                }
                this.dtmfModel += event.key
            }
        },

        formatNumber
    }
}
</script>

<style scoped>

</style>