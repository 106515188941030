import store from "../../../../store"

/**
 * Abstract CRM class
 */
export default class AbstractCrm {
    /**
     * @param {string} type
     * @param {string} smallLogo
     * @param {string} bigLogo
     * @param {string} title
     */
    constructor({type, smallLogo, bigLogo, title}) {
        this._type = type
        this._smallLogo = smallLogo
        this._bigLogo = bigLogo
        this._title = title
    }

    /**
     * @returns {string}
     */
    get type() {
        return this._type
    }

    /**
     * @returns {string}
     */
    get smallLogo() {
        return this._smallLogo
    }

    /**
     * @returns {string}
     */
    get bigLogo() {
        return this._bigLogo
    }

    /**
     * @returns {string}
     */
    get title() {
        return this._title
    }

    /**
     * Method returns name of newly created contact
     */
    getNameFromContactCreationFields() {}

    /**
     * Method returns sets of params that will be used in /call_info_extended request
     *
     * @returns {{tenant: string, email: string}}
     */
    getParamsForCallInfoExtendedRequest() {
        return {
            tenant: store.getters["user/crmTenantName"],
            email: store.getters["user/email"]
        }
    }

    /**
     * Method returns sets of fields that will be used to create a new contact in CRM
     */
    getCreateContactFields() {}

    /**
     * Method returns sets of fields that will be displayed after contact is expanded
     */
    getExpandedInfo() {}

    /**
     * Method returns name of contact creation button
     */
    getCreateButtonName() {}
}