<template>
    <settings-tab
        :btn-loading="loading"
        :btn-disabled="!somethingHasBeenChanged"
        @clickSave="save()"
        class="settings-tab-sound"
    >
        <template v-slot:content>
            <form class="settings-form settings-tab-sound">
                <div class="form-group filled ringtone-group">
                    <base-select
                        v-model="selectedRingtoneId"
                        :options="ringtonesOptions"
                        label="panel.settings.settings-tabs.sound.ringtone-label"
                    ></base-select>

                    <base-audio-element :src="selectedRingtoneSrc">
                        <template v-slot:default="{isPause, playPause}">
                            <base-button
                                @click="playPause"
                                :icon="isPause ? 'icon-play' : 'icon-pause'"
                                size="big"
                            ></base-button>
                        </template>
                    </base-audio-element>
                </div>

                <div class="form-group filled">
                    <base-select v-model="ringDeviceId" :options="audioDevicesOutput" :label="'panel.settings.settings-tabs.sound.ring-device-label'"></base-select>
                </div>

                <div class="form-group filled">
                    <base-select v-model="speakerDeviceId" :options="audioDevicesOutput" :label="'panel.settings.settings-tabs.sound.speaker-device-label'"></base-select>
                </div>

                <div class="form-group filled">
                    <base-select v-model="microphoneId" :options="audioDevicesInput" :label="'panel.settings.settings-tabs.sound.microphone-label'"></base-select>
                </div>
            </form>
        </template>
    </settings-tab>
</template>

<script>
import SettingsTab from "./SettingsTab.vue";
import {mapActions, mapGetters} from "vuex";
import i18n from "../../../locales";
import BaseSelect from "../../defaults/BaseSelect.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import BaseAudioElement from "../../defaults/BaseAudioElement.vue";

const DEVICE_TYPE_RING_DEVICE = "ring_device"
const DEVICE_TYPE_SPEAKER_DEVICE = "speaker_device"
const DEVICE_TYPE_MICROPHONE = "microphone"

export default {
    name: "SettingsTabSound",
    components: {BaseAudioElement, BaseButton, BaseSelect, SettingsTab},
    data() {
        return {
            loading: false,
            audioDevicesInput: [],
            audioDevicesOutput: [],
            ringDeviceId: '',
            speakerDeviceId: '',
            microphoneId: '',
            selectedRingtoneId: null
        }
    },
    mounted() {
        this.getMediaDevices().then(() => {
            this.setupMediaDevices()
        }).catch(() => {})

        this.selectedRingtoneId = this.ringtoneId
    },
    watch: {
        mediaDevices(value) {
            value.forEach((mediaDevice) => {
                let deviceType = mediaDevice['device_type']
                let deviceId = this.getMediaDeviceIdByType(deviceType)
                switch (deviceType) {
                    case DEVICE_TYPE_RING_DEVICE:
                        this.ringDeviceId = deviceId
                        document.dispatchEvent(new CustomEvent("updateRingDeviceId", {'detail': { deviceId: deviceId }}))
                        break
                    case DEVICE_TYPE_SPEAKER_DEVICE:
                        this.speakerDeviceId = deviceId
                        document.dispatchEvent(new CustomEvent("updateSpeakerDeviceId", {'detail': { deviceId: deviceId }}))
                        break
                    case DEVICE_TYPE_MICROPHONE:
                        this.microphoneId = deviceId
                        document.dispatchEvent(new CustomEvent("updateMicrophoneId", {'detail': { microphoneId: deviceId }}))
                        break
                }
            })
        }
    },
    computed: {
        ...mapGetters('softphone', ['mediaDevices']),
        ...mapGetters('user', ['ringtoneId', 'ringtones']),

        ringtonesOptions() {
            return this.ringtones.map((ringtone) => {
                return {value: ringtone.id, name: ringtone.name}
            })
        },

        selectedRingtoneSrc() {
            const ringtone = this.ringtones.find((ringtone) => ringtone.id === this.selectedRingtoneId)

            if (!ringtone) {
                return null
            }

            return require(`../../../sounds/ringtones/${ringtone.filename}`)
        },

        somethingHasBeenChanged() {
            return this.ringDeviceId !== this.getMediaDeviceIdByType(DEVICE_TYPE_RING_DEVICE) ||
                   this.speakerDeviceId !== this.getMediaDeviceIdByType(DEVICE_TYPE_SPEAKER_DEVICE) ||
                   this.microphoneId !== this.getMediaDeviceIdByType(DEVICE_TYPE_MICROPHONE) ||
                   this.selectedRingtoneId !== this.ringtoneId
        },
    },
    methods: {
        ...mapActions(['createAlertMessage']),
        ...mapActions('softphone', ['getMediaDevices', 'updateMediaDevices']),
        ...mapActions('user', ['changeRingtone']),

        save() {
            let mediaDevices = []
            let requests = []

            if (this.ringDeviceId !== this.getMediaDeviceIdByType(DEVICE_TYPE_RING_DEVICE)) {
                mediaDevices.push({'device_type': DEVICE_TYPE_RING_DEVICE, 'device_id': this.ringDeviceId})
            }

            if (this.speakerDeviceId !== this.getMediaDeviceIdByType(DEVICE_TYPE_SPEAKER_DEVICE)) {
                mediaDevices.push({'device_type': DEVICE_TYPE_SPEAKER_DEVICE, 'device_id': this.speakerDeviceId})
            }

            if (this.microphoneId !== this.getMediaDeviceIdByType(DEVICE_TYPE_MICROPHONE)) {
                mediaDevices.push({'device_type': DEVICE_TYPE_MICROPHONE, 'device_id': this.microphoneId})
            }

            if (mediaDevices.length) {
                requests.push(this.updateMediaDevices(mediaDevices))
            }

            if (this.selectedRingtoneId !== this.ringtoneId) {
                requests.push(this.changeRingtone(this.selectedRingtoneId))
            }

            this.loading = true
            Promise.all(requests)
                .then(() => this.createAlertMessage({type: 'success', message: i18n.t('panel.settings.changes-saved-successfully')}))
                .catch(() => {})
                .finally(() => this.loading = false)
        },

        setupMediaDevices() {
            const defaultDevice = {name: "Default", value: "default"}
            this.audioDevicesInput.push(defaultDevice)
            this.audioDevicesOutput.push(defaultDevice)

            this.ringDeviceId = this.getMediaDeviceIdByType(DEVICE_TYPE_RING_DEVICE)
            this.speakerDeviceId = this.getMediaDeviceIdByType(DEVICE_TYPE_SPEAKER_DEVICE)
            this.microphoneId = this.getMediaDeviceIdByType(DEVICE_TYPE_MICROPHONE)

            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    stream.getTracks().forEach((track) => {
                        track.stop()
                    })

                    this.updateConnectedMediaDevices()
                })
                .catch((error) => {
                    console.error(error)
                })

            navigator.mediaDevices.addEventListener('devicechange', event => {
                this.updateConnectedMediaDevices()
            })
        },

        updateConnectedMediaDevices() {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    let inputs = []
                    let outputs = []
                    let devicesIds = []
                    devices.forEach(device => {
                        if (device.kind === "audioinput") {
                            inputs.push({name: device.label, value: device.deviceId})
                        }

                        if (device.kind === "audiooutput") {
                            outputs.push({name: device.label, value: device.deviceId})
                        }

                        devicesIds.push(device.deviceId)
                    })

                    this.audioDevicesInput = inputs
                    this.audioDevicesOutput = outputs

                    let mediaDevices = []
                    if (!devicesIds.includes(this.ringDeviceId)) {
                        mediaDevices.push({'device_type': DEVICE_TYPE_RING_DEVICE, 'device_id': 'default'})
                    }

                    if (!devicesIds.includes(this.speakerDeviceId)) {
                        mediaDevices.push({'device_type': DEVICE_TYPE_SPEAKER_DEVICE, 'device_id': 'default'})
                    }

                    if (!devicesIds.includes(this.microphoneId)) {
                        mediaDevices.push({'device_type': DEVICE_TYPE_MICROPHONE, 'device_id': 'default'})
                    }

                    if (mediaDevices.length) {
                        this.updateMediaDevices(mediaDevices).catch(() => {})
                    }
                })
        },

        getMediaDeviceIdByType(deviceType) {
            const mediaDevice = this.mediaDevices.find((mediaDevice) => mediaDevice['device_type'] === deviceType)
            return mediaDevice ? mediaDevice['device_id'] : 'default'
        }
    }
}
</script>

<style scoped>

</style>