<template>
    <settings-tab :tab-name="tabName" :show-save-btn="false">
        <template v-slot:content>
            <form class="settings-form settings-tab-integrations">
                <div class="input-group crm-integrations-section">
                    <template v-if="isIntegrationsAvailable">
                        <p v-if="isOnlyZapierAvailable">
                            {{ $t("panel.settings.settings-tabs.crm-integrations.how-to-enable-integrations-if-only-zapier-enabled") }}
                        </p>
                        <p v-else>{{ $t("panel.settings.settings-tabs.crm-integrations.notice") }}</p>
                        <div class="crm-integrations-group">
                            <div v-for="integration in integrationsWithoutZapier" class="crm-integration">
                                <img :src="require(`../../../images/crmLogos/${getCrm(integration.type).bigLogo}`)">
                                <base-switch
                                    @change="switchIntegration(integration.type, integration.domain, integration.enabled)"
                                    :value="integration.enabled"
                                    :pre-label="getCrm(integration.type).title"
                                ></base-switch>
                            </div>
                        </div>

                        <div class="automation-section">
                            <span class="title">{{ $t("panel.settings.settings-tabs.crm-integrations.automation") }}</span>
                            <p v-if="!isZapierAvailable">{{ $t("panel.settings.settings-tabs.crm-integrations.zapier.how-to-enable") }}</p>
                            <p v-else>{{ $t("panel.settings.settings-tabs.crm-integrations.zapier.notice") }}</p>

                            <div v-if="isZapierAvailable" class="crm-integration">
                                <img :src="require(`../../../images/crmLogos/${getCrm(ZAPIER_TYPE).bigLogo}`)">

                                <span class="crm-name">{{ getCrm(ZAPIER_TYPE).title }}</span>
                                <chip
                                    :text="zapierChipText"
                                    :color="zapierEnabled ? 'green' : 'gray'"
                                    size="small"
                                ></chip>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <p class="how-to-enable-integrations">{{ $t("panel.settings.settings-tabs.crm-integrations.how-to-enable-integrations-new") }}</p>
                    </template>
                </div>
            </form>
        </template>
    </settings-tab>
</template>

<script>
import SettingsTab from "./SettingsTab.vue";
import {mapActions, mapGetters} from "vuex";
import Crm from "../../../helpers/integrations/crms";
import BaseSwitch from "../../defaults/BaseSwitch.vue";
import {ZAPIER_TYPE} from "../../../helpers/integrations/crms";
import Chip from "../../defaults/Chip.vue";
import {isElectronExist} from "../../../utils/electron";

export default {
    name: "settingsTabIntegrations",
    components: {Chip, BaseSwitch, SettingsTab},
    data() {
        return {
            ZAPIER_TYPE
        }
    },
    computed: {
        ...mapGetters('user', ['user', 'isIntegrationsEnabled', 'crmAccessToken', 'crmServiceUrl']),
        ...mapGetters('integrations', ['integrations', 'zapierEnabled']),

        integrationsWithoutZapier() {
            return this.integrations.filter((integration) => integration.type !== ZAPIER_TYPE)
        },

        zapierChipText() {
            if (this.zapierEnabled) {
                return "panel.settings.settings-tabs.crm-integrations.zapier.enabled"
            } else {
                return "panel.settings.settings-tabs.crm-integrations.zapier.disabled"
            }
        },

        isZapierAvailable() {
            return this.integrations.find((integration) => integration.type === ZAPIER_TYPE)
        },

        isOnlyZapierAvailable() {
            return this.integrations.length === 1 && this.integrations[0].type === ZAPIER_TYPE
        },

        tabName() {
            return this.isIntegrationsAvailable ? "panel.settings.settings-tabs.crm-integrations.title" : ""
        },

        isIntegrationsAvailable() {
            return this.isIntegrationsEnabled && this.integrations.length
        }
    },
    methods: {
        ...mapActions('integrations', ['disableIntegration']),

        switchIntegration(type, domain, enabled) {
            if (enabled) {
                this.disableIntegration(type)
                return
            }

            const url = new URL(window.location.href)
            const successLink = url.href + 'success-activate?type=' + type
            const failLink = url.href + 'failed-activate'
            const desktopAppProtocol = "voipcloud-webphone://"

            let params = {
                tenant: this.user.crmTenantName,
                user_email: this.user.email,
                crm_type: type,
                token: this.crmAccessToken,
                success_integration_link: isElectronExist() ? `${desktopAppProtocol}${successLink}` : successLink,
                error_integration_link: isElectronExist() ? `${desktopAppProtocol}${failLink}` : failLink
            }

            if (type === "zendesk") {
                params.zendesk_domain = domain
                params.redirect_uri = this.crmServiceUrl + '/crm/' + type + '/success'
            }

            const searchParams = new URLSearchParams(params)
            window.open(`${this.crmServiceUrl}/crm/enable?${searchParams.toString()}`)
        },

        getCrm(crmType) {
            return new Crm().create(crmType)
        }
    }
}
</script>

<style scoped>

</style>