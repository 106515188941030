import {sendToElectron, isElectronExist} from "../utils/electron";
import {formatNumber} from "../utils/phoneNumbers";
import store from "../store";

class NotificationHelper {
    constructor() {
        this._notification = null
    }

    createNotification(session) {
        const contact = store.getters["details/getContactByNumber"](session.number)
        const callReceivedOnName = session.didDetails.name
        const fromNumber = formatNumber(session.displayName)
        const callReceivedOnNumber = formatNumber(session.didDetails.number)

        if (isElectronExist()) {
            const contactCopy = Object.assign({}, contact)
            contactCopy.number = fromNumber

            if (contactCopy.avatar) {
                this._convertImgToBase64(contactCopy.avatar).then((dataURL) => {
                    contactCopy.avatar = dataURL
                    sendToElectron("showNotification", session.id, contactCopy, callReceivedOnNumber, callReceivedOnName)
                })
            } else {
                sendToElectron("showNotification", session.id, contactCopy, callReceivedOnNumber, callReceivedOnName)
            }

            return
        }

        if (contact.id && !contact.avatar) {
            this._createNotificationContactNoAvatar(contact.name, callReceivedOnNumber, contact.color);
        } else {
            const logoUrl = store.getters['logoUrl']
            this._createNotificationWithImage(contact.name ? contact.name : fromNumber, callReceivedOnNumber, contact.avatar ? contact.avatar : logoUrl, Boolean(contact.avatar))
        }
    }

    hideNotification(sessionId) {
        if (this._notification) {
            this._notification.close();
        }

        if (isElectronExist()) {
            sendToElectron("hideNotification", sessionId)
        }
    }

    _createNotificationWithImage(contactName, callReceivedOn, link, avatarExist = false) {
        this._convertImgToBase64(link).then((dataURL) => {
            this._showNotification(contactName, callReceivedOn, dataURL)
        })
    }

    _createNotificationContactNoAvatar(contactName, callReceivedOn, color) {
        const canvas = document.createElement("canvas");
        canvas.width = 62;
        canvas.height = 62;
        let ctx = canvas.getContext("2d");
        const x = 0;
        const y = 0;
        const width = canvas.width;
        const height = canvas.height;
        const borderRadius = 33;

        ctx.beginPath();
        ctx.moveTo(x + borderRadius, y);
        ctx.lineTo(x + width - borderRadius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
        ctx.lineTo(x + width, y + height - borderRadius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height);
        ctx.lineTo(x + borderRadius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
        ctx.lineTo(x, y + borderRadius);
        ctx.quadraticCurveTo(x, y, x + borderRadius, y);
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();

        ctx = canvas.getContext("2d");
        ctx.font = '28px Inter';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.fillText(contactName.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2 + 9);

        const dataURL = canvas.toDataURL('image/png', 0.70);
        this._showNotification(contactName, callReceivedOn, dataURL)
    }

    _showNotification(callFrom, callTo, icon) {
        const title = 'Inbound call from ' + callFrom
        const body = 'Call to ' + callTo

        try {
            this._notification = new Notification(title, {
                body: body,
                requireInteraction: true,
                icon: icon
            });
            this._notification.onclick = () => {
                window.focus()
            }
        } catch (e) {
            console.error(`Failed to show notification: ${e.toString()}`)
        }
    }

    _convertImgToBase64(link) {
        const img = new Image()
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        return new Promise((resolve, reject) => {
            img.onload = function() {
                canvas.width = this.naturalWidth
                canvas.height = this.naturalHeight
                ctx.drawImage(this, 0, 0)
                resolve(canvas.toDataURL('image/png', 0.70))
            }
            img.crossOrigin = ""
            img.src = link
        })
    }
}

export {NotificationHelper}