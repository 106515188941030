<template>
    <active-call-keypad-tab
        @input="onSelect"
        :value="value"
        :search-callback="searchCallback"
        :items="items"
        :no-items-text="noItemsText"
        class="active-call-keypad-call-parking-tab"
    >
        <template v-slot:item="{item}">
            <parking-speed-dial-list-item :call-parking="item"></parking-speed-dial-list-item>
        </template>
    </active-call-keypad-tab>
</template>

<script>
import ParkingSpeedDialListItem from "./ParkingSpeedDialListItem.vue";
import {mapActions, mapGetters} from "vuex";
import ActiveCallKeypadTab from "../ActiveCallKeypadTab.vue";
import {countNumberOfFreeSlots} from "../../../../../../utils/callParking";
import BaseSpinner from "../../../../../defaults/BaseSpinner.vue";

export default {
    name: "CallParkingTab",
    components: {BaseSpinner, ActiveCallKeypadTab, ParkingSpeedDialListItem},
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapGetters("callParkings", ["callParkings"]),

        items() {
            return this.callParkings.filter((callParking) => countNumberOfFreeSlots(callParking) > 0)
        },

        noItemsText() {
            if (this.callParkings.length === 0) {
                return "There is no call parking"
            }

            if (this.items.length === 0) {
                return "All call parkings are busy."
            }
        }
    },
    methods: {
        ...mapActions("callParkings", ["getCallParkings"]),

        searchCallback(searchString, item) {
            return item.name.toLowerCase().includes(searchString.toLowerCase())
        },
        onSelect(number) {
            this.$emit("input", number)
        }
    }
}
</script>

<style scoped>

</style>