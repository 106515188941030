<template>
    <div class="date-picker-years" v-bar>
        <div class="years-wrapper">
            <button
                v-for="year in years"
                :class="{'current': year.current, 'active': year.active}"
                @click="clickHandler(year.value)"
                :ref="year.current ? 'scrollToThisYear' : null"
                class="calendar-btn"
            >
                {{ year.value }}
            </button>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs"
import DayRange from "../../../helpers/DayRange";

export default {
    name: "DatePickerYears",
    props: {
        now: {
            type: dayjs,
            default: null
        },
        dayRange: {
            type: DayRange,
            default: null
        }
    },
    mounted() {
        const el = this.$refs.scrollToThisYear;

        if (el) {
            el[0].parentNode.scrollTop = (el[0].offsetTop + (el[0].offsetHeight / 2)) - (el[0].offsetParent.offsetHeight / 2);
        }
    },
    computed: {
        years() {
            const currentYear = this.now.year()
            const startYear = 2014
            const endYear = currentYear + 5

            let result = []
            for (let year = startYear; year <= endYear; year++) {
                result.push({
                    value: year,
                    current: year === currentYear,
                    active: this.dayRange.rangeIsSet() ? this.dayRange.isBetween(dayjs().set('year', year), 'year', '[]') : this.dayRange.firstDay.year() === year
                })
            }

            return result
        }
    },
    methods: {
        clickHandler(year) {
            this.$emit('select', year)
        }
    }
}
</script>

<style scoped>

</style>