<template>
    <base-popup
        @close="close"
        @save="save"
        :save-btn-disabled="numberIsInvalid"
        title="popups.add-external-number-popup.title"
        save-text="popups.add-external-number-popup.add-btn"
    >
        <base-phone-number-input
            :phone-number="phoneNumber"
            :country-code="countryCode"
            @update:phone-number="phoneNumber = $event"
            @update:country-code="countryCode = $event"
            autofocus
        ></base-phone-number-input>
    </base-popup>
</template>

<script>
import BasePopup from "../BasePopup.vue";
import BaseSelect from "../BaseSelect.vue";
import BasePhoneNumberInput from "../BasePhoneNumberInput.vue";
import {isValidNumberForCountry} from "../../../utils/phoneNumbers";

export default {
    name: "AddExternalNumberPopup",
    components: {BasePhoneNumberInput, BaseSelect, BasePopup},
    data() {
        return {
            phoneNumber: "",
            countryCode: "AU"
        }
    },
    created() {
        this.phoneNumber = this.value
    },
    computed: {
        numberIsInvalid() {
            return Boolean(this.phoneNumber && !isValidNumberForCountry(this.phoneNumber, this.countryCode)) || !this.phoneNumber
        }
    },
    methods: {
        close: function () {
            this.$emit('close')
        },
        save: function () {
            this.$emit('save', this.phoneNumber)
        }
    }
}
</script>

<style scoped>

</style>