<template>
    <div class="transfer-keypad-buttons">
        <call-button
            @press="$emit('pressCallFirst')"
            buttonClass="transfer-keypad-buttons call-first"
            iconClass="icon-call"
            :style="callFirstButtonStyles"
            :disabled="callFirstDisabled || !callFirstShow"
            :label="$t('buttons-group.buttons-group-transfer-keypad.call-first')"
        ></call-button>

        <call-button
            @press="$emit('pressTransferNow')"
            buttonClass="transfer-keypad-buttons transfer-now"
            :iconClass="parkCall ? 'icon-phone-parked' : 'icon-call-forwarded'"
            :disabled="transferNowDisabled"
            :label="parkCall ? 'Park call' : $t('buttons-group.buttons-group-transfer-keypad.transfer-now')"
        ></call-button>

        <call-button
            @press="$emit('pressCancelTransfer')"
            buttonClass="transfer-keypad-buttons cancel-transfer"
            iconClass="icon-arrow-left"
            :label="$t('softphone.go-back-button')"
        ></call-button>
    </div>
</template>

<script>
import CallButton from "../base/CallButton.vue";
export default {
    components: {CallButton},
    props: {
        callFirstDisabled: {default: false},
        transferNowDisabled: {default: false},
        parkCall: {default: false},
        callFirstShow: {default: true}
    },
    name: "ButtonsGroupTransferKeypad",
    computed: {
        callFirstButtonStyles() {
            return {
                'visibility': this.callFirstShow ? 'visible' : 'hidden'
            }
        }
    }
}
</script>

<style scoped>

</style>