<template>
    <label class="base-checkbox" :class="classes">
        <div class="base-checkbox-custom">
            <span v-if="value" class="icon icon-done"></span>
            <input
                @input="onInput"
                @change="onChange"
                :checked="value"
                :disabled="disabled"
                type="checkbox"
            >
        </div>

        <span v-if="label" class="base-checkbox-label">
            {{ $te(label) ? $t(label) : label }}
        </span>
    </label>
</template>

<script>
export default {
    name: "BaseCheckbox",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        semiSelected: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        }
    },
    computed: {
        classes() {
            return {
                "base-checkbox-selected": this.value,
                "base-checkbox-disabled": this.disabled,
                "base-checkbox-semi-selected": this.semiSelected && !this.value && !this.disabled
            }
        }
    },
    methods: {
        onInput() {
            this.$emit("input", !this.value)
        },
        onChange() {
            this.$emit('change')
        }
    }
}
</script>

<style scoped>

</style>