<template>
    <div class="softphone-dialpad">
        <div class="softphone-dialpad-wrapper">
            <dial-pad
                v-model="dialedNumber"
                @pressEnter="call"
                :placeholder="'softphone-dial-pad.placeholder'"
            >
                <template v-slot:numberAppend="{ defocus }">
                    <dial-pad-action-button
                        v-if="showAddButton"
                        @click="defocus(); showAddContactPopup(dialedNumber)"
                        class="add-contact-btn"
                        icon="icon-person-add"
                        position="left"
                    ></dial-pad-action-button>

                    <span v-show="contactName" class="contact-name">{{ contactName }}</span>
                </template>
            </dial-pad>

            <div class="callerid-container">
                <label>{{ $t("softphone-dial-pad.caller-id-display-as") }}</label>
                <caller-id-select
                    :options="calleridsOptions"
                    :value="callerId"
                    :offset="-40"
                    :search-placeholder="$t('caller-id-select.search-placeholder-callerid')"
                    @input="changeCallerId($event.id)"
                >
                    <caller-id-select-activator :value="callerId"></caller-id-select-activator>
                </caller-id-select>
            </div>

            <div class="do-call-button" :class="{'disabled': !getIsRegistered}">
                <button @click="call" :disabled="!getIsRegistered">
                    <span class="icon-call"></span>
                </button>
            </div>

            <connect-label v-if="connectLabel"></connect-label>
        </div>

        <internal-number-label></internal-number-label>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CallerIdSelect from "./base/CallerIdSelect/CallerIdSelect.vue";
import {formatNumber, sanitizePhoneNumber} from "../../utils/phoneNumbers";
import BaseButton from "../defaults/BaseButton.vue";
import DialPad from "./base/DialPad.vue";
import {showAddContactPopup} from "../../utils/phonebooks";
import InternalNumberLabel from "./base/InternalNumberLabel.vue";
import DialPadActionButton from "./base/DialPadActionButton.vue";
import CallerIdSelectActivator from "./base/CallerIdSelect/CallerIdSelectActivator.vue";
import calleridsOptionsMixin from "../../mixins/calleridsOptionsMixin";
import ConnectLabel from "./base/ConnectLabel.vue";

export default {
    name: "SoftphoneDialPad",
    components: {
        ConnectLabel,
        CallerIdSelectActivator, DialPadActionButton, InternalNumberLabel, DialPad, BaseButton, CallerIdSelect},
    mixins: [calleridsOptionsMixin],
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    data(){
        return {
            dialedNumber: this.value,
            contactName: '',
            showAddButton: false,
            connectLabel: false
        }
    },
    created() {
        if (this.dialedNumber) {
            this.findContact()
        }

        document.addEventListener("connectionStart", this.showConnectLabel)

        document.addEventListener("registerSuccess", this.hideConnectLabel)
        document.addEventListener("connectionError", this.hideConnectLabel)
    },
    beforeDestroy() {
        document.removeEventListener("connectionStart", this.showConnectLabel)

        document.removeEventListener("registerSuccess", this.hideConnectLabel)
        document.removeEventListener("connectionError", this.hideConnectLabel)
    },
    computed: {
        ...mapGetters('softphone', ['getIsRegistered']),

        ...mapGetters('user', ['user', 'calleridId']),

        ...mapGetters('details', ['callHistory', 'getContactByNumber', 'getDefaultPhonebook', 'defaultContact', 'contacts']),

        callerId() {
            if (!this.calleridId) {
                return this.anonymousItem
            }

            return this.calleridsOptions.find((callerid) => callerid.id === this.calleridId)
        },
    },
    watch: {
        dialedNumber(value) {
            this.findContact()
            this.$emit('input', value)
        },
        contacts() {
            this.findContact()
        },
        value(value) {
            this.dialedNumber = value
        }
    },
    methods: {
        ...mapActions('user', ['changeCallerId']),

        call() {
            if (this.dialedNumber) {
                this.$emit('call', this.dialedNumber);
            } else {
                const firstEntry = this.callHistory[0]
                if (firstEntry && firstEntry.number !== 'anonymous') {
                    this.dialedNumber = formatNumber(firstEntry.number)
                }
            }
        },

        findContact() {
            const contact = this.getContactByNumber(this.dialedNumber)

            if (!contact || !contact.id) {
                this.showAddButton = true
                this.contactName = ""
            } else {
                this.showAddButton = false
                this.contactName = contact.name
            }

            if (!this.dialedNumber) {
                this.showAddButton = false
            }
        },

        showConnectLabel() {
            this.connectLabel = true
        },

        hideConnectLabel() {
            this.connectLabel = false
        },

        showAddContactPopup
    }
}
</script>