import {isElectronExist, sendToElectron} from "./electron";

const DEFAULT_LOCALE = "en-au"

const DAYJS_LOCALES = require("dayjs/locale.json").map((l) => l.key)

/**
 * Function makes the locale compatible with dayjs
 *
 * @param locale
 * @returns {string}
 */
const transformLocale = (locale) => {
    const preparedLocale = locale.trim().toLowerCase()
    if (DAYJS_LOCALES.includes(preparedLocale)) {
        return locale
    }

    const splitLocale = preparedLocale.split(/-|_/)
    if (DAYJS_LOCALES.includes(splitLocale[0])) {
        return splitLocale[0]
    }

    return DEFAULT_LOCALE
}

/**
 * Function returns browser locale
 *
 * @returns {Promise<string>}
 */
const getBrowserLocale = () => {
    const locale = navigator.language
    if (!locale) {
        return Promise.resolve(DEFAULT_LOCALE)
    }

    return Promise.resolve(transformLocale(locale))
}

/**
 * Function returns system locale when using Electron app
 *
 * @returns {Promise<string>}
 */
const getSystemLocale = () => {
    if (!isElectronExist()) {
        return Promise.resolve(DEFAULT_LOCALE)
    }

    return sendToElectron("getSystemLocale")
        .then((locale) => {
            if (!locale) {
                return DEFAULT_LOCALE
            }

            return transformLocale(locale)
        })
        .catch(() => {
            return DEFAULT_LOCALE
        })

}

/**
 * Function returns app locale
 *
 * @returns {Promise<string>}
 */
const getAppLocale = () => {
    if (isElectronExist()) {
        return getSystemLocale()
    }

    return getBrowserLocale()
}

export { getAppLocale }