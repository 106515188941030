<template>
    <div class="softphone-state state-incoming state-incoming-incoming">
        <div class="call-info-wrapper">
            <div class="call-status call-status-animated">
                {{ 'Incoming calls...' }}
            </div>
            <div class="person">
                <div class="did-details">
                    <span class="caller-id-name">{{ getPrimarySession.didDetails.name }}</span>
                    <div v-show="getPrimarySession.didDetails.number">
                        <span class="label">{{ getPrimarySession.didDetails.label }}</span>
                        <span class="caller-id-num">{{ formatNumber(getPrimarySession.didDetails.number) }}</span>
                    </div>
                </div>
                <person-collapsed-incoming
                    :session="getPrimarySession"
                    @rejectBtnClick="rejectIncomingCall(getPrimarySession.id)"
                    @acceptBtnClick="acceptIncomingCall(getPrimarySession.id)"
                ></person-collapsed-incoming>
            </div>

            <div class="person">
                <div class="did-details">
                    <span class="caller-id-name">{{ getSecondSession.didDetails.name }}</span>
                    <div v-show="getSecondSession.didDetails.number">
                        <span class="label">{{ getSecondSession.didDetails.label }}</span>
                        <span class="caller-id-num">{{ formatNumber(getSecondSession.didDetails.number) }}</span>
                    </div>
                </div>
                <person-collapsed-incoming
                    :session="getSecondSession"
                    @rejectBtnClick="rejectIncomingCall(getSecondSession.id)"
                    @acceptBtnClick="acceptIncomingCall(getSecondSession.id)"
                ></person-collapsed-incoming>
            </div>
        </div>
    </div>
</template>

<script>
import PersonCollapsedIncoming from "../../base/PersonCollapsedIncoming.vue";
import {mapActions, mapGetters} from "vuex";
import {formatNumber} from "../../../../utils/phoneNumbers";

export default {
    name: "SoftphoneStateIncomingIncoming",
    components: {PersonCollapsedIncoming},
    data() {
        return {}
    },
    computed: {
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'acceptCall',
            'hangUp'
        ]),

        rejectIncomingCall(sessionId) {
            this.hangUp(sessionId)
        },

        acceptIncomingCall(sessionId) {
            const rejectSessionId = this.getPrimarySession.id === sessionId ? this.getSecondSession.id : this.getPrimarySession.id

            this.acceptCall(sessionId)
            this.hangUp(rejectSessionId)
        },

        formatNumber
    }
}
</script>

<style scoped>

</style>