<template>
    <div class="skeleton-softphone-dial-pad">
        <div class="wrapper">
            <div class="skeleton-input"></div>

            <div class="skeleton-keyboard">
                <div class="skeleton-keyboard-group">
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                </div>

                <div class="skeleton-keyboard-group">
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                </div>

                <div class="skeleton-keyboard-group">
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                </div>

                <div class="skeleton-keyboard-group">
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                    <div class="skeleton-button"></div>
                </div>

                <div class="skeleton-keyboard-group">
                    <div class="skeleton-button"></div>
                </div>
            </div>
        </div>

        <div class="skeleton-integrations"></div>
    </div>
</template>

<script>
export default {
    name: "SkeletonSoftphoneDialPad"
}
</script>

<style scoped>

</style>