<template>
    <settings-tab
            :btn-disabled="!somethingHasBeenChanged"
    >
        <template v-slot:content>
            <div class="settings-form settings-tab-device-management">
                <div class="ring-mode">
                    <div class="title">
                        <span>{{ $t('panel.settings.settings-tabs.device-management.ring-mode-label') }}</span>
                        <base-tooltip
                            :width="250"
                            text="panel.settings.settings-tabs.device-management.ring-mode-tooltip"
                            position="bottom"
                        ></base-tooltip>
                    </div>

                    <base-tab-selector v-model="localRingMode" :items="ringModes"></base-tab-selector>
                </div>

                <div v-if="localRingMode === RING_MODE_TWINNING" class="twinning-settings">
                    <p>{{ $t('panel.settings.settings-tabs.device-management.ring-first-device-tooltip') }}</p>
                    <base-counter v-model="localTwinningTime" :min-value="0" :max-value="60"></base-counter>
                </div>

                <div class="call-confirm-container">
                    <base-switch v-model="localCallConfirm" label="panel.settings.settings-tabs.device-management.call-confirm-label" class="call-confirm-switch"></base-switch>
                    <base-tooltip
                        :width="250"
                        text="panel.settings.settings-tabs.device-management.call-confirm-tooltip"
                        position="bottom"
                    ></base-tooltip>
                </div>

                <div class="follow-me-container">
                    <div class="scale-container">
                        <span>0</span>
                        <span>20</span>
                        <span>40</span>
                        <span>60</span>
                        <span class="sec">sec</span>
                    </div>
                    <base-sortable-list v-model="localFollowMes">
                        <template v-slot:listItem="{item}">
                            <follow-me-item
                                :ring-time="item.ring_time"
                                :is-enabled="item.is_enabled"
                                @update:ring-time="onUpdateFollowMeRingTime(item, $event)"
                                @update:is-enabled="onUpdateFollowMeIsEnabled(item, $event)"
                                @delete="onDeleteFollowMe(item)"
                                :follow-me-item="item"
                            ></follow-me-item>
                        </template>
                    </base-sortable-list>
                    <base-button
                        @click="showAddExternalNumberPopup"
                        color="green"
                        class="add-external-number-btn"
                        icon="icon-plus-in-circle"
                        text="panel.settings.settings-tabs.device-management.add-external-number"
                    ></base-button>
                </div>

                <add-external-number-popup
                    v-if="addExternalNumberPopup"
                    @save="onAddExternalNumber"
                    @close="closeAddExternalNumberPopup"
                ></add-external-number-popup>

                <device-management-warning-popup
                    v-if="warningPopup"
                    @close="closeWarningPopup"
                    :max-external-numbers="maxExternalNumbers"
                ></device-management-warning-popup>
            </div>
        </template>
    </settings-tab>
</template>

<script>
import SettingsTab from "../SettingsTab.vue";
import BaseSwitch from "../../../defaults/BaseSwitch.vue";
import BaseCounter from "../../../defaults/BaseCounter.vue";
import BaseButton from "../../../defaults/BaseButton.vue";
import BaseSlider from "../../../defaults/BaseSlider.vue";
import BaseTabSelector from "../../../defaults/BaseTabSelector.vue";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import DeviceManagementWarningPopup from "../../../defaults/popups/DeviceManagementWarningPopup.vue";
import AddExternalNumberPopup from "../../../defaults/popups/AddExternalNumberPopup.vue";
import BaseSortableList from "../../../defaults/BaseSortableList.vue";
import FollowMeItem from "./FollowMeItem.vue";
import {numbersAreMatched} from "../../../../utils/phoneNumbers";
import {mapActions, mapGetters} from "vuex";

const RING_MODE_RING_ALL = "ring_all"
const RING_MODE_HUNT = "hunt"
const RING_MODE_TWINNING = "twinning"

const FOLLOW_ME_TYPE_EXTERNAL_NUMBER = "External_number"

export default {
    name: "SettingsTabDeviceManagement",
    components: {
        FollowMeItem,
        BaseSortableList,
        AddExternalNumberPopup,
        DeviceManagementWarningPopup,
        BaseTooltip, BaseTabSelector, BaseSlider, BaseButton, BaseCounter, BaseSwitch, SettingsTab},
    data() {
        return {
            warningPopup: false,
            addExternalNumberPopup: false,
            localFollowMes: [],
            localRingMode: "",
            localCallConfirm: false,
            localTwinningTime: 15,
            RING_MODE_TWINNING
        }
    },
    created() {
        this.localRingMode = this.ringMode
        this.localCallConfirm = this.callConfirm
        this.localTwinningTime = this.twinningTime
        this.localFollowMes = JSON.parse(JSON.stringify(this.followMes))
    },
    watch: {
        localRingMode(value) {
            if (value === RING_MODE_RING_ALL || value === RING_MODE_TWINNING) {
                this.localFollowMes.forEach((item) => {
                    item.ring_time = 30
                })
            }
        }
    },
    computed: {
        ...mapGetters("user", ["followMes", "maxExternalNumbers", "ringMode", "twinningTime", "callConfirm"]),

        ringModes() {
            return [
                {value: RING_MODE_RING_ALL, title: "panel.settings.settings-tabs.device-management.ring-modes.ring-all"},
                {value: RING_MODE_HUNT, title: "panel.settings.settings-tabs.device-management.ring-modes.hunt"},
                {value: RING_MODE_TWINNING, title: "panel.settings.settings-tabs.device-management.ring-modes.twinning"}
            ]
        },
        numberOfExternalNumbers() {
            return this.localFollowMes.filter((followMe) => followMe.type === FOLLOW_ME_TYPE_EXTERNAL_NUMBER).length
        },
        somethingHasBeenChanged() {
            return this.localRingMode !== this.ringMode ||
                   this.localCallConfirm !== this.callConfirm ||
                   this.localTwinningTime !== this.localTwinningTime ||
                   JSON.stringify(this.localFollowMes) !== JSON.stringify(this.followMes)
        }
    },
    methods: {
        ...mapActions("user", ["updateFollowMes"]),

        showWarningPopup: function () {
            this.warningPopup = true
        },
        closeWarningPopup: function () {
            this.warningPopup = false
        },
        showAddExternalNumberPopup: function () {
            if (this.maxExternalNumbers === this.numberOfExternalNumbers) {
                this.showWarningPopup()
                return
            }

            this.addExternalNumberPopup = true
        },
        closeAddExternalNumberPopup: function () {
            this.addExternalNumberPopup = false
        },
        onUpdateFollowMeRingTime: function (followMe, value) {
            if (this.localRingMode === RING_MODE_RING_ALL || this.localRingMode === RING_MODE_TWINNING) {
                this.localFollowMes.forEach((item) => {
                    item.ring_time = value
                })
            } else {
                followMe.ring_time = value
            }
        },
        onUpdateFollowMeIsEnabled: function (followMe, value) {
            followMe.is_enabled = value
        },
        onAddExternalNumber: function (phoneNumber) {
            const existingFollowMe = this.localFollowMes.find((followMe) => {
                return followMe.type === FOLLOW_ME_TYPE_EXTERNAL_NUMBER && numbersAreMatched(followMe.display_name, phoneNumber)
            })

            if (existingFollowMe) {
                this.closeAddExternalNumberPopup()
                return
            }

            const followMe = {
                display_name: phoneNumber,
                icon: 'icon-phone',
                ring_time: 30,
                is_enabled: true,
                id: Date.now(),
                type: 'External_number'
            }

            this.closeAddExternalNumberPopup()

            this.localFollowMes.push(followMe)
        },
        onDeleteFollowMe: function (followMe) {
            if (followMe.type === FOLLOW_ME_TYPE_EXTERNAL_NUMBER) {
                this.localFollowMes = this.localFollowMes.filter((item) => item.id !== followMe.id)
            }
        }
    }
}
</script>

<style scoped>

</style>