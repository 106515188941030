<template>
    <span
        class="base-list-item-icon icon"
        :class="icon"
    ></span>
</template>

<script>
export default {
    name: "BaseListItemIcon",
    props: {
        icon: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>