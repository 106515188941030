<template>
    <div class="person-collapsed" :class="{'hold': session.isOnHold}">
        <Avatar
            :size="'big'"
            :src="contact.avatar"
            :text="contact.name"
            :color="contact.color"
            :call-type="session.direction"
        ></Avatar>
        <div class="person-details" :class="{ 'is-known': !!contact.id || contactNameInCrm(session.number)}">
            <span
                v-show="contact.id || contactNameInCrm(session.number)"
                class="name"
            >
                {{ contact.name || contactNameInCrm(session.number) }}
            </span>
            <base-tooltip v-model="copyButton.isCopied" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
                <template v-slot:activator>
                    <span @click="[copyNumber(session.displayName), showTooltip(copyButton, 'isCopied')]" class="number">
                        {{session.displayName}}
                    </span>
                </template>
            </base-tooltip>
            <div class="did-details">
                <span class="label">{{ session.didDetails.label }}</span>
                <span class="caller-id-num">{{formatNumber(session.didDetails.number)}}</span>
            </div>
        </div>
        <div class="call-info">
            <span v-if="status === 'default'" class="person-status">
                {{ session.isOnHold ? '(' + $t('person-collapsed.person-status-hold') + ')' : '(' + $t('person-collapsed.person-status-active') + ')' }}
            </span>
            <span v-if="status === 'outgoing'" class="person-status call-status-animated">{{ 'Outgoing' }}</span>

            <span v-if="status === 'default'" class="person-call-time">{{ session.time }}</span>
        </div>
    </div>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import {formatNumber} from "../../../utils/phoneNumbers";
import {copyNumber, showTooltip} from "../../../utils/copy";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import {mapGetters} from "vuex";

export default {
    name: "PersonCollapsed",
    components: {Avatar, BaseTooltip},
    props: {
        session: {
            type: Object
        },
        status: {
            default: 'default'
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    computed: {
        ...mapGetters("integrations", ["contactNameInCrm"]),
        ...mapGetters('details', ['getContactByNumber']),

        contact() {
            return this.getContactByNumber(this.session.number)
        }
    },
    methods: {
        formatNumber,
        copyNumber,
        showTooltip
    }
}
</script>

<style scoped>

</style>