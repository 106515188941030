import i18n from "../locales"
import DayRange from "./DayRange"
import store from '../store'

const PARAM_NAME_VOICEMAIL_IDS = "voicemail_ids"
const PARAM_NAME_DATE_RANGE = "date_range"
const PARAM_NAME_RANGE_FROM = "from_date"
const PARAM_NAME_RANGE_TO = "to_date"
const PARAM_NAME_UNREAD_MESSAGES_ONLY = "unread_messages_only"

/**
 * Class that represent filters for call voicemails
 */
export default class VoicemailFilters {

    /**
     * @param {null|number[]} voicemail
     * @param {null|DayRange} dayRange
     * @param {null|boolean} unreadMessagesOnly
     */
    constructor({voicemailIds, dayRange, unreadMessagesOnly}) {
        this._voicemailIds = voicemailIds ? voicemailIds : null
        this._dayRange = dayRange ? dayRange : null
        this._unreadMessagesOnly = unreadMessagesOnly ? unreadMessagesOnly : false
    }

    /**
     * @returns {number[]|null}
     */
    get voicemailIds() {
        return this._voicemailIds
    }

    /**
     * @param {number[]|null} value
     */
    set voicemailIds(value) {
        this._voicemailIds = value
    }

    /**
     * @returns {null|DayRange}
     */
    get dayRange() {
        return this._dayRange
    }

    /**
     * @param {DayRange|null} value
     */
    set dayRange(value) {
        this._dayRange = value
    }

    /**
     * @returns {null|boolean}
     */
    get unreadMessagesOnly() {
        return this._unreadMessagesOnly
    }

    /**
     * @param {null|boolean} value
     */
    set unreadMessagesOnly(value) {
        this._unreadMessagesOnly = value
    }

    /**
     * Returns sets of small text info displaying filters that have been set.
     * @returns {[{name: (string), value: (any)}]}
     */
    get chips() {
        let result = []

        if (this.dayRange) {
            const formattedDays = this.dayRange.getFormattedDays()
            result.push({
                name: PARAM_NAME_DATE_RANGE,
                value: i18n.t("panel.services.voicemail.installed-filters.date", {firstDay: formattedDays.first_day, lastDay: formattedDays.last_day})
            })
        }

        if (this.voicemailIds) {
            const values = this.voicemailIds.map((voicemailId) => {
                const voicemail = store.getters["user/voicemails"].find((item) => item.id === voicemailId)
                return i18n.t("panel.services.voicemail.installed-filters.voicemail", {name: voicemail ? voicemail.name : ""})
            })

            result.push({
                name: PARAM_NAME_VOICEMAIL_IDS,
                value: values.join(", ")
            })
        }

        if (this.unreadMessagesOnly) {
            result.push({
                name: PARAM_NAME_UNREAD_MESSAGES_ONLY,
                value: i18n.t("panel.services.voicemail.show-unread-messages-only")
            })
        }

        return result
    }

    /**
     * @returns {{}}
     */
    get requestParams() {
        let result = {}

        if (this.dayRange) {
            const format = "YYYY-MM-DD"
            result[PARAM_NAME_RANGE_FROM] = this.dayRange.firstDay.format(format)
            result[PARAM_NAME_RANGE_TO] = this.dayRange.lastDay.format(format)
        }

        if (this.voicemailIds) {
            result[PARAM_NAME_VOICEMAIL_IDS] = JSON.stringify(this.voicemailIds)
        }

        if (this.unreadMessagesOnly) {
            result[PARAM_NAME_UNREAD_MESSAGES_ONLY] = 1
        }

        return result
    }

    /**
     * @returns {number}
     */
    get count() {
        let result = 0

        if (this.dayRange) {
            result += 1
        }

        if (this.voicemailIds) {
            result += 1
        }

        if (this.unreadMessagesOnly) {
            result += 1
        }

        return result
    }

    /**
     * @param {string} name
     */
    removeFilter(name) {
        if (name === PARAM_NAME_DATE_RANGE) {
            this.dayRange = null
        }

        if (name === PARAM_NAME_VOICEMAIL_IDS) {
            this.voicemailIds = null
        }

        if (name === PARAM_NAME_UNREAD_MESSAGES_ONLY) {
            this.unreadMessagesOnly = false
        }
    }
}