<template>
    <div class="add-keypad-buttons">

        <call-button
            @press="$emit('pressAddCall')"
            buttonClass="add-keypad-buttons add-call"
            iconClass="icon-call"
            :disabled="addCallDisabled"
        ></call-button>

        <call-button
            @press="$emit('pressCancelAddCall')"
            buttonClass="add-keypad-buttons cancel-add-call"
            iconClass="icon-arrow-left"
            :label="$t('softphone.go-back-button')"
        ></call-button>
    </div>
</template>

<script>
import CallButton from "../base/CallButton.vue";
export default {
    name: "ButtonsGroupAddKeypad",
    components: {CallButton},
    props: {
        addCallDisabled: {default: false}
    }
}
</script>

<style scoped>

</style>