import Api from '../../utils/api';

export default {
    namespaced: true,
    state: {
        callRecordings: {}
    },
    getters: {
        callRecordings: (state) => callId => {
            return state.callRecordings[callId] ? Object.values(state.callRecordings[callId]) : []
        }
    },
    actions: {
        getCallRecordings: (context, callId) => {
            return new Promise((resolve, reject) => {
                Api().get('/call_recordings/get_call_recordings', {
                    params: {
                        call_id: callId
                    }
                }).then((response) => {
                    context.commit("setCallRecordings", {callId, callRecordings: response.data})
                    resolve()
                }).catch(() => reject())
            })
        },

        getCallRecordingUrl: (context, {callRecordingId, recStartAt}) => {
            return new Promise((resolve, reject) => {
                Api().get('/call_recordings/get_download_url', {
                    params: {
                        call_recording_id: callRecordingId,
                        rec_start_at: recStartAt
                    }
                }).then((response) => {
                    resolve(response.data.url)
                }).catch(() => reject())
            })
        },

        resetCallRecordings: (context, callId) => {
            context.commit("removeCallRecordings", callId)
        }
    },
    mutations: {
        setCallRecordings: (state, {callId, callRecordings}) => {
            state.callRecordings = {
                ...state.callRecordings,
                [callId]: {...callRecordings}
            }
        },
        removeCallRecordings: (state, callId) => {
            delete state.callRecordings[callId]
        }
    }
}