import Api from '../../utils/api';

export const MESSAGE_DIRNAME_INBOX = "INBOX"
export const MESSAGE_DIRNAME_OLD = "Old"

export default {
    namespaced: true,
    state: {
        voicemails: []
    },
    getters: {
        voicemails: (state) => {
            return state.voicemails
        }
    },
    actions: {
        getVoicemails: (context, params) => {
            return new Promise((resolve, reject) => {
                Api().get('/voicemail/get-list', {
                    params
                }).then((response) => {
                    const voicemails = response.data

                    voicemails.forEach((voicemail) => {
                        const messages = voicemail.messages

                        messages.forEach((message) => {
                            message.id = `${message.dirname}-${message.filename}`
                        })
                    })
                    context.commit("setVoicemails", response.data)
                    resolve()
                }).catch(() => reject())
            })
        },

        getVoicemailMessageFile: (context, {voicemailId, filename, dirname}) => {
            return new Promise((resolve, reject) => {
                Api().get('/voicemail/get-voicemail-message-file', {
                    params: {
                        voicemail_id: voicemailId,
                        filename,
                        dirname
                    },
                    responseType: 'blob'
                }).then((response) => {
                    resolve(response.data)
                }).catch(() => reject())
            })
        },

        removeVoicemailMessages: (context, messages) => {
            return new Promise((resolve, reject) => {
                Api().post('/voicemail/remove-voicemail-messages', {
                    messages
                }).then((response) => {
                    context.commit("removeVoicemailMessages", messages)
                    resolve()
                }).catch(() => reject())
            })
        },

        markAsListenedMessages: (context, messages) => {
            return new Promise((resolve, reject) => {
                Api().post('/voicemail/mark-as-listened-voicemail-messages', {
                    messages
                }).then((response) => {
                    context.commit("updateMessages", response.data)
                    resolve()
                }).catch(() => reject())
            })
        },

        markAsNotListenedMessages: (context, messages) => {
            return new Promise((resolve, reject) => {
                Api().post('/voicemail/mark-as-not-listened-voicemail-messages', {
                    messages
                }).then((response) => {
                    context.commit("updateMessages", response.data)
                    resolve()
                }).catch(() => reject())
            })
        }
    },
    mutations: {
        setVoicemails: (state, voicemails) => {
            state.voicemails = voicemails
        },
        removeVoicemailMessages: (state, messages) => {
            for (let message of messages) {
                let voicemail = state.voicemails.find((voicemail) => voicemail.id === message.voicemail_id)
                voicemail.messages = voicemail.messages.filter((item) => item.filename !== message.filename)
            }
        },
        updateMessages: (state, voicemails) => {
            const newVoicemails = [...state.voicemails]

            voicemails.forEach((voicemail) => {
                const voicemailId = voicemail.voicemail_id
                const messages = voicemail.messages
                const existsVoicemailIndex = state.voicemails.findIndex((voicemail) => voicemail.id === voicemailId)
                const newMessages = [...state.voicemails[existsVoicemailIndex].messages]

                messages.forEach((newMessage) => {
                    const messageIndex = newMessages.findIndex((msg) => msg.filename === newMessage.old_filename)
                    newMessages[messageIndex] = {
                        ...newMessages[messageIndex],
                        filename: newMessage.new_filename,
                        dirname: newMessage.new_dirname,
                        id: `${newMessage.new_dirname}-${newMessage.new_filename}`
                    }
                })

                newVoicemails[existsVoicemailIndex] = {
                    ...state.voicemails[existsVoicemailIndex],
                    messages: newMessages
                }
            })

            state.voicemails = newVoicemails
        }
    }
}
