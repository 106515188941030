<template>
    <div class="caller-id-select-search-input">
        <input
            @input="onInput"
            :value="value"
            :placeholder="placeholder"
            ref="searchInput"
            type="text"
            class="search"
        >
        <div class="actions">
            <span @click="clearSearch" class="search-clear icon-clear"></span>
            <span class="magnifier icon-search"></span>
        </div>
    </div>
</template>

<script>

export default {
    name: "CallerIdSelectSearchInput",
    props: {
        value: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    mounted() {
        window.requestAnimationFrame(() => {
            this.$refs.searchInput.focus()
        })
    },
    methods: {
        onInput(event) {
            this.$emit("input", event.target.value)
        },

        clearSearch() {
            this.$emit("input", "")
            this.$refs.searchInput.focus()
        }
    }
}
</script>

<style scoped>

</style>