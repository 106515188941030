<template>
    <settings-tab :btn-loading="loading" :btn-disabled="!somethingHasBeenChanged" @clickSave="save()">
        <template v-slot:content>
            <form class="settings-form settings-tab-general">
                <div class="form-group filled language-changer">
                    <base-select v-model="dataUser.languageCode" :options="languages" :label="'panel.settings.language-label'"></base-select>
                </div>

                <div class="form-group filled default-phonebook-view-changer">
                    <base-select
                        v-model="dataUser.defaultPhonebookViewId"
                        :options="phonebooksOptions"
                        :label="'panel.settings.default-phonebook-view-label'"
                        :dropdown-max-height="340"
                    ></base-select>
                </div>

                <div class="form-group filled default-page-changer">
                    <base-select
                        v-model="dataUser.defaultPage"
                        :options="pageOptions"
                        :label="'panel.settings.default-page-label'"
                    ></base-select>
                </div>

                <div class="form-group filled default-services-page-changer">
                    <base-select
                        v-model="dataUser.defaultServicesPage"
                        :options="servicesPageOptions"
                        label="panel.settings.default-services-page-label"
                    ></base-select>
                </div>

                <div class="input-group notification-section">
                    <base-switch
                        @change="notificationCheckboxHandler"
                        :value="Boolean(getIsNotificationEnabled)"
                        :disabled="isNotificationBlocked"
                        :label="'panel.settings.notifications.label'"
                    ></base-switch>
                    <div class="blocked" v-show="isNotificationBlocked">
                        {{ $t("panel.settings.notifications.blocked-hint") }} <br>
                        <a href="https://support.voipcloud.online/hc/en-us/articles/4406515793551" target="_blank">{{ $t("panel.settings.notifications.blocked-link") }}</a>
                    </div>
                </div>

                <div v-if="isElectronExist()" class="input-group make-default-section">
                    <!-- checkbox for disable/enable processing "tel:" links clicking -->
                    <base-switch
                        v-model="makeDefaultModel"
                        @change="makeDefaultCheckboxHandler"
                        :label="'panel.settings.default-client-label'"
                        border
                    ></base-switch>
                </div>

                <div v-if="isElectronExist() && (platformType !== 'linux')" class="input-group make-default-section">
                    <!-- checkbox for disable/enable autoload electron app -->
                    <base-switch
                        v-model="autoloadAppModel"
                        @change="toggleAutoloadAppHandler"
                        :label="'panel.settings.autoload-label'"
                        border
                    ></base-switch>
                </div>
            </form>
        </template>
    </settings-tab>
</template>

<script>
import SettingsTab from "./SettingsTab.vue";
import BaseSelect from "../../defaults/BaseSelect.vue";
import {mapActions, mapGetters} from "vuex";
import {sendToElectron, isElectronExist} from "../../../utils/electron";
import i18n, {changeLocale} from "../../../locales";
import BaseSwitch from "../../defaults/BaseSwitch.vue";
import phonebooksSelectOptionsMixin from "../../../mixins/phonebooks/phonebooksSelectOptionsMixin";

export default {
    name: "SettingsTabGeneral",
    components: {BaseSwitch, BaseSelect, SettingsTab},
    mixins: [phonebooksSelectOptionsMixin],
    data() {
        return {
            dataUser: {},
            loading: false,
            notificationPermission: '',
            makeDefaultModel: false,
            autoloadAppModel: false,
            platformType: ''
        }
    },
    created() {
        this.dataUser = Object.assign({}, this.user)

        sendToElectron("isDefaultClient")
            .then((isDefault) => {
                this.makeDefaultModel = isDefault
            })
            .catch((err) => {
                console.log(err)
                this.makeDefaultModel = false
            })

        sendToElectron("appIsAutoload")
            .then((isAutoload) => {
                this.autoloadAppModel = isAutoload
            })
            .catch((err) => {
                console.log(err)
                this.autoloadAppModel = false
            })

        sendToElectron("getPlatformType").then((type) => this.platformType = type)
    },
    mounted() {
        this.requestToAccessNotification()
            .then((permission) => {
                this.toggleNotification(true)
                this.notificationPermission = permission

            })
            .catch((permission) => {
                this.toggleNotification(false)
                this.notificationPermission = permission
            })
    },
    computed: {
        ...mapGetters('user', ['user']),
        ...mapGetters(['languages', 'electronAppVersion']),
        ...mapGetters('details', ['phonebooks']),
        ...mapGetters('softphone', ['getIsNotificationEnabled']),

        pageOptions() {
              return [
                  {name: this.$t("top-bar.menu.softphone"), value: "dialpad"},
                  {name: this.$t("top-bar.menu.phonebooks"), value: "phonebooks"},
                  {name: this.$t("top-bar.menu.sms"), value: "sms"},
                  {name: this.$t("top-bar.menu.history"), value: "history"},
                  {name: this.$t("top-bar.menu.services"), value: "services"}
              ]

        },

        servicesPageOptions() {
            return [
                {name: this.$t("panel.services.queue-membership.title"), value: "queue_membership"},
                {name: this.$t("panel.services.call-parking.title"), value: "call_parking"},
                {name: this.$t("panel.services.voicemail.title"), value: "voicemail"}
            ]
        },

        isNotificationBlocked() {
            return this.notificationPermission === 'denied'
        },

        somethingHasBeenChanged() {
            return this.dataUser.languageCode != this.user.languageCode ||
                   this.dataUser.defaultPhonebookViewId != this.user.defaultPhonebookViewId ||
                   this.dataUser.defaultServicesPage != this.user.defaultServicesPage ||
                   this.dataUser.defaultPage != this.user.defaultPage
        },
    },
    methods: {
        ...mapActions(['createAlertMessage']),
        ...mapActions('softphone', ['toggleNotification']),
        ...mapActions('user', ['changeLanguage', 'changeDefaultPhonebookView', 'changeDefaultServicesPage', 'changeDefaultPage']),

        notificationCheckboxHandler() {
            if (typeof Notification === 'undefined') {
                return
            }

            if (Notification.permission !== "granted" && !this.getIsNotificationEnabled) {
                this.requestToAccessNotification()
                    .then((permission) => {
                        this.toggleNotification(true)
                        this.notificationPermission = permission

                    })
                    .catch((permission) => {
                        this.toggleNotification(false)
                        this.notificationPermission = permission
                    })
            } else {
                this.toggleNotification(!this.getIsNotificationEnabled);
            }
        },

        requestToAccessNotification() {
            return new Promise((resolve, reject) => {
                if (typeof Notification !== 'undefined') {
                    if (Notification.permission !== "granted") {
                        const handleNotificationPermission = (permission) => {
                            // If the user accepts, let's create a notification
                            if (permission === "granted") {
                                resolve(permission)
                            } else {
                                reject(permission)
                            }
                        }
                        try {
                            Notification.requestPermission().then((permission) => {
                                handleNotificationPermission(permission)
                            });
                        } catch (err) {
                            // for safari below version 15
                            if (err instanceof TypeError) {
                                Notification.requestPermission((permission) => {
                                    handleNotificationPermission(permission)
                                });
                            } else {
                                throw err;
                            }
                        }
                    }
                }
            })
        },

        makeDefaultCheckboxHandler() {
            sendToElectron("toggleDefaultClient")
                .then((successfully) => {
                    if (!successfully) {
                        this.makeDefaultModel = !this.makeDefaultModel
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.makeDefaultModel = !this.makeDefaultModel
                })
        },

        toggleAutoloadAppHandler() {
            this.autoloadAppModel = !this.autoloadAppModel
            sendToElectron("toggleAutoload", this.autoloadAppModel)
        },

        save() {
            const user = this.user
            let requests = []

            if (this.dataUser.languageCode != user.languageCode) {
                this.loading = true
                changeLocale(this.dataUser.languageCode)
                requests.push(this.changeLanguage(this.dataUser.languageCode))
            }

            if (this.dataUser.defaultPhonebookViewId != user.defaultPhonebookViewId) {
                this.loading = true
                requests.push(this.changeDefaultPhonebookView(this.dataUser.defaultPhonebookViewId))
            }

            if (this.dataUser.defaultServicesPage != user.defaultServicesPage) {
                this.loading = true
                requests.push(this.changeDefaultServicesPage(this.dataUser.defaultServicesPage))
            }

            if (this.dataUser.defaultPage != user.defaultPage) {
                this.loading = true
                requests.push(this.changeDefaultPage(this.dataUser.defaultPage))
            }

            Promise.all(requests)
                .then(() => this.createAlertMessage({type: 'success', message: i18n.t('panel.settings.changes-saved-successfully')}))
                .catch(() => {})
                .finally(() => this.loading = false)
        },

        isElectronExist
    }
}
</script>

<style scoped>

</style>