import Vue from 'vue'

const SCREENS = {
    mobile: 800,
    tablet: 1100,
    laptop: 1536,
    desktop: Infinity
}

const getBreakpoints = (width) => {
    return {
        isMobile: width < SCREENS.mobile,
        isTablet: width < SCREENS.tablet,
        isLaptop: width < SCREENS.laptop,
        isDesktop: width < SCREENS.desktop
    }
}

const breakpoints = Vue.observable({
    width: window.innerWidth,
    height: window.innerHeight,
    ...getBreakpoints(window.innerWidth)
})

window.addEventListener('resize', () => {
    breakpoints.width = window.innerWidth
    breakpoints.height = window.innerHeight

    const newBreakpoints = getBreakpoints(window.innerWidth)
    Object.keys(newBreakpoints).forEach((key) => breakpoints[key] = newBreakpoints[key])
})

export default breakpoints