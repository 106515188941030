<template>
    <tr
        v-click-outside="() => toggleControlsDropdown(false)"
        :class="{'expanded': isExpanded}"
        class="call-history-table-row"
        @click="rowClick($event)"
        @mouseover="isHover = true"
        @mouseleave="isHover = false"
    >
    <td class="text-title">
        <Avatar
            :src="item.contact ? item.contact.avatar : ''"
            :text="item.contact ? getName(item) : ''"
            :call-type="item.type"
            :color="item.contact ? item.contact.color : '#C9C9C9'"
        ></Avatar>
        <base-tooltip v-model="copyButton.isCopiedName" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
            <template v-slot:activator>
                <div class="entry-title">
                    <p>{{getName(item)}}</p>
                    <p v-show="item.contact" class="entry-title-number">{{ formatNumber(item.number) }}</p>
                </div>
            </template>
        </base-tooltip>
        <copy-button v-if="isHover" @click="[copyContact(item), showTooltip(copyButton, 'isCopiedName')]"></copy-button>
    </td>
    <td class="text-type">
        <div
            class="call-type"
            :class="[item.type, (item.number === 'anonymous' ? 'anonymous-call' : '')]"
            @click="speedDial(item.number, item.callerid)"
        >
            <span class="entry-type-icon icon-call"></span>
            <span class="entry-type-icon" :class="{'icon-call-outgoing': item.type === 'outgoing', 'icon-call-ingoing': item.type === 'incoming', 'icon-call-missed': item.type === 'missed'}"></span>
            <span class="entry-type-text">{{ callType(item.type) }}</span>
        </div>

    </td>
    <td class="text-callerid">
        <base-tooltip v-model="copyButton.isCopiedNumber" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
            <template v-slot:activator>
                <span class="tip"><span class="callerid">{{ formatNumber(item.callerid) }}</span>
                <span class="tip-text tip-text-bottom">{{ getHint(item.type, item.callerid) }}</span></span>
            </template>
        </base-tooltip>
        <copy-button v-if="isHover" @click="[copyNumber(item.callerid), showTooltip(copyButton, 'isCopiedNumber')]"></copy-button>
    </td>
    <td class="text-duration">
        {{ callDuration }}
    </td>
    <td class="text-date">
        <div class="date">
            <span class="tip">
                {{ isExpanded && isMobile ? getCallDateHint(item.date) : callDate(item.date) }}
                <span v-show="!isExpanded" class="tip-text tip-text-bottom">
                    {{ $t("panel.call-history.call-date" + (item.type == 'outgoing' ? '-outgoing' : '') + "") }}
                    <br>
                    {{ getCallDateHint(item.date) }}
                </span>
            </span>
        </div>
        <base-button
            v-if="!item.contact"
            @click="showAddContactPopup(item.number)"
            :size="'medium'"
            :color="'table'"
            :icon="'icon-person-add'"
            class="add-contact-btn"
        ></base-button>
        <base-button
            v-if="canShowIntegrations && item.number !== 'anonymous'"
            @click="expandRow(EXPAND_TAB_CRMS)"
            :size="'medium'"
            :color="isExpandedTab(EXPAND_TAB_CRMS) ? 'dark-gray' : 'table'"
            :icon="'icon-join-right-black'"
            class="show-crms-btn"
        ></base-button>
        <base-button
            v-if="item.is_call_recordings_exist"
            @click="expandRow(EXPAND_TAB_RECORDS)"
            :size="'medium'"
            :color="isExpandedTab(EXPAND_TAB_RECORDS) ? 'dark-gray' : 'table'"
            :icon="'icon-mic'"
            class="show-records-btn"
        ></base-button>
    </td>
    <td class="text-controls">
        <base-button
            v-if="!item.contact"
            @click="showAddContactPopup(item.number)"
            :size="'medium'"
            :color="'table'"
            :icon="'icon-person-add'"
        ></base-button>
        <base-button
            v-if="canShowIntegrations && !isExtension && item.number !== 'anonymous'"
            @click="expandRow(EXPAND_TAB_CRMS)"
            :size="'medium'"
            :color="isExpandedTab(EXPAND_TAB_CRMS) ? 'dark-gray' : 'table'"
            :icon="'icon-join-right-black'"
        ></base-button>
        <base-button
            v-if="item.is_call_recordings_exist"
            @click="expandRow(EXPAND_TAB_RECORDS)"
            :size="'medium'"
            :color="isExpandedTab(EXPAND_TAB_RECORDS) ? 'dark-gray' : 'table'"
            :icon="'icon-mic'"
            class="show-records-btn"
        ></base-button>
    </td>
    <td class="entry-expanded">
        <div class="entry-expanded-contact">
            <base-button
                v-if="!item.contact"
                @click.native.stop="showAddContactPopup(item.number)"
                :size="'medium'"
                :color="'table'"
                :text="'panel.call-history.add-contact-button'"
                :icon="'icon-person-add'"
                class="entry-expanded-button add-contact-btn"
            ></base-button>
            <base-button
                v-if="item.contact"
                @click.native.stop="showEditContactPopup(item.contact)"
                :size="'medium'"
                :color="'table'"
                :text="formatNumber(item.number)"
                class="entry-expanded-button"
            ></base-button>
            <base-button
                v-if="canShowIntegrations && !isExtension && item.number !== 'anonymous'"
                @click.native.stop="expandRow(EXPAND_TAB_CRMS)"
                :size="'medium'"
                :color="isExpandedTab(EXPAND_TAB_CRMS) ? 'dark-gray' : 'table'"
                :icon="'icon-join-right-black'"
                class="show-crms-btn"
            ></base-button>
            <base-button
                v-if="item.is_call_recordings_exist"
                @click.native.stop="expandRow(EXPAND_TAB_RECORDS)"
                :size="'medium'"
                :color="isExpandedTab(EXPAND_TAB_RECORDS) ? 'dark-gray' : 'table'"
                :icon="'icon-mic'"
                class="show-records-btn"
            ></base-button>
        </div>
        <div class="entry-expanded-callerid">{{ formatNumber(item.callerid) }}</div>
        <div class="entry-expanded-duration"> {{ callDuration }}</div>
        <div v-if="isExpanded && isMobile" class="mobile-expanded">
            <integrations-inline
                v-if="isExpandedTab(EXPAND_TAB_CRMS) && canShowIntegrations"
                :number="item.number"
                :inline-call-log-data="getCallLogData(item)"
            ></integrations-inline>

            <call-recordings-viewer
                v-if="isExpandedTab(EXPAND_TAB_RECORDS) && item.is_call_recordings_exist"
                :call-id="item.call_id"
            ></call-recordings-viewer>
        </div>
    </td>
    <td v-if="isExpanded && !isMobile" class="row-expanded">
        <integrations-inline
            v-if="isExpandedTab(EXPAND_TAB_CRMS) && canShowIntegrations"
            :number="item.number"
            :inline-call-log-data="getCallLogData(item)"
        ></integrations-inline>

        <call-recordings-viewer
            v-if="isExpandedTab(EXPAND_TAB_RECORDS) && item.is_call_recordings_exist"
            :call-id="item.call_id"
        ></call-recordings-viewer>
    </td>
    </tr>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import {formatNumber} from "../../../utils/phoneNumbers";
import {speedDial} from "../../../utils/softphone";
import CopyButton from "../../defaults/CopyButton.vue";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import IntegrationsInline from "../../integrations/IntegrationsInline.vue";
import CallRecordingsViewer from "./CallRecordingsViewer.vue";
import {showAddContactPopup, showEditContactPopup} from "../../../utils/phonebooks";
import {copyNumber, showTooltip, copyContact} from "../../../utils/copy";
import {mapGetters} from "vuex";
import dayjsOverride from "../../../helpers/dayjsOverride";
import breakpoints from "../../../plugins/breakpoints";

const EXPAND_TAB_RECORDS = "records"
const EXPAND_TAB_CRMS = "crms"

export default {
    name: "PhonebooksTableRow",
    components: {
        CallRecordingsViewer, BaseButton, IntegrationsInline, Avatar, CopyButton, BaseTooltip
    },
    props: {
        item: {
            type: Object
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        callDuration: {
            type: String,
            default: '00:00'
        },
        canShowIntegrations: {
            type: Boolean,
            default: false
        },
        isExtension: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isExpandControlsDropdown: false,
            copyButton: {
                'isCopiedName': false,
                'isCopiedNumber': false
            },
            EXPAND_TAB_RECORDS,
            EXPAND_TAB_CRMS,
            expandedTabs: {},
            isHover: false
        }
    },
    computed: {
        isMobile() {
            return breakpoints.width < 720
        },
        ...mapGetters('details', ['callTypes']),
        ...mapGetters('user', ['languageCode'])
    },
    methods: {
        toggleControlsDropdown(value) {
            this.isExpandControlsDropdown = value
        },

        getName(item) {
            var name = this.formatNumber(item.number);

            if (item.contact) {
                name = item.contact.name;
            } else if (item.name && item.name.length) {
                name = item.name;
            }

            return name;
        },

        callType(type) {
            if (breakpoints.width < 550) {
                return '';
            }

            return this.$t(this.callTypes[type])
        },

        callDate(date) {
            const vm = this;
            const dayjsObj = dayjsOverride(new Date(date)).locale(this.languageCode)
            const LLFormat = this.getLongDateFormatByBrowserLocale('LL')
            const LTFormat = this.getLongDateFormatByBrowserLocale("LT")

            return dayjsObj.calendar(null, {
                sameDay: '[' + dayjsObj.format(LTFormat) + ']',
                nextDay: 'DD.MM.YYYY HH:mm',
                nextWeek: 'DD.MM.YYYY HH:mm',
                lastDay: `[${vm.$t("panel.call-history.yesterday-call")}]`,
                lastWeek: 'dddd',
                sameElse: '[' + dayjsObj.format(LLFormat) + ']'
            });

        },

        getHint(type, callerid) {
            var hint = '';

            if (type === 'outgoing') {
                hint = this.$t("panel.call-history.caller-id-used");
            } else {
                hint = this.$t("panel.call-history.call-received-on");
            }

            return hint;
        },

        getCallLogData(item) {
            return {
                seconds: item.duration,
                direction: item.type === "outgoing" ? "out" : "in",
                number: item.number,
                startTimestamp: dayjsOverride(item.date).utc().valueOf(),
                cid: item.callerid
            }
        },

        rowClick($event) {
            if (breakpoints.width <= 720 && !$event.target.classList.contains('entry-expanded-button') && !$event.target.classList.contains('btn-table')) {
                let path = $event.composedPath() || $event.path
                // if user click on integrations no need to collapse row
                if (Boolean(path.filter((el) => el.classList ? (el.classList.contains('mobile-expanded') || el.classList.contains('copy-button')) : false).length)) {
                    return
                }

                this.$emit("expand")

                if (this.expandedTabs[this.item.id]) {
                    delete this.expandedTabs[this.item.id]
                }
            }
        },

        expandRow(tabName) {
            if (this.isExpanded && tabName === this.expandedTabs[this.item.id]) {
                delete this.expandedTabs[this.item.id]
                this.$emit("expand")
            } else if (this.isExpanded && tabName !== this.expandedTabs[this.item.id]) {
                this.expandedTabs = {
                    ...this.expandedTabs,
                    [ this.item.id ]: tabName
                }
            } else if (!this.isExpanded) {
                this.expandedTabs[this.item.id] = tabName
                this.$emit("expand")
            }
        },

        isExpandedTab(tabName) {
            return this.expandedTabs[this.item.id] && this.expandedTabs[this.item.id] === tabName
        },

        getLongDateFormatByBrowserLocale(key) {
            const longDateFormat = dayjsOverride().localeData().longDateFormat(key)
            const regex = /\s\[.*?\]|\sг\.|\[-.*?\]/g // find all [de], [kl.], [-мӗшӗ], etc. and "г." in ru locale

            return longDateFormat.replace(regex, '')
        },

        formatNumber(number) {
            if (number === 'anonymous') {
                return this.$t("softphone.caller-id-anonymous")
            }

            return formatNumber(number)
        },

        getCallDateHint(date) {
            const dayjsObj = dayjsOverride(new Date(date))
            const LLLFormat = this.getLongDateFormatByBrowserLocale('LLL')

            return dayjsObj.locale(this.languageCode).format(LLLFormat)
        },

        showAddContactPopup,

        showEditContactPopup,

        speedDial,

        copyNumber,

        showTooltip,

        copyContact
    }
}
</script>

<style scoped>

</style>