import dayjs from "dayjs"

/**
 * Class that represent range between two days
 */
export default class DayRange {

    /**
     * @param {null|dayjs} firstDay
     * @param {null|dayjs} lastDay
     */
    constructor(firstDay= null, lastDay = null) {
        this._range = this._getValidRange(firstDay, lastDay)
    }

    /**
     * @returns {{last_day: (null|dayjs), first_day: (null|dayjs)}}
     */
    get range() {
        return this._range
    }

    /**
     * @returns {null|dayjs}
     */
    get firstDay() {
        return this._range.first_day
    }

    /**
     * @param {dayjs} day
     */
    set firstDay(day) {
        this._range = this._getValidRange(day, this.lastDay)
    }

    /**
     * @returns {null|dayjs}
     */
    get lastDay() {
        return this._range.last_day
    }

    /**
     * @param {dayjs} day
     */
    set lastDay(day) {
        this._range = this._getValidRange(this.firstDay, day)
    }

    /**
     * @param {dayjs} day
     * @returns {boolean}
     */
    isFirst(day) {
        if (this.firstDay) {
            return this.firstDay.isSame(day, 'date')
        }

        return false
    }

    /**
     * @param {dayjs} day
     * @returns {boolean}
     */
    isLast(day) {
        if (this.lastDay) {
            return this.lastDay.isSame(day, 'date')
        }

        return false
    }

    /**
     * @param {dayjs} day
     * @param {string} type
     * @param {string} inclusive
     * @returns {boolean}
     */
    isBetween(day, type = 'day', inclusive = '()') {
        if (this.rangeIsSet()) {
            return day.isBetween(this.firstDay, this.lastDay, type, inclusive)
        }

        return false
    }

    /**
     * @returns {boolean}
     */
    rangeIsSet() {
        return this.firstDay && this.lastDay
    }

    clear() {
        this.firstDay = null
        this.lastDay = null
    }

    /**
     * @returns {{last_day: (string), first_day: (string)}}
     */
    getFormattedDays() {
        const format = "DD.MM.YYYY"
        return {
            first_day: this.firstDay ? this.firstDay.startOf('day').format(format) : '',
            last_day: this.lastDay ? this.lastDay.endOf('day').format(format) : ''
        }
    }

    /**
     * @returns {{last_day: (string), first_day: (string)}}
     */
    getUTCFormattedDays() {
        const format = "YYYY-MM-DDTHH:mm:ss[Z]"
        return {
            first_day: this.firstDay ? this.firstDay.utc().format(format) : '',
            last_day: this.lastDay ? this.lastDay.utc().format(format) : ''
        }
    }

    /**
     * @param {null|dayjs} firstDay
     * @param {null|dayjs} lastDay
     * @returns {{last_day: (null|dayjs), first_day: (null|dayjs)}}
     * @private
     */
    _getValidRange(firstDay = null, lastDay = null) {
        if ((firstDay && lastDay) && (firstDay.valueOf() > lastDay.valueOf())) {
            return {
                first_day: lastDay,
                last_day: firstDay
            }
        }

        return {
            first_day: firstDay,
            last_day: lastDay
        }
    }
}