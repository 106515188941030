export function Stopwatch() {

    console.log('Stopwatch');

    var offset,
        clock,
        interval;

    // default options
    var options = {};
    options.delay     = options.delay || 1000;
    options.startTime = options.startTime || Date.now();
    var renderFunction = () => {console.log('Stopwatch render default')};

    function start(newRenderFunction) {
        if (!interval) {
            offset   = options.startTime;
            interval = setInterval(update, options.delay);
            renderFunction = newRenderFunction;
        }
    }

    function stop(stopFunction = () => {}) {
        console.log('Stopwatch stop')
        if (interval) {
            clearInterval(interval);
            interval = null;
            stopFunction();
        }
    }

    function reset() {
        clock = 0;
        render();
    }

    function update() {
        clock += delta();
        render();
    }

    function render() {
        console.log('Stopwatch render')
        renderFunction(clock)
    }

    function delta() {
        var now = Date.now(),
            d   = now - offset;

        offset = now;
        return d;
    }

    // initialize
    reset();

    // public API
    this.start = start; //function() { start; }
    this.stop  = stop; //function() { stop; }
}