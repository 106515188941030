<template>
    <div class="softphone-state outgoing-incoming state-incoming" v-if="getPrimarySession">
        <div class="person-collapsed-wrapper">
            <person-collapsed
                :session="getPrimarySession"
                status="outgoing"
            ></person-collapsed>
        </div>

        <div class="call-info-wrapper">
            <div class="call-status call-status-animated">
                {{ $t("softphone-state-incoming.call-status") }}
            </div>

            <person
                :session="getSecondSession"
            ></person>
        </div>

        <div class="active-call-buttons-wrapper">
            <call-button
                @press="rejectIncomingCall"
                :label="$t('softphone-state-incoming.buttons.decline')"
                buttonClass="hangup-call-button"
                iconClass="icon-call-end"
            ></call-button>

            <call-button
                @press="acceptIncomingCall"
                :label="$t('softphone-state-incoming.buttons.accept')"
                buttonClass="accept-call-button"
                iconClass="icon-call"
            ></call-button>
        </div>
    </div>
</template>

<script>
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import Avatar from "../../../defaults/Avatar.vue";
import ButtonsGroupManageCall from "../../buttonsGroup/ButtonsGroupManageCall.vue";
import CallButton from "../../base/CallButton.vue";
import PersonCollapsedIncoming from "../../base/PersonCollapsedIncoming.vue";
import {mapActions, mapGetters} from "vuex";
import {formatNumber} from "../../../../utils/phoneNumbers";
import Person from "../../base/Person.vue";

export default {
    name: "SoftphoneStateOutgoingIncoming",
    components: {Person, CallButton, ButtonsGroupManageCall, Avatar, PersonCollapsed, PersonCollapsedIncoming},
    computed: {
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession',
            'getSessions'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'acceptCall',
        ]),

        rejectIncomingCall() {
            this.hangUp(this.getSecondSession.id)
        },

        acceptIncomingCall() {
            this.acceptCall(this.getSecondSession.id)
            this.hangUp(this.getPrimarySession.id)
        },

        formatNumber
    }
}
</script>

<style scoped>

</style>