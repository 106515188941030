import store from '../store'
import Api from "./api"
import i18n from "../locales"
import dayjs from "dayjs";
import {ZAPIER_TYPE} from "../helpers/integrations/crms";
import {isExtension} from "./phoneNumbers";

const getCallLogDataStructure = ({direction, localNum, remoteNum, ownerName, contactType, contactId, startTimestamp, callDuration, note}) => {
    return {
        "tenant": store.getters['user/crmTenantName'].toString(),
        "user_email": store.getters['user/email'],
        "call_direction": direction,
        "local_number": localNum,
        "remote_number": remoteNum,
        "owner_name": ownerName,
        "contact_type": contactType,
        "contact_id": contactId,
        "start_timestamp": startTimestamp,
        "call_duration_sec": callDuration,
        "note": note
    }
}

const prepareLogDataAfterCallEnds = (session) => {
    let callLogData = []
    const number = session.number
    const callLogBufferData = store.getters["integrations/callLogBuffer"](number)

    if (callLogBufferData.length) {
        callLogBufferData.forEach((data) => {
            let callerId
            if (store.getters["user/calleridId"]) {
                callerId = store.getters["user/callerIds"].find((callerid) => callerid.id === store.getters["user/calleridId"])
            }

            let duration = Math.floor(session.callDuration / 1000)
            let dataStructure = getCallLogDataStructure({
                direction: session.direction,
                localNum: callerId ? callerId.num : store.getters["user/user"].extension.toString(),
                remoteNum: session.displayName,
                ownerName: data.ownerName,
                contactType: data.contactType,
                contactId: data.contactId,
                startTimestamp: Math.round(session.startTimestamp / 1000),
                callDuration: duration,
                note: store.getters["integrations/notes"](data.crmType, data.contactId, session.displayName, session.startTimestamp)
            })

            callLogData.push({
                'crm_type': data.crmType,
                'contact_name': data.contactName,
                'data': dataStructure
            })
        })
    }

    return callLogData
}

const logTheCall = (logData, callLogAll = false) => {
    return new Promise((resolve, reject) => {
        Api().post('/integrations/call_log', {
            log_data: logData,
        }).then((response) => {
            if (response.data.success) {
                if (callLogAll) {
                    store.dispatch('createAlertMessage', {message: i18n.t('integrations-popup.call-log-all-success-msg'), type: 'success'})
                } else {
                    store.dispatch('createAlertMessage', {message: i18n.t('integrations-popup.call-log-success-msg'), type: 'success'})
                }
            } else {
                store.dispatch('createAlertMessage', {message: i18n.t('integrations-popup.call-log-failed', [response.data.failed_contacts]), type: 'warning', notHide: true})
            }

            resolve()
        }).catch(() => reject())
    })
}

const fetchCallInfo = (params) => {
    return Api()
        .get('/integrations/call_info',{
            params: params
        })
        .then((response) => response)
}

const getDate = (timestamp) => {
    return timestamp ? dayjs.unix(timestamp).format("DD.MM.YYYY") : timestamp
}

const existEnabledCrms = () => {
    return Boolean(store.getters["integrations/integrations"].find((i) => i.enabled))
}

const onlyZapierEnabled = () => {
    const enabledCrms = store.getters["integrations/integrations"].filter((i) => i.enabled)
    return enabledCrms.length === 1 && enabledCrms[0].type === ZAPIER_TYPE
}

const isIntegrationEnabled = (type) => {
    const integration = store.getters["integrations/integrations"].find((item) => item.type === type)
    return store.getters["user/isIntegrationsEnabled"] && Boolean(integration)
}

const canShowIntegrations = (number) => {
    return Boolean(
            (store.getters["user/isIntegrationsEnabled"] && store.getters["integrations/integrations"].length && !isExtension(number)) ||
            (store.getters["integrations/callInfo"](number).length && !store.getters["integrations/integrations"].length)
        )
}

const splitName = (name) => {
    const result = {
        firstName: "",
        lastName: ""
    }

    name = name.trim()
    const firstSpaceIndex = name.indexOf(' ')
    if (firstSpaceIndex !== -1) {
        result.firstName = name.substring(0, firstSpaceIndex)
        result.lastName = name.substring(firstSpaceIndex + 1)

        return result
    }

    result.firstName = name

    return result
}

export {getCallLogDataStructure, logTheCall, getDate, prepareLogDataAfterCallEnds, existEnabledCrms, splitName, onlyZapierEnabled, isIntegrationEnabled, canShowIntegrations, fetchCallInfo}