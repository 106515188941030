<template>
    <div class="password-rule-checkbox">
        <span :class="{'checked': checked}" class="password-rule-checkbox-checkbox">
            <span class="icon icon-done"></span>
        </span>

        <span v-if="label" class="label-text">{{ $te(label) ? $t(label) : label }}</span>
    </div>
</template>

<script>
export default {
    name: "PasswordRuleCheckbox",
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        }
    }
}
</script>

<style scoped>

</style>