const LOCAL_STORAGE_PREFIX = "user_portal_"

export const localStorageHelper = {
    getItem(key) {
        return localStorage.getItem(LOCAL_STORAGE_PREFIX + key)
    },
    setItem(key, value) {
        localStorage.setItem(LOCAL_STORAGE_PREFIX + key, value)
    },
    removeItem(key) {
        return localStorage.removeItem(LOCAL_STORAGE_PREFIX + key)
    },
    clear() {
        Object.keys(localStorage).filter(v => v.startsWith(LOCAL_STORAGE_PREFIX)).forEach(key => localStorage.removeItem(key));
    }
};

// TODO - remove this after deploy 16.12.22, this will keep calleridId selected by user
const calleridId = localStorage.getItem('calleridId')
if (calleridId) {
    localStorageHelper.setItem('calleridId', calleridId)
    localStorage.removeItem('calleridId')
}