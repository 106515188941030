import SocketIOHelper from "../helpers/SocketIOHelper";
import {localStorageHelper} from "../helpers/LocalStorageHelper";
import {sendToElectron} from "./electron";
import store from '../store';
import router from '../router';

const authLogOut = () => {
    if (store.getters["user/isLoggedIn"]) {
        SocketIOHelper.disconnect()
        localStorageHelper.clear()
        sendToElectron("clearCookie")
        store.dispatch("user/resetState")
        router.push('/login')
    }
}

export { authLogOut }