import { render, staticRenderFns } from "./ButtonsGroupAddKeypad.vue?vue&type=template&id=5b36a35b&scoped=true&"
import script from "./ButtonsGroupAddKeypad.vue?vue&type=script&lang=js&"
export * from "./ButtonsGroupAddKeypad.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b36a35b",
  null
  
)

export default component.exports