<template>
    <div class="softphone-state state-conference">
        <div class="person-collapsed-wrapper" :class="{'union': window.width <= 1280 || window.height <= 875}">
            <person-collapsed
                v-for="session in getSessions"
                :key="session.id"
                :session="session"
            ></person-collapsed>
        </div>

        <div class="call-info-wrapper">
        </div>

        <buttons-group-manage-call
            :mute-btn="{show: true, active: getIsMuted}"
            :add-call-btn="{show: false}"
            :split-btn="{show: true}"
            :transfer-btn="{show: true, disabled: true}"
            :keypad-btn="{show: true, disabled: true}"
            :pause-btn="{show: true, disabled: true}"
            @pressMute="mute"
            @pressSplit="splitCalls"
        ></buttons-group-manage-call>

        <div class="active-call-buttons-wrapper">
            <call-button @press="hangUpConference" buttonClass="hangup-call-button" iconClass="icon-call-end"></call-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ButtonsGroupManageCall from "../../buttonsGroup/ButtonsGroupManageCall.vue";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import CallButton from "../../base/CallButton.vue";

export default {
    name: "SoftphoneStateActiveConference",
    components: {PersonCollapsed, ButtonsGroupManageCall, CallButton},
    data() {
        return {
            window: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }
    },
    created() {
        window.addEventListener("resize", this.resizeWindow);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindow);
    },
    computed: {
        ...mapGetters('softphone', [
            'getIsMuted',
            'getSessions',
            'getInitialSession'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'mute',
            'hangUpConference',
            'splitCalls'
        ]),

        resizeWindow() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    }
}
</script>

<style scoped>

</style>