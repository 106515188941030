<template>
    <div class="base-select">
        <base-dropdown
            v-model="showDropdown"
            :width="dropdownWidth"
            :max-height="dropdownMaxHeight"
            :position="dropdownPosition"
            :align="align"
            open-by="manual"
        >
            <template v-slot:activator>
                <div @mousedown="onInputClick" class="form-group selection" ref="activator" :class="selectionClasses">
                    <input
                        @input="onInput"
                        @focusin="onFocusIn"
                        :value="readonly ? selectedItem.name : inputModel"
                        :readonly="readonly"
                        :placeholder="placeholder ? $t(placeholder) : ''"
                        :class="inputClasses"
                        type="text"
                    >
                    <label v-if="contentLabel">{{ $t(contentLabel) }}</label>
                    <img v-else-if="selectedItem && selectedItem.img" :src="selectedItem.img">
                    <base-tooltip
                        v-if="tooltip"
                        :width="300"
                        :text="tooltip"
                    ></base-tooltip>
                    <span v-show="!showDropdown" class="icon icon-expand-down"></span>
                    <span v-show="showDropdown" class="icon icon-expand-up"></span>
                </div>
            </template>

            <base-list v-if="_options.length">
                <base-list-item
                    v-for="option in _options"
                    @click="selectItem(option)"
                    :selected="option.value === value"
                    :class="option.class"
                    :key="option.value"
                >
                    <base-list-item-icon v-if="option.icon" :icon="option.icon"></base-list-item-icon>
                    <base-list-item-title>{{ option.name }}</base-list-item-title>

                    <base-list-item-phone-number v-if="option.phone_number">{{ option.phone_number }}</base-list-item-phone-number>
                </base-list-item>
            </base-list>

            <base-list v-else :hover="false">
                <base-list-item-title>No elements found</base-list-item-title>
            </base-list>
        </base-dropdown>
    </div>
</template>

<script>
import BaseTooltip from "./BaseTooltip.vue";
import {getPhoneNumberDigitsOnly} from "../../utils/phoneNumbers";
import BaseDropdown from "./BaseDropdown.vue";
import BaseListItem from "./baseList/BaseListItem.vue";
import BaseListItemTitle from "./baseList/BaseListItemTitle.vue";
import BaseListItemPhoneNumber from "./baseList/BaseListItemPhoneNumber.vue";
import BaseListItemIcon from "./baseList/BaseListItemIcon.vue";
import BaseList from "./baseList/BaseList.vue";

const SELECT_SIZE_DEFAULT = "default" // height 40px
const SELECT_SIZE_SMALL = "small" // height 28px

const DEFAULT_OPTION_HEIGHT = 32
const DEFAULT_DROPDOWN_PADDINGS = 14

const DROPDOWN_POSITION_TOP = "top"
const DROPDOWN_POSITION_BOTTOM = "bottom"

const DROPDOWN_ALIGN_LEFT = "left"
const DROPDOWN_ALIGN_CENTER = "center"

export default {
    name: "BaseSelect",
    components: {
        BaseList,
        BaseListItemIcon, BaseListItemPhoneNumber, BaseListItemTitle, BaseListItem, BaseDropdown, BaseTooltip},
    props: {
        options: {
            type: Array,
            default: []
        },
        value: {
            type: String | Number,
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: SELECT_SIZE_DEFAULT
        },
        dropdownWidth: {
            type: Number,
            default: null
        },
        dropdownMaxHeight: {
            type: Number,
            default: 310
        },
        position: {
            type: String,
            default: 'bottom'
        },
        align: {
            type: String,
            default: DROPDOWN_ALIGN_LEFT
        },
        placeholder: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: true
        },
        tooltip: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showDropdown: false,
            dropdownPosition: this.position,
            contentLabel: this.label,
            inputModel: ""
        }
    },
    created() {
        document.addEventListener("keydown", this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.onKeydown)
    },
    computed: {
        _options() {
            if (this.readonly || !this.showDropdown) {
                return this.options
            }

            const value = this.inputModel
            const digitsOnlyValue = getPhoneNumberDigitsOnly(value)
            return this.options.filter((option) => {
                return option.name.toLowerCase().includes(value.toLowerCase()) ||
                    String(option.value).toLowerCase().includes(value.toLowerCase()) ||
                    (
                        option.phone_number &&
                        option.phone_number.length > 0 &&
                        digitsOnlyValue &&
                        getPhoneNumberDigitsOnly(option.phone_number).includes(digitsOnlyValue)
                    )
            })
        },

        selectedItem() {
            const item = this.options.find((option) => option.value === this.value)
            return item ? item : {}
        },

        selectionClasses() {
            return {
                "filled": (this.inputModel && this.inputModel.length) || this.selectedItem.name,
                "size-default": this.size === SELECT_SIZE_DEFAULT || this.contentLabel,
                "size-small": this.size === SELECT_SIZE_SMALL && !this.contentLabel
            }
        },

        inputClasses() {
            return {
                "has-label": this.contentLabel,
                "has-img": !this.contentLabel && this.selectedItem && this.selectedItem.img,
                "has-tooltip": this.tooltip
            }
        }
    },
    watch: {
        showDropdown(value) {
            if (value && !this.readonly) {
                this.inputModel = ""
            }

            if (!value && !this.readonly && (this.inputModel !== this.selectedItem.name)) {
                this.inputModel = this.selectedItem.name
            }
        }
    },
    methods: {
        selectItem(item) {
            this.inputModel = item.name

            this.$emit('input', item.value)
        },
        onInput(event) {
            this.inputModel = event.target.value
        },
        onFocusIn() {
            this.showDropdown = true
        },
        onInputClick() {
            this.showDropdown = !this.showDropdown
        },
        onKeydown(event) {
            if (event.key === "Tab" && this.showDropdown) {
                this.showDropdown = false
            }
        }
    }
}
</script>

<style scoped>

</style>