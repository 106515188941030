<template>
    <div class="caller-id-select-activator">
        <div v-if="value" class="rendered">
            <span class="caller-id-name">{{ value.name }}</span>
            <span class="caller-id-num">{{ value.number }}</span>
        </div>
        <div v-else class="rendered">
            <span class="not-selected">{{ $t("softphone.caller-id-anonymous") }}</span>
        </div>
        <div class="actions">
            <span class="select-dropdown-arrow icon-expand-down"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CallerIdSelectActivator",
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>

<style scoped>

</style>