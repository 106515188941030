<template>
    <div class="softphone-state state-outgoing" v-if="getPrimarySession">
        <div class="call-info-wrapper">
            <div class="call-status call-status-animated">
                {{ $t("softphone-state-outgoing.call-status") }}
            </div>
            <person
                :session="getPrimarySession"
            ></person>
        </div>

        <buttons-group-manage-call
            :mute-btn="{show: true, disabled: true}"
            :pause-btn="{show: true, disabled: true}"
            :add-call-btn="{show: true, disabled: true}"
            :keypad-btn="{show: true, disabled: true}"
            :record-btn="{show: true, disabled: true}"
            :transfer-btn="{show: true, disabled: true}"
        ></buttons-group-manage-call>

        <div class="active-call-buttons-wrapper">
            <call-button @press="hangUp(getPrimarySession.id)" buttonClass="hangup-call-button" iconClass="icon-call-end"></call-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "../../../defaults/Avatar.vue";
import ButtonsGroupManageCall from "../../buttonsGroup/ButtonsGroupManageCall.vue";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import CallButton from "../../base/CallButton.vue";
import {formatNumber} from "../../../../utils/phoneNumbers";
import Person from "../../base/Person.vue";

export default {
    name: "SoftphoneStateOutgoing",
    components: {Person, CallButton, ButtonsGroupManageCall, Avatar, PersonCollapsed},
    computed: {
        ...mapGetters('softphone', ['getPrimarySession']),
    },
    methods: {
        ...mapActions('softphone', ['hangUp']),

        formatNumber
    }
}
</script>

<style scoped>

</style>