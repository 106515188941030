<template>
  <div class="settings-tab">
      <div class="settings-tab-header">
          <span @click="clickBackToMenu" class="icon icon-arrow-left"></span>
          <span v-if="tabName" class="tab-name">{{ $t(tabName) }}</span>
      </div>

      <div class="settings-tab-content">
          <slot name="content">

          </slot>
      </div>

      <div class="settings-tab-actions">
          <base-button
              v-if="showSaveBtn"
              @click="clickSave"
              :text="'panel.settings.save-settings-button'"
              :loading="btnLoading"
              :disabled="btnDisabled"
              :color="'blue'"
              class="save-settings-button"
          ></base-button>

          <slot name="appendActions">

          </slot>
      </div>
  </div>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";

export default {
    name: "SettingsTab",
    components: {BaseButton},
    props: {
        tabName: {
            type: String,
            default: ""
        },
        btnLoading: {
            type: Boolean,
            default: false
        },
        btnDisabled: {
            type: Boolean,
            default: false
        },
        showSaveBtn: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        clickSave() {
            this.$emit("clickSave")
        },

        clickBackToMenu() {
            this.$root.$emit('clickBackToMenu')
        }
    }
}
</script>

<style scoped>

</style>