import Zoho from "./crmTypes/ZohoCrm";
import Hubspot from "./crmTypes/HubspotCrm";
import Zendesk from "./crmTypes/ZendeskCrm";
import Zapier from "./crmTypes/ZapierCrm";

const ZOHO_TYPE = "zoho"
const HUBSPOT_TYPE = "hubspot"
const ZENDESK_TYPE = "zendesk"
const ZAPIER_TYPE = "zapier"

export default class Crm {
    create(type) {
        let Crm

        if (type === ZOHO_TYPE) {
            Crm = Zoho;
        } else if (type === HUBSPOT_TYPE) {
            Crm = Hubspot;
        } else if (type === ZENDESK_TYPE) {
            Crm = Zendesk;
        } else if (type === ZAPIER_TYPE) {
            Crm = Zapier;
        }

        return new Crm({type: type})
    }
}

export {ZOHO_TYPE, HUBSPOT_TYPE, ZENDESK_TYPE, ZAPIER_TYPE}