import {mapGetters} from "vuex";

const callParkingSelectMixin = {
    data() {
        return {
            selectedCallParkingNumber: "all"
        }
    },
    computed: {
        ...mapGetters("callParkings", ["callParkings"]),

        isAll() {
            return this.selectedCallParkingNumber === "all"
        },

        filteredCallParkings() {
            if (this.isAll) {
                return this.callParkings
            }

            return this.callParkings.filter(callParking => callParking.number === this.selectedCallParkingNumber)
        }
    }
}

export default callParkingSelectMixin