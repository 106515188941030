<template>
    <div :class="classes" class="dial-pad-action-button">
        <base-tooltip
            :disabled="isMobile || !tooltip"
            :width="95"
            :text="tooltip"
            text-align="center"
            position="top"
        >
            <template v-slot:activator>
                <button
                    @mousedown.stop.prevent="startHoldRemove"
                    @touchstart.stop.prevent="startHoldRemove"
                    @mouseup.stop.prevent="endHoldRemove"
                    @touchcancel.stop.prevent="endHoldRemove"
                    @touchend.stop.prevent="endHoldRemove"
                    class="number-remove-button"
                >
                    <span
                        :class="icon"
                        class="icon"
                    ></span>
                </button>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import BaseTooltip from "../../defaults/BaseTooltip.vue";

const DIAL_PAD_COLOR_LIGHT = "color_light"
const DIAL_PAD_COLOR_DARK = "color_dark"

const POSITION_LEFT = "left"
const POSITION_RIGHT = "right"

export default {
    name: "DialPadActionButton",
    components: {BaseTooltip},
    props: {
        icon: {
            type: String
        },
        position: {
            type: String
        },
        tooltip: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: DIAL_PAD_COLOR_LIGHT
        }
    },
    computed: {
        classes() {
            return {
                "dial-pad-action-button-color-light": this.color === DIAL_PAD_COLOR_LIGHT,
                "dial-pad-action-button-color-dark": this.color === DIAL_PAD_COLOR_DARK,

                "dial-pad-action-button-position-right": this.position === POSITION_RIGHT,
                "dial-pad-action-button-position-left": this.position === POSITION_LEFT
            }
        },
        isMobile() {
            return window.innerWidth < 800
        }
    },
    methods: {
        startHoldRemove() {
            this.clearInputTimeout = setTimeout(() => {
                this.$emit("longClick")
            }, 600)
        },
        endHoldRemove() {
            clearInterval(this.clearInputTimeout)
            this.$emit("click")
        }
    }
}
</script>

<style scoped>

</style>