<template>
    <active-call-keypad-tab
        @input="onSelect"
        :value="value"
        :search-callback="searchCallback"
        :items="callHistory"
        no-items-text="active-call-keypad.no-history"
        class="active-call-keypad-history-tab"
    >
        <template v-slot:item="{item}">
            <history-speed-dial-list-item :item="item"></history-speed-dial-list-item>
        </template>
    </active-call-keypad-tab>
</template>

<script>
import HistorySpeedDialListItem from "./HistorySpeedDialListItem.vue";
import {mapGetters} from "vuex";
import ActiveCallKeypadTab from "../ActiveCallKeypadTab.vue";
import {findInContact} from "../../../../../../utils/phonebooks";
import {getPhoneNumberDigitsOnly, numbersAreMatched} from "../../../../../../utils/phoneNumbers";

export default {
    name: "HistoryTab",
    components: {ActiveCallKeypadTab, HistorySpeedDialListItem},
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapGetters("details", ["callHistory"]),
        ...mapGetters('details', [ 'getContactByNumber']),
    },
    methods: {
        searchCallback(searchString, item) {
            if (!searchString) {
                return true
            }

            const contact = this.getContactByNumber(item.number)
            if (contact.id) {
                return findInContact(searchString, contact)
            }

            const sanitizedSearchString = getPhoneNumberDigitsOnly(searchString)
            if (sanitizedSearchString) {
                return numbersAreMatched(searchString, item.number) || getPhoneNumberDigitsOnly(item.number).includes(sanitizedSearchString)
            }

            return false
        },
        onSelect(number) {
            this.$emit("input", number)
        }
    }
}
</script>

<style scoped>

</style>