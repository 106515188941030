<template>
    <div :class="{'inline': inline}" class="crm-contact-minimized" @click="onClick">
        <img :src="getCrmImgPath()">

        <div class="info-group">
            <span class="data">{{ contactName }}</span>
            <span class="label">{{ $t("integrations-popup.name") }}</span>
        </div>

        <span v-if="showUpIcon" class="icon icon-expand-up"></span>
        <span v-if="showDownIcon" class="icon icon-expand-down"></span>
    </div>
</template>

<script>
import Crm from "../../../helpers/integrations/crms";

export default {
    name: "MinimizedContactInfo",
    props: {
        crmType: {
            type: String,
            default: ""
        },
        contactName: {
            type: String,
            default: ""
        },
        expanded: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showUpIcon: this.expanded,
            showDownIcon: !this.expanded
        }
    },
    watch: {
        expanded() {
            this.showUpIcon = this.expanded
            this.showDownIcon = !this.expanded
        }
    },
    methods: {
        onClick: function() {
            this.$emit("contact-click")
        },
        getCrmImgPath: function() {
            const crm = new Crm().create(this.crmType)
            return require(`../../../images/crmLogos/${crm.smallLogo}`)
        }
    }
}
</script>

<style scoped>

</style>