<template>
    <div class="base-table" :class="classes" ref="scroll">
        <div class="base-table-content-scroll">
            <table>
                <colgroup>
                    <col
                        v-for="(widthColumn, idx) in widthColumns"
                        :width="isMobile ? null : widthColumn"
                        :key="idx"
                    >
                </colgroup>

                <base-table-header
                    @select="isFullSelected ? fullDeselect() : fullSelect()"
                    :headers="headers"
                    :selectable="selectable"
                    :multiple="isMultipleSelect"
                    :selected="isFullSelected"
                    :semi-selected="isSemiSelected"
                ></base-table-header>

                <tbody>
                    <template v-for="(item, idx) in items" >
                        <slot :item="item" :toggleRow="toggleRow" :isExpanded="expandedRowKeys.includes(item[itemKey])" name="row">
                            <base-table-row
                                @select="isSelectedRow(item[itemKey]) ? deselectRow(item) : selectRow(item)"
                                :columns="columns[idx]"
                                :selectable="selectable"
                                :selected="isSelectedRow(item[itemKey])"
                            ></base-table-row>
                        </slot>

                        <tr v-if="expandableRows && expandedRowKeys.includes(item[itemKey])" class="base-table-expanded-row">
                            <td :colspan="expandedRowColspan">
                                <slot :item="item" name="expandedRow"></slot>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import BaseSpinner from "../BaseSpinner.vue";
import BaseTableRow from "./BaseTableRow.vue";
import BaseTableHeader from "./BaseTableHeader.vue";

export default {
    name: "BaseTable",
    components: {BaseTableHeader, BaseTableRow, BaseSpinner},
    props: {
        value: {
            type: [Array, Object],
            default: null
        },
        selectable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array,
            default: []
        },
        items: {
            type: Array,
            default: []
        },
        loading: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        expandableRows: {
            type: Boolean,
            default: false
        },
        itemKey: {
            type: String,
            default: "id"
        },
        withScroll: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        showExpandedRowsByDefault: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expandedRowKeys: []
        }
    },
    created() {
        if (this.withScroll) {
            this.$vuebar.initScrollbar(this.$refs.scroll)
        }

        if (this.showExpandedRowsByDefault) {
            this.expandAllRows()
        }
    },
    watch: {
        showExpandedRowsByDefault(value) {
            if (value) {
                this.expandAllRows()
            } else {
                this.collapseAllRows()
            }
        },
        items() {
            if (this.showExpandedRowsByDefault) {
                this.expandAllRows()
            }
        }
    },
    computed: {
        classes() {
            return {
                "base-table-rounded": this.rounded
            }
        },
        isSemiSelected() {
            return (this.value instanceof Array) && this.value.length !== this.items.length && this.value.length > 0
        },
        isFullSelected() {
            return (this.value instanceof Array) && this.value.length === this.items.length && this.items.length > 0
        },
        isMultipleSelect() {
            return this.selectable && this.multiple && this.value instanceof Array
        },
        columns() {
            return this.items.map(item => {
                return this.headers.map(header => {
                    return {
                        value: item[header.key],
                        class: header.class
                    }
                })
            })
        },
        widthColumns() {
            const weight = this.headers.reduce((prev, current) => typeof current.fr === 'number' ? prev + current.fr : prev, 0)
            const result = this.headers.map(v => typeof v.fr === 'number' ? `${v.fr / weight * 100}%` : v.fr)

            if (this.selectable) {
                result.unshift("52px") // fixed width for checkbox
            }

            return result
        },
        expandedRowColspan() {
            let result = this.headers.length

            if (this.selectable) {
                result += 1
            }

            return result
        }
    },
    methods: {
        fullSelect() {
            if (!this.isMultipleSelect) {
                return
            }

            this.$emit("input", this.items)
        },
        fullDeselect() {
            if (!this.isMultipleSelect) {
                return
            }

            this.$emit("input", [])
        },
        selectRow(item) {
            if (!this.selectable) {
                return
            }

            let newValue
            if (this.isMultipleSelect) {
                newValue = [...this.value]
                newValue.push(item)
            } else {
                newValue = item
            }

            this.$emit("input", newValue)
        },
        deselectRow(item) {
            if (!this.selectable) {
                return
            }

            const newValue = this.isMultipleSelect ? this.value.filter((i) => i[this.itemKey] !== item[this.itemKey]) : {}
            this.$emit("input", newValue)
        },
        isSelectedRow(key) {
            if (!this.selectable) {
                return
            }

            if (this.isMultipleSelect) {
                return Boolean(this.value.find((item) => item[this.itemKey] === key))
            }

           return this.value[this.itemKey] === key
        },
        toggleRow(key) {
            if (this.expandedRowKeys.includes(key)) {
                this.expandedRowKeys = this.expandedRowKeys.filter(rowKey => rowKey !== key)
            } else {
                this.expandedRowKeys.push(key)
            }
        },
        expandAllRows() {
            this.expandedRowKeys = this.items.map((item) => item[this.itemKey])
        },
        collapseAllRows() {
            this.expandedRowKeys = []
        }
    }
}
</script>

<style scoped>

</style>