<template>
    <div class="base-tab-selector">
        <div
            v-for="(item, id) in items"
            @click="onClick(item.value)"
            :key="id"
            :class="{'base-tab-selector-item-selected': item.value === value}"
            class="base-tab-selector-item"
        >
            <span>{{ $te(item.title) ? $t(item.title) : item.title }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseTabSelector",
    props: {
        value: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: []
        }
    },
    methods: {
        onClick(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style scoped>

</style>