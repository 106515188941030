const TABS = Object.freeze({
    KEYPAD_TAB: {
        name: "keypad_tab",
        text: "top-bar.menu.softphone",
        icon: "icon-dialpad"
    },
    CONTACTS_TAB: {
        name: "contacts_tab",
        text: "top-bar.menu.phonebooks",
        icon: "icon-stories"
    },
    HISTORY_TAB: {
        name: "history_tab",
        text: "top-bar.menu.history",
        icon: "icon-history"
    },
    CALL_PARKING_TAB: {
        name: "call_parking_tab",
        text: "panel.services.call-parking.title",
        icon: "icon-local-parking"
    }
})

export {TABS}