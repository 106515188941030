<template>
    <base-popup
        v-on="$v.$invalid ? {} : {save: saveContact}"
        @close="close"
        :title="title"
        :save-text="saveText"
        :save-btn-disabled="$v.$invalid"
        close-text="popups.contact-popup.cancel-button"
        popup-class="contact-popup"
    >
        <form>
            <div class="contact-popup-avatar-section">
                <Avatar :size="'extraBig'" :src="editedContact.avatar" :text="editedContact.name" :color="editedContact.color"></Avatar>
                <input @input="inputImage" type="file" ref="imageUploader" id="change-contact-picture" style="display: none" accept="image/*" class="has-label">
                <base-button
                    @click="$refs.imageUploader.click()"
                    :size="'big'"
                    :color="'blue'"
                    :text="'popups.contact-popup.update-picture-label'"
                ></base-button>
                <base-button
                    @click="deleteImage"
                    :text="'popups.contact-popup.delete-picture-label'"
                    :size="'big'"
                ></base-button>
            </div>

            <div class="form-group" :class="{'filled': editedContact.phonebook_id}">
                <base-select
                    v-model="$v.editedContact.phonebook_id.$model"
                    :dropdown-max-height="330"
                    :options="phonebooksOptions"
                    :label="'popups.contact-popup.phonebook-label'"
                    :key="editedContact.phonebook_id"
                ></base-select>
                <div class="invalid-feedback"  v-if="$v.editedContact.phonebook_id.$dirty">
                    <span v-if="!$v.editedContact.phonebook_id.required">{{ $t("popups.contact-popup.invalid-feedback.required-field") }}</span>
                </div>
            </div>

            <div class="form-group" :class="{'filled': editedContact.name}">
                <base-input
                    v-model="$v.editedContact.name.$model"
                    :error="$v.editedContact.name.$invalid"
                    placeholder="popups.contact-popup.name-label"
                    label="popups.contact-popup.name-label"
                    type="text"
                    ref="contactNameInput"
                ></base-input>

                <div class="invalid-feedback" v-if="$v.editedContact.name.$dirty">
                    <span v-if="!$v.editedContact.name.required">{{ $t("popups.contact-popup.invalid-feedback.required-field") }}</span>
                    <span v-else-if="!$v.editedContact.name.maxLength">{{ $t("popups.contact-popup.invalid-feedback.name-max-length") }}</span>
                </div>
            </div>

            <div class="form-group" :class="{'filled': editedContact.number}">
                <base-input
                    v-model="$v.editedContact.number.$model"
                    @input="inputNumber"
                    :error="$v.editedContact.number.$invalid"
                    placeholder="popups.contact-popup.number-label"
                    label="popups.contact-popup.number-label"
                    type="phone"
                ></base-input>

                <div class="invalid-feedback" v-if="$v.editedContact.number.$dirty">
                    <span v-if="!$v.editedContact.number.required">{{ $t("popups.contact-popup.invalid-feedback.required-field") }}</span>
                    <span v-else-if="!$v.editedContact.number.validFormat">{{ $t("popups.contact-popup.invalid-feedback.invalid-number") }}</span>
                </div>
            </div>

            <div class="form-group" :class="{'filled': editedContact.email}">
                <base-input
                    v-model="editedContact.email"
                    :error="$v.editedContact.email.$invalid"
                    placeholder="popups.contact-popup.email-label"
                    label="popups.contact-popup.email-label"
                    type="text"
                ></base-input>

                <div class="invalid-feedback">
                    <span v-if="!$v.editedContact.email.email">{{ $t("popups.contact-popup.invalid-feedback.invalid-email") }}</span>
                </div>
            </div>
        </form>
    </base-popup>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { required, maxLength, email } from 'vuelidate/dist/validators.min.js'
import Avatar from "../Avatar.vue";
import {formatNumber, getPhoneNumberDigitsOnly} from "../../../utils/phoneNumbers.js";
import BaseSelect from "../BaseSelect.vue";
import BaseButton from "../BaseButton.vue";
import BasePopup from "../BasePopup.vue";
import BaseInput from "../BaseInput.vue";

export default {
    name: "ContactPopup",
    components: {BasePopup, BaseInput, BaseButton, BaseSelect, Avatar},
    props: ['contact'],
    data() {
        return {
            editedContact: {},
            expandCalleridDropdown: false
        }
    },
    mounted() {
        this.updateEditedContact()
    },
    validations: {
        editedContact: {
            name: {
                required,
                maxLength: maxLength(120)
            },
            email: {
                email
            },
            number: {
                required,
                validFormat: val => /^[\+]?(\d{1,15})$/.test(getPhoneNumberDigitsOnly(val))
            },
            phonebook_id: {
                required
            }
        }
    },
    watch: {
        contact () {
            this.updateEditedContact()
        }
    },
    computed: {
        ...mapGetters('details', [
            'defaultContact',
            'phonebooks'
        ]),

        ...mapGetters('user', [
            'user'
        ]),

        phonebooksOptions() {
            let options = []
            this.phonebooks.forEach((phonebook) => {
                if (!phonebook.readonly) {
                    options.push({name: phonebook.name, value: phonebook.id})
                }
            })
            return options
        },

        title() {
            return this.editedContact.is_new ? 'popups.contact-popup.title-add-contact' : 'popups.contact-popup.title-update-contact'
        },

        saveText() {
            return this.editedContact.is_new ? 'popups.contact-popup.add-contact-button' : 'popups.contact-popup.update-contact-button'
        }
    },
    methods: {
        ...mapActions('details', [
            'createContact',
            'editContact'
        ]),

        inputImage(event) {
            const image = event.target.files[0];
            if (image) {
                this.editedContact.image = image;
                this.editedContact.avatar = URL.createObjectURL(image);
            }
        },
        deleteImage() {
            this.editedContact.avatar = "";
            this.editedContact.image = null;
        },
        close () {
            this.editedContact = Object.assign({}, this.defaultContact);
            this.$v.$reset();
            this.$emit('close');
        },
        saveContact() {
            if (this.editedContact.is_new) {
                this.createContact(this.editedContact)
            } else {
                this.editContact(this.editedContact)
            }
            this.editedContact = Object.assign({}, this.defaultContact);
            this.$v.$reset();
            this.$emit('close');
        },
        inputNumber() {
            this.editedContact.number = formatNumber(this.editedContact.number)
        },
        updateEditedContact() {
            this.editedContact = Object.assign({}, this.contact);
            this.editedContact.number = formatNumber(this.editedContact.number)

            if (this.editedContact.is_new) {
                this.$nextTick(() => {
                    this.$refs.contactNameInput.$el.querySelector('input').focus()
                })
            }
        }
    }
}
</script>

<style scoped>

</style>