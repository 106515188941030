<template>
    <div class="base-tooltip">
        <base-dropdown
            v-model="localValue"
            :open-by="openBy"
            :width="width"
            :position="position"
            :disabled="disabled"
            :close-on-content-click="false"
            content-class="base-tooltip-content"
        >
            <template v-slot:activator>
                <slot name="activator">
                    <span class="base-tooltip-default-activator">?</span>
                </slot>
            </template>

            <template v-slot:no-scrollable-content>
                <p :style="{'textAlign': textAlign}" >{{ $te(text) ? $t(text) : text }}</p>
                <div
                    :class="{'top-arrow': position === 'bottom', 'bottom-arrow': position === 'top'}"
                    class="arrow"
                ></div>
            </template>
        </base-dropdown>
    </div>
</template>

<script>
import BaseDropdown from "./BaseDropdown.vue"

const OPEN_BY_HOVER = 'hover'
const OPEN_BY_MANUAL = 'manual'

const POSITION_TOP = 'top'
const POSITION_BOTTOM = 'bottom'

export default {
    name: "BaseTooltip",
    components: {BaseDropdown},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ""
        },
        width: {
            type: Number,
            default: 142
        },
        position: {
            type: String,
            default: POSITION_TOP
        },
        disabled: {
            type: Boolean,
            default: false
        },
        textAlign: {
            type: String,
            default: 'left'
        },
        openBy: {
            type: String,
            default: OPEN_BY_HOVER
        }
    },
    data() {
        return {
            localValue: this.value
        }
    },
    watch: {
        value(v) {
            this.localValue = v
        }
    }
}
</script>

<style scoped>

</style>