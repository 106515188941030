<template>
    <div class="date-picker-months">
        <button
            v-for="month in months"
            :class="{'current': month.current, 'active': month.active}"
            @click="clickHandler(month.value)"
            class="calendar-btn"
        >
            {{ month.title }}
        </button>
    </div>
</template>

<script>
import dayjs from "dayjs"
import DayRange from "../../../helpers/DayRange";

export default {
    name: "DatePickerMonths",
    props: {
        selectedDate: {
            type: dayjs,
            default: null
        },
        now: {
            type: dayjs,
            default: null
        },
        dayRange: {
            type: DayRange,
            default: null
        }
    },
    computed: {
        months() {
            const startMonth = 0
            const endMonth = 11
            const currentMonth = this.now.month()
            const currentYear = this.now.year()
            const selectedYear = this.selectedDate.year()

            const monthsShort = this.now.localeData().monthsShort()

            let result = []
            for (let i = startMonth; i <= endMonth; i++) {
                result.push({
                    title: monthsShort[i],
                    value: i,
                    current: i === currentMonth && selectedYear === currentYear,
                    active: this.dayRange.rangeIsSet() ? this.dayRange.isBetween(dayjs().set('year', selectedYear).set('month', i), 'month', '[]') : (this.dayRange.firstDay.year() === selectedYear && this.dayRange.firstDay.month() === i)
                })
            }

            return result
        }
    },
    methods: {
        clickHandler(month) {
            this.$emit('select', month)
        }
    }
}
</script>

<style scoped>

</style>