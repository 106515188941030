/**
 * Class that represent field for creating a CRM contact
 */
export default class ContactCreationField {
    /**
     * @param {string} name
     * @param {string} value
     * @param {string} label
     * @param {boolean} dirty
     * @param {{func: function, not_valid_label: string}[]} validation
     */
    constructor({name, value, label, validation}) {
        this._name = name
        this._value = value
        this._label = label
        this._validation = validation
        this._dirty = false
    }

    /**
     * @returns {string}
     */
    get name() {
        return this._name
    }

    /**
     * @returns {string}
     */
    get value() {
        return this._value
    }

    /**
     * @returns {string}
     */
    set value(value) {
        this._value = value
    }

    /**
     * @returns {string}
     */
    get label() {
        return this._label
    }

    /**
     * @returns {boolean}
     */
    set dirty(value) {
        this._dirty = value
    }

    /**
     * @returns {boolean}
     */
    get dirty() {
        return this._dirty
    }

    /**
     * @returns {{func: Function, not_valid_label: string}[]}
     */
    get validation() {
        return this._validation
    }
}