<template>
  <div id="panel" class="panel">
      <Phonebooks :active = "activeTab === 'phonebooks'" :loading="phonebooksIsLoading"></Phonebooks>
      <CallHistory :active = "activeTab === 'history'" :windowWidth="windowWidth" :loading="historyIsLoading"></CallHistory>
<!--      <QueueMembership  :active = "activeTab === 'queues'"></QueueMembership>-->
      <Settings v-if="user.extension" :user="user" :windowWidth="windowWidth" :active = "activeTab === 'settings'"></Settings>
      <sms :active="activeTab === 'sms'" :window-width="windowWidth"></sms>
      <services v-if="activeTab === 'services'" :active="activeTab === 'services'" :window-width="windowWidth"></services>
  </div>
</template>

<script>
  import Phonebooks from "./phonebooks/Phonebooks.vue";
  import CallHistory from "./callHistory/CallHistory.vue";
  import Settings from "./settings/Settings.vue";
  import {mapActions, mapGetters} from "vuex";
  import Sms from "./sms/Sms.vue";
  import BaseTabs from "../defaults/BaseTabs.vue";
  import Services from "./services/Services.vue"

  export default {
    name: "Panel",
    props: {
        windowWidth: Number,
        phonebooksIsLoading: Boolean,
        historyIsLoading: Boolean
    },
    components: {Services, Sms, BaseTabs, Phonebooks, CallHistory, Settings},
    data() {
      return {
          integrationPopupNumbers: []
      }
    },
    computed: {
      ...mapGetters([
        'activeTab'
      ]),
      ...mapGetters('user', [
        'user'
      ])
    }
  }
</script>

<style scoped>

</style>