import { required, email } from 'vuelidate/dist/validators.min.js'
import {getDate, splitName} from "../../../../utils/integrations";
import AbstractCrm from "./AbstractCrm";
import ContactCreationField from "../../ContactCreationField";
import GeneralInfoField from "../../GeneralInfoField";
import Card from "../../Card";

const CRM_TITLE = "HubSpot"
const CRM_SMALL_LOGO = "hub_spot.svg"
const CRM_BIG_LOGO = "hub_spot_big.svg"

/**
 * Class that represent Hubspot CRM
 */
export default class HubspotCrm extends AbstractCrm {

    /**
     * @param {string} type
     */
    constructor({type}) {
        super({
            type,
            smallLogo: CRM_SMALL_LOGO,
            bigLogo: CRM_BIG_LOGO,
            title: CRM_TITLE
        })
    }

    /**
     * @param {ContactCreationField[]} fields
     * @returns {string}
     */
    getNameFromContactCreationFields(fields) {
        const firstName = fields.find(field => field.name === "contact_first_name")
        const lastName = fields.find(field => field.name === "contact_last_name")
        return `${firstName.value} ${lastName.value}`
    }

    /**
     * @param {Object} contact
     * @returns {{tenant: string, email: string, crm_type: string, crm_contact_id: number, crm_object_type: string}}
     */
    getParamsForCallInfoExtendedRequest(contact) {
        return {
            ...super.getParamsForCallInfoExtendedRequest(),
            crm_type: this.type,
            crm_contact_id: contact.crm_object_id,
            crm_object_type: contact.crm_object_type
        }
    }

    /**
     * @returns {string}
     */
    getCreateButtonName() {
        return "integrations-popup.create-contact.create-contact-btn"
    }

    /**
     * @param {string} number
     * @param {string} name
     * @param {string} emailValue
     * @returns {ContactCreationField[]}
     */
    getCreateContactFields(number, name, emailValue) {
        const separatedName = splitName(name)
        return [
            new ContactCreationField({
                name: 'contact_phone',
                value: number,
                label: "integrations-popup.create-contact.contact-phone",
                validation: [
                    {
                        func: required,
                        not_valid_label: "integrations-popup.create-contact.invalid-feedback.required-field"
                    }
                ]
            }),
            new ContactCreationField({
                name: 'contact_first_name',
                value: separatedName.firstName,
                label: "integrations-popup.create-contact.contact-first-name",
                validation: [
                    {
                        func: required,
                        not_valid_label: "integrations-popup.create-contact.invalid-feedback.required-field"
                    }
                ]
            }),
            new ContactCreationField({
                name: 'contact_last_name',
                value: separatedName.lastName,
                label: "integrations-popup.create-contact.contact-last-name",
                validation: [
                    {
                        func: required,
                        not_valid_label: "integrations-popup.create-contact.invalid-feedback.required-field"
                    }
                ]
            }),
            new ContactCreationField({
                name: 'contact_email',
                value: emailValue,
                label: "integrations-popup.create-contact.contact-email",
                validation: [
                    {
                        func: email,
                        not_valid_label: "integrations-popup.create-contact.invalid-feedback.invalid-email"
                    },
                    {
                        func: required,
                        not_valid_label: "integrations-popup.create-contact.invalid-feedback.required-field"
                    }
                ]
            }),
            new ContactCreationField({
                name: 'contact_company',
                value: '',
                label: "integrations-popup.create-contact.contact-company",
                validation: []
            })
        ]
    }

    /**
     *
     * @param {Object} generalInfo
     * @param {Object} extendedInfo
     * @returns {{cards: Card[], generalInfo: GeneralInfoField[]}}
     */
    getExpandedInfo(generalInfo, extendedInfo) {
        return {
            generalInfo: [
                new GeneralInfoField({
                    value: extendedInfo.email,
                    label: "integrations-popup.email-id"
                }),
                new GeneralInfoField({
                    value: extendedInfo.company_name,
                    label: "integrations-popup.company"
                }),
                new GeneralInfoField({
                    value: generalInfo.owner,
                    label: "integrations-popup.owner"
                }),
                new GeneralInfoField({
                    value: getDate(extendedInfo.last_activity_time),
                    label: "integrations-popup.last-activity-date"
                }),
                new GeneralInfoField({
                    value: generalInfo.lead_status,
                    label: "integrations-popup.lead-status"
                })
            ],

            cards: [
                new Card({
                    className: "deals-wrapper",
                    title: "integrations-popup.open-deals",
                    count: extendedInfo.open_deals.length,
                    items: extendedInfo.open_deals.map(deal => {
                        return {
                            link: deal.link,
                            name: deal.name,
                            label: "integrations-popup.amount",
                            label_value: deal.amount
                        }
                    })
                }),
                new Card({
                    className: "tickets-wrapper",
                    title: "integrations-popup.open-tickets",
                    count: extendedInfo.open_tickets.length,
                    items: extendedInfo.open_tickets.map(ticket => {
                        return {
                            link: ticket.link,
                            name: ticket.name,
                            label: "integrations-popup.status",
                            label_value: ticket.status
                        }
                    })
                })
            ]
        }
    }
}