/**
 * Class that represent card like "Open deals", "Open tickets" etc. in extended CRM contact info
 */
export default class Card {

    /**
     *
     * @param {string} className
     * @param {string} title
     * @param {number} count
     * @param {{link: string, name: string, label: string, label_value: string}[]} items
     */
    constructor({className, title, count, items}) {
        this._className = className
        this._title = title
        this._count = count
        this._items = items
    }

    /**
     * @returns {string}
     */
    get className() {
        return this._className
    }

    /**
     * @returns {string}
     */
    get title() {
        return this._title
    }

    /**
     * @returns {number}
     */
    get count() {
        return this._count
    }

    /**
     * @returns {{link: string, name: string, label: string, label_value: string}[]}
     */
    get items() {
        return this._items
    }
}