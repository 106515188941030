import {getAppLocale} from "../utils/locale"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import calendar from "dayjs/plugin/calendar"
import localeData from "dayjs/plugin/localeData"
import utc from "dayjs/plugin/utc"

dayjs.extend(localizedFormat)
dayjs.extend(calendar)
dayjs.extend(localeData)
dayjs.extend(utc)

getAppLocale()
    .then(locale => {
        import(`dayjs/locale/${locale}.js`)
            .then(() => {
                dayjs.locale(locale)
            })
            .catch((error) => {
                console.error(error.message)
            })
    })

export default function (date = undefined) {
    return date ? dayjs(date) : dayjs()
}