<template>
    <div class="incoming-second-line-buttons">
        <call-button
            @press="$emit('pressDeclineSecondLine')"
            iconClass="icon-call-end"
            buttonClass="incoming-second-line-buttons decline"
            :label="$t('softphone-state-incoming.buttons.decline')"
        ></call-button>

        <call-button
            @press="$emit('pressHangUpAndAccept')"
            buttonClass="incoming-second-line-buttons hangup-and-accept"
            iconClass="icon-call"
            :label="$t('buttons-group.buttons-group-incoming-second-line.hang-up-and-accept')"
        ></call-button>

        <call-button
            @press="$emit('pressHoldAndAccept')"
            buttonClass="incoming-second-line-buttons hold-and-accept"
            iconClass="icon-call-paused"
            :label="$t('buttons-group.buttons-group-incoming-second-line.hold-and-accept')"
        ></call-button>
    </div>
</template>

<script>
import CallButton from "../base/CallButton.vue";
export default {
    name: "ButtonsGroupIncomingSecondLine",
    components: {CallButton}
}
</script>

<style scoped>

</style>