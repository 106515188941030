<template>
    <div class="base-slider">
        <div
            v-show="showTooltip"
            :style="tooltipStyle"
            class="base-slider-tooltip"
            ref="tooltip"
        >{{ $t(tooltipText) }}{{ value }}</div>

        <input
            @input="onInput"
            draggable="true"
            @dragstart.prevent.stop="() => false"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
            :value="localValue"
            :min="min"
            :max="max"
            :step="step"
            :style="{'--ratio': ratio}"
            ref="slider"
            type="range"
        >
    </div>
</template>

<script>
export default {
    name: "BaseSlider",
    props: {
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        tooltipText: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            localValue: 0,
            ratio: 0,
            showTooltip: false,
            sliderWidth: 0,
            tooltipWidth: 0
        }
    },
    created() {
        this.localValue = this.value
    },
    updated() {
        this.sliderWidth = this.$refs.slider.getBoundingClientRect().width
        this.tooltipWidth = this.$refs.tooltip?.getBoundingClientRect()?.width
    },
    watch: {
        value() {
            this.localValue = this.value
        },
        localValue() {
            this.updateRatio()
        }
    },
    computed: {
        tooltipStyle() {
            const thumbWidth = 12
            const leftOffset = (thumbWidth / 2 + this.ratio * this.sliderWidth - this.ratio * thumbWidth) - this.tooltipWidth / 2

            return {
                'left': `${leftOffset}px`
            }
        }
    },
    methods: {
        onInput(event) {
            this.localValue = parseInt(event.target.value)
            this.$emit('input', this.localValue)
        },
        updateRatio() {
            this.ratio = ((this.localValue - this.min) / (this.max - this.min))
        },
        onMouseLeave() {
            this.showTooltip = false
        },
        onMouseEnter() {
            this.showTooltip = true
        }
    }
}
</script>

<style scoped>

</style>