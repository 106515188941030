<template>
    <tr class="voicemail-item-table-row" :class="classes" @mouseover="isHover = true" @mouseleave="isHover = false">
        <td class="voicemail-item-table-row-cell">
            <base-checkbox
                @change="onSelect"
                :value="selected"
            ></base-checkbox>
        </td>

        <td class="voicemail-item-table-row-cell" :class="'voicemail-message-status-' + status">
            {{ status === MESSAGE_STATUS_READ ? $t('panel.services.voicemail.statuses.read') : $t('panel.services.voicemail.statuses.unread') }}
            <span v-if="breakpoints.isTablet" class="voicemail-message-status-date">{{ date }}</span>
        </td>

        <td class="voicemail-item-table-row-cell">
            <contact :number="item.callerid" :is-hover="isHover"></contact>
        </td>

        <td v-if="!breakpoints.isTablet" class="voicemail-item-table-row-cell">
            {{ date }}
        </td>

        <td v-if="!breakpoints.isMobile" class="voicemail-item-table-row-cell">
            <div class="voicemail-message-actions">
                <base-button
                    @click="speedDial(item.callerid)"
                    size="medium"
                    color="table"
                    icon="icon-call"
                    class="call-btn"
                ></base-button>

                <base-button
                    v-if="!contact.id"
                    @click="showAddContactPopup(item.callerid)"
                    size="medium"
                    color="table"
                    icon="icon-person-add"
                    class="add-contact-btn"
                ></base-button>

                <base-button
                    v-if="canShowIntegrations"
                    @click="onToggleIntegrations"
                    :color="isExpanded ? 'dark-gray' : 'table'"
                    size="medium"
                    icon="icon-join-right-black"
                    class="show-crms-btn"
                ></base-button>

                <base-button
                    v-if="breakpoints.isLaptop"
                    @click="onToggleLaptopPlayer"
                    size="medium"
                    color="table"
                    icon="icon-play"
                ></base-button>

                <base-button
                    v-if="breakpoints.isLaptop"
                    @click="onDownloadClick"
                    size="medium"
                    color="table"
                    icon="icon-file_download"
                ></base-button>

                <div v-if="!breakpoints.isLaptop" class="audio-player-wrapper">
                    <audio-player
                        @get-audio-url="getAudioUrl($event.resolve)"
                        @ended="onAudioEnded"
                        :audio-duration="item.duration"
                        :download-filename="downloadFilename"
                        buttons-color="table"
                        buttons-size="medium"
                    ></audio-player>
                </div>
            </div>
        </td>

        <td v-if="breakpoints.isLaptop && showLaptopPlayer" class="voicemail-item-table-row-cell overlap-cell">
            <audio-player
                @get-audio-url="getAudioUrl($event.resolve)"
                @ended="onAudioEnded"
                :audio-duration="item.duration"
                :download-button="false"
                autoplay
                buttons-color="table"
                buttons-size="medium"
            ></audio-player>

            <base-button
                @click="onToggleLaptopPlayer"
                size="medium"
                color="table"
                icon="icon-clear"
            ></base-button>
        </td>
    </tr>
</template>

<script>
import BaseCheckbox from "../../../defaults/BaseCheckbox.vue";
import Avatar from "../../../defaults/Avatar.vue";
import BaseButton from "../../../defaults/BaseButton.vue";
import AudioPlayer from "../../../defaults/AudioPlayer.vue";
import {showAddContactPopup} from "../../../../utils/phonebooks";
import {speedDial} from "../../../../utils/softphone";
import {MESSAGE_DIRNAME_INBOX} from "../../../../store/modules/voicemails";
import Contact from "../../../defaults/Contact.vue";
import breakpoints from "../../../../plugins/breakpoints";
import voicemailItemTableRowMixin from "../../../../mixins/voicemail/voicemailItemTableRowMixin";

const MESSAGE_STATUS_READ = "read"
const MESSAGE_STATUS_UNREAD = "unread"

export default {
    name: "VoicemailItemTableRow",
    components: {Contact, AudioPlayer, BaseButton, Avatar, BaseCheckbox},
    mixins: [voicemailItemTableRowMixin],
    props: {
        selected: {
            type: Boolean,
            default: false
        },
        isExpanded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showLaptopPlayer: false,
            voicemailMessageUrl: null,
            breakpoints,
            MESSAGE_STATUS_READ,
            isHover: false
        }
    },
    computed: {
        classes() {
            return {
                "base-table-row-selected": this.selected
            }
        },

        status() {
            return this.item.dirname === MESSAGE_DIRNAME_INBOX ? MESSAGE_STATUS_UNREAD : MESSAGE_STATUS_READ
        }
    },
    methods: {
        onSelect() {
            this.$emit("select")
        },

        onToggleIntegrations() {
            this.$emit("show-integrations")
        },

        onToggleLaptopPlayer() {
            this.showLaptopPlayer = !this.showLaptopPlayer
        },

        showAddContactPopup,

        speedDial
    }
}
</script>

<style scoped>

</style>