<template>
    <service-tab class="call-parking-wallboard">
        <template v-if="filteredCallParkings.length" v-slot:header>
            <h1>{{ $t("call-parking-wallboard.title") }}</h1>

            <base-select
                v-model="selectedCallParkingNumber"
                :options="callParkingDropdownOptions"
                placeholder="panel.call-history.call-types.all"
                class="select-call-parking"
            ></base-select>
        </template>

        <template v-slot:body>
            <base-spinner v-if="loading" center></base-spinner>

            <div v-else-if="!filteredCallParkings.length" class="call-parking-no-available">{{ $t("panel.services.call-parking.no-call-parkings") }}</div>

            <call-parking-item
                v-else
                v-for="callParking in filteredCallParkings"
                :call-parking="callParking"
                :key="callParking.number"
                is-wallboard
            ></call-parking-item>
        </template>
    </service-tab>
</template>

<script>
import BaseSelect from "../defaults/BaseSelect.vue";
import {mapActions, mapGetters} from "vuex";
import Api from "../../utils/api.js"
import BaseSpinner from "../defaults/BaseSpinner.vue";
import CallParkingItem from "../panel/services/callParking/CallParkingItem.vue";
import ServiceTab from "../panel/services/ServiceTab.vue";
import SocketIOHelper from "../../helpers/SocketIOHelper";
import breakpoints from "../../plugins/breakpoints";
import callParkingSelectMixin from "../../mixins/callParking/callParkingSelectMixin";
import {changeLocale} from "../../locales";

export default {
    name: "CallParkingWallboard",
    components: {ServiceTab, CallParkingItem, BaseSpinner, BaseSelect},
    mixins: [callParkingSelectMixin],
    data() {
        return {
            loading: true,
            windowWidth: window.innerWidth,
            breakpoints
        }
    },
    created() {
        Api().get("/workspace_load")
            .then((response) => {
                this.setUser(response.data)
                SocketIOHelper.connect(this.pbxUrl, this.realtimeToken, true)

                changeLocale(this.languageCode)

                return this.loadPhonebooks()
            })
            .then(() => {
                return this.getCallParkings()
            })
            .finally(() => this.loading = false)
    },
    computed: {
        ...mapGetters("callParkings", ["callParkings", "callParkingDropdownOptions"]),
        ...mapGetters("user", ["pbxUrl", "realtimeToken", "languageCode"])
    },
    methods: {
        ...mapActions('user', ['setUser']),
        ...mapActions('details', ['loadCallHistory', 'loadPhonebooks']),
        ...mapActions("callParkings", ["getCallParkings"])
    }
}
</script>

<style scoped>

</style>