<template>
    <div class="active-call-keypad-tab">
        <slot name="prepend"></slot>

        <slot>
            <active-call-keypad-search-input
                @input="onInput"
                :value="searchInput"
            ></active-call-keypad-search-input>

            <active-call-keypad-speed-dial-list
                @input="onSelect"
                :value="value"
                :items="localItems"
                :no-items-text="computedNoItemsText"
            >
                <template v-slot:item="{item}">
                    <slot name="item" :item="item"></slot>
                </template>
            </active-call-keypad-speed-dial-list>
        </slot>
    </div>
</template>

<script>
import ActiveCallKeypadSearchInput from "../ActiveCallKeypadSearchInput.vue";
import ActiveCallKeypadSpeedDialList from "../ActiveCallKeypadSpeedDialList.vue";

export default {
    name: "ActiveCallKeypadTab",
    components: {ActiveCallKeypadSpeedDialList, ActiveCallKeypadSearchInput},
    props: {
        value: {
            type: String,
            default: ""
        },
        searchCallback: {
            type: Function
        },
        noItemsText: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            searchInput: "",
            localItems: this.items,
            timeoutId: null
        }
    },
    watch: {
        items() {
            if (this.searchInput) {
                this.search()
            } else {
                this.localItems = this.items
            }
        }
    },
    computed: {
        computedNoItemsText() {
            return this.localItems.length === 0 && this.items.length === 0 ? this.noItemsText : "active-call-keypad.no-results-found"
        }
    },
    methods: {
        onInput(searchString) {
            this.searchInput = searchString
            clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(this.search, 300)
        },
        search() {
            this.localItems = this.items.filter((item) => this.searchCallback(this.searchInput, item))
        },
        onSelect(number) {
            this.$emit("input", number)
        }
    }
}
</script>

<style scoped>

</style>