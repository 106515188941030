<template>
    <div class="dnd-switcher" @click="toggleDND" :class="{'in-dropdown': inDropdown}">
        <base-tooltip :disabled="!getIsRegistered" text="top-bar.dnd-tooltip" position="bottom">
            <template v-slot:activator>
                <div
                    class="logged-in-panel-status"
                    :class="{
                        'online': user.isOnline,
                        'do-not-disturb': !user.isOnline,
                        'disabled': !getIsRegistered
                    }"
                >
                    <span v-if="!isDndLoading" class="passive" :class="{'icon-done': user.isOnline, 'icon-rectangle': !user.isOnline}"></span>
                    <span v-if="!isDndLoading" class="active" :class="{'icon-pause': user.isOnline, 'icon-play': !user.isOnline}"></span>
                    <div class="spinner-container" v-if="isDndLoading" :class="{'online': user.isOnline, 'do-not-disturb': !user.isOnline}">
                        <base-spinner :size="'small'" :color="user.isOnline ? 'white' : 'red'"></base-spinner>
                    </div>
                    <p>{{ $t("top-bar.status." + (user.isOnline ? 'online' : 'do-not-disturb')) }}</p>
                </div>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import BaseTooltip from "../defaults/BaseTooltip.vue";
import BaseSpinner from "../defaults/BaseSpinner.vue";
import DNDSwitcherMixin from "../../mixins/DNDSwitcherMixin";

export default {
    name: "DNDSwitcher",
    components: {BaseSpinner, BaseTooltip},
    mixins: [DNDSwitcherMixin],
    props: {
        inDropdown: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>