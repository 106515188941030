<template>
    <div :class="classes" v-click-outside="defocus" class="dialpad">
        <div class="number-input-container">
            <input
                v-model="inputValue"
                @keydown.prevent.backspace="removeDigit()"
                @keypress.prevent="inputNumber($event.key)"
                @keyup.delete="inputNumber($event.key)"
                @keyup="emitTypedSymbol($event.key)"
                @blur="inputBlur"
                @focus="inputFocus"
                @paste.prevent="pasteHandler"
                ref="inputNumber"
                class="number-input"
                type="text"
                :placeholder="$t(placeholder)"
            >

            <dial-pad-action-button
                v-if="inputValue"
                @longClick="clearAll"
                @click="removeDigit"
                :color="color"
                icon="icon-backspace"
                position="right"
                tooltip="softphone-dial-pad.hint-for-remove"
            ></dial-pad-action-button>

            <slot name="numberAppend" :defocus="defocus"></slot>
        </div>

        <div class="keyboard-container">
            <div v-if="showKeypad" class="keyboard">
                <div class="keyboard-group">
                    <button @click="onClickKeyboardBtn('1')" class="keyboard-item">
                        <div class="key">1</div>
                        <div class="text"></div>
                    </button>
                    <button @click="onClickKeyboardBtn('2')" class="keyboard-item">
                        <div class="key">2</div>
                        <div class="text">ABC</div>
                    </button>
                    <button @click="onClickKeyboardBtn('3')" class="keyboard-item">
                        <div class="key">3</div>
                        <div class="text">DEF</div>
                    </button>
                </div>
                <div class="keyboard-group">
                    <button @click="onClickKeyboardBtn('4')" class="keyboard-item">
                        <div class="key">4</div>
                        <div class="text">GHI</div>
                    </button>
                    <button @click="onClickKeyboardBtn('5')" class="keyboard-item">
                        <div class="key">5</div>
                        <div class="text">JKL</div>
                    </button>
                    <button @click="onClickKeyboardBtn('6')" class="keyboard-item">
                        <div class="key">6</div>
                        <div class="text">MNO</div>
                    </button>
                </div>
                <div class="keyboard-group">
                    <button @click="onClickKeyboardBtn('7')" class="keyboard-item">
                        <div class="key">7</div>
                        <div class="text">PQRS</div>
                    </button>
                    <button @click="onClickKeyboardBtn('8')" class="keyboard-item">
                        <div class="key">8</div>
                        <div class="text">TUV</div>
                    </button>
                    <button @click="onClickKeyboardBtn('9')" class="keyboard-item">
                        <div class="key">9</div>
                        <div class="text">WXYZ</div>
                    </button>
                </div>
                <div class="keyboard-group">
                    <button @click="onClickKeyboardBtn('*')" class="keyboard-item">
                      <div class="key asterisk-sign"><span>*</span></div>
                    </button>
                    <base-tooltip :disabled="isMobile" :width="95" text="softphone-dial-pad.hint-for-plus" position="bottom">
                        <template v-slot:activator>
                            <button
                                @mousedown.stop.prevent="startHoldZero"
                                @touchstart.stop.prevent="startHoldZero"
                                @mouseup.stop.prevent="endHoldZero"
                                @touchend.stop.prevent="endHoldZero"
                                @touchcancel.stop.prevent="endHoldZero"
                                class="keyboard-item zero-key"
                            >
                                <div class="key">0</div>
                                <div class="text">+</div>
                            </button>
                        </template>
                    </base-tooltip>
                    <button @click="onClickKeyboardBtn('#')" class="keyboard-item">
                        <div class="key pound-sign">#</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";
import {formatNumber, getPhoneNumberDigitsOnly, t9Translate} from "../../../utils/phoneNumbers";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import DialPadActionButton from "./DialPadActionButton.vue";

const DIAL_PAD_COLOR_LIGHT = "color_light"
const DIAL_PAD_COLOR_DARK = "color_dark"

export default {
    name: "DialPad",
    components: {DialPadActionButton, BaseTooltip, BaseButton},
    props: {
        value: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: DIAL_PAD_COLOR_LIGHT
        },
        placeholder: {
            type: String,
            default: ""
        },
        t9: {
            type: Boolean,
            default: true
        },
        showKeypad: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            clearInputTimeout: null,
            inputValue: formatNumber(this.value, true, this.t9),
            inputNumberIsFocus: false,
            holdZeroTimeout: null,
            plusHasBeenAdded: false,
            windowWidth: window.innerWidth,
            isCtrlPressed: false
        }
    },
    created() {
        document.addEventListener('keydown',  this.keydownHandler)
        document.addEventListener('keyup',  this.keyupHandler)
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        this.defocus()
    },
    destroyed() {
        document.removeEventListener('keydown', this.keydownHandler)
        document.removeEventListener('keyup', this.keyupHandler)
        window.removeEventListener('resize', this.handleResize)
    },
    watch: {
        value() {
            this.inputValue = formatNumber(this.value, true, this.t9)
        }
    },
    computed: {
        classes() {
            return {
                'dial-pad-color-light': this.color === DIAL_PAD_COLOR_LIGHT,
                'dial-pad-color-dark': this.color === DIAL_PAD_COLOR_DARK
            }
        },
        isMobile() {
            return this.windowWidth < 800
        }
    },
    methods: {
        inputNumber(key) {
            this.addDigit(key)

            if (key === 'Enter') {
                this.$emit("pressEnter")
            }
        },
        addDigit(key) {
            if (!this.$refs.inputNumber) {
                return
            }

            if (!this.inputNumberIsFocus) {
                this.$refs.inputNumber.focus()
                this.inputNumberIsFocus = true
            }

            if (this.isValidKey(key)) {
                let startPos = this.$refs.inputNumber.selectionStart
                let endPos = this.$refs.inputNumber.selectionEnd

                let firstDigits = this.inputValue.substring(0, startPos) + key.toString()
                if (this.t9) {
                    firstDigits = t9Translate(firstDigits)
                }

                const number = firstDigits + this.inputValue.substring(endPos, this.inputValue.length)
                this.inputValue = formatNumber(number)

                this.$nextTick(() => {
                    if (number.length === this.inputValue.length) {
                        startPos += 1
                        endPos += 1
                    } else {
                        const cursorPosition = this.getCursorPosition(firstDigits, this.inputValue)
                        startPos = cursorPosition
                        endPos = cursorPosition
                    }

                    this.$refs.inputNumber.setSelectionRange(endPos, endPos)
                })

                this.$emit("input", this.inputValue.trim())
            }
        },
        emitTypedSymbol(key) {
            if (key) {
                this.$emit("typedSymbol", {key: this.t9 ? t9Translate(key) : key})
            }
        },
        onClickKeyboardBtn(key) {
            this.emitTypedSymbol(key)
            this.addDigit(key)
        },
        removeDigit() {
            if (!this.$refs.inputNumber) {
                return
            }

            if (!this.inputNumberIsFocus) {
                this.setCursorToEnd()
                this.$refs.inputNumber.focus()
                this.inputNumberIsFocus = true
            }

            let startPos = this.$refs.inputNumber.selectionStart
            let endPos = this.$refs.inputNumber.selectionEnd

            if (startPos === 0 && endPos === 0) {
                return;
            }

            const firstDigits = this.inputValue.slice(0, (startPos === endPos ? startPos - 1 : startPos))
            const number = firstDigits + this.inputValue.slice(endPos, this.inputValue.length)
            this.inputValue = formatNumber(number, false)

            this.$nextTick(() => {
                if (number.length !== this.inputValue.length && !firstDigits.length) {
                    startPos = 0
                    endPos = 0
                } else if (number.length !== this.inputValue.length) {
                    const cursorPosition = this.getCursorPosition(firstDigits, this.inputValue)

                    startPos = cursorPosition
                    endPos = cursorPosition
                } else {
                    if (startPos === endPos) {
                        startPos -= 1
                        endPos -= 1
                    }
                }

                this.$refs.inputNumber.setSelectionRange(startPos,startPos)
            })

            this.$emit('input', this.inputValue.trim())
        },
        getCursorPosition(subNumber, origNumber) {
            subNumber = getPhoneNumberDigitsOnly(subNumber).split("")
            let cursorPosition = origNumber.length

            for (let i = 0; i < origNumber.length; i++) {
                if (subNumber.length && origNumber.charAt(i) === subNumber[0]) {
                    subNumber.shift()
                }

                if (!subNumber.length) {
                    cursorPosition = i + 1
                    break
                }
            }

            return cursorPosition
        },
        isValidKey(key) {
            const regex = new RegExp(/^([0-9]|[a-zA-Z]|[\*,\#,\+])$/);
            return regex.test(key);
        },
        setCursorToEnd() {
            const end = this.inputValue.length
            this.$refs.inputNumber.setSelectionRange(end, end)
        },
        inputBlur(event) {
            if (this.inputNumberIsFocus && Boolean(this.$refs.inputNumber) && event.relatedTarget) {
                this.$refs.inputNumber.focus()
            }
        },
        inputFocus() {
            this.inputNumberIsFocus = true
        },
        pasteHandler(event) {
            const number = (event.clipboardData || window.clipboardData).getData('text')
            this.inputValue = formatNumber(number, true, this.t9)
            this.$emit('input', this.inputValue.trim())
        },
        clearAll() {
            this.inputValue = ""
            this.$emit("input", this.inputValue)
        },
        startHoldZero() {
            this.holdZeroTimeout = setTimeout(() => {
                this.onClickKeyboardBtn('+')
                this.plusHasBeenAdded = true
            }, 600)
        },
        endHoldZero() {
            clearInterval(this.holdZeroTimeout)

            if (!this.plusHasBeenAdded) {
                this.onClickKeyboardBtn('0')
            }
            this.plusHasBeenAdded = false
        },
        defocus() {
            this.inputNumberIsFocus = false
            const input = this.$refs.inputNumber
            if (input) {
                this.$refs.inputNumber.blur()
            }
        },
        keydownHandler(event) {
            const activeElement = document.activeElement
            if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || event.key === 'Enter' || activeElement.hasAttribute("contenteditable")) {
                return
            }

            if (event.key === 'Control') {
                this.isCtrlPressed = true
            }

            if ((event.key === 'Backspace' || this.isValidKey(event.key)) && this.$refs.inputNumber && !this.isCtrlPressed) {
                this.$refs.inputNumber.focus()
                this.setCursorToEnd()
            }
        },
        keyupHandler(event) {
            if (event.key === 'Control') {
                this.isCtrlPressed = false
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        }
    }
}
</script>

<style scoped>

</style>