import Vue from 'vue';
import App from './components/App.vue';
import store from './store';
import router from './router';
import Vuetify from 'vuetify'
import Vuelidate from 'vuelidate';
import 'vuetify/dist/vuetify.min.css'
import './scss/style.scss';
import i18n from './locales/index.js'
import Vuebar from 'vuebar';
import PortalVue from 'portal-vue';
import { sentryInit } from "./sentry";

Vue.use(Vuetify);
Vue.use(Vuelidate);
Vue.use(Vuebar);
Vue.use(PortalVue);

Vue.prototype.$eventBus = new Vue();

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target)) && !event.composedPath().includes(el)) {
                if(binding.value instanceof Function) {
                    binding.value(event)
                } else {
                    vnode.context[binding.expression](event);
                }
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

sentryInit(Vue);

window.App = new Vue({
    el: '#vue-app',
    store,
    router,
    vuetify: new Vuetify(),
    i18n,
    render: h => h(App),
    created() {
        document.addEventListener('beforeunload', this.handler)
    },
    methods: {
        handler(event) {
            store.dispatch('softphone/ctxSipStop');
        }
    }
});
