<template>
    <base-popup
        @close="close"
        @save="sendSMS"
        :save-btn-loading="sendingInProgress"
        :save-btn-disabled="$v.$invalid || isReachedMaxSmsCount"
        :width="400"
        popup-class="new-sms-dialog-popup"
        title="popups.new-sms-dialog-popup.title"
        save-text="popups.new-sms-dialog-popup.send-button"
    >
        <div class="form-group" :class="{'filled': contactId}">
            <base-select
                @input="onSelectContactId"
                :value="contactId"
                :options="contactsOptions"
                :readonly="false"
                placeholder="popups.new-sms-dialog-popup.contact"
                label="popups.new-sms-dialog-popup.contact"
            ></base-select>
        </div>

        <div class="form-group" :class="{'filled': toNumber}">
            <base-input
                v-model="$v.toNumber.$model"
                ref="toNumberInput"
                type="phone"
                placeholder="popups.contact-popup.number-label"
                label="popups.contact-popup.number-label"
            ></base-input>

            <div class="invalid-feedback" v-if="$v.toNumber.$dirty">
                <span v-if="!$v.toNumber.required">{{ $t("popups.new-sms-dialog-popup.invalid-feedback.required-field") }}</span>
                <span v-else-if="!$v.toNumber.validFormat">{{ $t("popups.new-sms-dialog-popup.invalid-feedback.invalid-number") }}</span>
            </div>
        </div>

        <div class="form-group from-number-select">
            <base-select
                v-model="$v.fromNumberId.$model"
                :options="fromNumbersOptions"
                placeholder="popups.new-sms-dialog-popup.from-number.placeholder"
                label="popups.new-sms-dialog-popup.from-number.placeholder"
                tooltip="popups.new-sms-dialog-popup.from-number.tooltip"
            ></base-select>

            <div class="invalid-feedback" v-if="$v.fromNumberId.$dirty">
                <span v-if="!$v.fromNumberId.required">{{ $t("popups.new-sms-dialog-popup.invalid-feedback.required-field") }}</span>
            </div>
        </div>

        <div class="form-group textarea">
            <base-textarea
                v-model="$v.messageText.$model"
                :max-height="76"
                placeholder="popups.new-sms-dialog-popup.textarea-placeholder"
            ></base-textarea>

            <div class="invalid-feedback" v-if="$v.messageText.$dirty">
                <span v-if="!$v.messageText.required">{{ $t("popups.new-sms-dialog-popup.invalid-feedback.required-field") }}</span>
            </div>
        </div>

        <sms-characters-counter
            @update:is-reached-max-sms-count="isReachedMaxSmsCount = $event"
            :text="messageText"
        ></sms-characters-counter>
    </base-popup>
</template>

<script>
import BaseButton from "../BaseButton.vue";
import BaseSelect from "../BaseSelect.vue";
import BaseTextarea from "../BaseTextarea.vue";
import BasePopup from "../BasePopup.vue";
import {mapActions, mapGetters} from "vuex";
import {formatNumber, sanitizePhoneNumber, isExtension, numbersAreMatched, getPhoneNumberDigitsOnly} from "../../../utils/phoneNumbers";
import { required } from 'vuelidate/dist/validators.min.js'
import dayjs from "dayjs";
import BaseInput from "../BaseInput.vue";
import SmsCharactersCounter from "../../panel/sms/SmsCharactersCounter.vue";

export default {
    name: "NewSmsDialogPopup",
    components: {SmsCharactersCounter, BaseInput, BasePopup, BaseTextarea, BaseSelect, BaseButton},
    data() {
        return {
            contactId: null,
            contactName: "",
            toNumber: "",
            fromNumberId: "",
            messageText: "",
            sendingInProgress: false,
            isReachedMaxSmsCount: false
        }
    },
    validations: {
        messageText: {
            required
        },
        toNumber: {
            required,
            validFormat: val => /^[\+]?(\d{1,15})$/.test(sanitizePhoneNumber(val))
        },
        fromNumberId: {
            required
        }
    },
    created() {
        this.setFromNumberId()

        this.$nextTick(() => {
            const input = this.$refs.toNumberInput
            if (input) {
                input.focus()
            }
        })
    },
    watch: {
        toNumber(value) {
            const contact = this.contactsOptions.find((option) => {
                return numbersAreMatched(option.phone_number, value)
            })
            if (contact) {
                this.contactId = contact.value
            } else {
                this.contactId = null
            }
        }
    },
    computed: {
        ...mapGetters('details', ['contacts']),
        ...mapGetters('user', ['smsNumbers', 'defaultSmsFromNumberId']),

        contactsOptions() {
            return this.contacts.reduce((acc, contact) => {
                if (!isExtension(contact.number)) {
                    acc.push({name: contact.name, phone_number: formatNumber(contact.number), value: contact.id})
                }

                return acc
            }, [])
        },

        fromNumbersOptions() {
            return this.smsNumbers.map((number) => {
                return {name: number.name, phone_number: formatNumber(number.num), value: number.id}
            })
        }
    },
    methods: {
        ...mapActions("SMSes", ["smsSend"]),

        close() {
            this.toNumber = ""
            this.fromNumberId = ""
            this.$emit('close', "")
        },
        sendSMS() {
            const sentAt = dayjs().format('YYYY-MM-DDTHH:mm:ssZ')

            this.sendingInProgress = true
            this.smsSend({
                fromNumberId: this.fromNumberId,
                toNumber: getPhoneNumberDigitsOnly(this.toNumber),
                text: this.messageText.trim(),
                sentAt
            }).then(() => {
                this.$emit('close', this.toNumber)
            }).catch(() => {}).finally(() => this.sendingInProgress = false)
        },
        onSelectContactId(contactId) {
            const contact = this.contacts.find((contact) => contact.id === contactId)
            if (contact) {
                this.contactName = contact.name
                this.toNumber = contact.number
            }
        },
        setFromNumberId() {
            if (this.defaultSmsFromNumberId) {
                this.fromNumberId = this.defaultSmsFromNumberId
                return
            }

            if (this.smsNumbers.length === 1) {
                this.fromNumberId = this.smsNumbers[0].id
                return
            }
        }
    }
}
</script>

<style scoped>

</style>