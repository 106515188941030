<template>
    <div class="follow-me-item">
        <div class="follow-me-item-title-container">
            <span :class="iconClass" class="icon"></span>
            <span :title="followMeItem.display_name" class="name">{{ displayName }}</span>
        </div>
        <div class="follow-me-item-slider-container">
            <base-slider
                v-model="localRingTime"
                :tooltip-text="'panel.settings.settings-tabs.device-management.seconds'"
                :min="0"
                :max="60"
            ></base-slider>
        </div>
        <div class="follow-me-item-switch-container">
            <base-switch v-model="localIsEnabled"></base-switch>
        </div>
        <div class="follow-me-item-remove-container">
            <base-button
                v-show="followMeItem.type === FOLLOW_ME_TYPE_EXTERNAL_NUMBER"
                @click="onDelete"
                color="gray"
                size="small"
                icon="icon-delete-forever"
            ></base-button>
        </div>
        <div v-if="followMeItem.webphone_type" class="follow-me-item-device-session-container">
            <span>{{ followMeItem.webphone_type === FOLLOW_ME_TYPE_WEBPHONE_BROWSER ? "Browser" : followMeItem.user_agent }}</span>
            <span>{{ followMeItem.last_ping_at }}</span>
            <span>{{ followMeItem.ip }}</span>
        </div>
    </div>
</template>

<script>
import BaseButton from "../../../defaults/BaseButton.vue";
import BaseSlider from "../../../defaults/BaseSlider.vue";
import BaseSwitch from "../../../defaults/BaseSwitch.vue";
import {formatNumber} from "../../../../utils/phoneNumbers";

const FOLLOW_ME_TYPE_TEAMS = "Teams"
const FOLLOW_ME_TYPE_DEVICE = "Device"
const FOLLOW_ME_TYPE_EXTERNAL_NUMBER = "External_number"
const FOLLOW_ME_TYPE_WEBPHONE_DESKTOP = "desktop"
const FOLLOW_ME_TYPE_WEBPHONE_BROWSER = "browser"
const FOLLOW_ME_TYPE_WEBPHON_MOBILE = "mobile"

export default {
    name: "FollowMeItem",
    components: {BaseSwitch, BaseSlider, BaseButton},
    props: {
        ringTime: {
            type: Number,
            default: 0
        },
        isEnabled: {
            type: Boolean,
            default: false
        },
        followMeItem: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            localRingTime: 0,
            localIsEnabled: false,
            FOLLOW_ME_TYPE_EXTERNAL_NUMBER,
            FOLLOW_ME_TYPE_WEBPHONE_BROWSER
        }
    },
    created() {
        this.localRingTime = this.followMeItem.ring_time
        this.localIsEnabled = this.followMeItem.is_enabled
    },
    watch: {
        ringTime(value) {
            this.localRingTime = value
        },
        isEnabled(value) {
            this.localIsEnabled = value
        },
        localRingTime(value) {
            this.$emit("update:ring-time", value)
        },
        localIsEnabled(value) {
            this.$emit("update:is-enabled", value)
        }
    },
    computed: {
        iconClass() {
            return {
                "icon-teams": this.followMeItem.type === FOLLOW_ME_TYPE_TEAMS,
                "icon-call": this.followMeItem.type === FOLLOW_ME_TYPE_DEVICE && !this.followMeItem.webphone_type,
                "icon-phone": this.followMeItem.type === FOLLOW_ME_TYPE_EXTERNAL_NUMBER,
                "icon-webphone": this.followMeItem.type === FOLLOW_ME_TYPE_DEVICE && this.followMeItem.webphone_type
            }
        },
        displayName() {
            if (this.followMeItem.type === FOLLOW_ME_TYPE_EXTERNAL_NUMBER) {
                return formatNumber(this.followMeItem.display_name)
            }

            return this.followMeItem.display_name
        }
    },
    methods: {
        onDelete() {
            this.$emit("delete")
        }
    }
}
</script>

<style scoped>

</style>