import {PhoneNumberUtil, PhoneNumberFormat, Error, getSupportedRegions} from "google-libphonenumber"
import store from '../store'

const MAX_INTERNAL_NUMBER_LENGTH = 5
const PHONE_NUMBER_UTIL = PhoneNumberUtil.getInstance()
const COUNTRIES_CODES = {
    "AU": "AU",
    "NZ": "NZ",
    "UK": "GB",
    "IE": "IE",
    "US": "US",
    "CA": "CA",
    "SG": "SG"
}
const t9Map = {
    a: 2,
    b: 2,
    c: 2,
    d: 3,
    e: 3,
    f: 3,
    g: 4,
    h: 4,
    i: 4,
    j: 5,
    k: 5,
    l: 5,
    m: 6,
    n: 6,
    o: 6,
    p: 7,
    q: 7,
    r: 7,
    s: 7,
    t: 8,
    u: 8,
    v: 8,
    w: 9,
    x: 9,
    y: 9,
    z: 9,
};

const _parsePhoneNumber = (number, country = null) => {
    let numberObj = null

    try {
        numberObj = PHONE_NUMBER_UTIL.parse(number, country)
    } catch (e) {
        return numberObj
    }

    return numberObj
}

const numbersAreMatched = (firstNum, secondNum) => {
    firstNum = firstNum.toString()
    secondNum = secondNum.toString()

    const regexLeaveOnlyLastTenDigits = /^.*(\d{10})$/

    const firstPhoneNumberSanitized = getPhoneNumberDigitsOnly(firstNum).replace(regexLeaveOnlyLastTenDigits, "$1")
    const secondPhoneNumberSanitized = getPhoneNumberDigitsOnly(secondNum).replace(regexLeaveOnlyLastTenDigits, "$1")

    const MatchType = PhoneNumberUtil.MatchType

    const mt = PHONE_NUMBER_UTIL.isNumberMatch(firstPhoneNumberSanitized, secondPhoneNumberSanitized)

    return mt === MatchType.NSN_MATCH || mt === MatchType.EXACT_MATCH || (mt === MatchType.SHORT_NSN_MATCH && firstPhoneNumberSanitized.length > MAX_INTERNAL_NUMBER_LENGTH && secondPhoneNumberSanitized.length > MAX_INTERNAL_NUMBER_LENGTH)
}

const formatNumber = (number, forceAddPlus = true, autoTranslateT9 = false, countryCode = "") => {
    let cidPrefix = ""
    // check that the number contains a CID prefix
    if (number.indexOf(":") !== -1) {
        const numberSplitted = number.split(":")
        number = numberSplitted.pop()
        cidPrefix = numberSplitted.join(":")
    }

    let numberSanitized
    if (autoTranslateT9) {
        numberSanitized = sanitizePhoneNumber(t9Translate(number))
    } else {
        numberSanitized = sanitizePhoneNumber(number)
    }

    let numberForFormat = numberSanitized
    if (forceAddPlus) {
        numberForFormat = numberSanitized.indexOf('+') !== 0 ? "+" + numberSanitized : numberSanitized
    }
    // const numberWithPlus = numberSanitized.indexOf('+') !== 0 ? "+" + numberSanitized : numberSanitized

    if (numberSanitized.length <= MAX_INTERNAL_NUMBER_LENGTH) {
        return cidPrefix ? `${cidPrefix}:${number}` : number
    }

    let result = numberSanitized
    let numberObj = null

    if (countryCode) {
        if (isValidNumberForCountry(numberForFormat, countryCode)) {
            numberObj = _parsePhoneNumber(numberForFormat, countryCode)
            return PHONE_NUMBER_UTIL.format(numberObj, PhoneNumberFormat.INTERNATIONAL)
        }

        return result
    }


    numberObj = _parsePhoneNumber(numberForFormat)

    let country = null
    if (numberObj) {
        country = numberObj.getCountryCode()
    }

    if (numberObj && PHONE_NUMBER_UTIL.isValidNumber(numberObj) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)) {
        result = PHONE_NUMBER_UTIL.format(numberObj, PhoneNumberFormat.INTERNATIONAL)
    } else {
        const countryCode = country ? country : COUNTRIES_CODES[store.getters["user/country"]]
        numberObj = _parsePhoneNumber(numberSanitized, countryCode)
        if (numberObj && PHONE_NUMBER_UTIL.isValidNumberForRegion(numberObj, countryCode) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)) {
            result = PHONE_NUMBER_UTIL.format(numberObj, PhoneNumberFormat.NATIONAL)
        }
    }

    return cidPrefix ? `${cidPrefix}:${result}` : result
}

const getPhoneNumberDigitsOnly = (number) => {
    const regexLeaveOnlyDigits = /[^\d]/g

    return number.replace(regexLeaveOnlyDigits, "")
}

const getRegionCodeForNumber = (number) => {
    const numberSanitized = sanitizePhoneNumber(number)
    const numberObj = _parsePhoneNumber(numberSanitized)

    if (numberObj) {
        return PHONE_NUMBER_UTIL.getRegionCodeForNumber(numberObj)
    }

    return null
}

/**
 * Function clears phone number and leaves only digits and special symbols (*, #, +)
 * @param number
 * @returns {string}
 */
const sanitizePhoneNumber = (number) => {
    const regexLeaveOnlyDigitsAndSpecialSymbols = /[^\d,^\*,^\#,^\+]/g

    return number.replace(regexLeaveOnlyDigitsAndSpecialSymbols, "")
}

/**
 * Function return length of nationally significant number
 * @param {string} number
 * @returns {null|number}
 */
const getNationalNumberLength = (number) => {
    const numberSanitized = sanitizePhoneNumber(number)
    const numberWithPlus = numberSanitized.indexOf('+') !== 0 ? "+" + numberSanitized : numberSanitized
    let numberObj = null

    if (numberSanitized.length <= MAX_INTERNAL_NUMBER_LENGTH) {
        return number.length
    }

    numberObj = _parsePhoneNumber(numberWithPlus, PhoneNumberUtil.UNKNOWN_REGION_)
    if (numberObj && PHONE_NUMBER_UTIL.isValidNumber(numberObj) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)) {
        return numberObj.getNationalNumber().toString().length
    }

    const countryCode = COUNTRIES_CODES[store.getters["user/country"]]
    numberObj = _parsePhoneNumber(numberSanitized, countryCode)
    if (numberObj && PHONE_NUMBER_UTIL.isValidNumberForRegion(numberObj, countryCode) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)) {
        return numberObj.getNationalNumber().toString().length
    }

    // if number is in national format, but country in tenant settings is incorrect or invalid number
    return null
}

const isExtension = (number) => {
    return number.length <= store.getters["user/extensionLength"]
}

const t9Translate = (number) => {
    let arr = number.split("")
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].match(/[a-zA-z]/i)) {
            arr[i] = t9Map[arr[i].toLowerCase()]
        }
    }

    return arr.join("")
}

const isValidNumberForCountry = (number, code) => {
    const numberObj = _parsePhoneNumber(number, code)
    return numberObj && PHONE_NUMBER_UTIL.isValidNumberForRegion(numberObj, code) && PHONE_NUMBER_UTIL.isPossibleNumber(numberObj)
}

/**
 * Function checks that the string is a phone number
 * @param {string} number
 * @returns {boolean}
 */
const isPhoneNumber = (number) => {
    number = number.replace(/\s+/g, "")
    return /^\+?\d{2,18}$/.test(number)
}

export {
    numbersAreMatched,
    formatNumber,
    getPhoneNumberDigitsOnly,
    sanitizePhoneNumber,
    getNationalNumberLength,
    isExtension,
    t9Translate,
    isValidNumberForCountry,
    getRegionCodeForNumber,
    isPhoneNumber
}