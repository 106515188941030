<template>
      <div :class="{'electron-exist': isElectronExist()}" class="menu">
          <div class="navigation">
              <div class="navigation-item softphone-item" :class="{'active': activeTab === 'softphone'}" @click="changeActiveTab('softphone')">
                  <div v-if="!isInCall || (isInCall && activeTab === 'softphone')">
                    <span class="icon icon-dialpad"></span>
                    <span class="navigation-item-title">{{ $t("top-bar.menu.softphone") }}</span>
                  </div>

                  <div v-if="isInCall && activeTab !== 'softphone'">
                      <span class="icon icon-headphones"></span>
                      <span class="navigation-item-title time">{{ callTime }}</span>
                  </div>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'phonebooks'}" @click="changeActiveTab('phonebooks')">
                      <span class="icon icon-stories"></span>
                      <span class="navigation-item-title">{{ $t("top-bar.menu.phonebooks") }}</span>
              </div>
              <div class="navigation-item sms-item" :class="{'active': activeTab === 'sms'}" @click="changeActiveTab('sms')">
                      <span class="icon icon-sms"></span>
                      <span class="navigation-item-title">{{ $t("top-bar.menu.sms") }}</span>
                      <red-dot v-if="unreadSmsExist"></red-dot>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'history'}" @click="changeActiveTab('history')">
                      <span class="icon icon-history"></span>
                      <span class="navigation-item-title">{{ $t("top-bar.menu.history") }}</span>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'services'}" @click="changeActiveTab('services')">
                  <span class="icon icon-supervisor"></span>
                  <span class="navigation-item-title">{{ $t("top-bar.menu.services") }}</span>
                  <red-dot v-if="isUnreadVoicemailMessagesExists"></red-dot>
              </div>
              <div class="navigation-item" :class="{'active': activeTab === 'settings'}" @click="changeActiveTab('settings')">
                    <span class="icon icon-settings"></span>
                    <span class="navigation-item-title">{{ $t("top-bar.menu.settings") }}</span>
              </div>
              <base-dropdown
                  v-model="isOpenedAppsDropdown"
                  v-if="windowWidth > 800 && apps.length && !isElectronExist()"
                  :width="172"
                  :offsetY="-2"
                  open-by="click"
                  position="bottom"
              >
                  <template v-slot:activator>
                      <div class="navigation-item download-app">
                          <span class="navigation-item-title">{{ $t("top-bar.menu.download-app") }}</span>
                          <span v-if="isOpenedAppsDropdown" class="icon icon-expand-up"></span>
                          <span v-else class="icon icon-expand-down"></span>
                      </div>
                  </template>

                  <template v-slot:no-scrollable-content>
                      <base-list>
                          <base-list-item
                              v-for="app in desktopAppsDropdownOptions"
                              @click="downloadApp(app)"
                              :key="app.type"
                          >
                              <base-list-item-icon :icon="app.icon"></base-list-item-icon>

                              <base-list-item-title>
                                  {{ app.name }}
                              </base-list-item-title>
                          </base-list-item>
                      </base-list>

                      <divider v-if="isMobileLinksAvailable"></divider>

                      <base-list v-if="isMobileLinksAvailable">
                          <base-list-item
                              v-for="app in mobileAppsDropdownOptions"
                              @click="downloadApp(app)"
                              :key="app.type"
                          >
                              <base-list-item-icon :icon="app.icon"></base-list-item-icon>

                              <base-list-item-title>
                                  {{ app.name }}
                              </base-list-item-title>
                          </base-list-item>
                      </base-list>
                  </template>
              </base-dropdown>
          </div>
      </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {addElectronListener, isElectronExist, sendToElectron} from "../../utils/electron";
import BaseSelect from "../defaults/BaseSelect.vue";
import RedDot from "../defaults/RedDot.vue";
import {downloadFile, redirectByClick} from "../../utils/DOM";
import BaseDropdown from "../defaults/BaseDropdown.vue";
import BaseList from "../defaults/baseList/BaseList.vue";
import BaseListItem from "../defaults/baseList/BaseListItem.vue";
import BaseListItemTitle from "../defaults/baseList/BaseListItemTitle.vue";
import Divider from "../defaults/Divider.vue";
import BaseListItemIcon from "../defaults/baseList/BaseListItemIcon.vue";
import appsDropdownOptionsMixin from "../../mixins/appsDropdownOptionsMixin";

export default {
    name: "Menu",
    components: {BaseListItemIcon, Divider, BaseListItemTitle, BaseListItem, BaseList, BaseDropdown, RedDot, BaseSelect},
    mixins: [appsDropdownOptionsMixin],
    data() {
        return {
            windowWidth: 0,
            isOpenedAppsDropdown: false
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        addElectronListener("settingsTrayClick", () => {
            this.changeActiveTab('settings');
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        isIncoming(value) {
            if (value && this.windowWidth <= 800) {
                this.changeActiveTab('softphone');
            }
        },

        unreadSmsExist(value) {
            sendToElectron("missedSms", value)
        }
    },
    computed: {
        ...mapGetters([
            'activeTab'
        ]),

        ...mapGetters('softphone', ['isInCall', 'isIncoming', 'getPrimarySession']),

        ...mapGetters(['apps', 'isMobileLinksAvailable']),

        ...mapGetters('SMSes', ['chats']),

        ...mapGetters('user', ['isUnreadVoicemailMessagesExists']),

        unreadSmsExist() {
            const chatWithUnreadMessages = this.chats.find((chat) => chat.number_of_unread_messages > 0)
            return Boolean(chatWithUnreadMessages)
        },

        callTime() {
            return this.isInCall && this.getPrimarySession ? this.getPrimarySession.time : "0:00"
        }
    },
    methods: {
        ...mapActions([
          'changeActiveTab'
        ]),
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        downloadApp(app) {
            if (app.type === "android" || app.type === "ios") {
                redirectByClick(app.filepath)
            } else {
                downloadFile(app.filepath, "")
            }
        },

        isElectronExist
    }
}
</script>

<style scoped>

</style>