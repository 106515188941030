<template>
    <div class="active-call-keypad-search-input">
        <base-input
            v-model="localValue"
            placeholder="panel.call-history.search-placeholder"
            color="dark"
            ref="input"
        ></base-input>

        <dial-pad-action-button
            v-if="localValue"
            @click="removeOneSymbol"
            @longClick="clearAll"
            color="color_dark"
            position="right"
            icon="icon-backspace"
            tooltip="softphone-dial-pad.hint-for-remove"
        ></dial-pad-action-button>
    </div>
</template>

<script>
import BaseInput from "../../../defaults/BaseInput.vue";
import DialPadActionButton from "../DialPadActionButton.vue";

export default {
    name: "ActiveCallKeypadSearchInput",
    components: {DialPadActionButton, BaseInput},
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            localValue: this.value
        }
    },
    watch: {
        value() {
            this.localValue = this.value
        },
        localValue() {
            this.$emit("input", this.localValue)
        }
    },
    methods: {
        removeOneSymbol() {
            this.localValue = this.localValue.slice(0, this.localValue.length - 1)
        },
        clearAll() {
            this.localValue = ""
        }
    }
}
</script>

<style scoped>

</style>