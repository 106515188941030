<template>
    <span :class="classes" class="base-spinner"></span>
</template>

<script>
const SPINNER_SIZE_BIG = 'big' // height, width - 50px
const SPINNER_SIZE_MEDIUM = 'medium' // height, width - 25px
const SPINNER_SIZE_SMALL = 'small' // height, width - 15px, border 1.5px

const SPINNER_COLOR_BLUE = 'blue'
const SPINNER_COLOR_WHITE = 'white'
const SPINNER_COLOR_RED = 'red'

export default {
    name: "BaseSpinner",
    props: {
        size: {
            type: String,
            default: SPINNER_SIZE_MEDIUM
        },
        color: {
            type: String,
            default: SPINNER_COLOR_BLUE
        },
        center: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'base-spinner-size-big': this.size === SPINNER_SIZE_BIG,
                'base-spinner-size-medium': this.size === SPINNER_SIZE_MEDIUM,
                'base-spinner-size-small': this.size === SPINNER_SIZE_SMALL,

                'base-spinner-color-blue': this.color === SPINNER_COLOR_BLUE,
                'base-spinner-color-white': this.color === SPINNER_COLOR_WHITE,
                'base-spinner-color-red': this.color === SPINNER_COLOR_RED,

                'base-spinner-center': this.center
            }
        }
    }
}
</script>

<style scoped>

</style>