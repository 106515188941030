<template>
    <div class="button-wrapper" :class="[buttonClass, {'disable-button': disabled, 'exists-label': label}]">
        <base-tooltip
            :disabled="!tooltipIsShown"
            :text="tooltipText"
        >
            <template v-slot:activator>
                <button @click="pressButton" :disabled="disabled" :class="{'active': active}">
                    <span :class="[active ? iconClassActive : iconClass]" class="button-icon"></span>
                </button>
            </template>
        </base-tooltip>
        <slot>

        </slot>
        <label v-show="label">{{ labelActive && active ? labelActive : label }}</label>
    </div>
</template>

<script>
import BaseTooltip from "../../defaults/BaseTooltip.vue";

export default {
    name: "CallButton",
    components: {BaseTooltip},
    props: {
        label: {default: ''},
        labelActive: {default: ''},
        activeState: {default: false},
        disabled: {default: false},
        buttonActive: {default: false},
        buttonClass: {default: ''},
        iconClass: {default: ''},
        iconClassActive: {default: ''},
        disabledStateTooltip: {default: ''},
        enabledStateTooltip: {default: ""}
    },
    data() {
        return {
            active: false
        }
    },
    watch: {
        buttonActive: {
            immediate: true,
            handler(newVal, oldVal) {
                this.active = newVal;
            }
        }
    },
    computed: {
        tooltipText() {
            if (this.disabled && this.disabledStateTooltip) {
                return this.disabledStateTooltip
            }

            if (!this.disabled && this.enabledStateTooltip) {
                return this.enabledStateTooltip
            }

            return ""
        },
        tooltipIsShown() {
            return Boolean((this.disabled && this.disabledStateTooltip) || (!this.disabled && this.enabledStateTooltip))
        }
    },
    methods: {
        pressButton() {
            this.$emit('press');
            if (this.activeState) {
                this.active = !this.active
            }
        }
    }
}
</script>

<style scoped>

</style>