<template>
    <div class="connect-label">
        {{ $t("connecting") }}...
    </div>
</template>

<script>
export default {
    name: "ConnectLabel"
}
</script>

<style scoped>

</style>