<template>
    <div :class="classes" class="sms-dialog-message">
        <avatar
            v-if="windowWidth > 1536"
            size="small"
            :src="avatarSettings.avatar"
            :text="avatarSettings.name"
            :color="avatarSettings.color"
        ></avatar>

        <div class="message">
            <div class="message-header">
                <span class="message-sender-name">{{ senderName }}</span>
                <span class="message-send-time">{{ time }}</span>

                <template v-if="message.type === SMS_TYPE_OUTBOUND && !sendingStatuses.processing">
                    <div
                        v-if="sendingStatuses.failed"
                        @click="onClickResendMessage"
                        class="message-resend-wrapper"
                    >
                        <span class="icon icon-refresh"></span>
                        <span>{{ $t("sms-dialog-message.resend-message") }}</span>
                    </div>
                    <span v-else class="icon icon-done"></span>
                </template>
            </div>

            <div class="message-text">
                <p>{{ message.text }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import {mapGetters} from "vuex";
import {SMS_TYPE_OUTBOUND, SMS_TYPE_INBOUND} from "../../../store/modules/SMSes";
import dayjsOverride from "../../../helpers/dayjsOverride";

export default {
    name: "SmsDialogMessage",
    components: {Avatar},
    props: {
        message: {
            type: Object,
            default: {}
        },
        avatarSettings: {
            type: Object,
            default: {}
        },
        senderName: {
            type: String,
            default: ""
        },
        sendingStatuses: {
            type: Object,
            default() {
                return {
                    processing: false,
                    failed: false
                }
            }
        },
        windowWidth: Number
    },
    data() {
        return {
            SMS_TYPE_OUTBOUND
        }
    },
    computed: {
        ...mapGetters("details", ["getContactByNumber"]),
        ...mapGetters("user", ["languageCode"]),

        contact() {
            return this.getContactByNumber(this.number)
        },

        time() {
            return dayjsOverride(this.message.date).format("LT")
        },

        classes() {
            return {
                "message-in": this.message.type === SMS_TYPE_INBOUND,
                "message-out": this.message.type === SMS_TYPE_OUTBOUND
            }
        }
    },
    methods: {
        onClickResendMessage() {
            this.$emit("resend", this.message.id)
        }
    }
}
</script>

<style scoped>

</style>