<template>
    <div class="active-call-keypad">
        <active-call-keypad-tab-selector
            v-model="localCurrentTabName"
            :tabs="tabs"
        ></active-call-keypad-tab-selector>

        <dial-pad-tab
            v-if="localCurrentTabName === TABS.KEYPAD_TAB.name"
            v-model="localValue"
        ></dial-pad-tab>

        <phonebook-tab
            v-if="localCurrentTabName === TABS.CONTACTS_TAB.name"
            v-model="localValue"
        ></phonebook-tab>

        <history-tab
            v-if="localCurrentTabName === TABS.HISTORY_TAB.name"
            v-model="localValue"
        ></history-tab>

        <call-parking-tab
            v-if="localCurrentTabName === TABS.CALL_PARKING_TAB.name"
            v-model="localValue"
        ></call-parking-tab>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ActiveCallKeypadTabSelector from "./ActiveCallKeypadTabSelector.vue";
import CallParkingTab from "./ActiveCallKeypadTabs/CallParkingTab/CallParkingTab.vue";
import HistoryTab from "./ActiveCallKeypadTabs/HistoryTab/HistoryTab.vue";
import PhonebookTab from "./ActiveCallKeypadTabs/PhonebookTab/PhonebookTab.vue";
import DialPadTab from "./ActiveCallKeypadTabs/DialPadTab/DialPadTab.vue";
import {TABS} from "./activeCallKeypadTabs";

export default {
    name: "ActiveCallKeypad",
    components: {DialPadTab, PhonebookTab, HistoryTab, CallParkingTab, ActiveCallKeypadTabSelector},
    props: {
        value: {
            type: String,
            default: ''
        },
        tabs: {
            type: Array,
            default() {
                return [TABS.KEYPAD_TAB, TABS.CONTACTS_TAB, TABS.HISTORY_TAB, TABS.CALL_PARKING_TAB]
            }
        },
        currentTabName: {
            type: String,
            default: TABS.KEYPAD_TAB.name
        }
    },
    data() {
        return {
            localCurrentTabName: this.currentTabName,
            localValue: this.value,
            TABS
        }
    },
    watch: {
        value() {
            this.localValue = this.value
        },
        currentTabName() {
            this.localCurrentTabName = this.currentTabName
        },
        localValue() {
            this.$emit("input", this.localValue)
        },
        localCurrentTabName() {
            this.localValue = ""
            this.$emit("update:current-tab-name", this.localCurrentTabName)
        }
    },
    computed: {
        ...mapGetters("details", ["contacts", "callHistory", "getContactByNumber", "phonebooks"])
    }
}
</script>

<style scoped>

</style>