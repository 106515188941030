import { render, staticRenderFns } from "./DeleteContactPopup.vue?vue&type=template&id=73b0b844&scoped=true&"
import script from "./DeleteContactPopup.vue?vue&type=script&lang=js&"
export * from "./DeleteContactPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73b0b844",
  null
  
)

export default component.exports