<template>
    <div class="parking-speed-dial-list-item">
        <span class="parking-speed-dial-list-item-name">{{ callParking.name }}</span>

        <span class="parking-speed-dial-list-item-info">
            {{ `${callParking.first_slot_number}-${callParking.last_slot_number}` }}
            /
            {{ freeSlotsText }}
        </span>
    </div>
</template>

<script>
import callParkingItemMixin from "../../../../../../mixins/callParking/callParkingItemMixin";

export default {
    name: "ParkingSpeedDialListItem",
    mixins: [callParkingItemMixin]
}
</script>

<style scoped>

</style>