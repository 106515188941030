import {getPhoneNumberDigitsOnly, numbersAreMatched} from "./phoneNumbers";
import store from "../store"

/**
 * Function returns true if contact name or phone number contains searchString or phone number matches searchString otherwise false
 * @param {string} searchString
 * @param {{number: string, name: string}} contact
 * @returns {boolean}
 */
const findInContact = (searchString, contact) => {
    let matchByNumber = false
    // check that search string contain digits, if this true, then need to search by number
    if (/\d/g.test(searchString)) {
        matchByNumber = getPhoneNumberDigitsOnly(contact.number).includes(getPhoneNumberDigitsOnly(searchString)) || numbersAreMatched(contact.number, searchString)
    }

    const matchByName = contact.name ? contact.name.toLowerCase().includes(searchString.toLowerCase()) : false

    return matchByName || matchByNumber
}

/**
 * Opens a contact popup to add a new contact
 * @param {string} number
 * @param {number} phonebookId
 */
const showAddContactPopup = (number= "", phonebookId = null) => {
    const contact = Object.assign({}, store.getters["details/defaultContact"])
    contact.number = number
    if (phonebookId) {
        contact.phonebook_id = phonebookId
    } else {
        const defaultPhonebookViewId = store.getters["user/defaultPhonebookViewId"]
        if (defaultPhonebookViewId === 'all' || store.getters["details/getIsReadOnlyPhonebook"](defaultPhonebookViewId)) {
            contact.phonebook_id = store.getters["details/getDefaultPhonebook"].id
        } else {
            contact.phonebook_id = defaultPhonebookViewId
        }
    }

    const event = new CustomEvent('showContactPopup', {
        "detail": {"contact": contact}
    });
    document.dispatchEvent(event)
}

/**
 * Opens a contact popup to editing an existing contact
 * @param {object} contact
 */
const showEditContactPopup = (contact) => {
    const event = new CustomEvent('showContactPopup', {
        "detail": {"contact": contact}
    });
    document.dispatchEvent(event)
}

/**
 * Opens a delete contact popup
 * @param {object} contact
 */
const showDeleteContactPopup = (contact) => {
    const event = new CustomEvent('showDeleteContactPopup', {
        "detail": {"contact": contact }
    });
    document.dispatchEvent(event)
}

export {findInContact, showAddContactPopup, showEditContactPopup, showDeleteContactPopup}