<template>
    <div class="date-picker-dropdown">
        <base-dropdown
            :width="breakpoints.isMobile ? 270 : 410"
            :close-on-content-click="false"
            align="left"
            position="bottom"
            open-by="click"
        >
            <template v-slot:activator>
                <div class="form-group pick-date" :class="{'filled': date}">
                    <input :value="date" :placeholder="$t('filters-popup.date')" type="text" id="filters-date" readonly>
                    <span class="icon icon-today"></span>
                </div>
            </template>

            <template v-slot:no-scrollable-content="{close}">
                <date-picker @input="onInput" :value="localValue" @close="close" :offset-top="40"></date-picker>
            </template>
        </base-dropdown>
    </div>
</template>

<script>
import BaseDropdown from "../BaseDropdown.vue";
import DatePicker from "./DatePicker.vue";
import DayRange from "../../../helpers/DayRange";
import breakpoints from "../../../plugins/breakpoints";

export default {
    name: "DatePickerDropdown",
    components: {DatePicker, BaseDropdown},
    props: {
        value: {
            type: DayRange,
            default: null
        }
    },
    data() {
        return {
            breakpoints
        }
    },
    computed: {
        localValue() {
            return this.value
        },

        date() {
            if (this.localValue) {
                const formattedDays = this.localValue.getFormattedDays()
                return `${formattedDays.first_day} - ${formattedDays.last_day}`
            }
            return ''
        }
    },
    methods: {
        onInput(value) {
            this.$emit("input", value)
        }
    }
}
</script>

<style scoped>

</style>