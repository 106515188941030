<template>
    <div :class="classes" :style="styles" class="base-textarea">
        <div @click="onClick" class="base-textarea-wrapper" v-bar>
            <div ref="scrollWrapper">
                <textarea
                    v-model="localValue"
                    @input="calculateHeight"
                    @change="onChange"
                    @focus="onFocus"
                    @blur="onBlur"
                    :readonly="disabled"
                    :placeholder="$t(placeholder)"
                    ref="textarea"
                ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseTextarea",
    props: {
        value: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        maxHeight: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: "",
            focused: false
        }
    },
    mounted() {
        this.localValue = this.value

        window.requestAnimationFrame(() => {
            this.calculateHeight()
        })
    },
    watch: {
        value() {
            this.localValue = this.value
            this.calculateHeight()
        },
        localValue() {
            this.$emit('input', this.localValue)
        }
    },
    computed: {
        classes() {
            return {
                'textarea-focused': this.focused,
                'textarea-disabled': this.disabled
            }
        },
        styles() {
            return {
                'max-height': this.maxHeight ? `${this.maxHeight}px` : '100%'
            }
        }
    },
    methods: {
        onChange() {
            this.$nextTick(() => {
                this.calculateHeight()
            })
        },
        calculateHeight() {
            if (!this.localValue) {
                this.$refs.textarea.style.height = '0'
                return
            }

            // record scroll position
            const element = this.$refs.scrollWrapper
            const prevScrollTop = element.scrollTop

            // set textarea height
            this.$refs.textarea.style.height = '0'
            this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`

            // restore scroll position
            element.scrollTop = prevScrollTop
        },
        onFocus() {
            this.focused = true
        },
        onBlur() {
            this.focused = false
            this.$emit("blur")
        },
        onClick() {
            if (!this.disabled && !this.focused) {
                this.$refs.textarea.focus()
            }
        }
    }
}
</script>

<style scoped>

</style>