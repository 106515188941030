<template>
    <div class="voicemail-item-table-expanded-row">
        <div v-if="breakpoints.isMobile" class="mobile-expanded">
            <div v-if="showPlayer" class="mobile-expanded-audio-player">
                <audio-player
                    @get-audio-url="getAudioUrl($event.resolve)"
                    @ended="onAudioEnded"
                    :audio-duration="item.duration"
                    :download-button="false"
                    autoplay
                    buttons-color="default"
                    buttons-size="medium"
                ></audio-player>

                <base-button
                    @click="onTogglePlayer"
                    size="medium"
                    color="default"
                    icon="icon-clear"
                ></base-button>
            </div>

            <div v-else class="mobile-expanded-controls">
                <base-button
                    @click="speedDial(item.callerid)"
                    size="medium"
                    icon="icon-call"
                    class="call-btn"
                ></base-button>

                <base-button
                    v-if="!contact.id"
                    @click="showAddContactPopup(item.callerid)"
                    size="medium"
                    icon="icon-person-add"
                    class="add-contact-btn"
                ></base-button>

                <base-button
                    v-if="canShowIntegrations"
                    @click="onToggleIntegrations"
                    :color="showIntegrations ? 'dark-gray' : 'default'"
                    size="medium"
                    icon="icon-join-right-black"
                    class="show-crms-btn"
                ></base-button>

                <base-button
                    @click="onTogglePlayer"
                    size="medium"
                    icon="icon-play"
                ></base-button>

                <base-button
                    @click="onDownloadClick"
                    size="medium"
                    icon="icon-file_download"
                ></base-button>
            </div>
        </div>

        <integrations-inline
            v-if="(!breakpoints.isMobile || showIntegrations) && canShowIntegrations"
            :number="item.callerid"
            :allow-call-logging="false"
        ></integrations-inline>
    </div>
</template>

<script>
import IntegrationsInline from "../../../integrations/IntegrationsInline.vue";
import BaseButton from "../../../defaults/BaseButton.vue";
import AudioPlayer from "../../../defaults/AudioPlayer.vue";
import {showAddContactPopup} from "../../../../utils/phonebooks";
import {speedDial} from "../../../../utils/softphone";
import breakpoints from "../../../../plugins/breakpoints";
import voicemailItemTableRowMixin from "../../../../mixins/voicemail/voicemailItemTableRowMixin";

export default {
    name: "VoicemailItemTableExpandedRow",
    components: {AudioPlayer, BaseButton, IntegrationsInline},
    mixins: [voicemailItemTableRowMixin],
    data() {
        return {
            showIntegrations: false,
            showPlayer: false,
            breakpoints
        }
    },
    methods: {
        onToggleIntegrations() {
            this.showIntegrations = !this.showIntegrations
        },

        onTogglePlayer() {
            this.showPlayer = !this.showPlayer
        },

        showAddContactPopup,

        speedDial
    }
}
</script>

<style scoped>

</style>