<template>
    <span class="base-list-item-phone-number">
        <slot></slot>
    </span>
</template>

<script>
export default {
    name: "BaseListItemPhoneNumber"
}
</script>

<style scoped>

</style>