<template>
    <div class="phonebook-select">
        <base-dropdown
            v-model="isDropdownOpen"
            :offsetY="15"
            :max-height="dropdownMaxHeight"
            content-class="phonebook-select-dropdown"
            open-by="click"
            position="bottom"
        >
            <template v-slot:activator>
                <base-input
                    :value="selectedItem.name"
                    color="dark"
                    class="phonebook-select-activator replace-style"
                    :icon="isDropdownOpen ? 'icon-expand-up' : 'icon-expand-down'"
                    readonly
                ></base-input>
            </template>

            <phonebook-select-list :items="phonebooksOptions">
                <template v-slot:default="{ item }">
                    <phonebook-select-list-item
                        @click="selectItem(item)"
                        :selected="localValue === item.value"
                        :item="item"
                        :key="item.value"
                    ></phonebook-select-list-item>
                </template>
            </phonebook-select-list>
        </base-dropdown>
    </div>
</template>

<script>
import BaseInput from "../../../../../../defaults/BaseInput.vue";
import PhonebookSelectList from "./phonebookSelectList.vue";
import PhonebookSelectListItem from "./phonebookSelectListItem.vue";
import BaseSelect from "../../../../../../defaults/BaseSelect.vue";
import BaseDropdown from "../../../../../../defaults/BaseDropdown.vue";
import breakpoints from "../../../../../../../plugins/breakpoints";
import phonebooksSelectOptionsMixin from "../../../../../../../mixins/phonebooks/phonebooksSelectOptionsMixin";

export default {
    name: "phonebookSelect",
    components: {BaseDropdown, BaseSelect, PhonebookSelectListItem, PhonebookSelectList, BaseInput},
    mixins: [phonebooksSelectOptionsMixin],
    props: {
        value: {
            type: Number | String,
            default: ""
        }
    },
    data() {
        return {
            isDropdownOpen: false,
            localValue: this.value
        }
    },
    watch: {
        value() {
            this.localValue = this.value
        },
        localValue() {
            this.$emit("input", this.localValue)
        }
    },
    computed: {
        selectedItem() {
            return this.phonebooksOptions.find((option) => option.value === this.localValue)
        },

        dropdownMaxHeight() {
            if (breakpoints.isMobile) {
                return 300
            }

            if (breakpoints.isTablet) {
                return 200
            }

            if (breakpoints.isLaptop) {
                return 310
            }

            if (breakpoints.isDesktop) {
                return 400
            }
        }
    },
    methods: {
        selectItem(item) {
            this.localValue = item.value
        }
    }
}
</script>

<style scoped>

</style>