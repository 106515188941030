import {formatNumber, sanitizePhoneNumber, numbersAreMatched} from "./phoneNumbers";
import store from "../store"

/**
 * Speed dial function
 *
 * @param {string} number
 * @param {string|null} callerId
 */
const speedDial = (number, callerId = null) => {
    const sanitizedNumber = sanitizePhoneNumber(formatNumber(number))

    if (!sanitizedNumber) {
        return
    }

    if (store.getters["softphone/isInCall"]) {
        document.dispatchEvent(new CustomEvent('speedDial', {
            "detail": {"number": sanitizedNumber}
        }))

        return
    }

    let calleridId = null
    if (callerId && callerId !== "anonymous") {
        const foundCallerId = store.getters["user/callerIds"].find((callerid) => numbersAreMatched(callerid.num, callerId))
        calleridId = foundCallerId ? foundCallerId.id : store.getters["user/calleridId"]
    }

    if (!callerId) {
        calleridId = store.getters["user/calleridId"]
    }

    store.dispatch("softphone/doCall", {
        number: sanitizedNumber,
        silent: false,
        calleridId: calleridId
    })
    if (window.innerWidth <= 800) {
        store.dispatch("changeActiveTab", "softphone")
    }
}

export {speedDial}