<template>
    <div :class="{'person-no-did-details': !didDetails}" class="person">
        <div v-if="didDetails" class="did-details">
            <span class="caller-id-name">{{ session.didDetails.name }}</span>
            <div v-show="session.didDetails.number">
                <span class="label">{{ session.didDetails.label }}</span>
                <span class="caller-id-num">{{ formattedDidDetailsNumber }}</span>
            </div>
        </div>
        <Avatar
            :size="'extraBig'"
            :src="contact.avatar"
            :text="contact.name"
            :color="contact.color"
            :call-type="contact.direction"
        ></Avatar>
        <div class="person-details" :class="{ 'is-known': contact.id || contactNameInCrm(session.number)}">
            <span
                v-show="contact.id || contactNameInCrm(session.number)"
                class="name"
            >
                {{ contact.name || contactNameInCrm(session.number) }}
            </span>

            <base-tooltip v-model="copyButton.isCopied" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
                <template v-slot:activator>
                    <span @click="[copyNumber(session.displayName), showTooltip(copyButton, 'isCopied')]" class="number">{{session.displayName}}</span>
                </template>
            </base-tooltip>
        </div>
    </div>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import {copyNumber, showTooltip} from "../../../utils/copy";
import {formatNumber} from "../../../utils/phoneNumbers";
import {mapGetters} from "vuex";

export default {
    name: "Person",
    components: {BaseTooltip, Avatar},
    props: {
        session: {
            type: Object
        },
        didDetails: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    computed: {
        ...mapGetters("integrations", ["contactNameInCrm"]),
        ...mapGetters('details', ['getContactByNumber']),

        formattedDidDetailsNumber() {
            return formatNumber(this.session.didDetails.number)
        },

        contact() {
            return this.getContactByNumber(this.session.number)
        },
    },
    methods: {
        copyNumber,
        showTooltip,
        formatNumber
    }
}
</script>

<style scoped>

</style>