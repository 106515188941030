<template>
    <base-popup
        @save="onDelete"
        @close="onClose"
        title="popups.delete-sms-dialog-popup.title"
        save-text="popups.delete-sms-dialog-popup.delete-button"
        close-text="popups.delete-sms-dialog-popup.cancel-button"
        save-btn-color="delete"
    >
        <div>{{ $t("popups.delete-sms-dialog-popup.message") }}</div>
    </base-popup>
</template>

<script>
import BaseButton from "../BaseButton.vue";
import BasePopup from "../BasePopup.vue";

export default {
    name: "DeleteSmsDialogPopup",
    components: {BasePopup, BaseButton},
    methods: {
        onClose() {
            this.$emit('close')
        },
        onDelete() {
            this.$emit('delete')
        }
    }
}
</script>

<style scoped>

</style>