<template>
    <div class="softphone-state state-incoming" v-if="getPrimarySession">
        <div class="call-info-wrapper">
            <div class="call-status call-status-animated">
                {{ $t("softphone-state-incoming.call-status") }}
            </div>
            <person
                :session="getPrimarySession"
            ></person>
        </div>

        <div class="active-call-buttons-wrapper">
            <call-button
                @press="hangUp(getPrimarySession.id)"
                :label="$t('softphone-state-incoming.buttons.decline')"
                buttonClass="hangup-call-button"
                iconClass="icon-call-end"
            ></call-button>

            <call-button
                @press="acceptCall(getPrimarySession.id)"
                :label="$t('softphone-state-incoming.buttons.accept')"
                buttonClass="accept-call-button"
                iconClass="icon-call"
            ></call-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "../../../defaults/Avatar.vue";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import ButtonsGroupIncomingSecondLine from "../../buttonsGroup/ButtonsGroupIncomingSecondLine.vue";
import CallButton from "../../base/CallButton.vue";
import {formatNumber} from "../../../../utils/phoneNumbers";
import Person from "../../base/Person.vue";

export default {
    name: "SoftphoneStateIncoming",
    components: {Person, CallButton, ButtonsGroupIncomingSecondLine, PersonCollapsed, Avatar},
    data() {
        return {}
    },
    computed: {
        ...mapGetters('softphone', ['getPrimarySession']),
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'acceptCall'
        ]),

        formatNumber
    }
}
</script>

<style scoped>

</style>