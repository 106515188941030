<template>
    <div class="audio-player">
        <div class="controls">
            <base-button
                @click="play"
                :color="buttonsColor"
                :size="buttonsSize"
                :icon="isPause ? 'icon-play' : 'icon-pause'"
                class="play-button"
            ></base-button>

            <div class="time">
                <span class="current-time">{{ currentTime }}</span>
                <span class="delimiter">/</span>
                <span class="duration">{{ totalTime }}</span>
            </div>
        </div>

        <div class="timeline-container">
            <span v-show="shownSeekTime" :style="seekTimeStyle" ref="seekTime" class="seek-time">{{ seekTime }}</span>
            <div
                @mousemove="showSeekTime"
                @mouseout="hideSeekTime"
                @click="jumpToTime"
                ref="timeline"
                class="timeline"
            >
                <div :style="bufferTrackStyle" class="track buffer-track"></div>
                <div :style="progressTrackStyle" class="track progress-track"></div>
            </div>
        </div>

        <base-button
            v-if="downloadButton"
            @click="downloadAudio"
            :color="buttonsColor"
            :size="buttonsSize"
            :icon="'icon icon-file_download'"
            class="download-button"
        ></base-button>
    </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import duration from "dayjs/plugin/duration"
import dayjs from "dayjs";
import {mapActions} from "vuex";
import i18n from "../../locales";
import {downloadFile} from "../../utils/DOM";

export default {
    name: "AudioPlayer",
    components: {BaseButton},
    props: {
        audioDuration: {
            type: Number,
            default: 1,
            validator(value) {
                return value > 0
            }
        },
        buttonsColor: {
            type: String,
            default: "gray"
        },
        buttonsSize: {
            type: String,
            default: "small"
        },
        downloadFilename: {
            type: String,
            default: ""
        },
        downloadButton: {
            type: Boolean,
            default: true
        },
        autoplay: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isPause: true,
            audio: null,
            audioUrl: null,
            raf: null,
            currentTime: '00:00',
            totalTime: '00:00',
            seekTime: '00:00',
            shownSeekTime: false,
            bufferTrackOffset: -100,
            progressTrackOffset: -100,
            seekTimeOffset: 0,
            audioIsLoad: false
        }
    },
    created() {
        dayjs.extend(duration)

        this.audio = new Audio()
        this.totalTime = this.formatTime(this.audioDurationLocal)

        if (this.autoplay) {
            this.play()
        }
    },
    destroyed() {
        window.cancelAnimationFrame(this.raf)
        if (this.audio) {
            this.audio.pause()
            this.audio.currentTime = 0
            this.audio = null
        }
    },
    computed: {
        audioDurationLocal() {
            return this.audioDuration > 0 ? this.audioDuration : 1
        },
        progressTrackStyle() {
            return {
                'transform': `translateX(${this.progressTrackOffset}%)`
            }
        },
        bufferTrackStyle() {
            return {
                'transform': `translateX(${this.bufferTrackOffset}%)`
            }
        },
        seekTimeStyle() {
            return {
                'left': `${this.seekTimeOffset}px`
            }
        }
    },
    methods: {
        ...mapActions(['createAlertMessage']),

        async play() {
            if (this.audioIsLoad) {
                return
            }

            if (!this.audio.src) {
                this.audioIsLoad = true
                this.audio.src = await this.getAudioUrl()

                this.audio.onplay = () => {
                    window.requestAnimationFrame(this.whilePlaying)
                    this.isPause = false
                }
                this.audio.onpause = () => {
                    window.cancelAnimationFrame(this.raf)
                    this.isPause = true
                }
                this.audio.onerror = () => {
                    window.cancelAnimationFrame(this.raf)
                    this.isPause = true
                }
                this.audio.onloadeddata = () => {
                    this.audioIsLoad = false
                }
                this.audio.onended = () => {
                    this.$emit('ended')
                }

                this.audio.load()
            }

            if (this.isPause) {
                this.audio.play().catch((error) => {
                    console.error(error)
                })
            } else {
                this.audio.pause()
            }
            this.isPause = !this.isPause
        },
        showSeekTime(event) {
            const {seekTime, mouseOffsetX} = this.calculateSeekTime(event)

            this.seekTime = this.formatTime(seekTime)
            this.seekTimeOffset = mouseOffsetX
            this.shownSeekTime = true
        },
        hideSeekTime() {
            this.shownSeekTime = false
        },
        jumpToTime(event) {
            const {seekTime} = this.calculateSeekTime(event)
            this.audio.currentTime = seekTime

            if (this.isPause) {
                this.displayPlayProgress()
                this.play()
            }
        },
        calculateSeekTime(event) {
            const timelineRect = this.$refs.timeline.getBoundingClientRect()
            const mouseOffsetX = event.clientX - timelineRect.x
            const seekTime = (mouseOffsetX * this.audioDurationLocal) / timelineRect.width

            return {seekTime, mouseOffsetX}
        },
        downloadAudio() {
            this.getAudioUrl().then((url) => {
                downloadFile(url, this.downloadFilename)
            })
        },
        getAudioUrl() {
            if (this.audioUrl) {
                return new Promise((resolve) => resolve(this.audioUrl))
            }

            return new Promise((resolve) => {
                this.$emit('get-audio-url', {resolve})
            }).then((url) => {
                this.audioUrl = url
                return url
            })
        },
        whilePlaying() {
            this.displayBufferedAmount()
            this.displayPlayProgress()
            this.raf = window.requestAnimationFrame(this.whilePlaying)
        },
        displayBufferedAmount() {
            const bufferedLength = this.audio.buffered.length
            if (bufferedLength > 0) {
                const bufferedAmount = this.audio.buffered.end(bufferedLength - 1)
                if (bufferedAmount > this.audioDurationLocal) {
                    this.bufferTrackOffset = 0
                } else {
                    this.bufferTrackOffset = -100 + ((bufferedAmount * 100) / this.audioDurationLocal)
                }
            }
        },
        displayPlayProgress() {
            if (this.audio.currentTime <= this.audioDurationLocal) {
                this.progressTrackOffset = -100 + ((this.audio.currentTime * 100) / this.audioDurationLocal)
                this.currentTime = this.formatTime(this.audio.currentTime)
            }
        },
        formatTime(secs) {
            return dayjs.duration(Math.round(secs) * 1000).format('mm:ss')
        }
    }
}
</script>

<style scoped>

</style>