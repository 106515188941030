import { render, staticRenderFns } from "./CrmInfo.vue?vue&type=template&id=0282878a&scoped=true&"
import script from "./CrmInfo.vue?vue&type=script&lang=js&"
export * from "./CrmInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0282878a",
  null
  
)

export default component.exports