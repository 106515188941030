<template>
    <div class="softphone-state state-incoming incoming-second-line" v-if="getPrimarySession">
        <div class="person-collapsed-wrapper">
            <person-collapsed
                :session="getPrimarySession"
            ></person-collapsed>
        </div>

        <div class="call-info-wrapper">
            <div class="call-status call-status-animated">
                {{ $t("softphone-state-incoming.call-status") }}
            </div>
            <person
                :session="getSecondSession"
            ></person>

            <button :class="{'active': muteBtnActive}" @click="muteRingSoundClick" class="mute-ring-btn">
                <span class="icon icon-volume_off"></span>
                <span v-if="muteBtnActive" class="label">{{ $t("softphone-state-incoming.mute-ring-btn.muted") }}</span>
                <span v-else class="label">{{ $t("softphone-state-incoming.mute-ring-btn.mute") }}</span>
            </button>
        </div>

        <div class="incoming-second-line-buttons-wrapper">
            <buttons-group-incoming-second-line
                @pressDeclineSecondLine="declineSecondLine"
                @pressHangUpAndAccept="hangUpAndAccept"
                @pressHoldAndAccept="holdAndAccept">
            </buttons-group-incoming-second-line>
        </div>
    </div>
</template>

<script>
import CallButton from "../../base/CallButton.vue";
import ButtonsGroupIncomingSecondLine from "../../buttonsGroup/ButtonsGroupIncomingSecondLine.vue";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import Avatar from "../../../defaults/Avatar.vue";
import {mapActions, mapGetters} from "vuex";
import {formatNumber} from "../../../../utils/phoneNumbers";
import Person from "../../base/Person.vue";

export default {
    name: "SoftphoneStateActiveIncoming",
    components: {Person, CallButton, ButtonsGroupIncomingSecondLine, PersonCollapsed, Avatar},
    data() {
        return {
            muteBtnActive: false
        }
    },
    computed: {
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession'
        ])
    },
    methods: {
        ...mapActions('softphone', [
            'hangUp',
            'acceptCall',
            'sessionSetHold'
        ]),

        muteRingSoundClick() {
            this.muteBtnActive = !this.muteBtnActive
            if (this.muteBtnActive) {
                document.dispatchEvent(new CustomEvent('stopRingSound'))
            } else {
                document.dispatchEvent(new CustomEvent('playRingSound'))
            }
        },

        declineSecondLine() {
            this.hangUp(this.getSecondSession.id)
        },

        hangUpAndAccept() {
            this.acceptCall(this.getSecondSession.id)
            this.hangUp(this.getPrimarySession.id)
        },

        holdAndAccept() {
            this.acceptCall(this.getSecondSession.id)
            this.sessionSetHold({sessionid: this.getPrimarySession.id, manually: false})
        },

        formatNumber
    }
}
</script>

<style scoped>

</style>