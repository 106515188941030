<template>
    <div class="login-screen-wrapper auth-screen" :class="{'integrations' : integrationsLogin}">
        <div class="form-wrapper">
            <div class="info">
                <div class="logo">
                  <img :src="logoUrl">
                  <h1>{{ $t("login.title") }}</h1>
                </div>
                <div class="captcha" v-if="captchaKey">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                </div>
            </div>

            <form v-show="!sendUrlForResetPasswordScreen" class="login-form">
                <chip
                    class="auth-prompt"
                    v-if="integrationsLogin"
                    :size="'big'"
                    :text="'login.integrations.auth-prompt'"
                    :color="'white'"
                    :radius="'20px'"
                ></chip>

                <base-button
                    v-if="electronAppUpdateAvailable"
                    @click="downloadUpdate"
                    text="login.update-available"
                    color="orange"
                    size="big"
                    radius="20px"
                    class="update-available-btn"
                ></base-button>

                <div class="input-group">
                    <span class="label">{{ $t("login.email-label") }}</span>
                    <base-input
                        v-model="email"
                        @input="loginError = false; $v.$reset()"
                        @blur="$v.email.$touch()"
                        :error="!!(loginError || emailNotValid)"
                        type="text"
                        color="login"
                        rounded
                    ></base-input>
                </div>

                <div class="input-group">
                    <span class="label">{{ $t("login.password-label") }}</span>
                    <base-input
                        v-model="password"
                        @input="loginError = false"
                        @keyup.enter.native="integrationsLogin ? integrationsAuth() : auth()"
                        :error="!!loginError"
                        type="password"
                        color="login"
                        rounded
                    ></base-input>
                </div>

                <div class="button-group">
                    <base-button
                        v-if="integrationsLogin"
                        class="action-btn integrations-auth-btn"
                        @click="integrationsAuth"
                        :disabled="$v.$invalid"
                        :loading="loginLoading"
                        :size="'big'"
                        :color="'blue'"
                        :text="'login.integrations.auth-button'"
                        :radius="'18px'"
                    ></base-button>

                    <base-button
                        v-if="!integrationsLogin"
                        class="action-btn login-btn"
                        @click="auth"
                        :disabled="$v.$invalid"
                        :loading="loginLoading"
                        :size="'big'"
                        :color="'blue'"
                        :text="'login.login-button'"
                        :radius="'18px'"
                    ></base-button>

                    <base-button
                        v-if="!integrationsLogin"
                        class="text-btn"
                        @click="sendUrlForResetPasswordScreen = true"
                        :text="'login.forgot-password'"
                        :color="'text'"
                    ></base-button>
                </div>
                <div class="invalid-feedback">
                    <p v-if="emailNotValid">{{ $t("login.please-enter-valid-email") }}</p>
                    <p v-if="loginError">{{ $t("login." + loginError) }}</p>
                </div>
            </form>

            <form v-if="!integrationsLogin" v-show="sendUrlForResetPasswordScreen" :class="{'reset-final': resetFinal}" class="change-password-form">
                <p v-show="!resetFinal">{{ $t("login.reset-info") }}</p>

                <p v-show="resetFinal" class="reset-final-message">{{ $t("login.send-email-success") }}</p>

                <div v-show="!resetFinal" class="input-group">
                    <span class="label">{{ $t("login.reset-email-label") }}</span>
                    <base-input
                        v-model="email"
                        @input="$v.$reset()"
                        @blur="$v.email.$touch()"
                        :error="!!emailNotValid"
                        type="text"
                        color="login"
                        rounded
                    ></base-input>
                </div>

                <div class="button-group">
                    <base-button
                        class="action-btn"
                        v-show="!resetFinal"
                        @click="reset"
                        :disabled="$v.email.$invalid"
                        :loading="resetLoading"
                        :size="'big'"
                        :color="'blue'"
                        :text="'login.reset-password-button'"
                        :radius="'18px'"
                    ></base-button>

                    <base-button
                        class="text-btn"
                        v-show="!resetFinal"
                        @click="sendUrlForResetPasswordScreen = false"
                        :text="'Back'"
                        :color="'text'"
                    ></base-button>

                    <base-button
                        class="action-btn back-to-btn"
                        v-show="resetFinal"
                        @click="sendUrlForResetPasswordScreen = false"
                        :size="'big'"
                        :color="'blue'"
                        :text="'login.back-to-login'"
                        :radius="'18px'"
                    ></base-button>
                </div>

                <div class="invalid-feedback">
                    <p v-show="emailNotValid">{{ $t("login.please-enter-valid-email") }}</p>
                </div>
            </form>

            <div class="bottom">

                <div class="group">
                    <div class="language-changer">
                        <base-select @input="changeLocale($event)" :value="$i18n.locale" :position="'top'" :options="languages"></base-select>
                    </div>

                    <base-button
                        v-if="isElectronExist()"
                        @click="reloadPage"
                        size="big"
                        text="top-bar.reload-button"
                        icon="icon-refresh"
                        class="reload-button"
                    ></base-button>
                </div>

                <span v-if="isElectronExist && electronAppVersion" class="electron-app-version">{{ $t("electron-app-version") }} {{ electronAppVersion }}</span>

                <div class="download-app">
                    <div v-if="isMobileLinksAvailable && !isElectronExist() && !integrationsLogin" class="download-app-mobile">
                        <a :href="androidDownloadLink" target="_blank">
                            <img :src="require('../../images/google_play.svg')" alt="App store">
                        </a>

                        <a :href="iosDownloadLink" target="_blank">
                            <img :src="require('../../images/app_store.svg')" alt="Google play">
                        </a>
                    </div>

                    <div v-if="apps.length && !isElectronExist() && !integrationsLogin" class="download-app-desktop">
                        <base-dropdown
                            v-model="showDownloadAppDropdown"
                            position="top"
                            open-by="click"
                        >
                            <template v-slot:activator>
                                <div class="download-app-desktop-toggle">
                                    <span class="icon icon-file_download"></span>
                                    <span class="name">{{ $t("login.apps.title") }}</span>
                                    <span v-if="showDownloadAppDropdown" class="icon icon-expand-up"></span>
                                    <span v-else class="icon icon-expand-down"></span>
                                </div>
                            </template>

                            <base-list>
                                <base-list-item
                                    v-for="app in desktopAppsDropdownOptions"
                                    @click="downloadApp(app.filepath)"
                                    :key="app.type"
                                >
                                    <base-list-item-icon :icon="app.icon"></base-list-item-icon>
                                    <base-list-item-title>{{ app.name }}</base-list-item-title>
                                </base-list-item>
                            </base-list>
                        </base-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { required, email } from 'vuelidate/dist/validators.min.js'
import BaseSelect from "../defaults/BaseSelect.vue";
import {changeLocale} from "../../locales";
import {isElectronExist, sendToElectron} from "../../utils/electron";
import BaseButton from "../defaults/BaseButton.vue";
import Chip from "../defaults/Chip.vue";
import {getCaptchaToken} from "../../helpers/CaptchaHelper";
import BaseInput from "../defaults/BaseInput.vue";
import {downloadFile, reloadPage} from "../../utils/DOM";
import BaseDropdown from "../defaults/BaseDropdown.vue";
import BaseList from "../defaults/baseList/BaseList.vue";
import BaseListItem from "../defaults/baseList/BaseListItem.vue";
import BaseListItemIcon from "../defaults/baseList/BaseListItemIcon.vue";
import BaseListItemTitle from "../defaults/baseList/BaseListItemTitle.vue";
import appsDropdownOptionsMixin from "../../mixins/appsDropdownOptionsMixin";

export default {
    name: "Login",
    components: {
        BaseListItemTitle,
        BaseListItemIcon, BaseListItem, BaseList, BaseDropdown, BaseInput, Chip, BaseButton, BaseSelect},
    mixins: [appsDropdownOptionsMixin],
    props: {
        integrationsLogin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDownloadAppDropdown: false,
            sendUrlForResetPasswordScreen: false,
            email: '',
            password: '',
            loginLoading: false,
            loginError: false,
            resetLoading: false,
            resetFinal: false,
            prevRoutePath: null
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoutePath = from.path
        })
    },
    computed: {
        ...mapGetters(['captchaKey', 'languages', 'logoUrl', 'partnerId', 'electronAppVersion', 'apps', 'electronAppUpdateAvailable', 'isMobileLinksAvailable']),

        emailNotValid() {
            return this.$v.email.$error && this.email.length
        },

        androidDownloadLink() {
            const app = this.apps.find((app) => app.type === "android")

            return app ? app.filepath : ""
        },

        iosDownloadLink() {
            const app = this.apps.find((app) => app.type === "ios")

            return app ? app.filepath : ""
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    },
    methods: {
        ...mapActions(['updateLogoUrl', 'updateFaviconLogoUrl', 'updateAppNoLicense']),
        ...mapActions('user', ['logIn', 'sendResetLink', 'integrationsLogIn']),
        reset: function() {
            if (this.$v.email.$invalid || this.resetLoading) {
                return;
            }
            this.resetLoading = true;
            const email = this.email;
            const partnerId = this.partnerId;

            getCaptchaToken('password_reset_request')
                .then((token) => {
                    const captcha = token
                    this.sendResetLink({ email, captcha, partnerId })
                        .catch(() => {})
                        .finally(() => {
                            this.resetFinal = true
                            this.resetLoading = false
                        })
                })
                .catch(() => {
                    this.resetLoading = false
                })
        },

        auth: function () {
            const vm = this;
            if (this.$v.$invalid || this.loginLoading) {
                return;
            }
            this.loginLoading = true;
            this.loginError = false;
            const email = this.email;
            const password = this.password
            const partnerId = this.partnerId;

            getCaptchaToken('login')
                .then((token) => {

                    const captcha = token
                    this.logIn({ email, password, captcha, partnerId })
                        .then(response => {
                            if (response.data.darkLogoUrl) {
                              this.updateLogoUrl(response.data.darkLogoUrl)
                            }

                            if (response.data.favicon32x32Url) {
                              this.updateFaviconLogoUrl(response.data.favicon32x32Url)
                            }

                            this.loginLoading = false
                            if (this.prevRoutePath) {
                                this.$router.push(this.prevRoutePath)
                            } else {
                                this.$router.push('/')
                            }
                        })
                        .catch((err) => {
                            this.loginLoading = false

                            if (err?.response?.data?.code === "403" && err?.response?.data?.error_message === "No license") {
                                this.updateAppNoLicense(true)
                            } else {
                                this.loginError = 'invalid-username-or-password'
                            }
                        })
                })
                .catch(() => {
                    this.loginLoading = false
                    this.loginError = 'invalid-captcha'
                })
        },

        integrationsAuth: function () {
            if (this.$v.$invalid || this.loginLoading) {
                return
            }

            const clientId = this.$route.query.client_id
            const state = this.$route.query.state
            const redirectUri = this.$route.query.redirect_uri
            const email = this.email
            const password = this.password

            this.loginLoading = true
            this.loginError = false

            getCaptchaToken('login')
                .then((token) => {
                    const captcha = token
                    this.integrationsLogIn({ email, password, captcha, clientId, state, redirectUri })
                        .then((response) => {
                            this.loginLoading = false
                            if (response.data.darkLogoUrl) {
                                this.updateLogoUrl(response.data.darkLogoUrl)
                            }
                            if (response.data.favicon32x32Url) {
                              this.updateFaviconLogoUrl(response.data.favicon32x32Url)
                            }
                            window.open(response.data["redirect_uri"], "_self")
                        })
                        .catch((e) => {
                            this.loginLoading = false
                            this.loginError = 'invalid-username-or-password'
                        })
                })
                .catch(() => {
                    this.loginLoading = false
                    this.loginError = 'invalid-captcha'
                })
        },
        downloadApp(url) {
            downloadFile(url, "")
        },
        downloadUpdate: function () {
            sendToElectron("downloadUpdate")
        },

        changeLocale,

        isElectronExist,

        reloadPage
    }
}
</script>

<style scoped>

</style>