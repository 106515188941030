import english from './languages/english.json';
import french from './languages/french.json';
import german from './languages/german.json';
import italian from './languages/italian.json';
import portuguese from './languages/portuguese.json';
import spanish from './languages/spanish.json';
import VueI18n from "vue-i18n";
import Vue from 'vue';
import {sendToElectron} from "../utils/electron";

Vue.use(VueI18n);

const defaultLocale = 'en'
const languages = {
    en: english,
    fr: french,
    de: german,
    it: italian,
    pt: portuguese,
    es: spanish
}
const messages = Object.assign(languages);
let i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages
})

const changeLocale = (langCode) => {
    i18n.locale = langCode
    sendToElectron("setLocale", langCode)
}

export {changeLocale}

export default i18n;