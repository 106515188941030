<template>
    <div :class="{'one-column': !isNeedToShowSecondTable}" class="call-parking-item">
        <div class="call-parking-item-header">
            <h3>{{ callParking.name }}</h3>

            <chip
                :text="`${callParking.first_slot_number}-${callParking.last_slot_number}`"
                color="light-gray"
                radius="13px"
            ></chip>

            <chip
                :text="freeSlotsText"
                :color="chipFreeSlotsColor"
                radius="13px"
            ></chip>
        </div>

        <div class="call-parking-item-content">
            <div
                v-if="!callParking.slots.length"
                class="no-busy-slots"
            >
                {{ $t('panel.services.call-parking.no-busy-parking-slots') }}
            </div>

            <template v-else>
                <base-table
                    :headers="headers"
                    :items="isNeedToShowSecondTable ? firstTableItems : callParking.slots"
                    :rounded="isNeedToShowSecondTable"
                    item-key="number"
                >
                    <template v-slot:row="{item}">
                        <call-parking-item-table-row
                            @park-time-is-over="onParkTimeIsOver(item.number)"
                            :is-wallboard="isWallboard"
                            :key="item.number"
                            :item="item"
                        ></call-parking-item-table-row>
                    </template>
                </base-table>

                <base-table
                    v-if="secondTableItems.length && isNeedToShowSecondTable"
                    :headers="headers"
                    :items="secondTableItems"
                    item-key="number"
                    rounded
                >
                    <template v-slot:row="{item}">
                        <call-parking-item-table-row
                            @park-time-is-over="onParkTimeIsOver(item.number)"
                            :is-wallboard="isWallboard"
                            :key="item.number"
                            :item="item"
                        ></call-parking-item-table-row>
                    </template>
                </base-table>
            </template>
        </div>
    </div>
</template>

<script>
import Chip from "../../../defaults/Chip.vue";
import BaseTable from "../../../defaults/baseTable/BaseTable.vue";
import CallParkingItemTableRow from "./CallParkingItemTableRow.vue";
import {mapActions} from "vuex";
import callParkingItemMixin from "../../../../mixins/callParking/callParkingItemMixin";
import breakpoints from "../../../../plugins/breakpoints";

export default {
    name: "CallParkingItem",
    components: {CallParkingItemTableRow, BaseTable, Chip},
    mixins: [callParkingItemMixin],
    props: {
        callParking: {
            type: Object,
            default: {}
        },
        isWallboard: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        chipFreeSlotsColor() {
            const count = this.freeSlotsCount

            if (count >= 1) {
                return "light-green"
            }

            return "light-red"
        },

        firstTableItems() {
            return this.callParking.slots.filter((slot, idx) => idx % 2 === 0)
        },

        secondTableItems() {
            return this.callParking.slots.filter((slot, idx) => idx % 2 !== 0)
        },

        headers() {
            if (breakpoints.isMobile) {
                return [
                    {title: this.$t('panel.services.call-parking.headers.slot'), key: 'number', align: 'left', fr: 0.5},
                    {title: this.$t('panel.services.call-parking.headers.parking-end-in'), key: 'park_end_in_seconds', align: 'left', fr: 1.5},
                    {title: this.$t('panel.services.call-parking.headers.parked-call'), key: 'parked_number', align: 'left', fr: 2}
                ]
            }

            return [
                {title: this.$t('panel.services.call-parking.headers.slot'), key: 'number', align: 'left', fr: 0.5},
                {title: this.$t('panel.services.call-parking.headers.parked-by'), key: 'parked_by_number', align: 'left', fr: 1},
                {title: this.$t('panel.services.call-parking.headers.parking-end-in'), key: 'park_end_in_seconds', align: 'left', fr: 1},
                {title: this.$t('panel.services.call-parking.headers.parked-call'), key: 'parked_number', align: 'left', fr: 1}
            ]
        },

        isNeedToShowSecondTable() {
            return (!this.isWallboard && !breakpoints.isLaptop) || (this.isWallboard && !breakpoints.isTablet)
        }
    },
    methods: {
        ...mapActions("callParkings", ["removeSlot"]),

        onParkTimeIsOver(slotNumber) {
            this.removeSlot({callParkingNumber: this.callParking.number, slotNumber: slotNumber})
        }
    }
}
</script>

<style scoped>

</style>