<template>
    <div class="open-in-crm-button">
        <base-button
            @click="onClick"
            :color="color"
            :text="$t('open-in-crm-button', [crm.title])"
        ></base-button>
    </div>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";
import Crm from "../../../helpers/integrations/crms";
import {redirectByClick} from "../../../utils/DOM";

export default {
    name: "OpenInCrmButton",
    components: {BaseButton},
    props: {
        crmType: {
            type: String
        },
        link: {
            type: String
        }
    },
    data() {
        return {
            crm: null
        }
    },
    created() {
        this.crm = new Crm().create(this.crmType)
    },
    computed: {
        color() {
            return `${this.crm.type}-color`
        }
    },
    methods: {
        onClick() {
            redirectByClick(this.link)
        }
    }
}
</script>

<style scoped>

</style>