<template>
    <div class="table-top">
        <slot name="prepend">

        </slot>

        <div class="pagination">
            <div v-if="!itemsPerPageDisabled" class="items-per-page">
                <span class="label">{{ $t('panel.call-history.items-per-page') }}</span>
                <base-select
                    :value="itemsPerPage"
                    :disable-scroll="true"
                    :options="itemsPerPageOptions"
                    @input="changeItemsPerPage"
                >
                </base-select>
            </div>

            <div class="page-text">{{ getPageText }}</div>

            <div class="buttons">
                <base-button
                    @click="prevPage"
                    :disabled="disablePrevPageIcon"
                    :size="'big'"
                    :color="'gray'"
                    :icon="'icon-chevron-left'"
                ></base-button>

                <base-button
                    @click="nextPage"
                    :disabled="disableNextPageIcon"
                    :size="'big'"
                    :color="'gray'"
                    :icon="'icon-chevron-right'"
                ></base-button>

                <base-button
                    @click="refresh"
                    :size="'big'"
                    :color="'gray'"
                    :icon="'icon-refresh'"
                    ref="refreshBtn"
                ></base-button>
            </div>
        </div>

        <slot name="append">

        </slot>
    </div>
</template>

<script>
import BaseSelect from "./BaseSelect.vue";
import BaseButton from "./BaseButton.vue";

export default {
name: "TableTop",
    components: {BaseButton, BaseSelect},
    props: {
        itemsPerPage: {
            type: Number,
            default: 0
        },
        pagination: {
            type: Object,
            default: {}
        },
        options: {
            type: Object,
            default: {}
        },
        itemsPerPageDisabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getPageText() {
            const itemsLength = this.pagination.itemsLength
            let pageStart = this.pagination.pageStart
            let pageStop = this.pagination.pageStop
            let pageText = '-'

            if (this.pagination.itemsLength && this.pagination.itemsPerPage) {
                pageStart = this.pagination.pageStart + 1
                pageStop = (itemsLength < this.pagination.pageStop || this.pagination.pageStop < 0) ? itemsLength : this.pagination.pageStop

                pageText = this.$t("panel.call-history.of", {pageStart, pageStop, itemsLength})
            }

            return pageText
        },
        itemsPerPageOptions() {
            return [
                {name: '15', value: 15},
                {name: '20', value: 20},
                {name: '30', value: 30}
            ]
        },
        disablePrevPageIcon() {
            return this.options.page === 1
        },
        disableNextPageIcon() {
            return this.options.itemsPerPage <= 0 ||
                   this.options.page * this.options.itemsPerPage >= this.pagination.itemsLength ||
                   this.pagination.pageStop < 0
        }
    },
    methods: {
        updateOptions (obj) {
            this.$emit('updateOptions', Object.assign({}, this.options, obj))
        },
        prevPage() {
            this.updateOptions({ page: this.options.page - 1 })
        },
        nextPage() {
            this.updateOptions({ page: this.options.page + 1 })
        },
        changeItemsPerPage (itemsPerPage) {
            this.updateOptions({ itemsPerPage, page: 1 })
        },
        refresh() {
            this.$refs.refreshBtn.$el.blur()
            this.$emit('refresh')
        }
    }
}
</script>

<style scoped>

</style>