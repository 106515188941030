<template>
    <div class="base-counter">
        <input
            v-model="localValue"
            @blur="onBlur"
            @keydown.prevent.up="increase"
            @keydown.prevent.down="decrease"
            type="number"
            ref="inputCounter"
        >

        <div class="btns-container">
            <button
                @mousedown.stop.prevent="startHold(increase)"
                @touchstart.stop.prevent="startHold(increase)"
                @touchend.stop.prevent="endHold"
                @touchcancel.stop.prevent="endHold"
                class="increase-btn"
            >
                <span class="icon icon-arrow-drop-up"></span>
            </button>

            <button
                @mousedown.stop.prevent="startHold(decrease)"
                @touchstart.stop.prevent="startHold(decrease)"
                @touchend.stop.prevent="endHold"
                @touchcancel.stop.prevent="endHold"
                class="decrease-btn"
            >
                <span class="icon icon-arrow-drop-down"></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseCounter",
    props: {
        value: {
            type: Number,
            default: 0
        },
        minValue: {
            type: Number,
            default: null
        },
        maxValue: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            localValue: 0,
            interval: null,
            timeout: null
        }
    },
    created() {
        this.localValue = this.value
    },
    methods: {
        startHold(callback) {
            callback()

            this.$refs.inputCounter.focus()

            this.timeout = setTimeout(() => {
                this.interval = setInterval(callback, 50)
            }, 300)

            document.addEventListener("mouseup", this.endHold, {once: true})
        },
        endHold() {
            clearTimeout(this.timeout)
            clearInterval(this.interval)
        },
        increase() {
            this.updateValue(parseInt(this.localValue) + 1)
        },
        decrease() {
            this.updateValue(parseInt(this.localValue) - 1)
        },
        updateValue(value) {
            if (isNaN(value)) {
                this.localValue = Math.max(0, this.minValue)
                this.$emit('input', this.localValue)
                return
            }

            if (value >= (typeof this.maxValue === 'number' ? this.maxValue : Infinity)) {
                value = this.maxValue
            }

            if (value <= (typeof this.minValue === 'number' ? this.minValue : -Infinity)) {
                value = this.minValue
            }

            this.localValue = value
            this.$emit('input', this.localValue)
        },
        onBlur() {
            this.updateValue(parseInt(this.localValue))
        }
    }
}
</script>

<style scoped>

</style>