<template>
    <div class="alert-message" :class="type" v-click-outside="() => {$emit('onClose'); $emit('outsideClick')}">
        <div class="title">
            <span class="icon" :class="alertData[type].icon_class"></span>
            <p>{{ headerText ? headerText : $t(alertData[type].header) }}</p>
        </div>
        <span class="icon icon-clear" @click="$emit('onClose')"></span>

        <p>{{ message }}</p>

        <base-button
            v-if="buttonText"
            @click="$emit('onClose'); $emit('buttonClick')"
            :text="buttonText"
        ></base-button>
    </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
    name: "Alert",
    components: {BaseButton},
    props: {
        type: {
            type: String,
            default: ''
        },
        headerText: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            alertData: {
                success: {
                    icon_class: "icon-check-circle",
                    header: "alert-messages.headers.success"
                },
                info: {
                    icon_class: "icon-error",
                    header: "alert-messages.headers.info"
                },
                warning: {
                    icon_class: "icon-warning",
                    header: "alert-messages.headers.warning"
                },
                error: {
                    icon_class: "icon-report",
                    header: "alert-messages.headers.error"
                }
            }
        }
    }
}
</script>

<style scoped>

</style>