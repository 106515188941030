<template>
    <base-popup
        @save="deleteContact"
        @close="close"
        popup-class="contact-popup"
        title="popups.delete-contact-popup.title"
        save-text="popups.delete-contact-popup.delete-contact-button"
        close-text="popups.delete-contact-popup.cancel-button"
        save-btn-color="delete"
    >
        <div>{{ $t("popups.delete-contact-popup.message") }}</div>
    </base-popup>
</template>

<script>
import {mapActions} from "vuex";
import BasePopup from "../BasePopup.vue";

export default {
    name: "DeleteContactPopup",
    components: {BasePopup},
    props: ['contact'],
    methods: {
        ...mapActions('details', [
            'removeContact'
        ]),
        deleteContact() {
            this.removeContact(this.contact);
            this.$emit('close');
        },
        close () {
            this.$emit('close');
        },
    }
}
</script>

<style scoped>

</style>