<template>
    <div :class="{'inline': inline}" class="crm-contact-expand">
        <div class="general-info-wrapper">
            <div v-for="info in expandedInfo.generalInfo" v-if="info.value && info.label" class="info-group">
                <span class="data">{{ info.value }}</span>
                <span class="label">{{ $t(info.label) }}</span>
            </div>
        </div>

        <div v-for="card in expandedInfo.cards" :class="card.class" class="card-wrapper">
            <div class="crm-card-header">
                <span class="card-title">{{ $t(card.title) }}</span>
                <span class="total-number">{{ card.count }}</span>
            </div>

            <div class="info-groups-wrapper deals-group-wrapper" v-for="item in card.items">
                <div class="info-group">
                    <a class="data" :href="item.link" target="_blank">{{ item.name }}</a>
                    <span v-if="item.label && item.label_value" class="label">{{ $t(item.label, [item.label_value]) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Crm from "../../../helpers/integrations/crms";

export default {
    name: "ExpandedContactInfo",
    props: {
        crmType: {
            type: String,
            default: ""
        },
        extendedInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        generalInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expandedInfo: {}
        }
    },
    created() {
        this.setExpandedInfo()
    },
    computed: {
        combined(){
            return this.crmType && this.extendedInfo && this.generalInfo
        }
    },
    watch: {
        combined() {
            this.setExpandedInfo()
        }
    },
    methods: {
        setExpandedInfo() {
            const crm = new Crm().create(this.crmType)
            this.expandedInfo = crm.getExpandedInfo(this.generalInfo, this.extendedInfo)
        }
    }
}
</script>

<style scoped>

</style>