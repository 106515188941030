<template>
    <service-tab class="voicemails">
        <template v-if="userVoicemails.length" v-slot:header>
            <base-button
                @click="onChangeMessagesStatuses"
                :disabled="!existsSelectedVoicemailMessages"
                :loading="changeStatusesLoading"
                text="panel.services.voicemail.change-statuses-button"
                icon="icon-done-all"
                size="big"
            ></base-button>

            <base-button
                @click="toggleDeleteVoicemailMessagesPopup(true)"
                :disabled="!existsSelectedVoicemailMessages"
                text="panel.services.voicemail.delete-button"
                color="delete"
                icon="icon-delete-forever"
                size="big"
            ></base-button>

            <base-button
                @click="toggleFiltersPopup(true)"
                color="blue"
                size="big"
                icon="icon-filter"
                text="panel.call-history.filters-btn"
                class="btn-filter"
            >
                <template v-slot:append>
                    <span v-if="filters.count" class="counter">{{ filters.count }}</span>
                </template>
            </base-button>

            <chip
                v-if="!breakpoints.isLaptop"
                v-for="(chip, index) of filters.chips"
                :key="index"
                :text="chip.value"
                size="big"
                color="light-blue"
            >
                <template v-slot:append>
                    <base-button
                        @click="removeFilter(chip.name)"
                        :icon-size="14"
                        size="small"
                        color="white"
                        icon="icon-clear"
                        radius="50%"
                        class="chip-button-close"
                    ></base-button>
                </template>
            </chip>

            <base-button
                @click="onRefresh"
                size="big"
                color="gray"
                icon="icon-refresh"
                ref="refreshBtn"
                class="refresh-btn"
            ></base-button>

            <voicemail-filters-popup
                v-if="filtersPopup"
                v-model="filters"
                @close="toggleFiltersPopup(false)"
                @apply="filtersApply"
            ></voicemail-filters-popup>

            <delete-voicemail-messages-popup
                v-if="deleteVoicemailMessagesPopup"
                @close="toggleDeleteVoicemailMessagesPopup(false)"
                @delete="onDeleteMessages"
            ></delete-voicemail-messages-popup>
        </template>

        <template v-slot:body>
            <div v-if="!userVoicemails.length" class="voicemails-no-available">{{ $t("panel.services.voicemail.no-voicemails") }}</div>

            <base-spinner v-else-if="voicemailsLoading" center></base-spinner>

            <voicemail-item
                v-else
                v-for="(voicemail, index) in voicemailsVoicemails"
                v-model="selectedVoicemailMessages[voicemail.id]"
                :voicemail="voicemail"
                :key="index"
            ></voicemail-item>
        </template>
    </service-tab>
</template>

<script>
import BaseButton from "../../../defaults/BaseButton.vue";
import VoicemailFiltersPopup from "../../../defaults/popups/VoicemailFiltersPopup.vue";
import VoicemailFilters from "../../../../helpers/VoicemailFilters";
import BaseCheckbox from "../../../defaults/BaseCheckbox.vue";
import Chip from "../../../defaults/Chip.vue";
import VoicemailItem from "./VoicemailItem.vue";
import {mapActions, mapGetters} from "vuex";
import DeleteVoicemailMessagesPopup from "../../../defaults/popups/DeleteVoicemailMessagesPopup.vue";
import {MESSAGE_DIRNAME_INBOX, MESSAGE_DIRNAME_OLD} from "../../../../store/modules/voicemails";
import BaseSpinner from "../../../defaults/BaseSpinner.vue";
import ServiceTab from "../ServiceTab.vue";
import breakpoints from "../../../../plugins/breakpoints";

export default {
    name: "Voicemails",
    components: {
        ServiceTab,
        BaseSpinner,
        DeleteVoicemailMessagesPopup, VoicemailItem, BaseCheckbox, VoicemailFiltersPopup, BaseButton, Chip},
    data() {
        return {
            filters: null,
            filtersPopup: false,
            deleteVoicemailMessagesPopup: false,
            selectedVoicemailMessages: {},
            changeStatusesLoading: false,
            voicemailsLoading: false,
            breakpoints
        }
    },
    created() {
        this.filters = new VoicemailFilters({})
        this.voicemailsLoading = true
        this.getVoicemails(this.filters.requestParams).catch(() => {}).finally(() => this.voicemailsLoading = false)
    },
    watch: {
        isUnreadVoicemailMessagesExists(value) {
            this.updateIsUnreadVoicemailMessagesExists(value)
        }
    },
    computed: {
        ...mapGetters("voicemails", {voicemailsVoicemails: "voicemails"}),
        ...mapGetters("user", {userVoicemails: "voicemails"}),

        existsSelectedVoicemailMessages() {
            return Boolean(Object.keys(this.selectedVoicemailMessages).find((voicemailId) => this.selectedVoicemailMessages[voicemailId].length > 0))
        },
        isUnreadVoicemailMessagesExists() {
            let isUnreadVoicemailMessagesExists = false
            for (let voicemail of this.voicemailsVoicemails) {
                let message = voicemail.messages.find((msg) => msg.dirname === MESSAGE_DIRNAME_INBOX)

                if (message) {
                    isUnreadVoicemailMessagesExists = true
                    break
                }
            }

            return isUnreadVoicemailMessagesExists
        }
    },
    methods: {
        ...mapActions("voicemails", ["getVoicemails", "removeVoicemailMessages", "markAsListenedMessages", "markAsNotListenedMessages"]),
        ...mapActions("user", ["updateIsUnreadVoicemailMessagesExists"]),

        removeFilter(name) {
            this.filters.removeFilter(name)
            this.getVoicemails(this.filters.requestParams).catch(() => {})
        },
        toggleFiltersPopup(show) {
            this.filtersPopup = show
        },
        toggleDeleteVoicemailMessagesPopup(show) {
            this.deleteVoicemailMessagesPopup = show
        },
        filtersApply() {
            this.toggleFiltersPopup(false)
            this.getVoicemails(this.filters.requestParams).catch(() => {})
        },
        onDeleteMessages() {
            const messages = this.getSelectedVoicemailMessagesArrayForRequest()

            this.removeVoicemailMessages(messages).then(() => {
                this.selectedVoicemailMessages = {}
            }).catch(() => {})
            this.toggleDeleteVoicemailMessagesPopup()
        },
        onChangeMessagesStatuses() {
            const messages = this.getSelectedVoicemailMessagesArrayForRequest()
            const markAsListenedMessages = messages.filter((msg) => msg.dirname === MESSAGE_DIRNAME_INBOX)
            const markAsNotListenedMessages = messages.filter((msg) => msg.dirname === MESSAGE_DIRNAME_OLD)

            let requests = []

            if (markAsListenedMessages.length && markAsNotListenedMessages.length) {
                requests.push(this.markAsListenedMessages(markAsListenedMessages))
            } else {
                if (markAsListenedMessages.length) {
                    requests.push(this.markAsListenedMessages(markAsListenedMessages))
                }

                if (markAsNotListenedMessages.length) {
                    requests.push(this.markAsNotListenedMessages(markAsNotListenedMessages))
                }
            }

            this.changeStatusesLoading = true
            Promise.all(requests)
                .then(() => this.selectedVoicemailMessages = {})
                .catch(() => {})
                .finally(() => this.changeStatusesLoading = false)
        },
        getSelectedVoicemailMessagesArrayForRequest() {
            let messages = []
            for (let voicemailId in this.selectedVoicemailMessages) {
                messages.push(...this.selectedVoicemailMessages[voicemailId].map((item) => {
                    return {voicemail_id: Number(voicemailId), filename: item.filename, dirname: item.dirname}
                }))
            }

            return messages
        },
        onRefresh() {
            this.$refs.refreshBtn.$el.blur()
            this.getVoicemails(this.filters.requestParams).catch(() => {})
        }
    }
}
</script>

<style scoped>

</style>