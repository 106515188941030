<template>
    <base-popup
        @close="close"
        :save-btn-visible="false"
        :title="'alert-messages.headers.warning'"
    >
        <span>{{ $t("popups.device-management-warning-popup.message", [maxExternalNumbers]) }}</span>
    </base-popup>
</template>

<script>
import BasePopup from "../BasePopup.vue";

export default {
    name: "DeviceManagementWarningPopup",
    components: {BasePopup},
    props: {
        maxExternalNumbers: {
            type: Number,
            default: null
        }
    },
    methods: {
        close: function () {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>