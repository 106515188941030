<template>
    <div class="caller-id-select">
        <base-dropdown
            v-model="showDropdown"
            :width="300"
            :offsetY="offset"
            :max-height="310"
            :close-on-content-click="false"
            open-by="click"
            position="top"
        >
            <template v-slot:activator>
                <slot></slot>
            </template>

            <base-list>
                <base-list-item
                    v-for="(option, idx) in _options"
                    @click="onInput(option)"
                    :key="idx"
                    :selected="value.id === option.id"
                >
                    <base-list-item-title>{{ option.name }}</base-list-item-title>

                    <base-list-item-phone-number>{{ option.number }}</base-list-item-phone-number>
                </base-list-item>

                <base-list-item v-if="!_options.length" :hover="false">
                    <base-list-item-title>No elements found</base-list-item-title>
                </base-list-item>
            </base-list>

            <template v-slot:no-scrollable-content-after>
                <caller-id-select-search-input v-model="searchModel" :placeholder="searchPlaceholder"></caller-id-select-search-input>
            </template>
        </base-dropdown>
    </div>
</template>

<script>
import {formatNumber, sanitizePhoneNumber} from "../../../../utils/phoneNumbers.js";
import BaseSelect from "../../../defaults/BaseSelect.vue";
import CallerIdSelectSearchInput from "./CallerIdSelectSearchInput.vue";
import {mapGetters} from "vuex";
import BaseDropdown from "../../../defaults/BaseDropdown.vue";
import BaseList from "../../../defaults/baseList/BaseList.vue";
import BaseListItem from "../../../defaults/baseList/BaseListItem.vue";
import BaseListItemTitle from "../../../defaults/baseList/BaseListItemTitle.vue";
import BaseListItemPhoneNumber from "../../../defaults/baseList/BaseListItemPhoneNumber.vue";

export default {
    name: "CallerIdSelect",
    components: {
        BaseListItemPhoneNumber,
        BaseListItemTitle,
        BaseListItem,
        BaseList,
        BaseDropdown,
        CallerIdSelectSearchInput,
        BaseSelect
    },
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        options: {
            type: Array,
            default() {
                return []
            }
        },
        offset: {
            type: Number,
            default: 11
        },
        searchPlaceholder: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            showDropdown: false,
            expandDropdown: false,
            searchModel: ''
        }
    },
    watch: {
        showDropdown(value) {
            if (!value) {
                this.searchModel = ""
            }
        }
    },
    computed: {
        ...mapGetters("user", ["callerIds"]),
        ...mapGetters("details", ["getContactByNumber"]),

        _options() {
            const search = this.searchModel.toLowerCase()

            return this.options.filter((option) => {
                return option.name.toLowerCase().includes(search) ||
                    option.number.includes(search) ||
                    sanitizePhoneNumber(option.number).includes(search)
            })
        }
    },
    methods: {
        onInput(value) {
            this.showDropdown = false
            this.$emit('input', value)
        },

        formatNumber
    }
}
</script>

<style scoped>

</style>