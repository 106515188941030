import {io, Socket} from "socket.io-client";

const MAX_RECONNECTION_ATTEMPTS = 10

/**
 * This class helps to works with socket.io-client
 */
class SocketIOHelper extends EventTarget {
    constructor() {
        super()

        this._socket = io({
            path: '/realtimeuserportal',
            autoConnect: false,
            transports: ['websocket', 'polling']
        })

        this._reconnectionAttempts = 0
        this._initialized = false
    }

    /**
     * @returns {Socket}
     */
    get socket() {
        return this._socket;
    }

    /**
     * @param {string} uri
     * @param {string} token
     * @param {boolean} isWallboard
     */
    connect(uri, token, isWallboard = false) {
        this._socket.io.uri = uri
        this._socket.io.opts.query = {
            'access_token': token,
            'is_wallboard': isWallboard
        }
        this._socket.connect()

        if (!this._initialized) {
            this._bindDefaultEventsHandlers()
            this._initialized = true
        }
    }

    /**
     * Disconnects the socket
     */
    disconnect() {
        this._socket.close()
        this._socket.io.off("reconnect_error")
    }

    /**
     * Emit event immediately if the socket is already connected,
     * otherwise the event will be emitted when the socket establishes a connection
     *
     * @param ev
     * @param payload
     */
    emit(ev, payload) {
        if (this._socket.connected) {
            console.log("this._socket.connected - true, emit", ev)
            this._socket.emit(ev, payload)
        } else {
            console.log("this._socket.connected - false, add listener", ev)
            this._socket.once("connect", () => {
                console.log("connect event, emit", ev)
                this._socket.emit(ev, payload)
            })
        }
    }

    /**
     * @private
     */
    _bindDefaultEventsHandlers() {
        console.log("_bindDefaultEventsHandlers")
        this._socket.on("connect", () => {
            console.log('realtime socket connected')

            if (this._reconnectionAttempts >= MAX_RECONNECTION_ATTEMPTS) {
                console.log("socket reconnect")
                this.dispatchEvent(new Event("reconnect"))
            }

            this._reconnectionAttempts = 0
        })

        this._socket.on("disconnect", (reason) => {
            console.log('realtime socket disconnect event caught, reason: ' + reason)
            if (["transport error", "ping timeout"].includes(reason)) {
                console.log("send socketConnectionDisconnect event")
                document.dispatchEvent(new CustomEvent('socketConnectionDisconnect'));
            }
        })

        this._socket.io.on("reconnect_error", (error) => {
            this._reconnectionAttempts += 1
            console.log("realtime socket reconnect error - " + error, "attempt - " + this._reconnectionAttempts)
            if (this._reconnectionAttempts === MAX_RECONNECTION_ATTEMPTS) {
                console.log("realtime socket max attempts, send socketConnectionDisconnect event")
                document.dispatchEvent(new CustomEvent('socketConnectionDisconnect'));
            }
        })
    }
}

export default new SocketIOHelper()