<template>
    <div class="base-dropdown">
        <div
            @mouseenter="openBy === OPEN_BY_HOVER && onMouseover()"
            @mouseleave="openBy === OPEN_BY_HOVER && onMouseleave()"
            @touchend="openBy === OPEN_BY_HOVER && onMouseleave()"
            @click="openBy === OPEN_BY_CLICK && onActivatorClick()"
            class="base-dropdown-activator-container"
            ref="activator"
        >
            <slot name="activator"></slot>
        </div>

        <MountingPortal
            v-if="opened && !disabled"
            mountTo="body"
            append
        >
            <div
                v-click-outside="onClickContentOutside"
                @click="onClickContent"
                @mouseenter="openBy === OPEN_BY_HOVER && onMouseoverContent()"
                @mouseleave="openBy === OPEN_BY_HOVER && onMouseleaveContent()"
                @touchend="openBy === OPEN_BY_HOVER && onMouseleaveContent()"
                :class="[contentClass, contentVisible ? 'base-dropdown-content-visible' : '']"
                :style="styles"
                ref="content"
                class="base-dropdown-content"
            >
                <slot name="no-scrollable-content" :close="closeDropdown" :open="openDropdown"></slot>

                <div
                    v-bar
                    :style="{'maxHeight': _maxHeight}"
                    class="base-dropdown-scroll-wrapper"
                >
                    <div class="scroll">
                        <slot :close="closeDropdown" :open="openDropdown"></slot>
                    </div>
                </div>

                <slot name="no-scrollable-content-after" :close="closeDropdown" :open="openDropdown"></slot>
            </div>
        </MountingPortal>
    </div>
</template>

<script>
import breakpoints from "../../plugins/breakpoints";

const DEFAULT_OFFSET = 11
const MOBILE_MENU_HEIGHT = 54

const POSITION_TOP = 'top'
const POSITION_BOTTOM = 'bottom'

const OPEN_BY_HOVER = 'hover'
const OPEN_BY_CLICK = 'click'
const OPEN_BY_MANUAL = 'manual'

const CENTER_ALIGN = "center"
const LEFT_ALIGN = "left"
const RIGHT_ALIGN = "right"

export default {
    name: "BaseDropdown",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: null
        },
        maxHeight: {
            type: [Number, String],
            default: null,
        },
        position: {
            type: String,
            default: POSITION_TOP
        },
        disabled: {
            type: Boolean,
            default: false
        },
        openBy: {
            type: String,
            default: OPEN_BY_CLICK
        },
        contentClass: {
            type: String,
            default: '',
        },
        align: {
            type: String,
            default: CENTER_ALIGN
        },
        offsetX: {
            type: Number,
            default: 0
        },
        offsetY: {
            type: Number,
            default: DEFAULT_OFFSET
        },
        closeOnContentClick: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            opened: this.value,
            isActivatorHover: false,
            isContentHover: false,
            contentVisible: false,
            debounceTimeout: null,
            contentResizeObserver: null,
            contentRect: {
                width: 0,
                height: 0
            },
            breakpoints,
            OPEN_BY_HOVER,
            OPEN_BY_CLICK
        }
    },
    mounted() {
        this.contentResizeObserver = new ResizeObserver(() => {
            if (this.opened && this.$refs.content) {
                const rect = this.$refs.content.getBoundingClientRect()

                this.contentRect.width = rect.width
                this.contentRect.height = rect.height
            }
        })
    },
    watch: {
        value(v) {
            if (v) {
                this.openDropdown()
                return
            }

            this.closeDropdown()
        },
        isHover() {
            if (this.openBy !== OPEN_BY_HOVER) {
                return
            }

            if (!this.debounceTimeout) {
                this.debounceTimeout = setTimeout(() => {
                    this.opened = this.isHover
                    this.debounceTimeout = null
                }, 200)
            }
        },
        opened(value) {
            this.$emit("input", value)

            if (!value) {
                this.contentVisible = false
                this.isContentHover = false
                return
            }

            if (!this.contentVisible) {
                window.requestAnimationFrame(() => {
                    if (!this.$refs.content) {
                        return
                    }

                    this.contentResizeObserver.observe(this.$refs.content)
                    this.contentVisible = true
                })
            }
        }
    },
    computed: {
        styles() {
            let result = {}

            if (!this.contentVisible) {
                return result
            }

            const activatorRect = this.activator.getBoundingClientRect()
            result.width = `${this.width ? this.width : activatorRect.width}px`
            result.left = `${this.getLeftOffset()}px`
            result.top = `${this.getTopOffset()}px`

            return result
        },
        _maxHeight() {
            return (typeof this.maxHeight === "number") ? `${this.maxHeight}px` : this.maxHeight
        },
        isHover() {
            return this.openBy === OPEN_BY_HOVER && (this.isActivatorHover || this.isContentHover)
        },
        activator() {
            return this.$refs.activator.children[0]
        }
    },
    methods: {
        onActivatorClick() {
            if (this.openBy !== OPEN_BY_CLICK) {
                return
            }

            if (this.opened) {
                this.closeDropdown()
                return
            }

            this.openDropdown()
        },
        onClickContentOutside(event) {
            if ((this.openBy === OPEN_BY_CLICK || this.openBy === OPEN_BY_MANUAL) && this.opened && !this.activator.contains(event.target)) {
                this.closeDropdown()
            }
        },
        onClickContent() {
            if (this.closeOnContentClick) {
                this.closeDropdown()
            }
        },
        closeDropdown() {
            this.opened = false
        },
        openDropdown() {
            if (this.opened) {
                return
            }

            window.requestAnimationFrame(() => {
                this.opened = true
            })
        },
        onMouseover() {
            this.isActivatorHover = true
        },
        onMouseleave() {
            this.isActivatorHover = false
        },
        onMouseoverContent() {
            this.isContentHover = true
        },
        onMouseleaveContent() {
            this.isContentHover = false
        },
        getLeftOffset() {
            if (!this.opened) {
                return
            }

            const activatorRect = this.activator.getBoundingClientRect()
            const rightBorder = breakpoints.width - this.width - DEFAULT_OFFSET
            const width = this.width ? this.width : activatorRect.width

            let result
            if (this.align === LEFT_ALIGN) {
                result = activatorRect.left + this.offsetX
            } else if (this.align === RIGHT_ALIGN) {
                result = activatorRect.right - this.width + this.offsetX
            } else {
                result = activatorRect.left + (activatorRect.width / 2) - (width / 2)
            }

            if (result > rightBorder) {
                result = rightBorder
            }

            if (result < DEFAULT_OFFSET) {
                result = DEFAULT_OFFSET
            }

            return result
        },
        getTopOffset() {
            if (!this.opened) {
                return
            }

            const activatorRect = this.activator.getBoundingClientRect()
            let topBorder = breakpoints.height - this.contentRect.height - DEFAULT_OFFSET
            if (breakpoints.isMobile) {
                topBorder -= MOBILE_MENU_HEIGHT
            }

            let result = 0

            if (this.position === POSITION_TOP) {
                result = activatorRect.top + window.scrollY - this.contentRect.height - this.offsetY
            }

            if (this.position === POSITION_BOTTOM) {
                result = activatorRect.bottom + this.offsetY
            }

            if (result > topBorder) {
                result = topBorder
            }

            if (result < DEFAULT_OFFSET) {
                result = DEFAULT_OFFSET
            }

            return result
        }
    }
}
</script>

<style scoped>

</style>