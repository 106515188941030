<template>
    <div class="integrations-activate-block">
        <div v-if="success" class="integrations-activate-message">
            <h1>{{ $t("integrations-activate.activation-success-title") }}</h1>
            <i18n path="integrations-activate.crm-active" tag="p">
                <span>{{ crmName }}</span>
            </i18n>
            <i18n path="integrations-activate.redirect.info" tag="p">
                <a @click="redirectToHome" >{{ $t("integrations-activate.redirect.click-link") }}</a>
            </i18n>
        </div>

        <div v-if="!success" class="integrations-activate-message">
            <h1>{{ $t("integrations-activate.activation-failed-title") }}</h1>
            <p>{{ $t("integrations-activate.failed-message") }}</p>
        </div>
    </div>
</template>

<script>
import Crm from "../../helpers/integrations/crms";

export default {
    name: "integrationsSuccessActivate",
    props: ['success'],
    data() {
        return {
            crmName: ''
        }
    },
    created() {
        this.$nextTick(() => {
            let type = this.$route.query.type
            if (type) {
                const crm = new Crm().create(type)
                this.crmName = crm.title
            }
        })

        setTimeout(() => {
            this.redirectToHome()
        }, 10000)
    },
    methods: {
        redirectToHome() {
            if (window.opener) {
                window.opener.location.reload()
                window.top.close()
            } else {
                // redirect with reload
                window.location.assign(`${window.location.origin}${window.location.pathname}`)
            }
        }
    }
}
</script>

<style scoped>

</style>