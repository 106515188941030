<template>
    <div
        id="softphone"
        class="softphone"
        :class="{
        'in-call': isInCall,
        'incoming': isIncoming,
        'outgoing': isOutgoing || isOutgoingIncoming,
        'multiple-outgoing': isMultipleOutgoing,
        'incoming-second': isIncomingSecond,
        'mobile-integrations-popup': integrationPopup && breakpoints.isMobile
    }">
        <softphone-state-outgoing v-if="isOutgoing"></softphone-state-outgoing>
        <softphone-state-outgoing-multiple v-if="isMultipleOutgoing"></softphone-state-outgoing-multiple>
        <softphone-state-active v-if="isActiveCall"></softphone-state-active>
        <softphone-state-incoming
            v-if="isIncoming"
            :class="{'mobile-incoming-integrations-popup': integrationPopup && breakpoints.isMobile}"
        ></softphone-state-incoming>
        <softphone-state-active-incoming
            v-if="isIncomingSecond"
            :class="{'mobile-incoming-integrations-popup': integrationPopup && breakpoints.isMobile}"
        ></softphone-state-active-incoming>
        <softphone-state-active-attended-transfer v-if="isAttendedTransfer"></softphone-state-active-attended-transfer>
        <softphone-state-active-add-call v-if="isActiveAddCall"></softphone-state-active-add-call>
        <softphone-state-active-conference v-if="isConference"></softphone-state-active-conference>
        <softphone-state-outgoing-incoming v-if="isOutgoingIncoming"></softphone-state-outgoing-incoming>
        <softphone-state-incoming-incoming v-if="isIncomingIncoming"></softphone-state-incoming-incoming>

        <internal-number-label
            v-if="isInCall && !(isIncoming || isIncomingSecond || isIncomingIncoming)"
        ></internal-number-label>

        <div v-if="isInCall" class="integrations-wrapper">
            <button v-if="canShowIntegrations" :class="{'active': integrationPopup}" class="integrations-button" @click="toggleIntegrationPopup">
                <span class="icon xl icon-join-right-black"></span>
                <span class="icon sm icon-join-full-black"></span>
                <span class="label xl">{{ $t("softphone.integrations-xl") }}</span>
                <span class="label sm">{{ $t("softphone.integrations-sm") }}</span>
                <span class="icon arrow icon-chevron-right"></span>
            </button>
            <div class="integrations-dropdown"></div>
        </div>
        <integrations-popup
            v-if="integrationPopup"
            @close="closeIntegrationsPopup"
            :width="integrationsPopupWidth"
            :position="breakpoints.isMobile ? 'bottom' : 'left'"
            :mount-to="breakpoints.isMobile ? '#softphone' : '#panel'"
            :blackout="!breakpoints.isMobile"
            :numbers="integrationPopupNumbers"
            :call-start-timestamps="integrationPopupCallStartTimestamps"
            keep-crm-info
        ></integrations-popup>

        <div v-show="blockSoftphone" class="block-softphone-wrapper">
            <div class="blur"></div>
            <div class="block-softphone" :class="blockSoftphoneMessage.color">
                <div class="title">
                    <span class="icon icon-report"></span>
                    <p>{{ blockSoftphoneMessage.title }}</p>
                </div>
                <p v-html="blockSoftphoneMessage.text"></p>
            </div>
        </div>

        <SoftphoneDialPad
            v-show='!isInCall && !settingsIsLoading'
            v-model="dialNumber"
            @call="onCall"
        ></SoftphoneDialPad>
        <skeleton-softphone-dial-pad v-show="settingsIsLoading"></skeleton-softphone-dial-pad>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    import SoftphoneDialPad from "./SoftphoneDialPad.vue";
    import SoftphoneStateActive from "./softphoneStates/active/SoftphoneStateActive.vue";
    import SoftphoneStateOutgoing from "./softphoneStates/outgoing/SoftphoneStateOutgoing.vue";
    import SoftphoneStateIncoming from "./softphoneStates/incoming/SoftphoneStateIncoming.vue";
    import SoftphoneStateActiveAttendedTransfer from "./softphoneStates/active/SoftphoneStateActiveAttendedTransfer.vue";
    import SoftphoneStateActiveAddCall from "./softphoneStates/active/SoftphoneStateActiveAddCall.vue";
    import SkeletonSoftphoneDialPad from "../skeleton/SkeletonSoftphoneDialPad.vue";
    import IntegrationsPopup from "../integrations/IntegrationsPopup.vue";
    import SoftphoneStateActiveConference from "./softphoneStates/active/SoftphoneStateActiveConference.vue";
    import SoftphoneStateOutgoingMultiple from "./softphoneStates/outgoing/SoftphoneStateOutgoingMultiple.vue";
    import SoftphoneStateActiveIncoming from "./softphoneStates/active/SoftphoneStateActiveIncoming.vue";
    import SoftphoneStateOutgoingIncoming from "./softphoneStates/outgoing/SoftphoneStateOutgoingIncoming.vue";
    import SoftphoneStateIncomingIncoming from "./softphoneStates/incoming/SoftphoneStateIncomingIncoming.vue";
    import {isElectronExist} from "../../utils/electron";
    import {existEnabledCrms, onlyZapierEnabled} from "../../utils/integrations";
    import {getPhoneNumberDigitsOnly, isExtension} from "../../utils/phoneNumbers";
    import InternalNumberLabel from "./base/InternalNumberLabel.vue";
    import breakpoints from "../../plugins/breakpoints";

    export default {
        name: "Softphone",
        props: {
            settingsIsLoading: {
                type: Boolean,
                default: true
            },
            softphoneSettings: {
                type: Object,
                default: {}
            }
        },
        components: {
            InternalNumberLabel,
            SoftphoneStateIncomingIncoming,
            SoftphoneStateOutgoingIncoming,
            SoftphoneStateActiveIncoming,
            SoftphoneStateOutgoingMultiple,
            SoftphoneStateActiveConference,
            IntegrationsPopup,
            SkeletonSoftphoneDialPad,
            SoftphoneStateActiveAddCall,
            SoftphoneStateActiveAttendedTransfer,
            SoftphoneStateIncoming,
            SoftphoneStateOutgoing,
            SoftphoneStateActive,
            SoftphoneDialPad
        },
        data() {
            return {
                showDialPad: true,
                addToCallScreen: false,
                miniContact: false,
                transferCallScreen: false,
                selectedCallerId: '',
                integrationPopup: false,
                blockSoftphone: false,
                blockSoftphoneMessage: {title: '', text: '', color: ''},
                dialNumber: '',
                breakpoints
            }
        },
        computed: {
            ...mapGetters('softphone', [
                'isInCall',
                'isActiveCall',
                'isOutgoing',
                'isIncoming',
                'isIncomingSecond',
                'isActiveAddCall',
                'isAttendedTransfer',
                'isMultipleOutgoing',
                'isConference',
                'getSessions',
                'getSecondSession',
                'getElectronDialNumber',
                'isOutgoingIncoming',
                'isIncomingIncoming',
                'isCallEstablished'
            ]),

            ...mapGetters('user', ['user', 'isIntegrationsEnabled', 'crmTenantName', 'email', 'calleridId']),
            ...mapGetters([
              'activeTab'
            ]),

            ...mapGetters('integrations', [
                'integrations',
                'callInfo'
            ]),

            integrationPopupNumbers() {
                return this.getSessions.map((session) => session.number)
            },
            integrationPopupCallStartTimestamps() {
                const result = {}
                this.getSessions.forEach((session) => {
                    result[session.number] = session.startTimestamp
                })

                return result
            },
            canShowIntegrations() {
                const isExistNonInternalNumbers = this.getSessions.find((session) => !isExtension(session.number))

                return (this.isInCall && this.isIntegrationsEnabled && this.integrations.length && isExistNonInternalNumbers) || (!this.integrations.length && this.integrationPopup)
            },
            integrationsPopupWidth() {
                if (breakpoints.width <= 420) {
                    return 380
                }

                if (breakpoints.isLaptop) {
                    return 406
                }

                return 506
            }
        },
        watch: {
            blockSoftphoneMessage() {
                this.blockSoftphone = true
            },

            getElectronDialNumber(number) {
                if (number && number.length) {
                    this.dialNumber = number
                    this.updateElectronDialNumber("")
                }
            },

            getSessions() {
                if (this.getSessions.length === 0) {
                    this.closeIntegrationsPopup()
                    return
                }

                let number = ""
                if (this.getSecondSession) {
                    number = this.getSecondSession.number
                }

                if (
                    this.isInCall &&
                    !this.integrationPopup &&
                    this.isIntegrationsEnabled &&
                    this.integrations.length &&
                    existEnabledCrms() &&
                    getPhoneNumberDigitsOnly(number).length &&
                    !isExtension(number) &&
                    !this.callInfo(number).length
                ) {
                    if (onlyZapierEnabled()) {
                        this.getZapierContactInfo(number)
                            .then((response) => {
                                if (response && Object.keys(response).length && this.isInCall && !breakpoints.isMobile) {
                                    this.showIntegrationPopup()
                                }
                            }).catch(() => {})
                        return
                    }

                    this.getCallInfo({
                        tenant: this.crmTenantName,
                        email: this.email,
                        number: number
                    }).then(() => {
                        let crmInfoExist = false
                        for (let item of this.callInfo(number)) {
                            crmInfoExist = Boolean(item.contacts.length)

                            if (crmInfoExist && !breakpoints.isMobile) {
                                this.showIntegrationPopup()
                            }

                            if (crmInfoExist) {
                                break
                            }
                        }

                        if (!crmInfoExist) {
                            return this.getZapierContactInfo(number)
                        }
                    }).then((response) => {
                        if (response && Object.keys(response).length && this.isInCall && !breakpoints.isMobile) {
                            this.showIntegrationPopup()
                        }
                    }).catch(() => {})
                }
            },

            settingsIsLoading() {
                if (this.settingsIsLoading) {
                    return
                }

                if (this.softphoneSettings.credentials) {
                    this.setSettings(this.softphoneSettings)
                    this.initPhone()
                } else {
                    this.blockSoftphoneMessage = {
                        title: this.$t("alert-messages.devices-not-configured.title"),
                        text: this.$t("alert-messages.devices-not-configured.message"),
                        color: 'orange'
                    }
                }
            },

            "breakpoints.isMobile": function() {
                if (this.integrationPopup) {
                    this.closeIntegrationsPopup()
                }
            }
        },
        created() {
            if (isElectronExist() && this.getElectronDialNumber) {
                this.dialNumber = this.getElectronDialNumber
                this.updateElectronDialNumber("")
            }
        },

        mounted() {
            document.addEventListener('keyup', this.DTMFHandler)
        },
        destroyed() {
            document.removeEventListener('keyup', this.DTMFHandler)
        },
        methods: {
            ...mapActions('softphone', ['doCall', 'initPhone', 'setSettings', 'toggleNotification', 'sendDTMF', 'updateElectronDialNumber']),

            ...mapActions('user', ['changeCallerId']),

            ...mapActions(['createAlertMessage']),

            ...mapActions('integrations', ['getCallInfo', 'resetCallInfo', 'getZapierContactInfo']),

            toggleIntegrationPopup() {
                if (this.integrationPopup) {
                    this.closeIntegrationsPopup()
                    return
                }

                this.showIntegrationPopup()
            },

            showIntegrationPopup() {
                if (!this.isInCall) {
                    return;
                }

                this.integrationPopup = true
            },

            closeIntegrationsPopup() {
                this.integrationPopup = false;
            },

            DTMFHandler(event) {
                if (this.isInCall && this.isCallEstablished) {
                    const activeElement = document.activeElement
                    if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
                        return
                    }
                    this.sendDTMF(event.key)
                }
            },

            onCall(number) {
                this.doCall({
                    number: number,
                    silent: false,
                    calleridId: this.calleridId
                })

                this.dialNumber = ""
            }
        }
    }
</script>

<style scoped>

</style>