import {mapGetters} from "vuex";

const appsDropdownOptionsMixin = {
    computed: {
        ...mapGetters(["apps", "isMobileLinksAvailable"]),

        desktopAppsDropdownOptions() {
            return this.apps.reduce((acc, app) => {
                if (app.type === "android" || app.type === "ios") {
                    return acc
                }

                acc.push({
                    name: this.$t(`top-bar.menu.apps.${app.type}.link`),
                    icon: `icon-${app.icon}`,
                    type: app.type,
                    filepath: app.filepath
                })

                return acc
            }, [])
        },

        mobileAppsDropdownOptions() {
            if (!this.isMobileLinksAvailable) {
                return []
            }

            return this.apps.reduce((acc, app) => {
                if (app.type === "android" || app.type === "ios") {
                    acc.push({
                        name: this.$t(`top-bar.menu.apps.${app.type}.link`),
                        icon: `icon-${app.icon}`,
                        type: app.type,
                        filepath: app.filepath
                    })
                }

                return acc
            }, [])
        }
    }
}

export default appsDropdownOptionsMixin