<template>
    <div
        @click="onClick"
        :class="classes"
        class="base-list-item"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "BaseListItem",
    props: {
        selected: {
            type: Boolean,
            default: false
        },
        hover: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classes() {
            return {
                'base-list-item-selected': this.selected,
                'base-list-item-hover': this.hover
            }
        }
    },
    methods: {
        onClick() {
            this.$emit("click")
        }
    }
}
</script>

<style scoped>

</style>