import {formatNumber} from "../utils/phoneNumbers";
import {mapGetters} from "vuex";

const calleridsOptionsMixin = {
    computed: {
        ...mapGetters("user", ["callerIds"]),
        ...mapGetters("details", ["getContactByNumber"]),

        anonymousItem() {
            return {id: null, number: '', name: this.$t("softphone.caller-id-anonymous")}
        },

        calleridsOptions() {
            let options = this.callerIds.map((option) => {
                const contact = this.getContactByNumber(option.num)

                return {id: option.id, number: formatNumber(option.num), name: contact.id ? contact.name : option.name}
            })
            options.unshift(this.anonymousItem)

            return options
        }
    }
}

export default calleridsOptionsMixin