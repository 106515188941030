import Api from '../../utils/api';

export default {
    namespaced: true,
    state: {
        queues: []
    },
    getters: {
        queues: (state) => {
            return state.queues;
        }
    },
    actions: {
        getQueues: (context) => {
            return new Promise((resolve, reject) => {
                Api().get('/get_queues')
                    .then(response => {
                        context.commit('setQueues', response.data);
                        resolve(response)
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        saveQueueMembership: (context, queues) => {
            return new Promise((resolve, reject) => {
                Api().post('/save_queue_membership', {
                    queues: queues.map((queue) => {return {id: queue.id, status: queue.status, type: queue.type}})
                })
                    .then(response => {
                        queues.map((q) => context.commit('updateQueueStatus', {queueId: q.id, queueStatus: q.status}))
                        resolve(response);
                    })
                    .catch(error => {
                        queues.map((q) => context.commit('updateQueueStatus', {queueId: q.id, queueStatus: !q.status}))
                        reject()
                    })
            })

        }
    },
    mutations: {
        setQueues: (state, queues) => {
            state.queues = queues;
        },
        updateQueueStatus: (state, {queueId, queueStatus}) => {
            const queue = state.queues.find((q) => q.id === queueId)
            if (queue) {
                queue.status = queueStatus;
            }
        }
    }
}