<template>
    <div class="softphone-state state-active" v-if="getPrimarySession">
        <div v-show="personCollapsed" class="person-collapsed-wrapper">
            <person-collapsed
                :session="getPrimarySession"
            ></person-collapsed>
        </div>

        <div v-show="!personCollapsed" class="call-info-wrapper">
            <div class="call-status">
                {{getPrimarySession.time}}
            </div>
            <person
                :session="getPrimarySession"
            ></person>
        </div>

        <buttons-group-manage-call
            v-show="!personCollapsed"
            :mute-btn="{show: true, active: getIsMuted}"
            :pause-btn="{show: true, active: getPrimarySession.isOnHold}"
            :record-btn="{show: true, disabled: (!getIsCallRecordingAllowed || getPrimarySession.isOnHold), active: getCallIsRecording, tooltip: true}"
            @pressMute="mute"
            @pressPause="hold"
            @pressKeypad="toggleKeypad"
            @pressTransfer="toggleTransferKeypad"
            @pressAddCall="toggleAddCallKeypad"
            @pressRecord="record"
        ></buttons-group-manage-call>

        <div v-if="showKeypad" class="active-call-keypad-wrapper">
            <dtmf-dial-pad v-model="dtmfModel" @typedSymbol="sendDTMF"></dtmf-dial-pad>
        </div>
        <div v-if="showTransferKeypad" class="active-call-keypad-wrapper transfer-keypad">
            <active-call-keypad
                v-model="transferNumberModel"
                @update:current-tab-name="onUpdateTransferCurrentTabName"
                :current-tab-name="transferCurrentTabName"
                :tabs="transferTabs"
            ></active-call-keypad>
        </div>
        <div v-if="showAddCallKeypad" class="active-call-keypad-wrapper add-call-keypad">
            <active-call-keypad v-model="addCallNumberModel" :tabs="addCallTabs"></active-call-keypad>
        </div>

        <div v-show="activeCallButtons" class="active-call-buttons-wrapper">
            <call-button v-show="showKeypad" @press="toggleKeypad" buttonClass="hide-keypad-button" iconClass="icon-arrow-left" :label="$t('softphone.go-back-button')"></call-button>
            <call-button @press="hangUp(getPrimarySession.id)" buttonClass="hangup-call-button" iconClass="icon-call-end"></call-button>
        </div>
        <div v-show="showTransferKeypad" class="transfer-keypad-buttons-wrapper">
            <buttons-group-transfer-keypad
                @pressCallFirst="callFirstHandler"
                @pressTransferNow="transferNowHandler"
                @pressCancelTransfer="toggleTransferKeypad"
                :call-first-disabled="!transferNumberModel || isCurrentNumSameAsTransferNum"
                :transfer-now-disabled="!transferNumberModel || isCurrentNumSameAsTransferNum"
                :park-call="transferCurrentTabName === TABS.CALL_PARKING_TAB.name"
                :call-first-show="transferCurrentTabName !== TABS.CALL_PARKING_TAB.name"
            ></buttons-group-transfer-keypad>
        </div>
        <div v-show="showAddCallKeypad" class="add-call-keypad-buttons-wrapper">
            <buttons-group-add-keypad
                @pressAddCall="addToCallHandler"
                @pressCancelAddCall="toggleAddCallKeypad"
                :add-call-disabled="!addCallNumberModel || isCurrentNumSameAsAddToCallNum"
            ></buttons-group-add-keypad>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "../../../defaults/Avatar.vue";
import ButtonsGroupManageCall from "../../buttonsGroup/ButtonsGroupManageCall.vue";
import PersonCollapsed from "../../base/PersonCollapsed.vue";
import ActiveCallKeypad from "../../base/ActiveCallKeypad/ActiveCallKeypad.vue";
import ButtonsGroupTransferKeypad from "../../buttonsGroup/ButtonsGroupTransferKeypad.vue";
import ButtonsGroupAddKeypad from "../../buttonsGroup/ButtonsGroupAddKeypad.vue";
import CallButton from "../../base/CallButton.vue";
import {formatNumber, numbersAreMatched} from "../../../../utils/phoneNumbers";
import {TABS} from "../../base/ActiveCallKeypad/activeCallKeypadTabs";
import DtmfDialPad from "../../base/DtmfDialPad.vue";
import {copyNumber, showTooltip} from "../../../../utils/copy";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import Person from "../../base/Person.vue";

export default {
    name: "SoftphoneStateActive",
    components: {
        Person,
        DtmfDialPad,
        CallButton,
        ButtonsGroupAddKeypad,
        ButtonsGroupTransferKeypad,
        ActiveCallKeypad,
        PersonCollapsed,
        Avatar,
        ButtonsGroupManageCall,
        BaseTooltip
    },
    data () {
        return {
            personCollapsed: false,
            showKeypad: false,
            showTransferKeypad: false,
            showAddCallKeypad: false,
            activeCallButtons: true,
            dtmfModel: '',
            transferNumberModel: '',
            addCallNumberModel: '',
            transferCurrentTabName: TABS.KEYPAD_TAB.name,
            copyButton: {
                'isCopied': false
            },
            TABS
        }
    },
    mounted() {
        document.addEventListener('keyup', this.updateDTMFModel)
        document.addEventListener('speedDial', this.speedDialHandler)
    },
    destroyed() {
        document.removeEventListener('keyup', this.updateDTMFModel)
        document.removeEventListener('speedDial', this.speedDialHandler)
    },
    computed: {
        ...mapGetters('softphone', [
            'getIsMuted',
            'getPrimarySession',
            'isInCall',
            'getCallIsRecording',
            'getIsCallRecordingAllowed'
        ]),

        addCallTabs() {
            return [TABS.KEYPAD_TAB, TABS.CONTACTS_TAB, TABS.HISTORY_TAB]
        },

        transferTabs() {
            return [TABS.KEYPAD_TAB, TABS.CONTACTS_TAB, TABS.HISTORY_TAB, TABS.CALL_PARKING_TAB]
        },

        isCurrentNumSameAsTransferNum() {
            return numbersAreMatched(this.getPrimarySession.number, this.transferNumberModel)
        },

        isCurrentNumSameAsAddToCallNum() {
            return numbersAreMatched(this.getPrimarySession.number, this.addCallNumberModel)
        }
    },
    methods: {
        ...mapActions('softphone', [
            'sendDTMF',
            'mute',
            'hold',
            'hangUp',
            'blindTransferCall',
            'prepareAttendedTransferCall',
            'addToCall',
            'record'
        ]),

        toggleKeypad() {
            this.personCollapsed = !this.personCollapsed;
            this.showKeypad = !this.showKeypad;
        },

        toggleTransferKeypad() {
            this.personCollapsed = !this.personCollapsed;
            this.showTransferKeypad = !this.showTransferKeypad;
            this.activeCallButtons = !this.activeCallButtons;
        },

        toggleAddCallKeypad() {
            this.personCollapsed = !this.personCollapsed;
            this.showAddCallKeypad = !this.showAddCallKeypad;
            this.activeCallButtons = !this.activeCallButtons;
        },

        transferNowHandler() {
            this.blindTransferCall(this.transferNumberModel);
            this.toggleTransferKeypad();
        },

        callFirstHandler() {
            this.prepareAttendedTransferCall(this.transferNumberModel);
            this.toggleTransferKeypad();
        },

        addToCallHandler() {
            this.addToCall(this.addCallNumberModel);
            this.toggleAddCallKeypad();
        },

        speedDialHandler(event) {
            const number = event.detail.number
            if (this.showTransferKeypad) {
                this.transferNumberModel = number
            }

            if (this.showAddCallKeypad) {
                this.addCallNumberModel = number
            }
        },

        updateDTMFModel(event) {
            const activeElement = document.activeElement
            if (this.isInCall) {
                if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
                    return
                }
                this.dtmfModel += event.key
            }
        },

        onUpdateTransferCurrentTabName(tabName) {
            this.transferCurrentTabName = tabName
        },

        formatNumber,
        copyNumber,
        showTooltip
    }
}
</script>

<style scoped>

</style>