<template>
    <div class="tab phonebooks-tab" :class="{'active': this.active}">
       <div class="phonebooks-toolbar form-group">
           <base-select
               v-if="!breakpoints.isTablet"
               v-model="selectedPhonebookId"
               :options="phonebooksOptions"
               :dropdown-max-height="340"
               class="select-phonebook"
           ></base-select>

           <base-dropdown
               v-if="breakpoints.isTablet"
               :width="220"
               :max-height="340"
               position="bottom"
               align="left"
           >
               <template v-slot:activator>
                   <base-button
                       icon="icon-stories"
                       size="big"
                       class="select-phonebook"
                   ></base-button>
               </template>

               <base-list>
                   <base-list-item
                       v-for="(option, idx) in phonebooksOptions"
                       @click="selectedPhonebookId = option.value"
                       :selected="selectedPhonebookId === option.value"
                       :key="idx"
                   >
                       <base-list-item-title>{{ option.name }}</base-list-item-title>
                   </base-list-item>
               </base-list>
           </base-dropdown>

            <base-tooltip text="panel.phonebooks.select-phonebook-tooltip" :width="250" position="bottom"></base-tooltip>

           <base-input
               v-model="search"
               :style="{'margin-right': search ? '12px' : 'auto'}"
               class="find-contact"
               placeholder="panel.phonebooks.find-contact-placeholder"
               icon="icon-search"
               type="text"
           ></base-input>

           <chip
               v-if="search"
               :text="search"
               size="big"
               color="light-blue"
               class="search-chip"
           >
               <template v-slot:append>
                   <base-button
                       @click="clearSearch"
                       :icon-size="14"
                       size="small"
                       color="white"
                       icon="icon-clear"
                       radius="50%"
                       class="chip-button-close"
                   ></base-button>
               </template>
           </chip>

            <base-button
                @click="addContact"
                :icon="'icon-person-add2'"
                :text="breakpoints.isTablet ? '' : 'panel.phonebooks.add-contact-button'"
                :color="'blue'"
                :size="'big'"
                class="add-contact-button"
            ></base-button>
        </div>
        <div v-if="phonebookContacts.length && !loading" class="contacts-table">
            <v-data-table
                :headers="[
                    { text: $t('panel.phonebooks.header-name'), align: 'name', value: 'name', width: '45%'},
                    { text: $t('panel.phonebooks.header-phone-number'), align: 'number', value: 'number', width: '40%'},
                    { text: '', align: 'right', value: 'controls', sortable: false, width: '15%' }
                ]"
                :items="phonebookContacts"
                :search="search"
                :hide-default-footer="true"
                :mobile-breakpoint="0"
                :expanded.sync="expanded"
                :custom-filter="customSearch"
                :header-props="{'sort-icon': 'icon icon-arrow-up'}"
                disable-pagination>
              <template v-slot:body="{ items, expand, isExpanded }">
                  <div v-if="!items.length && search" class="no-results">
                      {{ $t("panel.call-history.no-results-found") }}
                  </div>
                <div class="phonebooks-table-wrapper" v-bar>
                  <div>
                    <tbody>
                        <phonebooks-table-row
                            v-for="item in items"
                            @expand="expand(item, !isExpanded(item))"
                            :is-expanded="isExpanded(item)"
                            :item="item"
                            :is-all-phonebooks="isAllPhonebooks"
                            :key="item.id"
                        ></phonebooks-table-row>
                    </tbody>
                  </div>
                </div>
              </template>
            </v-data-table>
        </div>
        <div v-else-if="loading">
            <base-spinner center></base-spinner>
        </div>
        <div v-else class="contacts-not-found">{{ $t("panel.phonebooks.no-contacts-message") }}</div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Avatar from "../../defaults/Avatar.vue";
import IntegrationsInline from "../../integrations/IntegrationsInline.vue";
import {findInContact} from "../../../utils/phonebooks";
import BaseSelect from "../../defaults/BaseSelect.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import BaseInput from "../../defaults/BaseInput.vue";
import {showAddContactPopup} from "../../../utils/phonebooks";
import BaseSpinner from "../../defaults/BaseSpinner.vue";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import PhonebooksTableRow from "./PhonebooksTableRow.vue";
import Chip from "../../defaults/Chip.vue";
import phonebooksSelectOptionsMixin from "../../../mixins/phonebooks/phonebooksSelectOptionsMixin";
import BaseDropdown from "../../defaults/BaseDropdown.vue";
import BaseList from "../../defaults/baseList/BaseList.vue";
import BaseListItem from "../../defaults/baseList/BaseListItem.vue";
import BaseListItemTitle from "../../defaults/baseList/BaseListItemTitle.vue";
import breakpoints from "../../../plugins/breakpoints";

export default {
    name: "Phonebooks",
    components: {
        BaseListItemTitle,
        BaseListItem,
        BaseList,
        BaseDropdown,
        Chip,
        PhonebooksTableRow,
        BaseTooltip, BaseSpinner, BaseInput, BaseButton, BaseSelect, IntegrationsInline, Avatar},
    props: {
      active: Boolean,
      loading: Boolean
    },
    mixins: [phonebooksSelectOptionsMixin],
    data() {
        return {
            search: '',
            selectedPhonebookId: null,
            phonebookContacts: [],
            expanded: [],
            breakpoints
        }
    },
    computed: {
        ...mapGetters('details', [
            'phonebooks',
            'contacts',
            'defaultContact',
            'getDefaultPhonebook',
            'getContactByNumber',
            'extensions',
            'getIsReadOnlyPhonebook'
        ]),

        ...mapGetters('softphone', [
            'isInCall'
        ]),

        ...mapGetters('user', [
            'isIntegrationsEnabled',
            'defaultPhonebookViewId'
        ]),

        ...mapGetters('integrations', [
            'integrations'
        ]),

        isAllPhonebooks() {
            return this.selectedPhonebookId === "all"
        },

        selectedPhonebookIsReadOnly() {
            if (this.isAllPhonebooks) {
                return false
            }
            return this.getIsReadOnlyPhonebook(this.selectedPhonebookId)
        }
    },
    watch: {
        phonebooks() {
            this.selectedPhonebookId = this.defaultPhonebookViewId ? this.defaultPhonebookViewId : this.phonebooks[0].id
        },
        contacts() {
            if (this.isAllPhonebooks) {
                this.phonebookContacts = this.contacts
            } else {
                this.phonebookContacts = this.contacts.filter(contact => contact.phonebook_id === this.selectedPhonebookId)
            }
        },
        selectedPhonebookId(value) {
            if (this.isAllPhonebooks) {
                this.phonebookContacts = this.contacts
            } else {
                this.phonebookContacts = this.contacts.filter(contact => contact.phonebook_id === value)
            }
        }
    },
    methods: {
        ...mapActions([
            'changeActiveTab'
        ]),
        ...mapActions('details', [
            'loadPhonebooks',
            'removeContact'
        ]),
        addContact () {
            let phonebookId

            if (this.selectedPhonebookId === 'all' || this.selectedPhonebookIsReadOnly) {
                phonebookId = null
            } else {
                phonebookId = this.selectedPhonebookId
            }

            showAddContactPopup("", phonebookId)
        },

        customSearch(value, search, item) {
            return findInContact(search, item)
        },

        clearSearch() {
            this.search = ""
        }
    }
}
</script>

<style scoped>

</style>