<template>
    <base-popup
        @save="apply"
        @close="close"
        title="filters-popup.title"
        save-text="filters-popup.apply"
        close-text="filters-popup.cancel"
        popup-class="voicemail-filters-popup"
    >
        <date-picker-dropdown v-model="dayRange"></date-picker-dropdown>

        <div class="form-group voicemail-select" :class="{'filled': selectedVoicemailId}">
            <base-select
                v-model="selectedVoicemailId"
                :options="voicemailOptions"
                label="popups.voicemail-filters-popup.voicemail"
                placeholder="panel.call-history.call-types.all"
            ></base-select>
        </div>

        <div class="input-group">
            <base-switch
                v-model="unreadMessagesOnly"
                label="panel.services.voicemail.show-unread-messages-only"
                border
            ></base-switch>
        </div>

        <template v-slot:actionsPrepend>
            <base-button
                @click="clear"
                :icon="'icon-clear'"
                :text="'filters-popup.clear'"
                class="clear-button"
            ></base-button>
        </template>
    </base-popup>
</template>

<script>
import VoicemailFilters from "../../../helpers/VoicemailFilters";
import BaseSelect from "../../defaults/BaseSelect.vue";
import {mapGetters} from "vuex";
import BaseButton from "../../defaults/BaseButton.vue";
import BasePopup from "../BasePopup.vue";
import BaseSwitch from "../BaseSwitch.vue";
import DatePickerDropdown from "../datePicker/DatePickerDropdown.vue";

export default {
    name: "VoicemailFiltersPopup",
    components: {DatePickerDropdown, BaseSwitch, BasePopup, BaseButton, BaseSelect},
    props: {
        value: {
            type: VoicemailFilters,
            default: null,
            deep: true
        }
    },
    data() {
        return {
            dayRange: this.value.dayRange,
            selectedVoicemailId: this.value.voicemailIds ? this.value.voicemailIds[0] : 0,
            unreadMessagesOnly: this.value.unreadMessagesOnly
        }
    },
    computed: {
        ...mapGetters("user", ['voicemails']),

        voicemailOptions() {
            const options = this.voicemails.map((item) => {
                return {name: item.name, value: item.id}
            })

            options.unshift({name: this.$t("panel.call-history.call-types.all"), value: 0})

            return options
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        apply() {
            this.value.voicemailIds = this.selectedVoicemailId !== 0 && this.selectedVoicemailId !== null ? [this.selectedVoicemailId] : null
            this.value.dayRange = this.dayRange
            this.value.unreadMessagesOnly = this.unreadMessagesOnly

            this.$emit('apply')
        },
        clear() {
            this.dayRange = null
            this.selectedVoicemailId = null
            this.unreadMessagesOnly = null
        }
    }
}
</script>

<style scoped>

</style>