import * as Sentry from "@sentry/vue";

export function isSentryEnabled() {
  try {
    return !!SENTRY_PROJECT && !!SENTRY_DSN;
  } catch (err) {
    return false;
  }
}

let sentryActive = false;

export function sentryInit(instance) {
  if (isSentryEnabled()) {
    Sentry.init({
      Vue: instance,

      dist: SENTRY_PROJECT,
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,

      tracesSampleRate: 1.0,

      integrations: [],
    });
  }

  sentryActive = true;
}