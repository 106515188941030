<template>
    <div class="queues-tab" :class="{'loading': saveQueueMembershipLoading}">
      <template v-if="saveQueueMembershipLoading">
        <base-spinner :size="'medium'" :color="'blue'" :center="true"></base-spinner>
        <span class="overlay"></span>
      </template>
      <div class="queues-title">
          <base-button
            @click="saveQueues"
            :disabled="!changedQueues.length"
            :text="'panel.services.queue-membership.save-button'"
            :color="'blue'"
          ></base-button>
      </div>

      <div v-if="queues.length && !queuesIsLoading" class="queues-wrapper" v-bar>
        <div>
          <div class="queues-container">
            <div
                class="queue-item"
                v-for="queue in dataQueues"
                :key="queue.id"
                :class="{
                    'originally-enabled': originallyQueueIsEnabled(queue.id),
                    'tmp-enabled': tmpQueueIsEnabled(queue.id),
                    'callback': queue.type === CALLBACK_TYPE
                }">
              <div @click="queue.status = !queue.status; toggleQueue(queue.id, queue.status)" class="switch-wrapper">
                <base-switch
                  v-model="queue.status"
                  @change="toggleQueue(queue.id, queue.status)"
                ></base-switch>
                <span class="name">{{queue.name}}</span>
                <span
                    :class="{'icon-person-add': queue.type === QUEUE_TYPE, 'icon-reply-all': queue.type === CALLBACK_TYPE}"
                    class="icon"
                ></span>
              </div>
              <div class="take-call-wrapper">
                <span class="calls-count-waiting">{{ getCallsCountText(queue.id, queue.type) }}</span>
                <base-button
                    v-if="!originallyQueueIsEnabled(queue.id) && !callIsWaiting(queue.id, queue.type)"
                    @click="takeOnCall(queue.id, queue.type, queue.extension)"
                    :color="'blue'"
                    :text="'panel.services.queue-membership.take-one-call'"
                    :disabled="getCallsInQueue(queue.id, queue.type) === 0 || !getIsRegistered"
                    :loading="callIsReceiving(queue.id, queue.type)"
                ></base-button>

                <chip
                    v-if="callIsWaiting(queue.id, queue.type)"
                    :size="'default'"
                    :color="'light-green'"
                    :text="'panel.services.queue-membership.awaiting-call'"
                >
                    <template v-slot:prepend>
                        <span class="circle"></span>
                    </template>
                </chip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="queuesIsLoading">
          <base-spinner center></base-spinner>
      </div>
      <div v-else class="queues-not-found">{{ $t("panel.services.queue-membership.no-queues-message") }}</div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseButton from "../../../defaults/BaseButton.vue";
import Chip from "../../../defaults/Chip.vue";
import SocketIOHelper from "../../../../helpers/SocketIOHelper";
import BaseSpinner from "../../../defaults/BaseSpinner.vue";
import BaseSwitch from "../../../defaults/BaseSwitch.vue";

const QUEUE_TYPE = "queue"
const CALLBACK_TYPE = "callback"

export default {
    name: "QueueMembership",
    components: {BaseSwitch, BaseSpinner, Chip, BaseButton},
    data() {
        return {
            saveQueueMembershipLoading: false,
            queuesIsLoading: false,
            changedQueues: [],
            callsCountInQueues: [],
            receivingCallQueues: [],
            awaitingCallQueues: [],
            QUEUE_TYPE,
            CALLBACK_TYPE
        }
    },
    created() {
        this.queuesIsLoading = true
        this.getQueues().catch(() => {}).finally(() => this.queuesIsLoading = false)

        SocketIOHelper.socket.on("update_current_calls", (socketData) => {
            this.callsCountInQueues = {
                ...this.callsCountInQueues,
                [ `${socketData.type}-${socketData.queue_id}` ]: Number(socketData.current_calls_count)
            }
        })
        SocketIOHelper.socket.on("update_queues", () => {
            this.getQueues().catch(() => {})
        })

        SocketIOHelper.addEventListener("reconnect", this.onReconnectHandler)

        document.addEventListener('silentSessionTerminated', this.silentSessionTerminatedHandler)
        document.addEventListener('incomingCallInit', this.incomingCallInitHandler)
    },
    destroyed() {
        document.removeEventListener('silentSessionTerminated', this.silentSessionTerminatedHandler)
        document.removeEventListener('incomingCallInit', this.incomingCallInitHandler)

        SocketIOHelper.removeEventListener("reconnect", this.onReconnectHandler)
        SocketIOHelper.socket.removeAllListeners("update_current_calls")
        SocketIOHelper.socket.removeAllListeners("update_queues")
    },
    computed: {
        ...mapGetters('queues', ['queues']),
        ...mapGetters('softphone', ['getIsRegistered']),
        ...mapGetters('user', ['calleridId']),

        dataQueues() {
            return JSON.parse(JSON.stringify(this.queues))
        }
    },
    watch: {
        queues() {
            this.queues.forEach((queue) => {
                const key = `${queue.type}-${queue.id}`
                if (!this.callsCountInQueues[key]) {
                    this.callsCountInQueues[key] = 0
                }
            })

            this.emitBuildQueues()
        }
    },
    methods: {
        ...mapActions('queues', [
            'saveQueueMembership',
            'getQueues'
        ]),
        ...mapActions('softphone', ['doCall']),

        onReconnectHandler() {
            this.getQueues().catch(() => {})
        },

        toggleQueue(id, status) {
            const queue = this.queues.find((q) => q.id === id)

            // if status is equal to prev status, then remove queue from changedQueues
            if (queue && queue.status === status) {
                this.changedQueues = this.changedQueues.filter((q) => q.id !== id)
            }

            // if status is not equal to prev status, add queue to changedQueues
            if (queue && queue.status !== status) {
                const changedQueue = Object.assign({}, queue)
                changedQueue.status = status
                this.changedQueues.push(changedQueue)
            }
        },
        saveQueues() {
            this.saveQueueMembershipLoading = true;
            this.saveQueueMembership(this.changedQueues)
                .catch(() => {})
                .finally(() => {
                    this.saveQueueMembershipLoading = false
                    this.changedQueues = []
                })
        },
        getCallsInQueue(queueId, type) {
            return this.callsCountInQueues[`${type}-${queueId}`]
        },
        originallyQueueIsEnabled(queueId) {
            const queue = this.queues.find((queue) => queue.id === queueId)
            return queue.status
        },
        tmpQueueIsEnabled(queueId) {
            const queue = this.dataQueues.find((queue) => queue.id === queueId)
            return queue.status
        },
        callIsReceiving(queueId, queueType) {
            return Boolean(this.receivingCallQueues.find(queue => queue.id === queueId && queue.type === queueType))
        },
        callIsWaiting(queueId, queueType) {
            return Boolean(this.awaitingCallQueues.find(queue => queue.id === queueId && queue.type === queueType))
        },
        takeOnCall(queueId, queueType, extension) {
            this.receivingCallQueues.push({id: queueId, type: queueType})

            this.doCall({
                number: "**" + extension.toString(),
                silent: true,
                calleridId: this.calleridId
            })
        },
        incomingCallInitHandler(event) {
            const data = event.detail.sessionData
            if (data.call_received_queue) {
                // call_received_queue contains this - "queue-def-1000000017-95"
                const queueId = Number(data.call_received_queue.split("-").pop())
                if (queueId) {
                    const index = this.awaitingCallQueues.findIndex((queue) => queue.id === queueId && queue.type === QUEUE_TYPE)
                    if (index !== -1) {
                        this.awaitingCallQueues.splice(index, 1)
                    }
                }
            }

            if (this.awaitingCallQueues.length > 0) {
                this.awaitingCallQueues = this.awaitingCallQueues.filter((queue) => queue.type !== CALLBACK_TYPE)
            }
        },
        silentSessionTerminatedHandler(event) {
            let number = event.detail.number.toString()
            if (number.substring(0, 2) === "**") {
                number = number.slice(2)
            }

            const queue = this.queues.find((queue) => queue.extension.toString() === number)
            if (queue) {
                const index = this.receivingCallQueues.findIndex((q) => q.id === queue.id && q.type === queue.type)
                if (index !== -1) {
                    this.receivingCallQueues.splice(index, 1);
                    this.awaitingCallQueues.push({id: queue.id, type: queue.type})
                }
            }
        },
        getCallsCountText(queueId, type) {
            const callsCount = this.getCallsInQueue(queueId, type)

            if (callsCount > 1 && type === QUEUE_TYPE) {
                return this.$t("panel.services.queue-membership.calls-count-waiting.queue.several-calls", [callsCount])
            }

            if (callsCount > 1 && type === CALLBACK_TYPE) {
                return this.$t("panel.services.queue-membership.calls-count-waiting.callback.several-calls", [callsCount])
            }

            if (callsCount === 1 && type === QUEUE_TYPE) {
                return this.$t("panel.services.queue-membership.calls-count-waiting.queue.one-call")
            }

            if (callsCount === 1 && type === CALLBACK_TYPE) {
                return this.$t("panel.services.queue-membership.calls-count-waiting.callback.one-call")
            }

            if (callsCount === 0) {
                return this.$t("panel.services.queue-membership.calls-count-waiting.no-calls")
            }
        },
        emitBuildQueues() {
            SocketIOHelper.emit("build_queues", this.queues.map((queue) => {
                return {id: queue.id, type: queue.type}
            }))
        },
    }
}
</script>

<style scoped>

</style>