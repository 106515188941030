import {getPhoneNumberDigitsOnly} from "./phoneNumbers";
 const copyValue = async (value) => {
    await navigator.clipboard.writeText(value).catch((error) => console.error(error.message))
}

const showTooltip = (item, property) => {
    item[property] = true
    setTimeout(() => {
        item[property] = false
    }, 1000)
}

const copyContact = (item) => {
    let name = ''
    if (item.contact) {
        name = item.contact.name + ' ';
    } else if (item.name && item.name.length) {
        name = item.name + ' ';
    }

    let number = getPhoneNumberDigitsOnly(item.number)

    copyValue(name + number)
}

const copyNameWithNumber = (name, number) => {
    let formattedNumber = getPhoneNumberDigitsOnly(number)

    if (name && formattedNumber) {
        copyValue(name + ' ' + formattedNumber)
    } else if (formattedNumber) {
        copyValue(formattedNumber)
    } else {
        copyValue(number)
    }
}

const copyNumber = (item) => {
    let number = getPhoneNumberDigitsOnly(item)

    copyValue(number ? number : item)
}

export {copyValue, showTooltip, copyNameWithNumber, copyNumber, copyContact}