<template>
    <div :class="classes" class="contact">
        <div class="contact-avatar-wrapper">
            <slot name="avatar">
                <avatar v-bind="bindToAvatar"></avatar>
            </slot>
        </div>

        <base-tooltip v-model="copyButton.isCopied" :open-by="'manual'" text="copy-message" position="top" textAlign="center" :width="70">
            <template v-slot:activator>
                <div class="contact-name-number-group">
                    <span v-if="localContact.id || contactName" class="name">{{ contactName }}</span>
                    <span class="number">{{ formattedNumber }}</span>
                </div>
            </template>
        </base-tooltip>
        <copy-button
            v-if="isHover"
            @click="onClickCopyButton"
        ></copy-button>
    </div>
</template>

<script>
import Avatar from "./Avatar.vue";
import CopyButton from "./CopyButton.vue";
import BaseTooltip from "./BaseTooltip.vue";
import {mapGetters} from "vuex";
import {formatNumber, isExtension} from "../../utils/phoneNumbers";
import {copyNameWithNumber, showTooltip, copyNumber} from "../../utils/copy";

const DARK_COLOR = "dark_color"
const LIGHT_COLOR = "light_color"

export default {
    name: "Contact",
    components: {Avatar, CopyButton, BaseTooltip},
    props: {
        // if a contact is passed, it will be used,
        // otherwise a contact search by number will be performed
        contact: {
            default() {
                return {}
            },
            type: Object
        },
        number: {
            default: "",
            type: String
        },
        color: {
            default: LIGHT_COLOR,
            type: String
        },
        avatarSize: {
            default: "small",
            type: String
        },
        isHover: {
            default: false,
            type: Boolean
        },
        // if it is an unknown contact and this prop is not empty, then this name will be displayed for the contact
        unknownName: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    computed: {
        ...mapGetters('details', [ 'getContactByNumber']),

        classes() {
            return {
                "unknown-contact": !this.localContact.id && !this.unknownName,
                "light-color": this.color === LIGHT_COLOR,
                "dark-color": this.color === DARK_COLOR
            }
        },

        localContact() {
            return Object.keys(this.contact).length ? this.contact : this.getContactByNumber(this.number)
        },

        contactName() {
            if (this.localContact.id) {
                return this.localContact.name
            }

            if (!this.localContact.id && this.unknownName) {
                return this.unknownName
            }

            return ""
        },

        formattedNumber() {
            return this.localContact.number ? formatNumber(this.localContact.number) : formatNumber(this.number)
        },

        canShowStatus() {
            return isExtension(this.number)
        },

        bindToAvatar() {
            const result = {}

            result.size = this.avatarSize

            if (!this.localContact.id) {
                result["unknown-person"] = true

                return result
            }

            result.src = this.localContact.avatar
            result.text = this.localContact.name
            result.color = this.localContact.color
            result["show-status"] = this.canShowStatus
            result.status = this.localContact.status

            return result
        }
    },
    methods: {
        onClickCopyButton() {
            if (this.localContact.id) {
                copyNameWithNumber(this.localContact.name, this.formattedNumber)
            } else {
                copyNumber(this.formattedNumber)
            }

            showTooltip(this.copyButton, 'isCopied')
        }
    }
}
</script>

<style scoped>

</style>