<template>
    <div class="base-tabs">
        <div class="base-tabs-header">
            <div
                v-for="tab in tabs"
                @click="selectTab(tab.name)"
                :class="isCurrentTab(tab.name) ? 'tab-current' : ''"
                class="base-tabs-tab"
            >
                {{ $te(tab.text) ? $t(tab.text) : tab.text }}

                <slot :name="'tab_header_' + tab.name"></slot>
            </div>
            <div class="base-tabs-tab">
                <slot :name="'tab_header_tooltip'"></slot>
            </div>
        </div>
        <div class="base-tabs-content">
            <div
                v-for="tab in tabs"
                v-show="isCurrentTab(tab.name)"
                class="base-tabs-tab-content"
            >
                <template v-if="isCurrentTab(tab.name)">
                    <slot :name="tab.name"></slot>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseTabs",
    props: {
        value: {
            type: String,
            default: ""
        },
        tabs: {
            type: Array,
            default: []
        }
    },
    methods: {
        isCurrentTab(tabName) {
            return this.value === tabName
        },
        selectTab(tabName) {
            this.$emit('input', tabName)
        }
    }
}
</script>

<style scoped>

</style>