import store from '../store'

const CAPTCHA_SCRIPT = "https://www.google.com/recaptcha/api.js"
const CAPTCHA_SCRIPT_ENTERPRISE = "https://www.recaptcha.net/recaptcha/enterprise.js"

const applyCaptcha = (captchaKey, isUniversalWebphone) => {
    store.dispatch('updateCaptchaKey', captchaKey)

    const element = document.createElement("script")
    element.src = (isUniversalWebphone ? CAPTCHA_SCRIPT_ENTERPRISE : CAPTCHA_SCRIPT) + "?render=" + captchaKey
    document.head.appendChild(element)
}

const getCaptchaToken = (action, attempt) => {
    const captchaKey = store.getters['captchaKey']
    const isUniversalWebphone = store.getters['isUniversalWebphone']

    if (!captchaKey) {
        return Promise.reject()
    }

    if(!attempt) {
        attempt = 1
    }

    return new Promise((resolve, reject) => {

        if (isUniversalWebphone) {
            grecaptcha.enterprise.ready(() => {
                grecaptcha.enterprise.execute(captchaKey, {action: action}).then((token) => {
                        if (token.length < 250 && attempt <= 5) {

                            setTimeout(async function () {
                                getCaptchaToken(action, attempt + 1).then((token) => {
                                    resolve(token)
                                })
                            }, 1000);

                        } else {
                            resolve(token)
                        }
                })
            })
        } else {
            grecaptcha.ready(() => {
                grecaptcha.execute(captchaKey, {action: action}).then((token) => {
                    resolve(token)
                })
            })
        }

    })
}

export {applyCaptcha, getCaptchaToken}