<template>
    <div class="tab history-tab" :class="{'active': this.active}">
        <div class="history-wrapper" :class="{'no-data': !callHistory.length}">
            <v-app v-if="!loading && (callHistory.length || filters.count || localLoading)">
                <v-data-table
                    :headers="[
                    { text: '', align: 'title', value: 'title', sortable: false},
                    { text: $t('panel.call-history.header-type'), align: 'type', value: 'type', sortable: false},
                    { text: $t('panel.call-history.header-line-used'), align: 'callerid', value: 'callerid', sortable: false},
                    { text: $t('panel.call-history.header-duration'), align: 'duration', value: 'duration', sortable: false},
                    { text: $t('panel.call-history.header-date'), align: 'date', value: 'date', sortable: false},
                    { text: '', align: 'controls', value: 'controls', sortable: false}
                    ]"
                    :items="callHistory"
                    :mobile-breakpoint="0"
                    :options.sync="options"
                    :items-per-page="15"
                    :hide-default-footer="true"
                    :server-items-length="parseInt(callHistoryTotalCountRows)"
                    :expanded.sync="expanded">
                    <template v-slot:body="{ items, expand, isExpanded  }">
                        <div v-if="!items.length && filters.count" class="no-results">
                            {{ $t("panel.call-history.no-results-found") }}
                        </div>

                        <div v-show="items.length" class="history-exist" v-bar>
                            <div>
                                <tbody>
                                    <call-history-table-row
                                        v-for="item in items"
                                        @expand="expand(item, !isExpanded(item))"
                                        :is-expanded="isExpanded(item)"
                                        :item="item"
                                        :key="item.id"
                                        :call-duration="callDuration(item.duration)"
                                        :can-show-integrations="canShowIntegrations(item.number)"
                                        :is-extension="isExtension(item.number)"
                                    ></call-history-table-row>
                                </tbody>
                            </div>
                        </div>
                    </template>
                    <template v-slot:top="{ pagination, options, updateOptions }" v-show="item.contact">
                        <table-top
                            :items-per-page="options.itemsPerPage"
                            :pagination="pagination"
                            :options="options"
                            @updateOptions="updateOptions"
                            @refresh="updateHistory"
                            items-per-page-disabled
                        >
                            <template v-slot:prepend>
                                <div class="form-group">
                                    <base-input
                                        v-model="filters.search"
                                        @input="activateSearch"
                                        class="find-by-number"
                                        type="text"
                                        placeholder="panel.call-history.search-placeholder"
                                        icon="icon-search"
                                    ></base-input>

                                    <base-button
                                        @click="toggleFiltersPopup(true)"
                                        :color="'blue'"
                                        :size="'big'"
                                        :icon="'icon-filter'"
                                        :text="'panel.call-history.filters-btn'"
                                        class="btn-filter"
                                    >
                                        <template v-slot:append>
                                            <span v-if="filters.count" class="counter">{{ filters.count }}</span>
                                        </template>
                                    </base-button>
                                </div>

                                <chip
                                    v-for="(chip, index) of filters.chips"
                                    :key="index"
                                    :text="chip.value"
                                    :size="'big'"
                                    :color="'light-blue'"
                                >
                                    <template v-slot:append>
                                        <base-button
                                            @click="removeFilter(chip.name)"
                                            :size="'small'"
                                            :color="'white'"
                                            :icon="'icon-clear'"
                                            :icon-size="14"
                                            :radius="'50%'"
                                            class="chip-button-close"
                                        ></base-button>
                                    </template>
                                </chip>

                                <filters-popup v-if="filtersPopup" v-model="filters" @close="toggleFiltersPopup(false)" @apply="filtersApply"></filters-popup>
                            </template>
                        </table-top>
                    </template>
                </v-data-table>
            </v-app>
            <div v-else-if="loading">
                <base-spinner center></base-spinner>
            </div>
            <div v-else class="history-not-found">
                {{ $t("panel.call-history.no-calls-message") }}
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FiltersPopup from "./FiltersPopup.vue";
import HistoryFilters from "../../../helpers/HistoryFilters";
import Chip from "../../defaults/Chip.vue";
import TableTop from "../../defaults/TableTop.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import BaseInput from "../../defaults/BaseInput.vue";
import {canShowIntegrations} from "../../../utils/integrations";
import {isExtension} from "../../../utils/phoneNumbers";
import CallHistoryTableRow from "./CallHistoryTableRow.vue";
import BaseSpinner from "../../defaults/BaseSpinner.vue";

export default {
    name: "CallHistory",
    components: {BaseInput, BaseButton, TableTop, Chip, FiltersPopup, CallHistoryTableRow, BaseSpinner},
    props: {
        active: Boolean,
        loading: Boolean
    },
    data() {
        return {
            options: {itemsPerPage: 15, page: 1},
            expanded: [],
            filtersPopup: false,
            filters: null,
            searchTimeoutId: null,
            ignoreOptionsPageWatcher: false,
            localLoading: false
        }
     },
    watch: {
        optionsPage(value) {
            if (!this.ignoreOptionsPageWatcher) {
                this.filters.page = value
                this.updateHistory()
            }
        }
    },
    created() {
        this.filters = new HistoryFilters({})
        document.addEventListener('sessionTerminated', this.updateHistory)
    },
    destroyed() {
        document.removeEventListener('sessionTerminated', this.updateHistory)
    },
    computed: {
        ...mapGetters('details', ['callHistory', 'callHistoryTotalCountRows']),

        optionsPage() {
            return this.options.page
        }
    },
    methods: {
        ...mapActions('details', ['loadCallHistory']),

        toggleFiltersPopup(show) {
            this.filtersPopup = show
        },
        filtersApply() {
            this.updateHistory(true)
            this.toggleFiltersPopup(false)
        },
        removeFilter(name) {
            this.filters.removeFilter(name)
            this.updateHistory(true)
        },
        activateSearch() {
            if (this.filters.search) {
                clearTimeout(this.searchTimeoutId)
                this.searchTimeoutId = setTimeout(() => {
                    this.updateHistory(true)
                }, 500)
            } else {
                this.updateHistory(true)
            }
        },
        updateHistory(backToFirstPage = false) {
            if (backToFirstPage) {
                this.ignoreOptionsPageWatcher = true
                this.filters.page = 1
                this.options.page = 1
                this.$nextTick(()=>{
                    this.ignoreOptionsPageWatcher = false
                })
            }
            this.localLoading = true
            this.loadCallHistory(this.filters.requestParams).then(() => this.localLoading = false)
        },
        callDuration(time) {
            var mins = 0;
            var secs = 0;
            if (time < 60) {
                secs = time;
            } else {
                mins = Math.floor(time / 60);
                secs = time % 60;
            }
            return `${("0" + mins).slice(-2)}:${("0" + secs).slice(-2)}`;
        },

        canShowIntegrations,

        isExtension
    }
}
</script>

<style scoped>

</style>