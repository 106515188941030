<template>
    <div class="person-collapsed-incoming">
        <Avatar
            :size="'big'"
            :src="contact.avatar"
            :text="contact.name"
            :color="contact.color"
            :call-type="session.direction"
        ></Avatar>
        <div class="person-details-collapsed" :class="{ 'is-known': contact.id || contactNameInCrm(session.number)}">
            <span
                v-show="contact.id || contactNameInCrm(session.number)"
                class="name"
            >
                {{ contact.name || contactNameInCrm(session.number) }}
            </span>
            <base-tooltip v-model="copyButton.isCopied" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
                <template v-slot:activator>
                    <span @click="[copyNumber(session.displayName), showTooltip(copyButton, 'isCopied')]" class="number">{{ session.displayName }}</span>
                </template>
            </base-tooltip>
        </div>

        <div class="buttons">
            <call-button @press="rejectBtnClick" buttonClass="hangup-call-button" iconClass="icon-call-end"></call-button>
            <call-button @press="acceptBtnClick" buttonClass="accept-call-button" iconClass="icon-call"></call-button>
        </div>
    </div>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import ButtonsGroupManageCall from "../buttonsGroup/ButtonsGroupManageCall.vue";
import CallButton from "./CallButton.vue";
import {copyNumber, showTooltip} from "../../../utils/copy";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import {mapGetters} from "vuex";

export default {
    name: "PersonCollapsedIncoming",
    components: {CallButton, ButtonsGroupManageCall, Avatar, BaseTooltip},
    props: {
        session: {
            type: Object
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    computed: {
        ...mapGetters("integrations", ["contactNameInCrm"]),
        ...mapGetters('details', ['getContactByNumber']),

        contact() {
            return this.getContactByNumber(this.session.number)
        }
    },
    methods: {
        rejectBtnClick() {
            this.$emit("rejectBtnClick")
        },

        acceptBtnClick() {
            this.$emit("acceptBtnClick")
        },

        copyNumber,
        showTooltip
    }
}
</script>

<style scoped>

</style>