import {mapGetters} from "vuex";

const phonebooksSelectOptionsMixin = {
    computed: {
        ...mapGetters("details", ["phonebooks"]),

        phonebooksOptions() {
            const options = this.phonebooks.map((phonebook) => { return {name: phonebook.name, value: phonebook.id} })
            options.unshift({name: this.$t("panel.phonebooks.all-phonebooks-item"), value: "all"})

            return options
        }
    }
}

export default phonebooksSelectOptionsMixin