/**
 * Class that represent field in extended CRM contact info
 */
export default class GeneralInfoField {
    /**
     * @param {string} value
     * @param {string} label
     */
    constructor({value, label}) {
        this._value = value
        this._label = label
    }

    /**
     * @returns {string}
     */
    get value() {
        return this._value
    }

    /**
     * @returns {string}
     */
    get label() {
        return this._label
    }
}