const SYMBOLS = ['#8A0022', '#0047AB', '#D58A94', '#614051', '#FF00FF', '#597227', '#4D815B', '#C08524', '#C13F3F', '#64569E']
const DIGITS = ['#1560BD', '#6A5ACD', '#7F552F', '#38A3A5', '#A51136', '#5FD7D7', '#B76E2C', '#C9676F', '#9F83E2', '#5A4AF5']

const LETTERS = {
    A: '#F96A95',
    B: '#96BEFF',
    C: '#E358B4',
    D: '#F6AB6C',
    E: '#6F42C1',
    F: '#C74375',
    G: '#DAA520',
    H: '#61CED0',
    I: '#790079',
    J: '#DD546A',
    K: '#CFA258',
    L: '#746AEA',
    M: '#C04000',
    N: '#4545ED',
    O: '#989819',
    P: '#007FFF',
    Q: '#F5663A',
    R: '#C76B1E',
    S: '#5EB9DE',
    T: '#047474',
    U: '#386B4C',
    V: '#7F00FF',
    W: '#C6A0F0',
    X: '#4278BD',
    Y: '#F77A07',
    Z: '#FDB402'
}

const getColor = (name) => {
    let firstSymbol = name.charAt(0)

    if ( LETTERS[firstSymbol.toUpperCase()] ) {
        return LETTERS[firstSymbol.toUpperCase()]
    }

    if ( DIGITS[+firstSymbol] ) {
        return DIGITS[+firstSymbol]
    }

    return SYMBOLS[Math.floor(Math.random() * SYMBOLS.length)]
}

export {getColor}