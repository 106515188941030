<template>
    <base-popup
        @save="apply"
        @close="close"
        title="filters-popup.title"
        save-text="filters-popup.apply"
        close-text="filters-popup.cancel"
        popup-class="filters-popup"
    >
        <date-picker-dropdown v-model="dayRange"></date-picker-dropdown>

        <div class="form-group filled call-type-select">
            <base-select
                v-model="selectedCallType"
                :options="callTypesOptions"
                :label="'filters-popup.call-types-title'"
                :placeholder="'panel.call-history.call-types.all'"
            ></base-select>
        </div>

        <div class="form-group">
            <base-input
                v-model="search"
                type="text"
                placeholder="filters-popup.search"
                label="filters-popup.search"
            ></base-input>
        </div>

        <div class="input-group">
            <base-switch
                v-model="internalCallsOnly"
                :label="'filters-popup.internals-only'"
                border
            ></base-switch>
        </div>

        <template v-slot:actionsPrepend>
            <base-button
                @click="clear"
                :icon="'icon-clear'"
                :text="'filters-popup.clear'"
                class="clear-button"
            ></base-button>
        </template>
    </base-popup>
</template>

<script>
import HistoryFilters from "../../../helpers/HistoryFilters";
import BaseSelect from "../../defaults/BaseSelect.vue";
import {mapGetters} from "vuex";
import BaseButton from "../../defaults/BaseButton.vue";
import BaseSwitch from "../../defaults/BaseSwitch.vue";
import BasePopup from "../../defaults/BasePopup.vue";
import BaseInput from "../../defaults/BaseInput.vue";
import BaseDropdown from "../../defaults/BaseDropdown.vue";
import DatePickerDropdown from "../../defaults/datePicker/DatePickerDropdown.vue";

export default {
    name: "FiltersPopup",
    components: {DatePickerDropdown, BaseDropdown, BaseInput, BasePopup, BaseSwitch, BaseButton, BaseSelect},
    props: {
        value: {
            type: HistoryFilters,
            default: null,
            deep: true
        }
    },
    data() {
        return {
            dayRange: this.value.dayRange,
            search: this.value.search,
            internalCallsOnly: this.value.internalsOnly,
            selectedCallType: this.value.callType,
            expandCallTypeDropdown: false
        }
    },
    computed: {
        ...mapGetters("details", ['callTypes']),

        callTypesOptions() {
            let options = Object.keys(this.callTypes).map((type) => {
                return {name: this.$t(this.callTypes[type]), value: type}
            })
            options.unshift({name: this.$t("panel.call-history.call-types.all"), value: ""})
            return options
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        apply() {
            this.value.callType = this.selectedCallType
            this.value.internalsOnly = this.internalCallsOnly
            this.value.search = this.search
            this.value.dayRange = this.dayRange

            this.$emit('apply')
        },
        clear() {
            this.dayRange = null
            this.search = ''
            this.internalCallsOnly = false
            this.selectedCallType = ''
        }
    }
}
</script>

<style scoped>

</style>