/**
 * Start downloading file
 *
 * @param {string} url
 * @param {string} filename
 */
const downloadFile = (url, filename) => {
    const a = document.createElement('a')
    a.href = url
    a.download = filename

    a.click()
    a.remove()
}

/**
 * Reload page
 */
const reloadPage = () => {
    window.location.reload();
}

/**
 * Redirect by click
 *
 * @param {String} url
 * @param {String} target
 */
const redirectByClick = (url, target = '_blank') => {
    const a = document.createElement('a')
    a.target = target
    a.href = url

    a.click()
    a.remove()
}

export { downloadFile, reloadPage, redirectByClick }