import Api from "../../utils/api";

const SMS_TYPE_INBOUND = "inbound"
const SMS_TYPE_OUTBOUND = "outbound"

export default {
    namespaced: true,
    state: {
        chats: [],
        messages: []
    },
    getters: {
        chats: (state) => {
            return state.chats
        },
        messages: (state) => {
            return state.messages
        }
    },
    actions: {
        getChats: (context, limit) => {
            return new Promise((resolve, reject) => {
                Api().get('/sms/chats', {
                    params: {
                        limit
                    }
                }).then((response) => {
                    context.commit('setChats', response.data)
                    resolve()
                }).catch(() => reject())
            })
        },

        getInitChatHistory: (context, {chatId, limit}) => {
            return new Promise((resolve, reject) => {
                Api().get('/sms/chat_history', {
                    params: {
                        chat_id: chatId,
                        limit
                    }
                }).then((response) => {
                    context.commit('setMessages', response.data)
                    resolve(response.data)
                }).catch(() => reject())
            })
        },

        getChatHistoryBefore: (context, {chatId, smsId, limit}) => {
            return new Promise((resolve, reject) => {
                Api().get('/sms/chat_history', {
                    params: {
                        chat_id: chatId,
                        before_sms_id: smsId,
                        limit
                    }
                }).then((response) => {
                    context.commit('setMessagesBefore', response.data)
                    resolve(response.data)
                }).catch(() => reject())
            })
        },

        getChatHistoryAfter: (context, {chatId, smsId, limit}) => {
            return new Promise((resolve, reject) => {
                Api().get('/sms/chat_history', {
                    params: {
                        chat_id: chatId,
                        after_sms_id: smsId,
                        limit
                    }
                }).then((response) => {
                    context.commit('setMessagesAfter', response.data)
                    resolve(response.data)
                }).catch(() => reject())
            })
        },

        deleteChat: (context, chatId) => {
            return new Promise((resolve, reject) => {
                Api().post('/sms/delete_chat', {
                    chat_id: chatId
                }).then((response) => {
                    context.commit('deleteChat', chatId)
                    resolve()
                }).catch(() => reject())
            })
        },

        updateChatFromNumber: (context, {chatId, fromNumberId}) => {
            return new Promise((resolve, reject) => {
                Api().post('/sms/update_chat_from_number', {
                    chat_id: chatId,
                    from_number_id: fromNumberId
                }).then((response) => {
                    context.commit('updateChatFromNumber', {chatId, fromNumberId})
                    resolve()
                }).catch(() => reject())
            })
        },

        updateChatLastSms: (context, {chatId, date, text, type}) => {
            context.commit('updateChatLastSms', {chatId, sms: {date, text, type}})
        },

        updateChatNumberOfUnreadMessages: (context, {chatId, numberOfUnreadMessages}) => {
            context.commit('updateChatNumberOfUnreadMessages', {chatId, numberOfUnreadMessages})
        },

        createNewChat: (context, {id, number, numberOfUnreadMessages, fromNumberId, lastSmsDate, lastSmsText, lastSmsType}) => {
            const chat = {
                id,
                number,
                number_of_unread_messages: numberOfUnreadMessages,
                from_number_id: fromNumberId,
                last_sms: {
                    date: lastSmsDate,
                    text: lastSmsText,
                    type: lastSmsType
                }
            }
            context.commit('createNewChat', chat)
        },

        createNewSms: (context, {id = null, text, date, type, receivedOn = "", isRead = false}) => {
            const sms = {
                id,
                text,
                date,
                type,
                received_on: receivedOn,
                is_read: isRead
            }
            context.commit('createNewSms', sms)
        },

        markSMSesAsRead: (context, smsIds) => {
            return new Promise((resolve, reject) => {
                Api().post('/sms/read_smses', {
                    sms_ids: JSON.stringify(smsIds)
                }).then((response) => {
                    context.commit('markSMSesAsRead', smsIds)
                    resolve()
                }).catch(() => reject())
            })
        },

        smsSend: (context, {toNumber, text, sentAt, fromNumberId = null}) => {
            const data = {
                to_number: toNumber,
                text: text,
                sent_at: sentAt
            }

            if (fromNumberId) {
                data.from_number_id = fromNumberId
            }

            return new Promise((resolve, reject) => {
                Api().post('/sms/send', data).then((response) => {
                    resolve()
                }).catch(() => reject())
            })
        },

        updateMessageDate: (context, {id, date}) => {
            context.commit("updateMessageDate", {id, date})
        },

        trimMessagesFromTheBeginning: (context, count) => {
            context.commit("trimMessagesFromTheBeginning", count)
        },

        trimMessagesFromTheEnd: (context, count) => {
            context.commit("trimMessagesFromTheEnd", count)
        },
    },
    mutations: {
        setChats: (state, chats) => {
            state.chats = chats
        },

        setMessages: (state, messages) => {
            state.messages = messages
        },

        trimMessagesFromTheBeginning: (state, count) => {
            state.messages = state.messages.slice(-(state.messages.length - count))
        },

        trimMessagesFromTheEnd: (state, count) => {
            state.messages = state.messages.slice(0, state.messages.length - count)
        },

        setMessagesBefore: (state, messages) => {
            state.messages = [...messages, ...state.messages]
        },
        setMessagesAfter: (state, messages) => {
            state.messages = [...state.messages, ...messages]
        },

        deleteChat: (state, chatId) => {
            state.chats = state.chats.filter((chat) => chat.id !== chatId)
        },

        updateChatFromNumber: (state, {chatId, fromNumberId}) => {
            const chat = state.chats.find((chat) => chat.id === chatId)
            if (chat) {
                chat.from_number_id = fromNumberId
            }
        },

        updateChatLastSms: (state, {chatId, sms}) => {
            const chat = state.chats.find((chat) => chat.id === chatId)
            if (chat) {
                chat.last_sms = sms
            }
        },

        updateChatNumberOfUnreadMessages: (state, {chatId, numberOfUnreadMessages}) => {
            const chat = state.chats.find((chat) => chat.id === chatId)
            if (chat) {
                chat.number_of_unread_messages = numberOfUnreadMessages
            }
        },

        createNewChat: (state, chat) => {
            state.chats.unshift(chat)
        },

        createNewSms: (state, sms) => {
            state.messages.push(sms)
        },

        markSMSesAsRead: (state, ids) => {
            ids.forEach((id) => {
                const message = state.messages.find(message => message.id === id)
                if (message) {
                    message.is_read = true
                }
            })
        },

        updateMessageDate: (state, {id, date}) => {
            const message = state.messages.find(message => message.id === id)
            if (message) {
                message.date = date
            }
        }
    }
}

export {SMS_TYPE_INBOUND, SMS_TYPE_OUTBOUND}