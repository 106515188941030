<template>
    <div class="login-screen-wrapper reset-password-screen">
        <div class="form-wrapper reset-password">
            <div class="info reset-password-info" :class="{'success-changed-wrapper': successChanged}">
                <div v-show="!successChanged" class="password-recommendation">
                    <h2>{{ $t("reset-password.subtitle") }}</h2>

                    <p>{{ $t("reset-password.password-complexity-recommendations.title") }}</p>

                    <password-rule-checkbox
                        v-for="(passwordRule, idx) in passwordRules"
                        :key="idx"
                        :checked="Boolean(passwordRule.isRuleFollowed && password.length)"
                        :label="passwordRule.rule"
                    ></password-rule-checkbox>
                </div>

                <div v-show="successChanged" class="success-changed-info">
                    <h2>{{ $t("reset-password.success-changed.subtitle") }}</h2>
                    <i18n path="reset-password.success-changed.info" tag="p">
                        <a @click=redirectToLogin>{{ $t("reset-password.success-changed.info-click-here") }}</a>
                    </i18n>
                </div>
            </div>

            <form v-show="!successChanged" class="reset-password-form">
                <div class="input-group">
                    <span class="label">{{ $t("reset-password.new-password-label") }}</span>
                    <base-input
                        v-model="password"
                        @input="resetValidation"
                        @blur="$v.password.$touch()"
                        :error="passwordHasError"
                        type="password"
                        color="login"
                        rounded
                    ></base-input>
                </div>

                <div class="input-group">
                    <span class="label">{{ $t("reset-password.confirm-password-label") }}</span>
                    <base-input
                        v-model="confirmPassword"
                        @input="resetValidation"
                        @blur="$v.password.$touch()"
                        :error="passwordHasError"
                        type="password"
                        color="login"
                        rounded
                    ></base-input>
                </div>

                <div class="button-group">
                    <base-button
                        class="action-btn"
                        @click="changePassword"
                        :disabled="$v.$invalid"
                        :loading="resetPasswordLoading"
                        :size="'big'"
                        :color="'blue'"
                        :text="'reset-password.change-password-button'"
                        :radius="'18px'"
                    ></base-button>
                </div>

                <div class="invalid-feedback">
                    <p v-if="resetPasswordError">{{ $t("reset-password.invalid-feedback." + resetPasswordError) }}</p>
                    <p v-if="(!$v.password.validFormat && $v.password.$error)">{{ $t("reset-password.invalid-feedback.invalid-format") }}</p>
                    <p v-if="(!$v.password.maxLength && $v.password.$error)">{{ $t("reset-password.invalid-feedback.max-password-length") }}</p>
                </div>
            </form>

            <div class="bottom-wrapper">
                <div class="language-changer">
                    <base-select @input="changeLocale($event)" :value="$i18n.locale" :position="'top'" :size="'small'" :options="languages"></base-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, maxLength, minLength, sameAs } from 'vuelidate/dist/validators.min.js'
import {mapActions, mapGetters} from "vuex";
import BaseSelect from "../defaults/BaseSelect.vue";
import {changeLocale} from "../../locales";
import BaseButton from "../defaults/BaseButton.vue";
import {getCaptchaToken} from "../../helpers/CaptchaHelper";
import PasswordRuleCheckbox from "./PasswordRuleCheckbox.vue";
import BaseInput from "../defaults/BaseInput.vue";

export default {
    name: "ResetPassword",
    components: {BaseInput, PasswordRuleCheckbox, BaseButton, BaseSelect},
    data() {
        return {
            password: '',
            confirmPassword: '',
            resetPasswordLoading: false,
            successChanged: false,
            redirectTimerId: null,
            resetPasswordError: false
        }
    },
    validations: {
        password: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(16),
            validFormat: val => /(^$|^[a-zA-Z\d\@\#\$\%\&\!\?\*\+\=\^\|\{\}\(\)\[\]\/\'\"\`\~\,\;\:\.\<\>\_\-]+$)/.test(val),
            sameAsPassword: sameAs("confirmPassword"),
            upperCase: (value) => /[A-Z]/.test(value),
            lowerCase: (value) => /[a-z]/.test(value),
            digit: (value) => /[0-9]/.test(value)
        }
    },
    computed: {
        ...mapGetters('user', ['isLoggedIn']),
        ...mapGetters(['captchaKey', 'languages', 'partnerId']),

        passwordRules() {
            return [
                {rule: "reset-password.password-complexity-recommendations.min-length", isRuleFollowed: this.$v.password.minLength},
                {rule: "reset-password.password-complexity-recommendations.upper-case-letter", isRuleFollowed: this.$v.password.upperCase},
                {rule: "reset-password.password-complexity-recommendations.lower-case-letter", isRuleFollowed: this.$v.password.lowerCase},
                {rule: "reset-password.password-complexity-recommendations.least-one-number", isRuleFollowed: this.$v.password.digit},
                {rule: "reset-password.password-complexity-recommendations.same-passwords", isRuleFollowed: this.$v.password.sameAsPassword}
            ]
        },

        passwordHasError() {
            return (!this.$v.password.validFormat || !this.$v.password.maxLength) && this.$v.password.$error && this.password.length
        }
    },
    methods: {
        ...mapActions('user', ['saveNewPassword', 'logOut']),

        resetValidation() {
            this.resetPasswordError = false;
            this.$v.$reset();
        },

        redirectToLogin() {
            clearTimeout(this.redirectTimerId)
            const url = new URL(window.location.href)
            url.search = this.partnerId ? ("?partnerId=" + this.partnerId) : ""
            url.hash = "#/login"
            window.location.assign(url.toString())
        },

        changePassword() {
            if (this.$v.$invalid || this.resetPasswordLoading) {
                return
            }

            this.resetPasswordLoading = true;
            const password = this.password;
            const url = new URL(window.location.href);
            const resetPasswordToken = url.searchParams.get("token");
            const partnerId = this.partnerId;

            getCaptchaToken('password_change_on_reset')
                .then((token) => {
                    this.saveNewPassword({password: password, token: resetPasswordToken, captcha: token, partnerId: partnerId})
                        .then(response => {
                            if (!response.data.success) {
                                this.resetPasswordError = response.data.message
                            } else {
                                this.redirectTimerId = setTimeout(this.redirectToLogin, 5000)
                                this.successChanged = true
                            }
                        })
                        .catch(() => {
                            this.resetPasswordError = 'reset-password-error'
                        })
                        .finally(() => {
                            this.resetPasswordLoading = false
                        })
                })
                .catch(() => {
                    this.resetPasswordError = 'invalid-captcha'
                    this.resetPasswordLoading = false
                })
        },

        changeLocale
    }
}
</script>

<style scoped>

</style>