<template>
    <thead class="base-table-header">
        <tr>
            <th v-if="selectable" class="base-table-header-item-wrapper">
                <div class="base-table-header-item base-table-header-select-item">
                    <base-checkbox
                        @change="onSelect"
                        :value="selected"
                        :disabled="!multiple"
                        :semi-selected="semiSelected"
                    ></base-checkbox>
                </div>
            </th>

            <th
                v-for="(header, id) in headers"
                :key="id"
                class="base-table-header-item-wrapper"
            >
                <div class="base-table-header-item" :class="headersClasses[id]">
                    {{ header.title }}
                </div>
            </th>
        </tr>
    </thead>
</template>

<script>
import BaseCheckbox from "../BaseCheckbox.vue";

export default {
    name: "BaseTableHeader",
    components: {BaseCheckbox},
    props: {
        headers: {
            type: Array,
            default: []
        },
        selectable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false
        },
        semiSelected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        headersClasses() {
            return this.headers.map((header) => {
                const result = []

                if (header.align === 'center') {
                    result.push('base-table-header-item-align-center')
                } else if (header.align === 'right') {
                    result.push('base-table-header-item-align-right')
                } else {
                    result.push('base-table-header-item-align-left')
                }

                if (header.class) {
                    result.push(this.header.class)
                }

                return result
            })
        }
    },
    methods: {
        onSelect() {
            this.$emit("select")
        }
    }
}
</script>

<style scoped>

</style>