<template>
    <div class="phonebook-select-list">
        <ul>
            <slot v-for="item in items" :item="item">

            </slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "phonebookSelectList",
    props: {
        items: {
            type: Array,
            default: []
        }
    }
}
</script>

<style scoped>

</style>