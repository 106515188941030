<template>
    <li @click="onClick" :class="classes" class="phonebook-select-list-item">
        {{ item.name }}
    </li>
</template>

<script>

export default {
    name: "phonebookSelectListItem",
    props: {
        item: {
            type: Object
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                "phonebook-select-list-item-selected": this.selected
            }
        }
    },
    methods: {
        onClick() {
            this.$emit("click")
        }
    }
}
</script>

<style scoped>

</style>