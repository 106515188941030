<template>
    <div @click="onClick" :class="{'selected': selected}" class="sms-chat">
        <avatar
            size="medium"
            :src="contact.avatar"
            :text="contact.name"
            :color="contact.color"
        ></avatar>

        <div class="name-group">
            <span class="contact-name">{{ contact.name ? contact.name : formatNumber(number) }}</span>
            <span class="last-message">
                <span v-if="lastSms.type === SMS_TYPE_OUTBOUND" class="you">{{ $t("sms-chat.you") }}:</span>
                 {{ lastSms.text }}
            </span>
        </div>

        <div class="time-group">
            <span class="last-message-time">{{ date }}</span>
            <span v-show="numberOfUnreadMessages > 0" class="unread-messages-count">{{ numberOfUnreadMessages }}</span>
        </div>
    </div>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import {mapGetters} from "vuex";
import {SMS_TYPE_OUTBOUND} from "../../../store/modules/SMSes";
import {formatNumber} from "../../../utils/phoneNumbers";
import dayjsOverride from "../../../helpers/dayjsOverride";

export default {
    name: "SmsChat",
    components: {Avatar},
    props: {
        number: {
            type: String,
            default: ""
        },
        lastSms: {
            type: Object,
            default: {}
        },
        numberOfUnreadMessages: {
            type: Number,
            default: 0
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            SMS_TYPE_OUTBOUND
        }
    },
    computed: {
        ...mapGetters("details", ["getContactByNumber"]),
        ...mapGetters("user", ["languageCode"]),

        contact() {
            return this.getContactByNumber(this.number)
        },

        date() {
            return dayjsOverride(this.lastSms.date).locale(this.languageCode).calendar(null, {
                sameDay: `[${dayjsOverride(this.lastSms.date).format("LT")}]`,
                nextDay: 'dddd',
                nextWeek: 'D MMM YYYY',
                lastDay: `[${this.$t("panel.call-history.yesterday-call")}]`,
                lastWeek: 'dddd',
                sameElse: 'D MMM YYYY'
            })
        }
    },
    methods: {
        onClick() {
            this.$emit("click")
        },
        formatNumber
    }
}
</script>

<style scoped>

</style>